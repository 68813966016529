import classnames from 'classnames';
import { isNumber } from 'lodash';
import React, { useLayoutEffect, useRef, useState, useEffect } from 'react';
import './filter.scss';

const FILTER_DATAS = {
  OFFICE_MANAGEMENT_DATA: [
    { '등록된 매장순': 0 },
    { '진행률 낮은순': 1 },
    { '진행률 높은순': 2 },
    { 'ㄱ~ㅎ순': 3 },
    { '초대/가입중 매장만': 4 },
    { '계정연동 매장만': 11 },
    { '연동오류 매장만': 12 },
    { '계정연동Live 매장만': 13 },
    { '설치수집/검증 매장만': 6 },
    { 'DATA수신오류 매장만': 7 },
    { 'D/C설치Live 매장만': 14 },
    { '미납정지 매장만': 10 },
    { '정상분석 매장만': 8 },
    { '분석제외 매장만': 9 },
  ],

  USER_MANAGEMENT_DATA: ['등록 순', 'ㄱ~ㅎ 순'],
  RESOURCE_DAY: ['최근 날짜순', '과거 날짜순', '소비원가 높은순', '소비원 낮은순'],
  BRAND_DATA1: ['주문액 기준', '주문건 기준', '주문고객 기준'],
  BRAND_DATA2: [
    '최근 날짜순',
    '과거 날짜순',
    '주문액 높은순',
    '주문액 낮은순',
    '재주문액 높은순',
    '재주문액 낮은순',
    '신규주문액 높은순',
    '신규주문액 낮은순',
    '주문건 많은순',
    '주문건 적은순',
    '재주문건 많은순',
    '재주문건 적은순',
    '신규주문건 많은순',
    '신규주문건 적은순',
    '주문고객 많은순',
    '주문고객 적은순',
    '재주문고객 많은순',
    '재주문고객 적은순',
    '신규주문고객 많은순',
    '신규주문고객 적은순',
  ],
};

// TODO: [ 클래스 타입 정하기 ] classType 에는 기본 : 디폴트, 버튼형 select, 검색 및 select 화면
// input 데이터, 클래스타입, 이벤트 핸들러, 선택된 필터 view, 필터 내부 카운트, 필터 내부 custom content
const Filter = ({ inputType = '', classType = 'default', eventHandle, filterType = 0, count, cotent }) => {
  const ref = useRef();
  const [show, setShow] = useState(false); // 리스트 토글
  const [classes, setClasses] = useState({
    // 선택된 거 select 처리하기
    active: 0,
  });

  const Toggle = () => {
    setShow(!show);
  };

  const onClick = i => {
    if (isNumber(i) === false) {
      return;
    }
    eventHandle(i);
    setShow(!show);
    setClasses({
      ...classes,
      active: i,
    });
  };

  // 필터 범위밖에 클릭시 필터 닫히게 하기
  const checkClickOuter = e => {
    if (ref && ref.current && !ref.current.contains(e.target)) {
      setShow(false);
    }
  };

  useEffect(() => {
    setClasses({
      ...classes,
      active: filterType,
    });
  }, [filterType]);

  useLayoutEffect(() => {
    window.addEventListener('click', checkClickOuter);
    return () => window.removeEventListener('click', checkClickOuter);
  }, []);

  return (
    <div
      ref={ref}
      className={classnames('Filter', classType ? classType : 'default', show === true ? 'is-select' : '')}
    >
      <strong onClick={Toggle}>
        {FILTER_DATAS[inputType] && typeof FILTER_DATAS[inputType][0] === 'object' ? (
          <span>
            {FILTER_DATAS[inputType].map(item => {
              return Object.values(item)[0] === parseInt(classes.active) ? Object.keys(item)[0] : null;
            })}
          </span>
        ) : (
          <span>{FILTER_DATAS[inputType][classes.active]}</span>
        )}

        {count && <span className="count">{count}</span>}
      </strong>
      {inputType === 'PLACE_TYPE_DATA' && show && (
        <div className="list">
          {FILTER_DATAS[inputType].map((_item, i) => {
            return (
              <>
                {typeof _item === 'string' ? (
                  <ul>
                    <li>{_item}</li>
                  </ul>
                ) : (
                  <>
                    <div className="sub-title">{Object.keys(_item)}</div>
                    {Object.values(_item).map((item, i) => {
                      return (
                        <>
                          {item.map((item, i) => {
                            return (
                              <ul>
                                <li>{item}</li>
                              </ul>
                            );
                          })}
                        </>
                      );
                    })}
                  </>
                )}
              </>
            );
          })}
        </div>
      )}
      {inputType !== 'PLACE_TYPE_DATA' && show && (
        <div className="list">
          <ul>
            {FILTER_DATAS[inputType] &&
              FILTER_DATAS[inputType].map((_item, i) => {
                let value = null;
                let title = null;
                if (typeof _item === 'object') {
                  value = Object.values(_item)[0];
                  title = Object.keys(_item)[0];
                }

                return (
                  <li
                    key={i}
                    className={classnames(
                      '',
                      parseInt(classes.active) === (value === null ? i : value) ? 'is-active' : '',
                    )}
                    onClick={() => {
                      onClick(value === null ? i : value);
                    }}
                  >
                    {value === null ? _item : title}
                  </li>
                );
              })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Filter;
