import React from 'react';
import styled from 'styled-components';
import { content } from './AdvertismentContent';

export const Advertisment = () => {
  return <Wrapper dangerouslySetInnerHTML={{ __html: content }} />;
};

const Wrapper = styled.div`
  p, li, div, span {
    line-height: 1.6 !important;
  }

  span.c6 {
    margin-bottom: 16px;
    display: inline-block;
  }

  span.c4 {
    margin: 16px 0 8px;
    display: inline-block;
  }

  padding: 40px 32px 32px;
`;