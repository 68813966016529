// polyfills는 가장 처음에 import되어야함
import './polyfills';

import gtag, { install } from 'ga-gtag';
import { createBrowserHistory } from 'history';
import querystring from 'querystring';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import './assets/base.scss';
import { getMyUserData, setLocalData, setTabKeyByQuery, store } from './helpers';
import ChannelService from './helpers/ChannelChat';
import AppMain from './Layout/AppMain';
import * as serviceWorker from './serviceWorker';
import { checkReactNativeWebView } from './helpers/AppWebView';
import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientProvider } from 'react-query';
import { isMobile } from './constants';

const rootElement = document.getElementById('root');
const history = createBrowserHistory();

if (window.location) {
  var urlStr = window.location.toString().split('?');
  var query = querystring.parse(urlStr[urlStr.length - 1].slice(0));
  setTabKeyByQuery(query);

  if (query) {
    query['search_date'] && setLocalData('url_search_date', query['search_date']);
    query['select_office'] && setLocalData('url_select_office', query['select_office']);
  }
}

const setMobileViewPort = () => {
  let viewport = document.querySelector('meta[name=viewport]');

  const setZoomView = () => {
    viewport.setAttribute('content', 'width=1280');
  };

  const setCommonView = () => {
    viewport.setAttribute(
      'content',
      'width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no, user-scalable=no, viewport-fit=cover',
    );
  };

  if (isMobile()) {
    setCommonView();
    return;
  }

  if (
    (window.location.hash &&
      window.location.hash.indexOf('/mobile/') === -1 &&
      window.location.hash.indexOf('/auth/') === -1 &&
      window.location.hash.indexOf('/service/') === -1 &&
      window.location.hash.indexOf('/members/') === -1 &&
      window.location.hash.indexOf('/back/admin-signup') === -1 &&
      window.location.hash.indexOf('/marketboro/mobileMarketboroAgree') === -1 &&
      window.location.hash.indexOf('/policy') === -1 &&
      window.location.hash.indexOf('/event/') === -1 &&
      window.location.hash.indexOf('/redirect/') === -1) ||
    window.location.hash.indexOf('/service/serviceGuide') !== -1
  ) {
    setZoomView();
    return;
  } else {
    setCommonView();
  }

  window.dispatchEvent(new Event('resize'));
};

const locationGAKey = () => {
  const devKey = 'G-44KLWFDC00'; // GA dev 속성 생성후 devKey 교체 필요
  const liveKey = 'G-44KLWFDC00';

  var key = '';
  switch (window.location.host) {
    case 'data.attracker.shop':
      key = liveKey;
      break;
    case 'service.attracker.shop':
      key = liveKey;
      break;
    case 'data-dev.attracker.shop':
      key = devKey;
      break;
    default:
      key = devKey;
      break;
  }

  return key;
};

const getPlanName = () => {
  const userData = getMyUserData(false);
  let planType = '';

  let productCode = userData['product_code'] ? userData['product_code'] : userData['brand_product_code'];

  switch (productCode) {
    case 'P000F1':
      planType = 'sm-free';
      break;
    case 'P000B1':
      planType = 'sm-basic';
      break;
    case 'P000B2':
      planType = 'sm-basic6';
      break;
    case 'P000B3':
      planType = 'sm-basic12';
      break;
    case 'PF00F1':
      planType = 'fr-free';
      break;
    case 'PF0000':
      planType = 'fr-free-partner';
      break;
    case 'PF00F0':
      planType = 'fr-free';
      break;
    case 'PF00B1':
      planType = 'fr-basic';
      break;
    case 'PF00L1':
      planType = 'fr-light';
      break;
    case 'PF00P1':
      planType = 'fr-premium';
      break;
    case 'PM00B1':
      planType = 'sm-marketbom';
      break;
    default:
      planType = '';
      break;
  }

  return planType;
};

const handleChangePage = location => {
  window.scrollTo(0, 0);
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  let container = document.getElementById('container');
  if (container) {
    container.scrollTo(0, 0);
  }

  let userData = getMyUserData(false);
  let properties = {};
  if (userData) {
    properties = {
      user_auth: userData['auth_name'],
      app_type: checkReactNativeWebView() ? 'APP' : 'Web',
      plan_type: getPlanName(),
    };

    gtag('set', { user_id: userData['user_sno'] });
  } else {
    properties = {
      user_auth: '비회원',
      app_type: checkReactNativeWebView() ? 'APP' : 'Web',
      plan_type: null,
    };
  }

  gtag('set', 'user_properties', properties);

  var urlStr = location.hash.toString().split('?');
  var query = querystring.parse(urlStr[urlStr.length - 1].slice(0));
  setTabKeyByQuery(query);

  gtag('event', 'page_view', {
    page_path: location.hash,
    send_to: locationGAKey(),
  });

  // 채널톡 생성
  ChannelService.boot();

  setMobileViewPort();
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      onError: err => {
        // console.log(`error message : ${err}`);
      },
    },
  },
});

install(locationGAKey());
handleChangePage(window.location);

window.gtag &&
  window.gtag('set', 'linker', {
    domains: ['www.attracker.shop', 'www.service.attracker.shop'],
  });

history.listen(location => {
  handleChangePage(location);
});

const renderApp = Component => {
  ReactDOM.render(
    <Provider store={store}>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <HashRouter history={history}>
            <Component />
          </HashRouter>
        </QueryClientProvider>
      </RecoilRoot>
    </Provider>,
    rootElement,
  );
};

renderApp(AppMain);

if (module.hot) {
  module.hot.accept();
}

serviceWorker.unregister();
