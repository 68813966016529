import React from 'react';
import Chart from 'react-apexcharts';

import { comparePercent, isContainDisableLink, mComma } from '../../helpers';
import DayDeliveryModal from '../Modal/DayDeliveryModal';
import './menuDataTable.scss';

const GraphDataTable = props => {
  const {
    list = [],
    onClick = () => {},
    viewOptions = false,
    titleKeyName = '',
    titleFormmater = undefined,
    isMenuHeader = false,
  } = props;

  return (
    <>
      <div className="menu-data-table graph-data-table">
        <div className="menu-data-table__title">
          <div className="data-table inline-table">
            <div className="data-row">
              <span className="option-name">전체 매출</span>
              <span className="option-name">매달 매출</span>
              <span className="option-name">홀 매출</span>
              <span className="option-name">배달 점유율</span>
              <span className="option-name">홀 점유율</span>
            </div>
          </div>
        </div>
        {list &&
          list.map((item, i) => {
            let options = viewOptions && item['options'] ? item['options'] : [];
            let subList = new Array(options.length > 2 ? options.length : 2).fill(0);

            return (
              <div
                className="table-wrap"
                onClick={e => isContainDisableLink(e) === false && onClick(item['office_sno'])}
              >
                <div className="l-table">
                  <div className="l-data">
                    <div className="data-table">
                      <div className="data-row title">
                        <div className="table-title">
                          <span className="numbering">{i + 1}</span>
                          <p className="item">
                            {titleFormmater
                              ? titleFormmater(item[titleKeyName])
                              : item[titleKeyName]}
                          </p>
                          <span className="people">
                            {mComma(item['customer_cnt'])}
                            <div className="tooltip">
                              <div>- 주소지미상 고객수는 합산되지 않음</div>
                              <div>- 기간내 중복 고객은 한명으로 정의되어 추가 합산되지 않음</div>
                            </div>
                          </span>
                        </div>
                        <span className="t-bold">
                          {/* <span>{mComma(item['order_customer'])}</span> */}
                          <span>9,807,000원</span><br />
                          1,278건
                        </span>
                        <span className="t-bold">
                          {/* <span> {mComma(item['order_sale'])}</span>원 */}
                          <span>9,807,000원</span><br />
                          245건
                        </span>
                        <span className="t-bold">
                          {/* <span> {mComma(item['order_sale'])}</span>원 */}
                          <span>9,807,000원</span><br />
                          903건
                        </span>
                      </div>

                      {/* {subList.map((_key, i) => {
                        let option = options[i];
                        return (
                          <div className={option ? 'data-row' : 'option-empty'}>
                            <div className={'option-name'}>
                              {option ? option['option_menu_name'] : ''}
                            </div>
                            <span className="option-count">
                              {option ? mComma(option['sale_cnt']) + '건' : ''}
                            </span>
                            <span className="option-count">
                              {option
                                ? option['sale_price'] === 0
                                  ? '-'
                                  : mComma(option['sale_price']) + '원'
                                : ''}
                            </span>
                          </div>
                        );
                      })} */}
                    </div>
                  </div>

                  <div className="r-data">
                    <div className="data-table">
                      <div className="title-data data-row">
                        <span className="color-green">74%</span>
                        <div className="progress-wrap">
                          {/* <ProgressBar now={74} /> */}
                        </div>
                        <span className="color-blue">26%</span>
                      </div>
                      {/* {subList.map((_key, i) => {
                        return (
                          <div className="sub-data data-row">
                            {i === 0 &&
                              ['re_order_sale', 'new_order_sale', 'unverified_order_sale'].map(
                                key => {
                                  return <span className="option-count">{mComma(item[key])}</span>;
                                },
                              )}
                            {i === 1 &&
                              ['re_order_sale', 'new_order_sale', 'unverified_order_sale'].map(
                                key => {
                                  return (
                                    <span className="option-count">
                                      {comparePercent(item[key], item['order_sale'], 1)}%
                                    </span>
                                  );
                                },
                              )}
                          </div>
                        );
                      })} */}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      </>
  );
};


export default GraphDataTable;
