import classnames from 'classnames';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { brandActions } from '../../actions';
import { isMobile } from '../../constants';
import { getMyUserData } from '../../helpers';
import ChannelChat from '../../helpers/ChannelChat';
import { dateToStr } from '../../helpers/DateConverter';

const ToastNonPayment = () => {
  const userData = getMyUserData(false);
  if (!userData) {
    return null;
  }

  const dispatch = useDispatch();  
  const { nonPaymentCnt } = useSelector(state => state.getFranchiseOfficeStatus);
  useEffect(() => {
    dispatch(brandActions.getFranchiseOfficeStatus(userData.brand_code));
  }, []);

  let showNonPayment = userData['show_non_payment'];
  let lastPaymentYM = userData['non_payment_ym'];

  if (showNonPayment === 0 || !showNonPayment || !lastPaymentYM) {
    return null;
  }

  return (
    <div className={classnames(`toast-ui period is-ani is-date`)}>
      {/* 미납안내 */}
      {showNonPayment && showNonPayment === 1 && (
        <div className="desc" style={{ width: 'initial' }}>
          {isMobile() ? (
            <>
              <strong>2개월이상 미납으로 서비스가 곧 중지됩니다.</strong>
            </>
          ) : (
            <>
              <div>
                이용료 미납 안내입니다. &nbsp;&nbsp;|&nbsp;&nbsp;
                <strong>2개월이상 미납으로 서비스가 곧 중지됩니다.</strong>
              </div>
            </>
          )}
        </div>
      )}
      {/* 이용정지안내  */}
      {showNonPayment && showNonPayment === 11 && (
        <div className="desc" style={{ width: 'initial' }}>
          {isMobile() ? (
            <>
              <strong>이용료 미납으로 인한 서비스 정지 상태입니다.</strong>
            </>
          ) : (
            <>
              <div>
                현재 <strong>{nonPaymentCnt ? nonPaymentCnt : 0}개 매장의 이용료 미납</strong>으로 인한{' '}
                <strong>서비스 정지 상태</strong>입니다. &nbsp;&nbsp;|&nbsp;&nbsp;{' '}
                <strong>미납 금액을 정산해주세요.</strong>
              </div>
            </>
          )}
        </div>
      )}
      {/* 미납안내 */}
      {showNonPayment && showNonPayment === 1 && (
        <>
          <div className="sub-desc">
            (이용료 미납 기간 : {dateToStr(lastPaymentYM, 'YYYY.MM.DD')} ~ {dateToStr(null, 'YYYY.MM.DD')})
          </div>
          <button className="inquiry-btn" onClick={() => ChannelChat.openChat()}>
            고객센터 문의하기
          </button>
        </>
      )}
      {/* 이용정지안내  */}
      {showNonPayment && showNonPayment === 11 && (
        <>
          <div className="sub-desc">
            ( {nonPaymentCnt ? nonPaymentCnt : 0}개 매장에 대한 매출은 제외되어 분석됩니다. )
          </div>
          <button className="inquiry-btn" onClick={() => ChannelChat.openChat()}>
            미납 이용료
          </button>
        </>
      )}
    </div>
  );
};

export default ToastNonPayment;
