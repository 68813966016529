import { getMyUserData, isMarketbomLink } from './LoadData';

export const paymentPlanProcess = () => {
  const userData = getMyUserData(false);

  if (isMarketbomLink()) {
    return false
  }

  if (userData && userData.service_open_status) {
    return userData.service_open_status !== 0;
  } else {
    return false;
  }
};

export const processingText = (text, changeText) => {
  let result = text;

  if (paymentPlanProcess()) {
    result = changeText ? changeText : '?';
  }

  return result;
};
