export const TEST_ATOM_KEY = {
  GET_TEST: 'GET_TEST',
};

export const USER_ATOM_KEY = {
  ADDITIONAL_PAGE_INFO: 'ADDITIONAL_PAGE_INFO',
  ADDITIONAL_ADDRESS_INFO: 'ADDITIONAL_ADDRESS_INFO',
  ADDITIONAL_BUSINESS_INFO: 'ADDITIONAL_BUSINESS_INFO',
};

export const COMMON_ATOM_KEY = {
  VISIBILITY_FLAG: 'VISIBILITY_FLAG',
  MODAL_INFO: 'MODAL_INFO',
  NOTICE_FILTER_TYPE_KEY: 'NOTICE_FILTER_TYPE_KEY',
};

export const FRANCHISE_ATOM_KEY = {
  MOBILE: {
    REPORT_MODAL_INFO: 'REPORT_MODAL_INFO',
    COMMENT_INPUT_INFO: 'COMMENT_INPUT_INFO',
    CONFIRM_CHECKED: 'CONFIRM_CHECKED',
  },
  PC: {
    COMMENT_EDIT_STATE: 'COMMENT_EDIT_STATE',
    NOTICE_READ_LIST_FILTER_KEY: 'NOTICE_READ_LIST_FILTER_KEY',
    NOTICE_READ_LIST: 'NOTICE_READ_LIST',
    NOTICE_LIST: 'NOTICE_LIST',
    NOTICE_DATE: 'NOTICE_DATE',
    NOTICE_INFO: 'NOTICE_INFO'
  },
};

export const CUSTOMER_REVIEW_ATOM_KEY = {
  MOBILE: {
    SELECTED_OFFICE_INFO: 'SELECTED_OFFICE_INFO',
    PREVIOUS_SELECTED_OFFICE_INFO: 'PREVIOUS_SELECTED_OFFICE_INFO',
    BACK_REVIEW_PAGE: 'BACK_REVIEW_PAGE',
    AUTO_CUSTOMER_REVIEW_TITLE_INFO: 'AUTO_CUSTOMER_REVIEW_TITLE_INFO',
    TMP_NEW_OFFICE: 'TMP_NEW_OFFICE',
    NEW_AUTO_REVIEW_STATUS: 'NEW_AUTO_REVIEW_STATUS'
  }
}