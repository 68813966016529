import React, { Fragment } from 'react';
import isEqual from 'react-fast-compare';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { Col, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { authActions } from '../../actions';
import { authConstants } from '../../constants';
import { getMyUserData, replaceHypen, rules, setMyUserData, validation } from '../../helpers';
import { CountDown } from '../CountDown';

class VerifyCodeModal extends React.Component {
  constructor(props) {
    super(props)

    var userData = getMyUserData();

    this.state = {
      userData: userData,
      userSno: userData['user_sno'],
      inputCode: '',
      authCode: '',
      authToken: undefined,
      modal: props.modal,
      changedMobile: replaceHypen(props.changedMobile),
      isCorrect: false
    }

    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this)
    this.handleEnter = this.handleEnter.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSendPhoneAuthNumber = this.handleSendPhoneAuthNumber.bind(this)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    var returnState = {};
    if (nextProps.sendPhoneAuthNumber !== undefined && !isEqual(nextProps.sendPhoneAuthNumber, prevState.sendPhoneAuthNumber)) {
      returnState.sendPhoneAuthNumber = nextProps.sendPhoneAuthNumber;
      if (nextProps.sendPhoneAuthNumber.result) {
        returnState.authToken = nextProps.sendPhoneAuthNumber.result;
      }
    }

    if (prevState.inputCode.length === 4) {
      returnState.isCorrect = true;
    } else {
      returnState.isCorrect = false;
    };

    if (nextProps.checkAuthNumber !== undefined && !isEqual(nextProps.checkAuthNumber, prevState.checkAuthNumber)) {
      returnState.checkAuthNumber = nextProps.checkAuthNumber;
      if (nextProps.checkAuthNumber['result'] === 1) {
        if (window.confirm('휴대전화 번호를 변경하시겠습니까?')) {
          nextProps.dispatch(authActions.changeMobile(prevState.userSno, prevState.changedMobile))
        }
      } else {
        alert('인증번호가 만료되었거나, 잘못 입력하셨습니다. 다시 확인해주세요.')
      }
    }
    if (nextProps.changeMobile !== undefined && !isEqual(nextProps.changeMobile, prevState.changeMobile)) {
      if (nextProps.changeMobile.result === 1) {
        alert('휴대전화 번호가 변경되었습니다.');
        returnState.changeMobile = nextProps.changeMobile;
        var userData = prevState.userData;
        userData['user_phone'] = nextProps.changedMobile;
        setMyUserData(userData);
        nextProps.onRegistStep(0);
        returnState.modal = false;
      } else if (nextProps.changeMobile.result === 1001) {
        alert('이미 등록된 휴대전화 번호 입니다.')
        returnState.changeMobile = nextProps.changeMobile;
        nextProps.onRegistStep(0);
        returnState.modal = false;
      }
    }
    return returnState ? returnState : null;
  }

  componentDidMount() {
    if (this.state.changedMobile) {
      this.handleSendPhoneAuthNumber(this.state.changedMobile);
    }
  }

  componentDidUpdate() {
    const { authCode, inputCode } = this.state;

    if (authCode !== inputCode && inputCode.length === 4) {
      this.setState({ authCode: inputCode });
    }
  }

  componentWillUnmount() {
    this.props.dispatch({ type: authConstants.RESETSTATE })
    this.props.onRegistStep(0);
    this.props.toggle({ target: { name: "mobile" } })
  }

  toggle() {
    this.setState({ modal: !this.state.modal });

    this.props.toggle({ target: { name: "mobile" } });
  }

  handleChange(e) {
    e.preventDefault();
    this.setState({ inputCode: e.target.value })
  }

  handleEnter(e) {
    if (e.key === 'Enter') {
      this.handleSubmit();
    }
  }

  handleSendPhoneAuthNumber(phoneNumber, recount = false) {
    if (phoneNumber) {
      this.props.dispatch(authActions.sendPhoneAuthNumber(phoneNumber, 2));
    }

    if (recount === true) {
      this.refs.refCountDown.reCount();
    }
  }

  handleSubmit() {
    const { inputCode, authCode, authToken } = this.state
    if (!validation(inputCode, rules.num)) {
      return;
    }
    if (authToken) {
      this.props.dispatch(authActions.checkAuthNumber(authToken, authCode));
    }
  }

  render() {

    const { inputNum, isCorrect } = this.state

    const modalStyle = {
      header: {
        paddingBottom: "4px"
      },
      isTrue: {
        backgroundColor: "#adb5bd",
        marginBottom: "4px"
      },
      isFalse: {
        backgroundColor: "#adb5bd",
        marginBottom: "4px",
        opacity: "0.5",
        cursor: "default"
      },
      footer: {
        textAlign: "right",
        display: "block",
        height: "72px"
      },
      countDown: {
        marginLeft: "4px"
      }
    }

    return (
      <Fragment>
        <Modal isOpen={this.state.modal} className={this.props.className} backdrop="static">
          <ModalHeader toggle={this.toggle}>인증번호 입력</ModalHeader>
          <ModalBody >
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <div className="modal-usr-guide">
                    인증번호를 입력하세요.
                  </div>
                  <NumberFormat
                    className="form-control"
                    type="text"
                    name="Input num"
                    value={inputNum}
                    placeholder="인증번호 입력"
                    maxLength="4"
                    onChange={this.handleChange}
                    onKeyPress={this.handleEnter}
                    autoComplete="off"
                  />
                </FormGroup>
              </Col>
            </Row>
            <div style={modalStyle.countDown}>
              <CountDown ref='refCountDown' />
              <a href="javascript:void(0);" className="text-primary container" onClick={() => {
                if (window.confirm('인증번호를 재발송 하시겠습니까?')) {
                  this.handleSendPhoneAuthNumber(this.state.changedMobile, true)
                }
              }}>인증번호 재발송 요청</a>
            </div>
          </ModalBody>
          <ModalFooter style={modalStyle.footer}>
            <button className="ml-auto badge badge-secondary modal-edt-btn" style={isCorrect ? modalStyle.isTrue : modalStyle.isFalse} onClick={isCorrect ? this.handleSubmit : undefined}>인증하기</button>
          </ModalFooter>
        </Modal>
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { sendPhoneAuthNumber } = state.sendPhoneAuthNumber;
  const { checkAuthNumber } = state.checkAuthNumber;
  const { changeMobile } = state.changeMobile;

  return {
    sendPhoneAuthNumber,
    checkAuthNumber,
    changeMobile
  };
}

export default connect(mapStateToProps)(VerifyCodeModal);