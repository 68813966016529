import { globalCount, userConstants } from '../constants';

export function companyUserList(state = {}, action) {
  switch (action.type) {
    case userConstants.COMPANY_USER_LIST_REQUEST:
      return {
        loading: true,
      };
    case userConstants.COMPANY_USER_LIST_SUCCESS:
      return {
        companyUserList: action.result['result'],
      };
    case userConstants.COMPANY_USER_LIST_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function franchiseBrandList(state = {}, action) {
  switch (action.type) {
    case userConstants.FRANCHISE_BRAND_LIST_REQUEST:
      return {
        loading: true,
      };
    case userConstants.FRANCHISE_BRAND_LIST_SUCCESS:
      return {
        franchiseBrandList: action.result['result'],
      };
    case userConstants.FRANCHISE_BRAND_LIST_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function userProfileChange(state = {}, action) {
  switch (action.type) {
    case userConstants.RESETSTATE:
      return {};
    case userConstants.USER_PROFILE_CHANGE_REQUEST:
      return {
        loading: true,
      };
    case userConstants.USER_PROFILE_CHANGE_SUCCESS:
      return {
        userProfileChange: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case userConstants.USER_PROFILE_CHANGE_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function myUserData(state = {}, action) {
  switch (action.type) {
    case userConstants.GET_MY_USER_REQUEST:
      return {
        loading: true,
      };
    case userConstants.GET_MY_USER_SUCCESS:
      return {
        myUserData: action.result['result'],
      };
    case userConstants.GET_MY_USER_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function serviceNoticeList(state = {}, action) {
  switch (action.type) {
    case userConstants.SERVICE_NOTICE_LIST_REQUEST:
      return {
        loading: true,
      };
    case userConstants.SERVICE_NOTICE_LIST_SUCCESS:
      return {
        serviceNoticeList: action.result['result'],
        noticeToastList: action.result['toast'],
      };
    case userConstants.SERVICE_NOTICE_LIST_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function getFaqList(state = {}, action) {
  switch (action.type) {
    case userConstants.GET_FAQ_LIST_REQUEST:
      return {
        loading: true,
      };
    case userConstants.GET_FAQ_LIST_SUCCESS:
      return {
        getFaqList: action.result['result'],
      };
    case userConstants.GET_FAQ_LIST_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function completeTutorial(state = {}, action) {
  switch (action.type) {
    case userConstants.COMPLETE_TUTORIAL_REQUEST:
      return {
        loading: true,
      };
    case userConstants.COMPLETE_TUTORIAL_SUCCESS:
      return {
        completeTutorial: action.result['result'],
      };
    case userConstants.COMPLETE_TUTORIAL_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function registUser(state = {}, action) {
  switch (action.type) {
    case userConstants.REGIST_USER_RESET:
      return {};
    case userConstants.REGIST_USER_REQUEST:
      return {
        loading: true,
      };
    case userConstants.REGIST_USER_SUCCESS:
      return {
        registUser: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case userConstants.REGIST_USER_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function getUserList(state = {}, action) {
  switch (action.type) {
    case userConstants.GET_USER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.GET_USER_LIST_SUCCESS:
      return {
        userList: action.result['result']['userList'],
        totalCnt: action.result['result']['totalCnt'],
        beforeSignUpCnt: action.result['result']['beforeSignUpCnt'],
        afterSignUpCnt: action.result['result']['afterSignUpCnt'],
      };
    case userConstants.GET_USER_LIST_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function createMultiUser(state = {}, action) {
  switch (action.type) {
    case userConstants.CREATE_MULTI_USER_RESET:
      return {};
    case userConstants.CREATE_MULTI_USER_REQUEST:
      return {
        loading: true,
      };
    case userConstants.CREATE_MULTI_USER_SUCCESS:
      return {
        createMultiUser: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case userConstants.CREATE_MULTI_USER_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function createSingleUser(state = {}, action) {
  switch (action.type) {
    case userConstants.CREATE_SINGLE_USER_RESET:
      return {};
    case userConstants.CREATE_SINGLE_USER_REQUEST:
      return {
        loading: true,
      };
    case userConstants.CREATE_SINGLE_USER_SUCCESS:
      return {
        createSingleUser: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case userConstants.CREATE_SINGLE_USER_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function inviteSingleUser(state = {}, action) {
  switch (action.type) {
    case userConstants.INVITE_SINGLE_USER_RESET:
      return {};
    case userConstants.INVITE_SINGLE_USER_REQUEST:
      return {
        loading: true,
      };
    case userConstants.INVITE_SINGLE_USER_SUCCESS:
      return {
        inviteSingleUser: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case userConstants.INVITE_SINGLE_USER_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function inviteMultiUser(state = {}, action) {
  switch (action.type) {
    case userConstants.INVITE_MULTI_USER_RESET:
      return {};
    case userConstants.INVITE_MULTI_USER_REQUEST:
      return {
        loading: true,
      };
    case userConstants.INVITE_MULTI_USER_SUCCESS:
      return {
        inviteMultiUser: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case userConstants.INVITE_MULTI_USER_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function editUserInfo(state = {}, action) {
  switch (action.type) {
    case userConstants.EDIT_USER_INFO_RESET:
      return {};
    case userConstants.EDIT_USER_INFO_REQUEST:
      return {
        loading: true,
      };
    case userConstants.EDIT_USER_INFO_SUCCESS:
      return {
        editUserInfo: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case userConstants.EDIT_USER_INFO_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function disableUser(state = {}, action) {
  switch (action.type) {
    case userConstants.DISABLE_USER_RESET:
      return {};
    case userConstants.DISABLE_USER_REQUEST:
      return {
        loading: true,
      };
    case userConstants.DISABLE_USER_SUCCESS:
      return {
        disableUser: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case userConstants.DISABLE_USER_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function editUserAuth(state = {}, action) {
  switch (action.type) {
    case userConstants.EDIT_USER_AUTH_RESET:
      return {};
    case userConstants.EDIT_USER_AUTH_REQUEST:
      return {
        loading: true,
      };
    case userConstants.EDIT_USER_AUTH_SUCCESS:
      return {
        editUserAuth: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case userConstants.EDIT_USER_AUTH_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function editUserAuthInfo(state = {}, action) {
  switch (action.type) {
    case userConstants.EDIT_USER_ACCESS_AUTH_RESET:
      return {};
    case userConstants.EDIT_USER_ACCESS_AUTH_REQUEST:
      return {
        loading: true,
      };
    case userConstants.EDIT_USER_ACCESS_AUTH_SUCCESS:
      return {
        editUserAuthInfo: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
        loading: false
      };
    case userConstants.EDIT_USER_ACCESS_AUTH_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function withdrawalRequest(state = {}, action) {
  switch (action.type) {
    case userConstants.WITHDRAWL_USER_REQUEST:
      return {
        loading: true,
      };
    case userConstants.WITHDRAWL_USER_SUCCESS:
      return {
        withdrawalRequest: action.result['result'],
      };
    case userConstants.WITHDRAWL_USER_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function withdrawalCancel(state = {}, action) {
  switch (action.type) {
    case userConstants.WITHDRAWL_CANCEL_USER_REQUEST:
      return {
        loading: true,
      };
    case userConstants.WITHDRAWL_CANCEL_USER_SUCCESS:
      return {
        withdrawalCancel: action.result['result'],
      };
    case userConstants.WITHDRAWL_CANCEL_USER_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}