import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { brandActions, commonActions } from '../actions';
import { getMyUserData, isSystemLink } from '../helpers';
import { isMobile } from '../constants';

// AuthType 에 따른 Action
// 현재는 마스터 관리자가 매장등록을 하지 않은 상태에서만 동작
const CheckAuthTypeAction = () => {
  const userData = getMyUserData(false);
  if (!userData || isSystemLink() === true) {
    return null;
  }

  const dispatch = useDispatch();
  let location = useLocation();

  const { managedDatas: managedDatas = null } = useSelector(state => state.getFranchiseOfficeStatus);
  const { masterManagerState: masterManagerState = null } = useSelector(state => state.masterManagerState);
  const masterManagerPath = '/manage/office';

  useEffect(() => {
    if (userData && userData.auth_type == 'A1') {
      dispatch(brandActions.getFranchiseOfficeStatus(userData.brand_code));
    }
  }, []);

  useEffect(() => {
    // 마스터 관리자의 경우 등록된 매장이 없을때 다른 페이지 접근을 할 수 없음
    if (managedDatas && userData && userData.auth_type == 'A1') {
      if (managedDatas.length === 0 && isMobile() === false) {
        dispatch(commonActions.masterManagerState(0));
      } else {
        dispatch(commonActions.masterManagerState(1));
      }
    }
  }, [managedDatas]);

  useEffect(() => {
    if (masterManagerState === 0) {
      // 매장 관리/현황 메뉴만 active 시킨다.
      dispatch(commonActions.activeNavItemOnly(masterManagerPath));

      window.location.href = `#${masterManagerPath}`;
    } else {
      // deactive
      dispatch(commonActions.activeNavItemOnly(null));
    }
  }, [masterManagerState]);

  useEffect(() => {
    const whiteList = ['/mobile/transformView', '/auth/login', masterManagerPath];

    if (masterManagerState === 0) {
      if (whiteList.indexOf(location.pathname) === -1) {
        window.location.href = `#${masterManagerPath}`;
      }
    }
  }, [location]);

  return <></>;
};

export default CheckAuthTypeAction;
