import _ from 'lodash';
import { DELIVERY_FILTER_WORDS } from '../../../../constants';
import {
  arrayToObject,
  arrsEachSum,
  comparePercent,
  convertForFindName,
  filterDisableConts,
  getDataKeyByViewType,
  getFields,
  groupCount,
  mComma,
  objectDesc2,
  removeSpace,
  replaceBrandName,
  sortAsc,
  sortDesc,
  sumItems,
  getAverage,
  dateToStr2,
} from '../../../../helpers';

export const TEST_ORDER_MARKERS = [
  {
    tdu_sno: -1,
    office_sno: 21,
    addr_geo_x: 0,
    addr_geo_y: 0,
    user_phone: '',
    address: '',
    addr_detail: '',
    first_sale_date: '',
    last_sale_date: '',
    pay_amount: 138000,
    sale_count: 4,
    distance: 0,
    key: '0,0',
  },
  {
    tdu_sno: 60000,
    office_sno: 21,
    addr_geo_x: 127.05078679783,
    addr_geo_y: 37.5017583569333,
    user_phone: '050-7946-5565',
    address: '서울특별시 강남구 대치동 선릉로76길 5 5 피라니아 빌리지',
    addr_detail: '',
    first_sale_date: '2019-11-01 14:19:00',
    last_sale_date: '2021-03-27 16:35:20',
    pay_amount: 41000,
    sale_count: 1,
    distance: 1038.72,
    key: '37.5017583569333,127.05078679783',
  },
  {
    tdu_sno: 60089,
    office_sno: 21,
    addr_geo_x: 127.063295073911,
    addr_geo_y: 37.5040904265226,
    user_phone: '050-37362-3532',
    address: '서울특별시 강남구 대치동 영동대로71길 22 애드윈빌딩',
    addr_detail: '',
    first_sale_date: '2019-11-01 22:34:00',
    last_sale_date: '2021-03-23 15:04:00',
    pay_amount: 32500,
    sale_count: 1,
    distance: 502.68,
    key: '37.5040904265226,127.063295073911',
  },
  {
    tdu_sno: 60220,
    office_sno: 21,
    addr_geo_x: 127.060186229557,
    addr_geo_y: 37.5034425165765,
    user_phone: '0504-4819-8482',
    address: '서울특별시 강남구 대치동 역삼로 520 MS빌딩',
    addr_detail: '',
    first_sale_date: '2019-11-02 21:23:00',
    last_sale_date: '2021-03-23 18:11:15',
    pay_amount: 34500,
    sale_count: 1,
    distance: 460.02,
    key: '37.5034425165765,127.060186229557',
  },
  {
    tdu_sno: 60534,
    office_sno: 21,
    addr_geo_x: 127.054540237524,
    addr_geo_y: 37.50240865135,
    user_phone: '010-2945-6166',
    address: '서울특별시 강남구 대치동 역삼로71길 7 메트로빌',
    addr_detail: '',
    first_sale_date: '2019-11-06 11:18:00',
    last_sale_date: '2021-03-29 18:02:56',
    pay_amount: 19000,
    sale_count: 1,
    distance: 746.82,
    key: '37.50240865135,127.054540237524',
  },
  {
    tdu_sno: 60555,
    office_sno: 21,
    addr_geo_x: 127.061617564506,
    addr_geo_y: 37.4940924880388,
    user_phone: '010-2021-4746',
    address: '서울특별시 강남구 대치동 남부순환로 2937 까치빌딩',
    addr_detail: '',
    first_sale_date: '2019-11-06 13:37:00',
    last_sale_date: '2021-03-24 19:19:19',
    pay_amount: 34500,
    sale_count: 1,
    distance: 620.02,
    key: '37.4940924880388,127.061617564506',
  },
  {
    tdu_sno: 60688,
    office_sno: 21,
    addr_geo_x: 127.05226450027,
    addr_geo_y: 37.5026254709801,
    user_phone: '050-71223-0213',
    address: '서울특별시 강남구 대치동 역삼로65길 21 ',
    addr_detail: '',
    first_sale_date: '2019-11-07 19:00:00',
    last_sale_date: '2021-03-23 19:11:20',
    pay_amount: 47000,
    sale_count: 2,
    distance: 941.67,
    key: '37.5026254709801,127.05226450027',
  },
  {
    tdu_sno: 60809,
    office_sno: 21,
    addr_geo_x: 127.058365609066,
    addr_geo_y: 37.5003652342291,
    user_phone: '050-37369-9162',
    address: '서울특별시 강남구 대치동 삼성로63길 16 17',
    addr_detail: '',
    first_sale_date: '2019-11-08 20:28:00',
    last_sale_date: '2021-03-25 21:07:55',
    pay_amount: 34500,
    sale_count: 1,
    distance: 352.44,
    key: '37.5003652342291,127.058365609066',
  },
  {
    tdu_sno: 60833,
    office_sno: 21,
    addr_geo_x: 127.059323005294,
    addr_geo_y: 37.4976174994655,
    user_phone: '050-4064-37656',
    address: '서울특별시 강남구 대치동 도곡로78길 7 ',
    addr_detail: '',
    first_sale_date: '2019-11-09 11:15:00',
    last_sale_date: '2021-03-26 21:37:59',
    pay_amount: 21000,
    sale_count: 1,
    distance: 343.23,
    key: '37.4976174994655,127.059323005294',
  },
  {
    tdu_sno: 61209,
    office_sno: 21,
    addr_geo_x: 127.057183986012,
    addr_geo_y: 37.4971760699869,
    user_phone: '050-4472-76061',
    address: '서울특별시 강남구 대치동 삼성로57길 48 아크로빌',
    addr_detail: '',
    first_sale_date: '2019-11-10 12:02:00',
    last_sale_date: '2021-03-26 13:53:04',
    pay_amount: 18000,
    sale_count: 1,
    distance: 525,
    key: '37.4971760699869,127.057183986012',
  },
  {
    tdu_sno: 61265,
    office_sno: 21,
    addr_geo_x: 127.054891247787,
    addr_geo_y: 37.5025205738702,
    user_phone: '0504-4717-2727',
    address: '서울특별시 강남구 대치동 삼성로75길 41 ',
    addr_detail: '',
    first_sale_date: '2019-11-10 18:32:00',
    last_sale_date: '2021-03-25 11:29:09',
    pay_amount: 29500,
    sale_count: 1,
    distance: 724.11,
    key: '37.5025205738702,127.054891247787',
  },
  {
    tdu_sno: 61340,
    office_sno: 21,
    addr_geo_x: 127.059770669616,
    addr_geo_y: 37.5043732859489,
    user_phone: '010-4052-2872',
    address: '서울특별시 강남구 대치동 역삼로83길 12 ',
    addr_detail: '',
    first_sale_date: '2019-11-11 15:51:00',
    last_sale_date: '2021-03-24 22:22:19',
    pay_amount: 18000,
    sale_count: 1,
    distance: 569.61,
    key: '37.5043732859489,127.059770669616',
  },
  {
    tdu_sno: 61472,
    office_sno: 21,
    addr_geo_x: 127.055796012442,
    addr_geo_y: 37.5040636625307,
    user_phone: '050-37385-5445',
    address: '서울특별시 강남구 대치동 삼성로81길 23 ',
    addr_detail: '',
    first_sale_date: '2019-11-13 11:36:00',
    last_sale_date: '2021-03-29 15:54:45',
    pay_amount: 18000,
    sale_count: 1,
    distance: 752.43,
    key: '37.5040636625307,127.055796012442',
  },
  {
    tdu_sno: 61664,
    office_sno: 21,
    addr_geo_x: 127.058453392715,
    addr_geo_y: 37.4972064433283,
    user_phone: '050-4472-40544',
    address: '서울특별시 강남구 대치동 삼성로57길 33 1 리치빌',
    addr_detail: '',
    first_sale_date: '2019-11-15 17:23:00',
    last_sale_date: '2021-03-25 13:06:32',
    pay_amount: 41000,
    sale_count: 1,
    distance: 431.4,
    key: '37.4972064433283,127.058453392715',
  },
  {
    tdu_sno: 61672,
    office_sno: 21,
    addr_geo_x: 127.058791438668,
    addr_geo_y: 37.4999901144861,
    user_phone: '010-9122-2900',
    address: '서울특별시 강남구 대치동 삼성로63길 16 4',
    addr_detail: '',
    first_sale_date: '2019-11-15 18:33:00',
    last_sale_date: '2021-03-25 13:45:35',
    pay_amount: 34500,
    sale_count: 1,
    distance: 308.06,
    key: '37.4999901144861,127.058791438668',
  },
  {
    tdu_sno: 61880,
    office_sno: 21,
    addr_geo_x: 127.057799888858,
    addr_geo_y: 37.4989886850265,
    user_phone: '010',
    address: '서울특별시 강남구 대치동 삼성로61길 30 1',
    addr_detail: '',
    first_sale_date: '2019-11-17 19:42:00',
    last_sale_date: '2021-03-24 13:03:35',
    pay_amount: 29500,
    sale_count: 1,
    distance: 399.86,
    key: '37.4989886850265,127.057799888858',
  },
  {
    tdu_sno: 62640,
    office_sno: 21,
    addr_geo_x: 127.060914012298,
    addr_geo_y: 37.5044387455945,
    user_phone: '050-37388-9148',
    address: '서울특별시 강남구 대치동 역삼로87길 7 라임',
    addr_detail: '',
    first_sale_date: '2019-11-18 22:38:00',
    last_sale_date: '2021-03-23 13:42:52',
    pay_amount: 41000,
    sale_count: 1,
    distance: 545.99,
    key: '37.5044387455945,127.060914012298',
  },
  {
    tdu_sno: 62676,
    office_sno: 21,
    addr_geo_x: 127.05837240702,
    addr_geo_y: 37.4973304618556,
    user_phone: '050-71228-3603',
    address: '서울특별시 강남구 대치동 삼성로57길 33 ',
    addr_detail: '',
    first_sale_date: '2019-11-19 14:27:00',
    last_sale_date: '2021-03-23 18:54:35',
    pay_amount: 23000,
    sale_count: 1,
    distance: 428.55,
    key: '37.4973304618556,127.05837240702',
  },
  {
    tdu_sno: 60833,
    office_sno: 21,
    addr_geo_x: 127.059323005294,
    addr_geo_y: 37.4976174994655,
    user_phone: '050-4064-37656',
    address: '서울특별시 강남구 대치동 도곡로78길 7 ',
    addr_detail: '',
    first_sale_date: '2019-11-09 11:15:00',
    last_sale_date: '2021-03-26 21:37:59',
    pay_amount: 21000,
    sale_count: 1,
    distance: 343.23,
    key: '37.4976174994655,127.059323005294',
  },
  {
    tdu_sno: 61209,
    office_sno: 21,
    addr_geo_x: 127.057183986012,
    addr_geo_y: 37.4971760699869,
    user_phone: '050-4472-76061',
    address: '서울특별시 강남구 대치동 삼성로57길 48 아크로빌',
    addr_detail: '',
    first_sale_date: '2019-11-10 12:02:00',
    last_sale_date: '2021-03-26 13:53:04',
    pay_amount: 18000,
    sale_count: 1,
    distance: 525,
    key: '37.4971760699869,127.057183986012',
  },
  {
    tdu_sno: 61265,
    office_sno: 21,
    addr_geo_x: 127.054891247787,
    addr_geo_y: 37.5025205738702,
    user_phone: '0504-4717-2727',
    address: '서울특별시 강남구 대치동 삼성로75길 41 ',
    addr_detail: '',
    first_sale_date: '2019-11-10 18:32:00',
    last_sale_date: '2021-03-25 11:29:09',
    pay_amount: 29500,
    sale_count: 1,
    distance: 724.11,
    key: '37.5025205738702,127.054891247787',
  },
  {
    tdu_sno: 61340,
    office_sno: 21,
    addr_geo_x: 127.059770669616,
    addr_geo_y: 37.5043732859489,
    user_phone: '010-4052-2872',
    address: '서울특별시 강남구 대치동 역삼로83길 12 ',
    addr_detail: '',
    first_sale_date: '2019-11-11 15:51:00',
    last_sale_date: '2021-03-24 22:22:19',
    pay_amount: 18000,
    sale_count: 1,
    distance: 569.61,
    key: '37.5043732859489,127.059770669616',
  },
  {
    tdu_sno: 61472,
    office_sno: 21,
    addr_geo_x: 127.055796012442,
    addr_geo_y: 37.5040636625307,
    user_phone: '050-37385-5445',
    address: '서울특별시 강남구 대치동 삼성로81길 23 ',
    addr_detail: '',
    first_sale_date: '2019-11-13 11:36:00',
    last_sale_date: '2021-03-29 15:54:45',
    pay_amount: 18000,
    sale_count: 1,
    distance: 752.43,
    key: '37.5040636625307,127.055796012442',
  },
  {
    tdu_sno: 61664,
    office_sno: 21,
    addr_geo_x: 127.058453392715,
    addr_geo_y: 37.4972064433283,
    user_phone: '050-4472-40544',
    address: '서울특별시 강남구 대치동 삼성로57길 33 1 리치빌',
    addr_detail: '',
    first_sale_date: '2019-11-15 17:23:00',
    last_sale_date: '2021-03-25 13:06:32',
    pay_amount: 41000,
    sale_count: 1,
    distance: 431.4,
    key: '37.4972064433283,127.058453392715',
  },
  {
    tdu_sno: 61672,
    office_sno: 21,
    addr_geo_x: 127.058791438668,
    addr_geo_y: 37.4999901144861,
    user_phone: '010-9122-2900',
    address: '서울특별시 강남구 대치동 삼성로63길 16 4',
    addr_detail: '',
    first_sale_date: '2019-11-15 18:33:00',
    last_sale_date: '2021-03-25 13:45:35',
    pay_amount: 34500,
    sale_count: 1,
    distance: 308.06,
    key: '37.4999901144861,127.058791438668',
  },
  {
    tdu_sno: 61880,
    office_sno: 21,
    addr_geo_x: 127.057799888858,
    addr_geo_y: 37.4989886850265,
    user_phone: '010',
    address: '서울특별시 강남구 대치동 삼성로61길 30 1',
    addr_detail: '',
    first_sale_date: '2019-11-17 19:42:00',
    last_sale_date: '2021-03-24 13:03:35',
    pay_amount: 29500,
    sale_count: 1,
    distance: 399.86,
    key: '37.4989886850265,127.057799888858',
  },
  {
    tdu_sno: 62640,
    office_sno: 21,
    addr_geo_x: 127.060914012298,
    addr_geo_y: 37.5044387455945,
    user_phone: '050-37388-9148',
    address: '서울특별시 강남구 대치동 역삼로87길 7 라임',
    addr_detail: '',
    first_sale_date: '2019-11-18 22:38:00',
    last_sale_date: '2021-03-23 13:42:52',
    pay_amount: 41000,
    sale_count: 1,
    distance: 545.99,
    key: '37.5044387455945,127.060914012298',
  },
  {
    tdu_sno: 62676,
    office_sno: 21,
    addr_geo_x: 127.05837240702,
    addr_geo_y: 37.4973304618556,
    user_phone: '050-71228-3603',
    address: '서울특별시 강남구 대치동 삼성로57길 33 ',
    addr_detail: '',
    first_sale_date: '2019-11-19 14:27:00',
    last_sale_date: '2021-03-23 18:54:35',
    pay_amount: 23000,
    sale_count: 1,
    distance: 428.55,
    key: '37.4973304618556,127.05837240702',
  },
  {
    tdu_sno: 60833,
    office_sno: 21,
    addr_geo_x: 127.059323005294,
    addr_geo_y: 37.4976174994655,
    user_phone: '050-4064-37656',
    address: '서울특별시 강남구 대치동 도곡로78길 7 ',
    addr_detail: '',
    first_sale_date: '2019-11-09 11:15:00',
    last_sale_date: '2021-03-26 21:37:59',
    pay_amount: 21000,
    sale_count: 1,
    distance: 343.23,
    key: '37.4976174994655,127.059323005294',
  },
  {
    tdu_sno: 61209,
    office_sno: 21,
    addr_geo_x: 127.057183986012,
    addr_geo_y: 37.4971760699869,
    user_phone: '050-4472-76061',
    address: '서울특별시 강남구 대치동 삼성로57길 48 아크로빌',
    addr_detail: '',
    first_sale_date: '2019-11-10 12:02:00',
    last_sale_date: '2021-03-26 13:53:04',
    pay_amount: 18000,
    sale_count: 1,
    distance: 525,
    key: '37.4971760699869,127.057183986012',
  },
  {
    tdu_sno: 61265,
    office_sno: 21,
    addr_geo_x: 127.054891247787,
    addr_geo_y: 37.5025205738702,
    user_phone: '0504-4717-2727',
    address: '서울특별시 강남구 대치동 삼성로75길 41 ',
    addr_detail: '',
    first_sale_date: '2019-11-10 18:32:00',
    last_sale_date: '2021-03-25 11:29:09',
    pay_amount: 29500,
    sale_count: 1,
    distance: 724.11,
    key: '37.5025205738702,127.054891247787',
  },
  {
    tdu_sno: 61340,
    office_sno: 21,
    addr_geo_x: 127.059770669616,
    addr_geo_y: 37.5043732859489,
    user_phone: '010-4052-2872',
    address: '서울특별시 강남구 대치동 역삼로83길 12 ',
    addr_detail: '',
    first_sale_date: '2019-11-11 15:51:00',
    last_sale_date: '2021-03-24 22:22:19',
    pay_amount: 18000,
    sale_count: 1,
    distance: 569.61,
    key: '37.5043732859489,127.059770669616',
  },
  {
    tdu_sno: 61472,
    office_sno: 21,
    addr_geo_x: 127.055796012442,
    addr_geo_y: 37.5040636625307,
    user_phone: '050-37385-5445',
    address: '서울특별시 강남구 대치동 삼성로81길 23 ',
    addr_detail: '',
    first_sale_date: '2019-11-13 11:36:00',
    last_sale_date: '2021-03-29 15:54:45',
    pay_amount: 18000,
    sale_count: 1,
    distance: 752.43,
    key: '37.5040636625307,127.055796012442',
  },
  {
    tdu_sno: 61664,
    office_sno: 21,
    addr_geo_x: 127.058453392715,
    addr_geo_y: 37.4972064433283,
    user_phone: '050-4472-40544',
    address: '서울특별시 강남구 대치동 삼성로57길 33 1 리치빌',
    addr_detail: '',
    first_sale_date: '2019-11-15 17:23:00',
    last_sale_date: '2021-03-25 13:06:32',
    pay_amount: 41000,
    sale_count: 1,
    distance: 431.4,
    key: '37.4972064433283,127.058453392715',
  },
  {
    tdu_sno: 61672,
    office_sno: 21,
    addr_geo_x: 127.058791438668,
    addr_geo_y: 37.4999901144861,
    user_phone: '010-9122-2900',
    address: '서울특별시 강남구 대치동 삼성로63길 16 4',
    addr_detail: '',
    first_sale_date: '2019-11-15 18:33:00',
    last_sale_date: '2021-03-25 13:45:35',
    pay_amount: 34500,
    sale_count: 1,
    distance: 308.06,
    key: '37.4999901144861,127.058791438668',
  },
  {
    tdu_sno: 61880,
    office_sno: 21,
    addr_geo_x: 127.057799888858,
    addr_geo_y: 37.4989886850265,
    user_phone: '010',
    address: '서울특별시 강남구 대치동 삼성로61길 30 1',
    addr_detail: '',
    first_sale_date: '2019-11-17 19:42:00',
    last_sale_date: '2021-03-24 13:03:35',
    pay_amount: 29500,
    sale_count: 1,
    distance: 399.86,
    key: '37.4989886850265,127.057799888858',
  },
  {
    tdu_sno: 62640,
    office_sno: 21,
    addr_geo_x: 127.060914012298,
    addr_geo_y: 37.5044387455945,
    user_phone: '050-37388-9148',
    address: '서울특별시 강남구 대치동 역삼로87길 7 라임',
    addr_detail: '',
    first_sale_date: '2019-11-18 22:38:00',
    last_sale_date: '2021-03-23 13:42:52',
    pay_amount: 41000,
    sale_count: 1,
    distance: 545.99,
    key: '37.5044387455945,127.060914012298',
  },
  {
    tdu_sno: 62676,
    office_sno: 21,
    addr_geo_x: 127.05837240702,
    addr_geo_y: 37.4973304618556,
    user_phone: '050-71228-3603',
    address: '서울특별시 강남구 대치동 삼성로57길 33 ',
    addr_detail: '',
    first_sale_date: '2019-11-19 14:27:00',
    last_sale_date: '2021-03-23 18:54:35',
    pay_amount: 23000,
    sale_count: 1,
    distance: 428.55,
    key: '37.4973304618556,127.05837240702',
  },
  {
    tdu_sno: 60833,
    office_sno: 21,
    addr_geo_x: 127.059323005294,
    addr_geo_y: 37.4976174994655,
    user_phone: '050-4064-37656',
    address: '서울특별시 강남구 대치동 도곡로78길 7 ',
    addr_detail: '',
    first_sale_date: '2019-11-09 11:15:00',
    last_sale_date: '2021-03-26 21:37:59',
    pay_amount: 21000,
    sale_count: 1,
    distance: 343.23,
    key: '37.4976174994655,127.059323005294',
  },
  {
    tdu_sno: 61209,
    office_sno: 21,
    addr_geo_x: 127.057183986012,
    addr_geo_y: 37.4971760699869,
    user_phone: '050-4472-76061',
    address: '서울특별시 강남구 대치동 삼성로57길 48 아크로빌',
    addr_detail: '',
    first_sale_date: '2019-11-10 12:02:00',
    last_sale_date: '2021-03-26 13:53:04',
    pay_amount: 18000,
    sale_count: 1,
    distance: 525,
    key: '37.4971760699869,127.057183986012',
  },
  {
    tdu_sno: 61265,
    office_sno: 21,
    addr_geo_x: 127.054891247787,
    addr_geo_y: 37.5025205738702,
    user_phone: '0504-4717-2727',
    address: '서울특별시 강남구 대치동 삼성로75길 41 ',
    addr_detail: '',
    first_sale_date: '2019-11-10 18:32:00',
    last_sale_date: '2021-03-25 11:29:09',
    pay_amount: 29500,
    sale_count: 1,
    distance: 724.11,
    key: '37.5025205738702,127.054891247787',
  },
  {
    tdu_sno: 61340,
    office_sno: 21,
    addr_geo_x: 127.059770669616,
    addr_geo_y: 37.5043732859489,
    user_phone: '010-4052-2872',
    address: '서울특별시 강남구 대치동 역삼로83길 12 ',
    addr_detail: '',
    first_sale_date: '2019-11-11 15:51:00',
    last_sale_date: '2021-03-24 22:22:19',
    pay_amount: 18000,
    sale_count: 1,
    distance: 569.61,
    key: '37.5043732859489,127.059770669616',
  },
  {
    tdu_sno: 61472,
    office_sno: 21,
    addr_geo_x: 127.055796012442,
    addr_geo_y: 37.5040636625307,
    user_phone: '050-37385-5445',
    address: '서울특별시 강남구 대치동 삼성로81길 23 ',
    addr_detail: '',
    first_sale_date: '2019-11-13 11:36:00',
    last_sale_date: '2021-03-29 15:54:45',
    pay_amount: 18000,
    sale_count: 1,
    distance: 752.43,
    key: '37.5040636625307,127.055796012442',
  },
  {
    tdu_sno: 61664,
    office_sno: 21,
    addr_geo_x: 127.058453392715,
    addr_geo_y: 37.4972064433283,
    user_phone: '050-4472-40544',
    address: '서울특별시 강남구 대치동 삼성로57길 33 1 리치빌',
    addr_detail: '',
    first_sale_date: '2019-11-15 17:23:00',
    last_sale_date: '2021-03-25 13:06:32',
    pay_amount: 41000,
    sale_count: 1,
    distance: 431.4,
    key: '37.4972064433283,127.058453392715',
  },
  {
    tdu_sno: 61672,
    office_sno: 21,
    addr_geo_x: 127.058791438668,
    addr_geo_y: 37.4999901144861,
    user_phone: '010-9122-2900',
    address: '서울특별시 강남구 대치동 삼성로63길 16 4',
    addr_detail: '',
    first_sale_date: '2019-11-15 18:33:00',
    last_sale_date: '2021-03-25 13:45:35',
    pay_amount: 34500,
    sale_count: 1,
    distance: 308.06,
    key: '37.4999901144861,127.058791438668',
  },
  {
    tdu_sno: 61880,
    office_sno: 21,
    addr_geo_x: 127.057799888858,
    addr_geo_y: 37.4989886850265,
    user_phone: '010',
    address: '서울특별시 강남구 대치동 삼성로61길 30 1',
    addr_detail: '',
    first_sale_date: '2019-11-17 19:42:00',
    last_sale_date: '2021-03-24 13:03:35',
    pay_amount: 29500,
    sale_count: 1,
    distance: 399.86,
    key: '37.4989886850265,127.057799888858',
  },
  {
    tdu_sno: 62640,
    office_sno: 21,
    addr_geo_x: 127.060914012298,
    addr_geo_y: 37.5044387455945,
    user_phone: '050-37388-9148',
    address: '서울특별시 강남구 대치동 역삼로87길 7 라임',
    addr_detail: '',
    first_sale_date: '2019-11-18 22:38:00',
    last_sale_date: '2021-03-23 13:42:52',
    pay_amount: 41000,
    sale_count: 1,
    distance: 545.99,
    key: '37.5044387455945,127.060914012298',
  },
  {
    tdu_sno: 62676,
    office_sno: 21,
    addr_geo_x: 127.05837240702,
    addr_geo_y: 37.4973304618556,
    user_phone: '050-71228-3603',
    address: '서울특별시 강남구 대치동 삼성로57길 33 ',
    addr_detail: '',
    first_sale_date: '2019-11-19 14:27:00',
    last_sale_date: '2021-03-23 18:54:35',
    pay_amount: 23000,
    sale_count: 1,
    distance: 428.55,
    key: '37.4973304618556,127.05837240702',
  },
  {
    tdu_sno: 60833,
    office_sno: 21,
    addr_geo_x: 127.059323005294,
    addr_geo_y: 37.4976174994655,
    user_phone: '050-4064-37656',
    address: '서울특별시 강남구 대치동 도곡로78길 7 ',
    addr_detail: '',
    first_sale_date: '2019-11-09 11:15:00',
    last_sale_date: '2021-03-26 21:37:59',
    pay_amount: 21000,
    sale_count: 1,
    distance: 343.23,
    key: '37.4976174994655,127.059323005294',
  },
  {
    tdu_sno: 61209,
    office_sno: 21,
    addr_geo_x: 127.057183986012,
    addr_geo_y: 37.4971760699869,
    user_phone: '050-4472-76061',
    address: '서울특별시 강남구 대치동 삼성로57길 48 아크로빌',
    addr_detail: '',
    first_sale_date: '2019-11-10 12:02:00',
    last_sale_date: '2021-03-26 13:53:04',
    pay_amount: 18000,
    sale_count: 1,
    distance: 525,
    key: '37.4971760699869,127.057183986012',
  },
  {
    tdu_sno: 61265,
    office_sno: 21,
    addr_geo_x: 127.054891247787,
    addr_geo_y: 37.5025205738702,
    user_phone: '0504-4717-2727',
    address: '서울특별시 강남구 대치동 삼성로75길 41 ',
    addr_detail: '',
    first_sale_date: '2019-11-10 18:32:00',
    last_sale_date: '2021-03-25 11:29:09',
    pay_amount: 29500,
    sale_count: 1,
    distance: 724.11,
    key: '37.5025205738702,127.054891247787',
  },
  {
    tdu_sno: 61340,
    office_sno: 21,
    addr_geo_x: 127.059770669616,
    addr_geo_y: 37.5043732859489,
    user_phone: '010-4052-2872',
    address: '서울특별시 강남구 대치동 역삼로83길 12 ',
    addr_detail: '',
    first_sale_date: '2019-11-11 15:51:00',
    last_sale_date: '2021-03-24 22:22:19',
    pay_amount: 18000,
    sale_count: 1,
    distance: 569.61,
    key: '37.5043732859489,127.059770669616',
  },
  {
    tdu_sno: 61472,
    office_sno: 21,
    addr_geo_x: 127.055796012442,
    addr_geo_y: 37.5040636625307,
    user_phone: '050-37385-5445',
    address: '서울특별시 강남구 대치동 삼성로81길 23 ',
    addr_detail: '',
    first_sale_date: '2019-11-13 11:36:00',
    last_sale_date: '2021-03-29 15:54:45',
    pay_amount: 18000,
    sale_count: 1,
    distance: 752.43,
    key: '37.5040636625307,127.055796012442',
  },
  {
    tdu_sno: 61664,
    office_sno: 21,
    addr_geo_x: 127.058453392715,
    addr_geo_y: 37.4972064433283,
    user_phone: '050-4472-40544',
    address: '서울특별시 강남구 대치동 삼성로57길 33 1 리치빌',
    addr_detail: '',
    first_sale_date: '2019-11-15 17:23:00',
    last_sale_date: '2021-03-25 13:06:32',
    pay_amount: 41000,
    sale_count: 1,
    distance: 431.4,
    key: '37.4972064433283,127.058453392715',
  },
  {
    tdu_sno: 61672,
    office_sno: 21,
    addr_geo_x: 127.058791438668,
    addr_geo_y: 37.4999901144861,
    user_phone: '010-9122-2900',
    address: '서울특별시 강남구 대치동 삼성로63길 16 4',
    addr_detail: '',
    first_sale_date: '2019-11-15 18:33:00',
    last_sale_date: '2021-03-25 13:45:35',
    pay_amount: 34500,
    sale_count: 1,
    distance: 308.06,
    key: '37.4999901144861,127.058791438668',
  },
  {
    tdu_sno: 61880,
    office_sno: 21,
    addr_geo_x: 127.057799888858,
    addr_geo_y: 37.4989886850265,
    user_phone: '010',
    address: '서울특별시 강남구 대치동 삼성로61길 30 1',
    addr_detail: '',
    first_sale_date: '2019-11-17 19:42:00',
    last_sale_date: '2021-03-24 13:03:35',
    pay_amount: 29500,
    sale_count: 1,
    distance: 399.86,
    key: '37.4989886850265,127.057799888858',
  },
  {
    tdu_sno: 62640,
    office_sno: 21,
    addr_geo_x: 127.060914012298,
    addr_geo_y: 37.5044387455945,
    user_phone: '050-37388-9148',
    address: '서울특별시 강남구 대치동 역삼로87길 7 라임',
    addr_detail: '',
    first_sale_date: '2019-11-18 22:38:00',
    last_sale_date: '2021-03-23 13:42:52',
    pay_amount: 41000,
    sale_count: 1,
    distance: 545.99,
    key: '37.5044387455945,127.060914012298',
  },
  {
    tdu_sno: 62676,
    office_sno: 21,
    addr_geo_x: 127.05837240702,
    addr_geo_y: 37.4973304618556,
    user_phone: '050-71228-3603',
    address: '서울특별시 강남구 대치동 삼성로57길 33 ',
    addr_detail: '',
    first_sale_date: '2019-11-19 14:27:00',
    last_sale_date: '2021-03-23 18:54:35',
    pay_amount: 23000,
    sale_count: 1,
    distance: 428.55,
    key: '37.4973304618556,127.05837240702',
  },
  {
    tdu_sno: 60833,
    office_sno: 21,
    addr_geo_x: 127.059323005294,
    addr_geo_y: 37.4976174994655,
    user_phone: '050-4064-37656',
    address: '서울특별시 강남구 대치동 도곡로78길 7 ',
    addr_detail: '',
    first_sale_date: '2019-11-09 11:15:00',
    last_sale_date: '2021-03-26 21:37:59',
    pay_amount: 21000,
    sale_count: 1,
    distance: 343.23,
    key: '37.4976174994655,127.059323005294',
  },
  {
    tdu_sno: 61209,
    office_sno: 21,
    addr_geo_x: 127.057183986012,
    addr_geo_y: 37.4971760699869,
    user_phone: '050-4472-76061',
    address: '서울특별시 강남구 대치동 삼성로57길 48 아크로빌',
    addr_detail: '',
    first_sale_date: '2019-11-10 12:02:00',
    last_sale_date: '2021-03-26 13:53:04',
    pay_amount: 18000,
    sale_count: 1,
    distance: 525,
    key: '37.4971760699869,127.057183986012',
  },
  {
    tdu_sno: 61265,
    office_sno: 21,
    addr_geo_x: 127.054891247787,
    addr_geo_y: 37.5025205738702,
    user_phone: '0504-4717-2727',
    address: '서울특별시 강남구 대치동 삼성로75길 41 ',
    addr_detail: '',
    first_sale_date: '2019-11-10 18:32:00',
    last_sale_date: '2021-03-25 11:29:09',
    pay_amount: 29500,
    sale_count: 1,
    distance: 724.11,
    key: '37.5025205738702,127.054891247787',
  },
  {
    tdu_sno: 61340,
    office_sno: 21,
    addr_geo_x: 127.059770669616,
    addr_geo_y: 37.5043732859489,
    user_phone: '010-4052-2872',
    address: '서울특별시 강남구 대치동 역삼로83길 12 ',
    addr_detail: '',
    first_sale_date: '2019-11-11 15:51:00',
    last_sale_date: '2021-03-24 22:22:19',
    pay_amount: 18000,
    sale_count: 1,
    distance: 569.61,
    key: '37.5043732859489,127.059770669616',
  },
  {
    tdu_sno: 61472,
    office_sno: 21,
    addr_geo_x: 127.055796012442,
    addr_geo_y: 37.5040636625307,
    user_phone: '050-37385-5445',
    address: '서울특별시 강남구 대치동 삼성로81길 23 ',
    addr_detail: '',
    first_sale_date: '2019-11-13 11:36:00',
    last_sale_date: '2021-03-29 15:54:45',
    pay_amount: 18000,
    sale_count: 1,
    distance: 752.43,
    key: '37.5040636625307,127.055796012442',
  },
  {
    tdu_sno: 61664,
    office_sno: 21,
    addr_geo_x: 127.058453392715,
    addr_geo_y: 37.4972064433283,
    user_phone: '050-4472-40544',
    address: '서울특별시 강남구 대치동 삼성로57길 33 1 리치빌',
    addr_detail: '',
    first_sale_date: '2019-11-15 17:23:00',
    last_sale_date: '2021-03-25 13:06:32',
    pay_amount: 41000,
    sale_count: 1,
    distance: 431.4,
    key: '37.4972064433283,127.058453392715',
  },
  {
    tdu_sno: 61672,
    office_sno: 21,
    addr_geo_x: 127.058791438668,
    addr_geo_y: 37.4999901144861,
    user_phone: '010-9122-2900',
    address: '서울특별시 강남구 대치동 삼성로63길 16 4',
    addr_detail: '',
    first_sale_date: '2019-11-15 18:33:00',
    last_sale_date: '2021-03-25 13:45:35',
    pay_amount: 34500,
    sale_count: 1,
    distance: 308.06,
    key: '37.4999901144861,127.058791438668',
  },
  {
    tdu_sno: 61880,
    office_sno: 21,
    addr_geo_x: 127.057799888858,
    addr_geo_y: 37.4989886850265,
    user_phone: '010',
    address: '서울특별시 강남구 대치동 삼성로61길 30 1',
    addr_detail: '',
    first_sale_date: '2019-11-17 19:42:00',
    last_sale_date: '2021-03-24 13:03:35',
    pay_amount: 29500,
    sale_count: 1,
    distance: 399.86,
    key: '37.4989886850265,127.057799888858',
  },
  {
    tdu_sno: 62640,
    office_sno: 21,
    addr_geo_x: 127.060914012298,
    addr_geo_y: 37.5044387455945,
    user_phone: '050-37388-9148',
    address: '서울특별시 강남구 대치동 역삼로87길 7 라임',
    addr_detail: '',
    first_sale_date: '2019-11-18 22:38:00',
    last_sale_date: '2021-03-23 13:42:52',
    pay_amount: 41000,
    sale_count: 1,
    distance: 545.99,
    key: '37.5044387455945,127.060914012298',
  },
  {
    tdu_sno: 62676,
    office_sno: 21,
    addr_geo_x: 127.05837240702,
    addr_geo_y: 37.4973304618556,
    user_phone: '050-71228-3603',
    address: '서울특별시 강남구 대치동 삼성로57길 33 ',
    addr_detail: '',
    first_sale_date: '2019-11-19 14:27:00',
    last_sale_date: '2021-03-23 18:54:35',
    pay_amount: 23000,
    sale_count: 1,
    distance: 428.55,
    key: '37.4973304618556,127.05837240702',
  },
  {
    tdu_sno: 62689,
    office_sno: 21,
    addr_geo_x: 127.066565204126,
    addr_geo_y: 37.5077460789187,
    user_phone: '010-5738-8588',
    address: '서울특별시 강남구 대치동 테헤란로108길 25 대동빌딩',
    addr_detail: '',
    first_sale_date: '2019-11-19 17:22:00',
    last_sale_date: '2021-03-24 19:12:05',
    pay_amount: 44500,
    sale_count: 1,
    distance: 977.66,
    key: '37.5077460789187,127.066565204126',
  },
  {
    tdu_sno: 62721,
    office_sno: 21,
    addr_geo_x: 127.05455737007,
    addr_geo_y: 37.5020185980796,
    user_phone: '050-71231-1950',
    address: '서울특별시 강남구 대치동 역삼로 435 ',
    addr_detail: '',
    first_sale_date: '2019-11-19 21:26:00',
    last_sale_date: '2021-03-23 13:28:08',
    pay_amount: 18000,
    sale_count: 1,
    distance: 728.64,
    key: '37.5020185980796,127.05455737007',
  },
  {
    tdu_sno: 62746,
    office_sno: 21,
    addr_geo_x: 127.054543876361,
    addr_geo_y: 37.5035241846606,
    user_phone: '0507-1210-4354',
    address: '서울특별시 강남구 대치동 삼성로75길 40 14',
    addr_detail: '',
    first_sale_date: '2019-11-20 11:56:00',
    last_sale_date: '2021-03-26 11:33:06',
    pay_amount: 67000,
    sale_count: 2,
    distance: 805.54,
    key: '37.5035241846606,127.054543876361',
  },
  {
    tdu_sno: 62885,
    office_sno: 21,
    addr_geo_x: 127.056114084616,
    addr_geo_y: 37.5029175229972,
    user_phone: '050-7908-8508',
    address: '서울특별시 강남구 대치동 삼성로75길 25 ',
    addr_detail: '',
    first_sale_date: '2019-11-21 20:16:00',
    last_sale_date: '2021-03-29 22:23:00',
    pay_amount: 38500,
    sale_count: 1,
    distance: 652.67,
    key: '37.5029175229972,127.056114084616',
  },
  {
    tdu_sno: 62889,
    office_sno: 21,
    addr_geo_x: 127.053801342114,
    addr_geo_y: 37.5026440625547,
    user_phone: '050-7923-2082',
    address: '서울특별시 강남구 대치동 역삼로69길 15 ',
    addr_detail: '',
    first_sale_date: '2019-11-21 20:58:00',
    last_sale_date: '2021-03-24 16:41:06',
    pay_amount: 28000,
    sale_count: 1,
    distance: 817,
    key: '37.5026440625547,127.053801342114',
  },
  {
    tdu_sno: 63125,
    office_sno: 21,
    addr_geo_x: 127.06264342278,
    addr_geo_y: 37.5027448519306,
    user_phone: '050-37375-3213',
    address: '서울특별시 강남구 대치동 역삼로90길 23 대치3동성당 교육관',
    addr_detail: '',
    first_sale_date: '2019-11-24 16:57:00',
    last_sale_date: '2021-03-25 13:03:18',
    pay_amount: 21000,
    sale_count: 1,
    distance: 346.33,
    key: '37.5027448519306,127.06264342278',
  },
  {
    tdu_sno: 63246,
    office_sno: 21,
    addr_geo_x: 127.062509582774,
    addr_geo_y: 37.5024134428722,
    user_phone: '010-9356-5493',
    address: '서울특별시 강남구 대치동 역삼로90길 26 한강팰리스',
    addr_detail: '',
    first_sale_date: '2019-11-26 13:03:00',
    last_sale_date: '2021-03-23 11:27:41',
    pay_amount: 17500,
    sale_count: 1,
    distance: 308.6,
    key: '37.5024134428722,127.062509582774',
  },
  {
    tdu_sno: 64118,
    office_sno: 21,
    addr_geo_x: 127.0554369921,
    addr_geo_y: 37.4998600091952,
    user_phone: '0504-0613-1151',
    address: '서울특별시 강남구 대치동 삼성로67길 51 ',
    addr_detail: '',
    first_sale_date: '2019-11-29 19:15:00',
    last_sale_date: '2021-03-25 20:04:06',
    pay_amount: 36500,
    sale_count: 1,
    distance: 602.06,
    key: '37.4998600091952,127.0554369921',
  },
  {
    tdu_sno: 64269,
    office_sno: 21,
    addr_geo_x: 127.05990782868,
    addr_geo_y: 37.4989611452777,
    user_phone: '0507-1223-8601',
    address: '서울특별시 강남구 대치동 도곡로 453 목빌딩',
    addr_detail: '',
    first_sale_date: '2019-12-01 17:27:00',
    last_sale_date: '2021-03-24 20:24:25',
    pay_amount: 34500,
    sale_count: 1,
    distance: 220.71,
    key: '37.4989611452777,127.05990782868',
  },
  {
    tdu_sno: 64610,
    office_sno: 21,
    addr_geo_x: 127.059240829175,
    addr_geo_y: 37.4978080134449,
    user_phone: '010-9303-2441',
    address: '서울특별시 강남구 대치동 삼성로57길 22 ',
    addr_detail: '',
    first_sale_date: '2019-12-06 14:40:00',
    last_sale_date: '2021-03-25 13:48:21',
    pay_amount: 21000,
    sale_count: 1,
    distance: 335.42,
    key: '37.4978080134449,127.059240829175',
  },
  {
    tdu_sno: 64620,
    office_sno: 21,
    addr_geo_x: 127.053531520856,
    addr_geo_y: 37.4953849585944,
    user_phone: '0504-4755-1453',
    address: '서울특별시 강남구 대치동 선릉로 224 대치아이파크',
    addr_detail: '',
    first_sale_date: '2019-12-06 15:53:00',
    last_sale_date: '2021-03-26 13:34:02',
    pay_amount: 41000,
    sale_count: 1,
    distance: 903.81,
    key: '37.4953849585944,127.053531520856',
  },
  {
    tdu_sno: 65435,
    office_sno: 21,
    addr_geo_x: 127.058741226189,
    addr_geo_y: 37.4976793289145,
    user_phone: '0504-4819-9189',
    address: '서울특별시 강남구 대치동 삼성로57길 28 ',
    addr_detail: '',
    first_sale_date: '2019-12-09 12:08:00',
    last_sale_date: '2021-03-25 12:47:16',
    pay_amount: 41000,
    sale_count: 1,
    distance: 379.41,
    key: '37.4976793289145,127.058741226189',
  },
  {
    tdu_sno: 65504,
    office_sno: 21,
    addr_geo_x: 127.06279566981,
    addr_geo_y: 37.5080381764122,
    user_phone: '050-37377-1431',
    address: '서울특별시 강남구 대치동 테헤란로 534 글라스타워빌딩',
    addr_detail: '',
    first_sale_date: '2019-12-10 17:50:00',
    last_sale_date: '2021-03-23 11:39:42',
    pay_amount: 44500,
    sale_count: 1,
    distance: 934.44,
    key: '37.5080381764122,127.06279566981',
  },
  {
    tdu_sno: 65541,
    office_sno: 21,
    addr_geo_x: 127.0646421331,
    addr_geo_y: 37.5011760252425,
    user_phone: '010-8954-4621',
    address: '서울특별시 강남구 대치동 도곡로93길 13 ',
    addr_detail: '',
    first_sale_date: '2019-12-11 11:35:00',
    last_sale_date: '2021-03-23 16:45:08',
    pay_amount: 19000,
    sale_count: 1,
    distance: 270.66,
    key: '37.5011760252425,127.0646421331',
  },
  {
    tdu_sno: 65665,
    office_sno: 21,
    addr_geo_x: 127.059206732762,
    addr_geo_y: 37.504896513416,
    user_phone: '010-9376-0345',
    address: '서울특별시 강남구 대치동 역삼로83길 21 ',
    addr_detail: '',
    first_sale_date: '2019-12-12 21:57:00',
    last_sale_date: '2021-03-26 18:08:25',
    pay_amount: 28000,
    sale_count: 1,
    distance: 642.89,
    key: '37.504896513416,127.059206732762',
  },
  {
    tdu_sno: 65672,
    office_sno: 21,
    addr_geo_x: 127.058035467037,
    addr_geo_y: 37.5023696842739,
    user_phone: '050-71231-4659',
    address: '서울특별시 강남구 대치동 삼성로71길 10 대치4동천주교 성당',
    addr_detail: '',
    first_sale_date: '2019-12-13 11:38:00',
    last_sale_date: '2021-03-25 11:31:24',
    pay_amount: 18000,
    sale_count: 1,
    distance: 480.01,
    key: '37.5023696842739,127.058035467037',
  },
  {
    tdu_sno: 65765,
    office_sno: 21,
    addr_geo_x: 127.053186821033,
    addr_geo_y: 37.5038016824405,
    user_phone: '010-5550-5538',
    address: '서울특별시 강남구 대치동 선릉로86길 40 3',
    addr_detail: '',
    first_sale_date: '2019-12-14 16:04:00',
    last_sale_date: '2021-03-26 20:43:54',
    pay_amount: 17500,
    sale_count: 1,
    distance: 923.96,
    key: '37.5038016824405,127.053186821033',
  },
  {
    tdu_sno: 65806,
    office_sno: 21,
    addr_geo_x: 127.053443211134,
    addr_geo_y: 37.5001564579799,
    user_phone: '0503-7390-1723',
    address: '서울특별시 강남구 대치동 선릉로72길 26 8',
    addr_detail: '',
    first_sale_date: '2019-12-15 12:16:00',
    last_sale_date: '2021-03-25 20:48:54',
    pay_amount: 21000,
    sale_count: 1,
    distance: 779.54,
    key: '37.5001564579799,127.053443211134',
  },
  {
    tdu_sno: 66691,
    office_sno: 21,
    addr_geo_x: 127.059602218139,
    addr_geo_y: 37.5058737249463,
    user_phone: '01032293419',
    address: '서울특별시 강남구 대치동 삼성로84길 25 ',
    addr_detail: '',
    first_sale_date: '2019-12-19 15:56:00',
    last_sale_date: '2021-03-23 13:59:54',
    pay_amount: 18000,
    sale_count: 1,
    distance: 731.06,
    key: '37.5058737249463,127.059602218139',
  },
  {
    tdu_sno: 66741,
    office_sno: 21,
    addr_geo_x: 127.060065021363,
    addr_geo_y: 37.5026322138939,
    user_phone: '0504-0600-7319',
    address: '서울특별시 강남구 대치동 삼성로72길 11 ',
    addr_detail: '',
    first_sale_date: '2019-12-20 12:53:00',
    last_sale_date: '2021-03-25 11:30:09',
    pay_amount: 18000,
    sale_count: 1,
    distance: 384.28,
    key: '37.5026322138939,127.060065021363',
  },
  {
    tdu_sno: 66777,
    office_sno: 21,
    addr_geo_x: 127.066027127746,
    addr_geo_y: 37.5093545841304,
    user_phone: '050-7823-1196',
    address: '서울특별시 강남구 대치동 테헤란로 624 오로라빌딩',
    addr_detail: '',
    first_sale_date: '2019-12-20 18:08:00',
    last_sale_date: '2021-03-26 18:55:45',
    pay_amount: 48000,
    sale_count: 1,
    distance: 1129.68,
    key: '37.5093545841304,127.066027127746',
  },
  {
    tdu_sno: 66829,
    office_sno: 21,
    addr_geo_x: 127.052121239264,
    addr_geo_y: 37.4999007124296,
    user_phone: '010-5417-9126',
    address: '서울특별시 강남구 대치동 선릉로72길 10 6',
    addr_detail: '',
    first_sale_date: '2019-12-21 16:05:00',
    last_sale_date: '2021-03-24 22:27:20',
    pay_amount: 36500,
    sale_count: 1,
    distance: 894.54,
    key: '37.4999007124296,127.052121239264',
  },
  {
    tdu_sno: 66865,
    office_sno: 21,
    addr_geo_x: 127.062627017858,
    addr_geo_y: 37.5020602765605,
    user_phone: '010-7599-4359',
    address: '서울특별시 강남구 대치동 삼성로72길 43 ',
    addr_detail: '',
    first_sale_date: '2019-12-21 21:47:00',
    last_sale_date: '2021-03-23 17:57:14',
    pay_amount: 18000,
    sale_count: 1,
    distance: 270.5,
    key: '37.5020602765605,127.062627017858',
  },
  {
    tdu_sno: 67002,
    office_sno: 21,
    addr_geo_x: 127.053401782479,
    addr_geo_y: 37.4975625699718,
    user_phone: '050-7918-9031',
    address: '서울특별시 강남구 대치동 선릉로64길 10 3',
    addr_detail: '',
    first_sale_date: '2019-12-24 17:58:00',
    last_sale_date: '2021-03-24 22:39:35',
    pay_amount: 21000,
    sale_count: 1,
    distance: 814.75,
    key: '37.4975625699718,127.053401782479',
  },
  {
    tdu_sno: 67014,
    office_sno: 21,
    addr_geo_x: 127.059413456816,
    addr_geo_y: 37.5010212748665,
    user_phone: '010-2056-7982',
    address: '서울특별시 강남구 대치동 삼성로 329 진선빌딩',
    addr_detail: '',
    first_sale_date: '2019-12-24 21:20:00',
    last_sale_date: '2021-03-28 13:46:51',
    pay_amount: 46500,
    sale_count: 1,
    distance: 293.77,
    key: '37.5010212748665,127.059413456816',
  },
  {
    tdu_sno: 67823,
    office_sno: 21,
    addr_geo_x: 127.053453582409,
    addr_geo_y: 37.4989996515713,
    user_phone: '010-6660-1503',
    address: '서울특별시 강남구 대치동 도곡로63길 31 ',
    addr_detail: '',
    first_sale_date: '2019-12-28 14:09:00',
    last_sale_date: '2021-03-26 22:42:04',
    pay_amount: 17500,
    sale_count: 1,
    distance: 779.87,
    key: '37.4989996515713,127.053453582409',
  },
  {
    tdu_sno: 67825,
    office_sno: 21,
    addr_geo_x: 127.05085627128,
    addr_geo_y: 37.5021431465942,
    user_phone: '010-9911-2397',
    address: '서울특별시 강남구 대치동 선릉로76길 5 12',
    addr_detail: '',
    first_sale_date: '2019-12-28 15:01:00',
    last_sale_date: '2021-03-24 11:56:27',
    pay_amount: 41000,
    sale_count: 1,
    distance: 1043.31,
    key: '37.5021431465942,127.05085627128',
  },
  {
    tdu_sno: 67937,
    office_sno: 21,
    addr_geo_x: 127.054115847671,
    addr_geo_y: 37.5007529781061,
    user_phone: '050-7861-5058',
    address: '서울특별시 강남구 대치동 선릉로72길 36 ',
    addr_detail: '',
    first_sale_date: '2019-12-30 17:23:00',
    last_sale_date: '2021-03-25 14:19:24',
    pay_amount: 28000,
    sale_count: 1,
    distance: 728.61,
    key: '37.5007529781061,127.054115847671',
  },
  {
    tdu_sno: 68048,
    office_sno: 21,
    addr_geo_x: 127.057562743259,
    addr_geo_y: 37.4975155654589,
    user_phone: '010-4779-4448',
    address: '서울특별시 강남구 대치동 도곡로 430 단우빌딩',
    addr_detail: '',
    first_sale_date: '2020-01-01 20:06:00',
    last_sale_date: '2021-03-26 18:21:41',
    pay_amount: 21000,
    sale_count: 1,
    distance: 477.01,
    key: '37.4975155654589,127.057562743259',
  },
  {
    tdu_sno: 68065,
    office_sno: 21,
    addr_geo_x: 127.056766719047,
    addr_geo_y: 37.4991419065552,
    user_phone: '050-7940-2376',
    address: '서울특별시 강남구 대치동 삼성로63길 42 ',
    addr_detail: '',
    first_sale_date: '2020-01-02 11:10:00',
    last_sale_date: '2021-03-26 15:48:27',
    pay_amount: 21000,
    sale_count: 1,
    distance: 487.51,
    key: '37.4991419065552,127.056766719047',
  },
  {
    tdu_sno: 68130,
    office_sno: 21,
    addr_geo_x: 127.054386499316,
    addr_geo_y: 37.5053095993117,
    user_phone: '010-8730-7031',
    address: '서울특별시 강남구 대치동 선릉로90길 64 ',
    addr_detail: '',
    first_sale_date: '2020-01-03 16:08:00',
    last_sale_date: '2021-03-23 22:30:52',
    pay_amount: 18000,
    sale_count: 1,
    distance: 937.36,
    key: '37.5053095993117,127.054386499316',
  },
  {
    tdu_sno: 68731,
    office_sno: 21,
    addr_geo_x: 127.051627322774,
    addr_geo_y: 37.5049127733133,
    user_phone: '050-37374-7214',
    address: '서울특별시 강남구 대치동 테헤란로 414 엘앤비타워',
    addr_detail: '',
    first_sale_date: '2020-01-03 18:10:00',
    last_sale_date: '2021-03-25 17:16:37',
    pay_amount: 18000,
    sale_count: 1,
    distance: 1105.53,
    key: '37.5049127733133,127.051627322774',
  },
  {
    tdu_sno: 68927,
    office_sno: 21,
    addr_geo_x: 127.059726171779,
    addr_geo_y: 37.4991980216727,
    user_phone: '010-9539-9833',
    address: '서울특별시 강남구 대치동 삼성로61길 9 한양팰리스아파트',
    addr_detail: '',
    first_sale_date: '2020-01-07 13:09:00',
    last_sale_date: '2021-03-26 13:30:15',
    pay_amount: 36500,
    sale_count: 1,
    distance: 228.68,
    key: '37.4991980216727,127.059726171779',
  },
  {
    tdu_sno: 68933,
    office_sno: 21,
    addr_geo_x: 127.059203145239,
    addr_geo_y: 37.4989207750551,
    user_phone: '050-7901-7751',
    address: '서울특별시 강남구 대치동 삼성로61길 13 2',
    addr_detail: '',
    first_sale_date: '2020-01-07 15:14:00',
    last_sale_date: '2021-03-26 12:46:12',
    pay_amount: 44500,
    sale_count: 1,
    distance: 281.14,
    key: '37.4989207750551,127.059203145239',
  },
  {
    tdu_sno: 68944,
    office_sno: 21,
    addr_geo_x: 0,
    addr_geo_y: 0,
    user_phone: '010-6298-5111',
    address: '서울특별시 강남구 대치동 영동대로65길 52 ',
    addr_detail: '',
    first_sale_date: '2020-01-07 17:14:00',
    last_sale_date: '2021-03-25 15:05:42',
    pay_amount: 48000,
    sale_count: 1,
    distance: 0,
    key: '0,0',
  },
  {
    tdu_sno: 69106,
    office_sno: 21,
    addr_geo_x: 127.05736474386,
    addr_geo_y: 37.5018208489189,
    user_phone: '050-7919-7904',
    address: '서울특별시 강남구 대치동 삼성로71길 19 ',
    addr_detail: '',
    first_sale_date: '2020-01-11 11:39:00',
    last_sale_date: '2021-03-24 14:46:58',
    pay_amount: 47500,
    sale_count: 1,
    distance: 494.69,
    key: '37.5018208489189,127.05736474386',
  },
  {
    tdu_sno: 69764,
    office_sno: 21,
    addr_geo_x: 127.055618071591,
    addr_geo_y: 37.5027883731716,
    user_phone: '050-71241-3438',
    address: '서울특별시 강남구 대치동 삼성로75길 33 ',
    addr_detail: '',
    first_sale_date: '2020-01-12 17:55:00',
    last_sale_date: '2021-03-25 14:35:25',
    pay_amount: 57000,
    sale_count: 2,
    distance: 681.95,
    key: '37.5027883731716,127.055618071591',
  },
  {
    tdu_sno: 69994,
    office_sno: 21,
    addr_geo_x: 127.058956274286,
    addr_geo_y: 37.5040988885784,
    user_phone: '0504-4753-9366',
    address: '서울특별시 강남구 대치동 역삼로81길 13 ',
    addr_detail: '',
    first_sale_date: '2020-01-18 15:15:00',
    last_sale_date: '2021-03-23 18:47:18',
    pay_amount: 21000,
    sale_count: 1,
    distance: 574.43,
    key: '37.5040988885784,127.058956274286',
  },
  {
    tdu_sno: 70017,
    office_sno: 21,
    addr_geo_x: 127.058493065304,
    addr_geo_y: 37.4970353225681,
    user_phone: '050-37394-0846',
    address: '서울특별시 강남구 대치동 삼성로57길 37 5 대치하나빌',
    addr_detail: '',
    first_sale_date: '2020-01-18 20:17:00',
    last_sale_date: '2021-03-23 17:50:13',
    pay_amount: 29500,
    sale_count: 1,
    distance: 440.96,
    key: '37.4970353225681,127.058493065304',
  },
  {
    tdu_sno: 70067,
    office_sno: 21,
    addr_geo_x: 127.05520367858,
    addr_geo_y: 37.5030721140025,
    user_phone: '050-4467-93552',
    address: '서울특별시 강남구 대치동 역삼로73길 16 ',
    addr_detail: '',
    first_sale_date: '2020-01-19 19:52:00',
    last_sale_date: '2021-03-24 20:57:13',
    pay_amount: 44500,
    sale_count: 1,
    distance: 729.56,
    key: '37.5030721140025,127.05520367858',
  },
  {
    tdu_sno: 70085,
    office_sno: 21,
    addr_geo_x: 127.05943164765,
    addr_geo_y: 37.5058510154577,
    user_phone: '0503-7400-3181',
    address: '서울특별시 강남구 대치동 삼성로84길 23 효령주택',
    addr_detail: '',
    first_sale_date: '2020-01-20 12:56:00',
    last_sale_date: '2021-03-26 12:21:57',
    pay_amount: 29500,
    sale_count: 1,
    distance: 733.64,
    key: '37.5058510154577,127.05943164765',
  },
  {
    tdu_sno: 70834,
    office_sno: 21,
    addr_geo_x: 127.058223216371,
    addr_geo_y: 37.5006408321078,
    user_phone: '0504-4764-6941',
    address: '서울특별시 강남구 대치동 삼성로63길 16 23',
    addr_detail: '',
    first_sale_date: '2020-01-25 12:17:00',
    last_sale_date: '2021-03-23 12:23:02',
    pay_amount: 47500,
    sale_count: 1,
    distance: 372.61,
    key: '37.5006408321078,127.058223216371',
  },
  {
    tdu_sno: 70861,
    office_sno: 21,
    addr_geo_x: 127.062646222255,
    addr_geo_y: 37.5018993467629,
    user_phone: '050-71203-5746',
    address: '서울특별시 강남구 대치동 삼성로72길 45 ',
    addr_detail: '',
    first_sale_date: '2020-01-25 17:12:00',
    last_sale_date: '2021-03-24 16:49:52',
    pay_amount: 18000,
    sale_count: 1,
    distance: 252.97,
    key: '37.5018993467629,127.062646222255',
  },
  {
    tdu_sno: 70925,
    office_sno: 21,
    addr_geo_x: 127.056725906238,
    addr_geo_y: 37.4985598761637,
    user_phone: '050-37400-2514',
    address: '서울특별시 강남구 대치동 삼성로61길 46 ',
    addr_detail: '',
    first_sale_date: '2020-01-27 12:56:00',
    last_sale_date: '2021-03-29 18:50:59',
    pay_amount: 38500,
    sale_count: 1,
    distance: 502.59,
    key: '37.4985598761637,127.056725906238',
  },
  {
    tdu_sno: 70965,
    office_sno: 21,
    addr_geo_x: 127.058579611206,
    addr_geo_y: 37.5021478584002,
    user_phone: '010-5674-0838',
    address: '서울특별시 강남구 대치동 삼성로71길 5 3',
    addr_detail: '',
    first_sale_date: '2020-01-28 17:10:00',
    last_sale_date: '2021-03-26 15:29:25',
    pay_amount: 29500,
    sale_count: 1,
    distance: 427.35,
    key: '37.5021478584002,127.058579611206',
  },
  {
    tdu_sno: 71050,
    office_sno: 21,
    addr_geo_x: 127.06046905373,
    addr_geo_y: 37.4983618704159,
    user_phone: '0504-4719-7563',
    address: '서울특별시 강남구 대치동 도곡로 458 정일빌딩',
    addr_detail: '',
    first_sale_date: '2020-01-30 22:08:00',
    last_sale_date: '2021-03-26 18:03:25',
    pay_amount: 46500,
    sale_count: 1,
    distance: 212.67,
    key: '37.4983618704159,127.06046905373',
  },
  {
    tdu_sno: 71594,
    office_sno: 21,
    addr_geo_x: 127.057134912086,
    addr_geo_y: 37.5029712742061,
    user_phone: '050-7918-8539',
    address: '서울특별시 강남구 대치동 역삼로 455 4',
    addr_detail: '',
    first_sale_date: '2020-01-31 14:08:00',
    last_sale_date: '2021-03-24 17:26:55',
    pay_amount: 47500,
    sale_count: 1,
    distance: 583.85,
    key: '37.5029712742061,127.057134912086',
  },
  {
    tdu_sno: 71815,
    office_sno: 21,
    addr_geo_x: 127.052162763088,
    addr_geo_y: 37.5028556327807,
    user_phone: '010-3926-6878',
    address: '서울특별시 강남구 대치동 역삼로65길 25 ',
    addr_detail: '',
    first_sale_date: '2020-02-06 12:22:00',
    last_sale_date: '2021-03-23 21:20:31',
    pay_amount: 34500,
    sale_count: 1,
    distance: 959.3,
    key: '37.5028556327807,127.052162763088',
  },
  {
    tdu_sno: 72480,
    office_sno: 21,
    addr_geo_x: 127.052370453579,
    addr_geo_y: 37.4980695774469,
    user_phone: '050-4475-95802',
    address: '서울특별시 강남구 대치동 선릉로 318 ',
    addr_detail: '',
    first_sale_date: '2020-02-09 17:10:00',
    last_sale_date: '2021-03-26 21:35:58',
    pay_amount: 34500,
    sale_count: 1,
    distance: 889.54,
    key: '37.4980695774469,127.052370453579',
  },
  {
    tdu_sno: 72620,
    office_sno: 21,
    addr_geo_x: 127.057340419503,
    addr_geo_y: 37.4972237475024,
    user_phone: '050-7912-9972',
    address: '서울특별시 강남구 대치동 삼성로57길 46 아크로빌',
    addr_detail: '',
    first_sale_date: '2020-02-15 13:41:00',
    last_sale_date: '2021-03-26 18:19:41',
    pay_amount: 17500,
    sale_count: 1,
    distance: 510.47,
    key: '37.4972237475024,127.057340419503',
  },
  {
    tdu_sno: 72632,
    office_sno: 21,
    addr_geo_x: 127.056642042996,
    addr_geo_y: 37.5013178057303,
    user_phone: '050-37385-5997',
    address: '서울특별시 강남구 대치동 삼성로71길 27 6',
    addr_detail: '',
    first_sale_date: '2020-02-15 17:12:00',
    last_sale_date: '2021-03-26 11:48:07',
    pay_amount: 29500,
    sale_count: 1,
    distance: 529.03,
    key: '37.5013178057303,127.056642042996',
  },
  {
    tdu_sno: 72647,
    office_sno: 21,
    addr_geo_x: 127.051225786119,
    addr_geo_y: 37.5028646023608,
    user_phone: '050-37383-5066',
    address: '서울특별시 강남구 대치동 선릉로82길 15 ',
    addr_detail: '',
    first_sale_date: '2020-02-15 20:38:00',
    last_sale_date: '2021-03-24 13:21:51',
    pay_amount: 21000,
    sale_count: 1,
    distance: 1036.82,
    key: '37.5028646023608,127.051225786119',
  },
  {
    tdu_sno: 73460,
    office_sno: 21,
    addr_geo_x: 127.058192286112,
    addr_geo_y: 37.5027537959204,
    user_phone: '0503-7378-9991',
    address: '서울특별시 강남구 대치동 역삼로 466 ',
    addr_detail: '',
    first_sale_date: '2020-02-24 13:01:00',
    last_sale_date: '2021-03-23 14:56:58',
    pay_amount: 32500,
    sale_count: 1,
    distance: 497.99,
    key: '37.5027537959204,127.058192286112',
  },
  {
    tdu_sno: 73509,
    office_sno: 21,
    addr_geo_x: 127.061726304099,
    addr_geo_y: 37.5026944278975,
    user_phone: '050-7123-85737',
    address: '서울특별시 강남구 대치동 역삼로88길 19 3',
    addr_detail: '',
    first_sale_date: '2020-02-26 13:34:00',
    last_sale_date: '2021-03-25 11:13:08',
    pay_amount: 21000,
    sale_count: 1,
    distance: 342.24,
    key: '37.5026944278975,127.061726304099',
  },
  {
    tdu_sno: 73999,
    office_sno: 21,
    addr_geo_x: 127.066997299214,
    addr_geo_y: 37.5077999850219,
    user_phone: '050-71254-3775',
    address: '서울특별시 강남구 대치동 테헤란로114길 30 ',
    addr_detail: '',
    first_sale_date: '2020-02-27 21:25:00',
    last_sale_date: '2021-03-24 17:05:54',
    pay_amount: 23000,
    sale_count: 1,
    distance: 998.54,
    key: '37.5077999850219,127.066997299214',
  },
  {
    tdu_sno: 74005,
    office_sno: 21,
    addr_geo_x: 127.053268696919,
    addr_geo_y: 37.5001331106452,
    user_phone: '010-7111-3911',
    address: '서울특별시 강남구 대치동 역삼로64길 15 ',
    addr_detail: '',
    first_sale_date: '2020-02-28 12:20:00',
    last_sale_date: '2021-03-26 12:20:10',
    pay_amount: 19000,
    sale_count: 1,
    distance: 794.72,
    key: '37.5001331106452,127.053268696919',
  },
  {
    tdu_sno: 74086,
    office_sno: 21,
    addr_geo_x: 127.059155199029,
    addr_geo_y: 37.5039419240926,
    user_phone: '050-7924-5342',
    address: '서울특별시 강남구 대치동 역삼로81길 8 ',
    addr_detail: '',
    first_sale_date: '2020-03-02 12:15:00',
    last_sale_date: '2021-03-23 21:31:47',
    pay_amount: 17500,
    sale_count: 1,
    distance: 550.53,
    key: '37.5039419240926,127.059155199029',
  },
  {
    tdu_sno: 74096,
    office_sno: 21,
    addr_geo_x: 127.05383776354,
    addr_geo_y: 37.502808929987,
    user_phone: '010-3039-5387',
    address: '서울특별시 강남구 대치동 역삼로69길 17 ',
    addr_detail: '',
    first_sale_date: '2020-03-02 17:25:00',
    last_sale_date: '2021-03-24 15:24:00',
    pay_amount: 12500,
    sale_count: 1,
    distance: 821.75,
    key: '37.502808929987,127.05383776354',
  },
  {
    tdu_sno: 74188,
    office_sno: 21,
    addr_geo_x: 127.057677426222,
    addr_geo_y: 37.5023855371293,
    user_phone: '010-8928-4850',
    address: '서울특별시 강남구 대치동 역삼로 460 2',
    addr_detail: '',
    first_sale_date: '2020-03-06 15:08:00',
    last_sale_date: '2021-03-25 13:16:32',
    pay_amount: 28000,
    sale_count: 1,
    distance: 505.96,
    key: '37.5023855371293,127.057677426222',
  },
  {
    tdu_sno: 74852,
    office_sno: 21,
    addr_geo_x: 127.056571524892,
    addr_geo_y: 37.4971881680472,
    user_phone: '050-7937-5101',
    address: '서울특별시 강남구 대치동 도곡로 420 ',
    addr_detail: '',
    first_sale_date: '2020-03-09 14:11:00',
    last_sale_date: '2021-03-23 15:00:59',
    pay_amount: 47500,
    sale_count: 1,
    distance: 571.11,
    key: '37.4971881680472,127.056571524892',
  },
  {
    tdu_sno: 74975,
    office_sno: 21,
    addr_geo_x: 127.059575582532,
    addr_geo_y: 37.505516760151,
    user_phone: '050-7909-7024',
    address: '서울특별시 강남구 대치동 삼성로82길 23 ',
    addr_detail: '',
    first_sale_date: '2020-03-15 12:07:00',
    last_sale_date: '2021-03-26 19:35:48',
    pay_amount: 28000,
    sale_count: 1,
    distance: 694.37,
    key: '37.505516760151,127.059575582532',
  },
  {
    tdu_sno: 74990,
    office_sno: 21,
    addr_geo_x: 127.066764125492,
    addr_geo_y: 37.5083543250525,
    user_phone: '0503-7372-4270',
    address: '서울특별시 강남구 대치동 테헤란로114길 20 ',
    addr_detail: '',
    first_sale_date: '2020-03-15 21:37:00',
    last_sale_date: '2021-03-24 22:58:36',
    pay_amount: 29500,
    sale_count: 1,
    distance: 1046.84,
    key: '37.5083543250525,127.066764125492',
  },
  {
    tdu_sno: 75608,
    office_sno: 21,
    addr_geo_x: 127.056348087478,
    addr_geo_y: 37.4990198404433,
    user_phone: '050-71231-1101',
    address: '서울특별시 강남구 대치동 삼성로63길 48 ',
    addr_detail: '',
    first_sale_date: '2020-03-21 13:51:00',
    last_sale_date: '2021-03-23 21:59:34',
    pay_amount: 12500,
    sale_count: 1,
    distance: 525.83,
    key: '37.4990198404433,127.056348087478',
  },
  {
    tdu_sno: 75645,
    office_sno: 21,
    addr_geo_x: 127.050867018924,
    addr_geo_y: 37.5024821904039,
    user_phone: '050-7934-4129',
    address: '서울특별시 강남구 대치동 선릉로82길 10 예일빌딩',
    addr_detail: '',
    first_sale_date: '2020-03-22 17:40:00',
    last_sale_date: '2021-03-26 20:07:50',
    pay_amount: 41000,
    sale_count: 1,
    distance: 1053.07,
    key: '37.5024821904039,127.050867018924',
  },
  {
    tdu_sno: 76336,
    office_sno: 21,
    addr_geo_x: 127.05533837197,
    addr_geo_y: 37.5050615609923,
    user_phone: '010-2182-7604',
    address: '서울특별시 강남구 대치동 테헤란로78길 16 노벨빌딩',
    addr_detail: '',
    first_sale_date: '2020-03-30 13:56:00',
    last_sale_date: '2021-03-26 15:50:27',
    pay_amount: 21000,
    sale_count: 1,
    distance: 857.39,
    key: '37.5050615609923,127.05533837197',
  },
  {
    tdu_sno: 77077,
    office_sno: 21,
    addr_geo_x: 127.052743439657,
    addr_geo_y: 37.5030908977993,
    user_phone: '050-7864-6163',
    address: '서울특별시 강남구 대치동 역삼로67길 26 ',
    addr_detail: '',
    first_sale_date: '2020-04-07 19:20:00',
    last_sale_date: '2021-03-23 21:28:32',
    pay_amount: 32500,
    sale_count: 1,
    distance: 922.5,
    key: '37.5030908977993,127.052743439657',
  },
  {
    tdu_sno: 77102,
    office_sno: 21,
    addr_geo_x: 127.057471494535,
    addr_geo_y: 37.5012195558786,
    user_phone: '010-5169-0772',
    address: '서울특별시 강남구 대치동 삼성로69길 21 ',
    addr_detail: '',
    first_sale_date: '2020-04-09 20:01:00',
    last_sale_date: '2021-03-26 16:38:17',
    pay_amount: 18000,
    sale_count: 1,
    distance: 456.96,
    key: '37.5012195558786,127.057471494535',
  },
  {
    tdu_sno: 77149,
    office_sno: 21,
    addr_geo_x: 127.053055886879,
    addr_geo_y: 37.4993692433737,
    user_phone: '050406176283',
    address: '서울특별시 강남구 대치동 선릉로68길 17 ',
    addr_detail: '',
    first_sale_date: '2020-04-12 11:53:00',
    last_sale_date: '2021-03-25 16:51:50',
    pay_amount: 28000,
    sale_count: 1,
    distance: 812.22,
    key: '37.4993692433737,127.053055886879',
  },
  {
    tdu_sno: 77714,
    office_sno: 21,
    addr_geo_x: 127.05264766207,
    addr_geo_y: 37.5005327148999,
    user_phone: '010-2972-1969',
    address: '서울특별시 강남구 대치동 선릉로72길 19 ',
    addr_detail: '',
    first_sale_date: '2020-04-13 20:40:00',
    last_sale_date: '2021-03-26 21:50:00',
    pay_amount: 18000,
    sale_count: 1,
    distance: 853.37,
    key: '37.5005327148999,127.05264766207',
  },
  {
    tdu_sno: 77750,
    office_sno: 21,
    addr_geo_x: 127.052066011966,
    addr_geo_y: 37.5021237885759,
    user_phone: '050447691860',
    address: '서울특별시 강남구 대치동 역삼로63길 15 ',
    addr_detail: '',
    first_sale_date: '2020-04-16 11:48:00',
    last_sale_date: '2021-03-24 19:59:23',
    pay_amount: 19000,
    sale_count: 1,
    distance: 940.24,
    key: '37.5021237885759,127.052066011966',
  },
  {
    tdu_sno: 78429,
    office_sno: 21,
    addr_geo_x: 127.056824149732,
    addr_geo_y: 37.4986702022103,
    user_phone: '0507-1243-9852',
    address: '서울특별시 강남구 대치동 도곡로73길 11 1',
    addr_detail: '',
    first_sale_date: '2020-04-23 11:38:36',
    last_sale_date: '2021-03-26 19:43:49',
    pay_amount: 21000,
    sale_count: 1,
    distance: 491.33,
    key: '37.4986702022103,127.056824149732',
  },
  {
    tdu_sno: 78477,
    office_sno: 21,
    addr_geo_x: 127.065448548249,
    addr_geo_y: 37.5059245105322,
    user_phone: '050-4062-36534',
    address: '서울특별시 강남구 대치동 영동대로 410 ',
    addr_detail: '',
    first_sale_date: '2020-04-26 15:06:07',
    last_sale_date: '2021-03-23 16:58:26',
    pay_amount: 18000,
    sale_count: 1,
    distance: 752.85,
    key: '37.5059245105322,127.065448548249',
  },
  {
    tdu_sno: 78525,
    office_sno: 21,
    addr_geo_x: 127.058316332866,
    addr_geo_y: 37.5024409957021,
    user_phone: '050-7833-0656',
    address: '서울특별시 강남구 대치동 삼성로71길 6 ',
    addr_detail: '',
    first_sale_date: '2020-04-30 15:16:59',
    last_sale_date: '2021-03-24 14:52:43',
    pay_amount: 46500,
    sale_count: 1,
    distance: 466.3,
    key: '37.5024409957021,127.058316332866',
  },
  {
    tdu_sno: 79223,
    office_sno: 21,
    addr_geo_x: 127.056122245689,
    addr_geo_y: 37.5014310399487,
    user_phone: '010-7210-9721',
    address: '서울특별시 강남구 대치동 삼성로71길 35 ',
    addr_detail: '',
    first_sale_date: '2020-05-01 22:49:19',
    last_sale_date: '2021-03-23 16:50:08',
    pay_amount: 41000,
    sale_count: 1,
    distance: 576.4,
    key: '37.5014310399487,127.056122245689',
  },
  {
    tdu_sno: 79244,
    office_sno: 21,
    addr_geo_x: 127.063443541334,
    addr_geo_y: 37.5001820546491,
    user_phone: '0503-7354-7791',
    address: '서울특별시 강남구 대치동 도곡로87길 6 ',
    addr_detail: '',
    first_sale_date: '2020-05-02 22:29:05',
    last_sale_date: '2021-03-24 11:24:39',
    pay_amount: 66000,
    sale_count: 2,
    distance: 120.53,
    key: '37.5001820546491,127.063443541334',
  },
  {
    tdu_sno: 79324,
    office_sno: 21,
    addr_geo_x: 127.060150611709,
    addr_geo_y: 37.4996448855404,
    user_phone: '010-4731-2557',
    address: '서울특별시 강남구 대치동 삼성로 311 제동빌딩',
    addr_detail: '',
    first_sale_date: '2020-05-09 15:42:25',
    last_sale_date: '2021-03-25 12:28:15',
    pay_amount: 23000,
    sale_count: 1,
    distance: 185.76,
    key: '37.4996448855404,127.060150611709',
  },
  {
    tdu_sno: 80143,
    office_sno: 21,
    addr_geo_x: 127.053268654745,
    addr_geo_y: 37.5019840470069,
    user_phone: '050-7834-1080',
    address: '서울특별시 강남구 대치동 역삼로67길 8 ',
    addr_detail: '',
    first_sale_date: '2020-05-16 12:17:06',
    last_sale_date: '2021-03-24 12:48:48',
    pay_amount: 34500,
    sale_count: 1,
    distance: 834.41,
    key: '37.5019840470069,127.053268654745',
  },
  {
    tdu_sno: 80156,
    office_sno: 21,
    addr_geo_x: 127.053894182029,
    addr_geo_y: 37.5008994929004,
    user_phone: '010-5803-8567',
    address: '서울특별시 강남구 대치동 선릉로72길 35 ',
    addr_detail: '',
    first_sale_date: '2020-05-16 18:57:42',
    last_sale_date: '2021-03-23 11:35:58',
    pay_amount: 18000,
    sale_count: 1,
    distance: 750.75,
    key: '37.5008994929004,127.053894182029',
  },
  {
    tdu_sno: 80933,
    office_sno: 21,
    addr_geo_x: 127.056225014682,
    addr_geo_y: 37.5001825573202,
    user_phone: '010-4057-2546',
    address: '서울특별시 강남구 대치동 도곡로73길 37 ',
    addr_detail: '',
    first_sale_date: '2020-05-20 11:21:53',
    last_sale_date: '2021-03-25 11:53:11',
    pay_amount: 19000,
    sale_count: 1,
    distance: 535.41,
    key: '37.5001825573202,127.056225014682',
  },
  {
    tdu_sno: 80954,
    office_sno: 21,
    addr_geo_x: 127.056770110024,
    addr_geo_y: 37.5019778210734,
    user_phone: '010-2408-1484',
    address: '서울특별시 강남구 대치동 역삼로78길 6 ',
    addr_detail: '',
    first_sale_date: '2020-05-22 17:15:45',
    last_sale_date: '2021-03-26 14:06:19',
    pay_amount: 17500,
    sale_count: 1,
    distance: 549.08,
    key: '37.5019778210734,127.056770110024',
  },
  {
    tdu_sno: 80965,
    office_sno: 21,
    addr_geo_x: 127.056407268867,
    addr_geo_y: 37.5022251407478,
    user_phone: '050-7906-9235',
    address: '서울특별시 강남구 대치동 역삼로 450 ',
    addr_detail: '',
    first_sale_date: '2020-05-23 17:06:29',
    last_sale_date: '2021-03-24 12:53:33',
    pay_amount: 28000,
    sale_count: 1,
    distance: 590.36,
    key: '37.5022251407478,127.056407268867',
  },
  {
    tdu_sno: 80970,
    office_sno: 21,
    addr_geo_x: 127.055326890919,
    addr_geo_y: 37.5016108954836,
    user_phone: '050-7908-8747',
    address: '서울특별시 강남구 대치동 역삼로74길 5 ',
    addr_detail: '',
    first_sale_date: '2020-05-24 12:39:51',
    last_sale_date: '2021-03-26 17:05:19',
    pay_amount: 44500,
    sale_count: 1,
    distance: 649.18,
    key: '37.5016108954836,127.055326890919',
  },
  {
    tdu_sno: 80971,
    office_sno: 21,
    addr_geo_x: 127.052482443222,
    addr_geo_y: 37.5021573920135,
    user_phone: '0504-4762-2300',
    address: '서울특별시 강남구 대치동 역삼로65길 13 ',
    addr_detail: '',
    first_sale_date: '2020-05-24 15:19:04',
    last_sale_date: '2021-03-23 13:26:08',
    pay_amount: 17500,
    sale_count: 1,
    distance: 906.33,
    key: '37.5021573920135,127.052482443222',
  },
  {
    tdu_sno: 80983,
    office_sno: 21,
    addr_geo_x: 127.052982069884,
    addr_geo_y: 37.5037329376349,
    user_phone: '010-8010-9520',
    address: '서울특별시 강남구 대치동 선릉로86길 40 4',
    addr_detail: '',
    first_sale_date: '2020-05-25 16:34:53',
    last_sale_date: '2021-03-26 22:21:02',
    pay_amount: 12500,
    sale_count: 1,
    distance: 935.9,
    key: '37.5037329376349,127.052982069884',
  },
  {
    tdu_sno: 81004,
    office_sno: 21,
    addr_geo_x: 127.053972199329,
    addr_geo_y: 37.4988354326415,
    user_phone: '050-7942-2430',
    address: '서울특별시 강남구 대치동 도곡로63길 28 ',
    addr_detail: '',
    first_sale_date: '2020-05-27 12:59:00',
    last_sale_date: '2021-03-26 21:32:42',
    pay_amount: 48000,
    sale_count: 1,
    distance: 736.34,
    key: '37.4988354326415,127.053972199329',
  },
  {
    tdu_sno: 81005,
    office_sno: 21,
    addr_geo_x: 127.064501719435,
    addr_geo_y: 37.500834891089,
    user_phone: '050-7907-8378',
    address: '서울특별시 강남구 대치동 도곡로93길 9 ',
    addr_detail: '',
    first_sale_date: '2020-05-27 14:13:06',
    last_sale_date: '2021-03-26 22:59:06',
    pay_amount: 21000,
    sale_count: 1,
    distance: 238.17,
    key: '37.500834891089,127.064501719435',
  },
  {
    tdu_sno: 81737,
    office_sno: 21,
    addr_geo_x: 127.058882032366,
    addr_geo_y: 37.5047538663788,
    user_phone: '0504-4687-8017',
    address: '서울특별시 강남구 대치동 역삼로81길 22 프라임빌',
    addr_detail: '',
    first_sale_date: '2020-05-29 21:58:10',
    last_sale_date: '2021-03-23 19:45:23',
    pay_amount: 18000,
    sale_count: 1,
    distance: 641.3,
    key: '37.5047538663788,127.058882032366',
  },
  {
    tdu_sno: 81762,
    office_sno: 21,
    addr_geo_x: 127.06272738712,
    addr_geo_y: 37.5029383433672,
    user_phone: '010-4805-9098',
    address: '서울특별시 강남구 대치동 역삼로92길 20 3',
    addr_detail: '',
    first_sale_date: '2020-06-01 14:09:47',
    last_sale_date: '2021-03-23 15:32:18',
    pay_amount: 19000,
    sale_count: 1,
    distance: 368.5,
    key: '37.5029383433672,127.06272738712',
  },
  {
    tdu_sno: 82462,
    office_sno: 21,
    addr_geo_x: 127.052792649128,
    addr_geo_y: 37.5042343418543,
    user_phone: '010-9117-0933',
    address: '서울특별시 강남구 대치동 테헤란로70길 14 12',
    addr_detail: '',
    first_sale_date: '2020-06-06 17:30:49',
    last_sale_date: '2021-03-26 20:21:52',
    pay_amount: 12500,
    sale_count: 1,
    distance: 978.43,
    key: '37.5042343418543,127.052792649128',
  },
  {
    tdu_sno: 82529,
    office_sno: 21,
    addr_geo_x: 127.054945837963,
    addr_geo_y: 37.500159282558,
    user_phone: '050-37362-6095',
    address: '서울특별시 강남구 대치동 역삼로74길 26 정진맨션',
    addr_detail: '',
    first_sale_date: '2020-06-13 15:38:43',
    last_sale_date: '2021-03-27 16:03:17',
    pay_amount: 44500,
    sale_count: 1,
    distance: 647.44,
    key: '37.500159282558,127.054945837963',
  },
  {
    tdu_sno: 83276,
    office_sno: 21,
    addr_geo_x: 127.060759593651,
    addr_geo_y: 37.4985249836703,
    user_phone: '050-3736-64661',
    address: '서울특별시 강남구 대치동 도곡로 462 ',
    addr_detail: '',
    first_sale_date: '2020-06-15 16:58:16',
    last_sale_date: '2021-03-26 14:50:08',
    pay_amount: 36500,
    sale_count: 1,
    distance: 181.54,
    key: '37.4985249836703,127.060759593651',
  },
  {
    tdu_sno: 84028,
    office_sno: 21,
    addr_geo_x: 127.06066935795,
    addr_geo_y: 37.5039860262112,
    user_phone: '0504-4730-5907',
    address: '서울특별시 강남구 대치동 역삼로 527 그린메디칼사옥',
    addr_detail: '',
    first_sale_date: '2020-06-27 14:18:32',
    last_sale_date: '2021-03-25 22:40:03',
    pay_amount: 18000,
    sale_count: 1,
    distance: 502.55,
    key: '37.5039860262112,127.06066935795',
  },
  {
    tdu_sno: 84036,
    office_sno: 21,
    addr_geo_x: 127.0601904901,
    addr_geo_y: 37.5015649088087,
    user_phone: '010-7433-9995',
    address: '서울특별시 강남구 대치동 삼성로68길 5 대치 주성아파트',
    addr_detail: '',
    first_sale_date: '2020-06-28 11:36:06',
    last_sale_date: '2021-03-24 17:56:58',
    pay_amount: 18000,
    sale_count: 1,
    distance: 280.66,
    key: '37.5015649088087,127.0601904901',
  },
  {
    tdu_sno: 116314,
    office_sno: 21,
    addr_geo_x: 127.055435600547,
    addr_geo_y: 37.5039434578937,
    user_phone: '010-5848-8850',
    address: '서울특별시 강남구 대치동 삼성로81길 27 ',
    addr_detail: '',
    first_sale_date: '2020-07-28 11:01:21',
    last_sale_date: '2021-03-23 14:34:13',
    pay_amount: 44500,
    sale_count: 1,
    distance: 768.4,
    key: '37.5039434578937,127.055435600547',
  },
  {
    tdu_sno: 131554,
    office_sno: 21,
    addr_geo_x: 127.059140377351,
    addr_geo_y: 37.4990590208389,
    user_phone: '010-2711-6317',
    address: '서울특별시 강남구 대치동 삼성로61길 15 ',
    addr_detail: '',
    first_sale_date: '2020-08-27 16:53:08',
    last_sale_date: '2021-03-26 13:45:17',
    pay_amount: 21000,
    sale_count: 1,
    distance: 282.51,
    key: '37.4990590208389,127.059140377351',
  },
  {
    tdu_sno: 150450,
    office_sno: 21,
    addr_geo_x: 127.059120190322,
    addr_geo_y: 37.5064446637805,
    user_phone: '050-7124-86574',
    address: '서울특별시 강남구 대치동 테헤란로86길 14 윤천빌딩',
    addr_detail: '',
    first_sale_date: '2020-09-10 19:49:42',
    last_sale_date: '2021-03-24 19:22:19',
    pay_amount: 12500,
    sale_count: 1,
    distance: 805.07,
    key: '37.5064446637805,127.059120190322',
  },
  {
    tdu_sno: 162080,
    office_sno: 21,
    addr_geo_x: 127.059052989507,
    addr_geo_y: 37.5065630894523,
    user_phone: '010-4633-9426',
    address: '서울특별시 강남구 대치동 테헤란로86길 12 디케이오케이빌딩',
    addr_detail: '',
    first_sale_date: '2020-09-24 12:24:13',
    last_sale_date: '2021-03-26 16:18:30',
    pay_amount: 32500,
    sale_count: 1,
    distance: 819.47,
    key: '37.5065630894523,127.059052989507',
  },
  {
    tdu_sno: 208372,
    office_sno: 21,
    addr_geo_x: 127.054431279119,
    addr_geo_y: 37.5032750184755,
    user_phone: '050-71212-9510',
    address: '서울특별시 강남구 대치동 삼성로75길 40 11',
    addr_detail: '',
    first_sale_date: '2020-11-10 18:31:46',
    last_sale_date: '2021-03-25 15:18:43',
    pay_amount: 21000,
    sale_count: 1,
    distance: 799.62,
    key: '37.5032750184755,127.054431279119',
  },
  {
    tdu_sno: 216328,
    office_sno: 21,
    addr_geo_x: 127.055219085074,
    addr_geo_y: 37.502646291565,
    user_phone: '050-4476-75755',
    address: '서울특별시 강남구 대치동 역삼로73길 9 ',
    addr_detail: '',
    first_sale_date: '2020-11-17 13:08:03',
    last_sale_date: '2021-03-26 21:44:43',
    pay_amount: 23000,
    sale_count: 1,
    distance: 704.79,
    key: '37.502646291565,127.055219085074',
  },
  {
    tdu_sno: 309031,
    office_sno: 21,
    addr_geo_x: 127.053399830844,
    addr_geo_y: 37.5026033390823,
    user_phone: '010-5550-5538',
    address: '서울특별시 강남구 대치동 테헤란로68길 41 ',
    addr_detail: '',
    first_sale_date: '2021-01-21 12:00:06',
    last_sale_date: '2021-03-24 22:35:20',
    pay_amount: 17500,
    sale_count: 1,
    distance: 847.69,
    key: '37.5026033390823,127.053399830844',
  },
  {
    tdu_sno: 439648,
    office_sno: 21,
    addr_geo_x: 127.052868319544,
    addr_geo_y: 37.5000771578316,
    user_phone: '050-7845-3553',
    address: '서울특별시 강남구 대치동 역삼로64길 16 2',
    addr_detail: '',
    first_sale_date: '2021-03-25 17:10:37',
    last_sale_date: '2021-03-25 17:10:37',
    pay_amount: 18000,
    sale_count: 1,
    distance: 829.57,
    key: '37.5000771578316,127.052868319544',
  },
  {
    tdu_sno: 441528,
    office_sno: 21,
    addr_geo_x: 127.058478336869,
    addr_geo_y: 37.5006256592936,
    user_phone: '0504-4706-9641',
    address: '서울특별시 강남구 대치동 삼성로63길 16 14',
    addr_detail: '',
    first_sale_date: '2021-03-26 16:36:17',
    last_sale_date: '2021-03-26 16:36:17',
    pay_amount: 34500,
    sale_count: 1,
    distance: 350.65,
    key: '37.5006256592936,127.058478336869',
  },
];

export const ORDER_FREQ_COUNT = {
  one: { name: '1건', value: 0 },
  moreOne: { name: '1건 이상', value: 1 },
  moreTwo: { name: '2건 이상', value: 2 },
  moreThree: { name: '3건 이상', value: 3 },
  moreFive: { name: '5건 이상', value: 5 },
};

export const getOrderLabelType = (firstOrderDate = '', lastOrderDate = '') => {
  // 정렬 우선순위 : 0 주소없음 | 1 재주문 | 2 신규주문
  if (!firstOrderDate || !lastOrderDate) {
    return 0;
  }

  if (firstOrderDate !== lastOrderDate) {
    return 1;
  }

  if (firstOrderDate === lastOrderDate) {
    return 2;
  }

  return 0;
};

export const trimSelectedOfficeNames = (officeList = [], selectedList = []) => {
  let originList = convertForFindName(filterDisableConts(officeList), 'office_sno', 'partner_name');

  let result =
    selectedList.length === 0
      ? ['전체매장']
      : selectedList.map(selected => {
        return replaceBrandName(originList[selected]);
      });
  result = result.join(', ');

  return result;
};

export const sortList = (data, viewType, sortType = [1, true]) => {
  if (!data || !sortType) {
    return data;
  }

  var sortKey = '';
  if (sortType[0] === 1) {
    sortKey = getDataKeyByViewType(viewType, 'total');
  } else if (sortType[0] === 2) {
    sortKey = getDataKeyByViewType(viewType, 're');
  } else if (sortType[0] === 3) {
    sortKey = getDataKeyByViewType(viewType, 'new');
  } else if (sortType[0] === 4) {
    sortKey = 'pay_date';
  } else if (sortType[0] === 5) {
    sortKey = getDataKeyByViewType(viewType, 'unverified');
  }

  if (sortType[1] === true) {
    return sortAsc(data, sortKey);
  } else {
    return sortDesc(data, sortKey);
  }
};

export const filterRangeCount = (markerDataItem, rangeCount, key = 'sale_count') => {
  switch (true) {
    case rangeCount === 0:
      return markerDataItem[key] === 1;
    case rangeCount === 1:
      return true;
    case rangeCount > 0:
      return markerDataItem[key] >= rangeCount && markerDataItem['tdu_sno'] > 0;
    default:
      return true;
  }
};

export const filterOrderViewType = (markerDataItem, orderViewType) => {
  switch (true) {
    case orderViewType === 0:
      return true;
    case orderViewType === 1:
      return markerDataItem['first_sale_date'] === markerDataItem['last_sale_date'];
    case orderViewType === 2:
      return markerDataItem['first_sale_date'] !== markerDataItem['last_sale_date'];
    case orderViewType === 3:
      return markerDataItem['tdu_sno'] <= 0;
    default:
      return true;
  }
};

export const getSumDeliveryData = (
  deliveryOfficeAllListData,
  deliveryDateListData,
  viewType,
  filterDate,
  selectedOfficeSno = 0,
) => {
  let result = {
    // 매장별 합
    totalSum: 0, // 뷰타입에 따른 합
    reSum: 0,
    newSum: 0,
    unverifiedSum: 0,

    // (신규추가)뷰타입과 관계없이
    sumOrderCustomer: 0, // 건
    sumCustomerCount: 0, // 고객
    sumOrderSale: 0, // 금액

    latelyOrder: [], // 건
    latelyCustomer: [], // 고객
    latelySale: [], //금액

    // 특정매장 날짜별 합
    officeTotalSum: 0,
    officeReSum: 0,
    officeNewSum: 0,
    officeLatelyOrder: [],
    officeLatelyCustomer: [],
    officeLatelySale: [],

    // (신규추가)뷰타입과 관계없이
    sumOfficeOrderCustomer: 0, // 건
    sumOfficeCustomerCount: 0, // 고객
    sumOfficeOrderSale: 0, // 금액

    // 선택한 매장을 매칭시키기 위해
    officeSno: selectedOfficeSno,
  };

  if (deliveryOfficeAllListData && deliveryOfficeAllListData.length > 0) {
    result.sumOrderCustomer = sumItems(deliveryOfficeAllListData, getDataKeyByViewType(1, 'total')); // 건 1
    result.sumCustomerCount = sumItems(deliveryOfficeAllListData, getDataKeyByViewType(2, 'total')); // 고객 2
    result.sumOrderSale = sumItems(deliveryOfficeAllListData, getDataKeyByViewType(3, 'total')); // 금액 3

    result.totalSum = sumItems(deliveryOfficeAllListData, getDataKeyByViewType(viewType, 'total'));
    result.reSum = sumItems(deliveryOfficeAllListData, getDataKeyByViewType(viewType, 're'));
    result.newSum = sumItems(deliveryOfficeAllListData, getDataKeyByViewType(viewType, 'new'));
    result.unverifiedSum = sumItems(
      deliveryOfficeAllListData,
      getDataKeyByViewType(viewType, 'unverified'),
    );

    result.latelyOrder = arrsEachSum(deliveryOfficeAllListData.map(item => item['lately_order']));
    result.latelyCustomer = arrsEachSum(
      deliveryOfficeAllListData.map(item => item['lately_customer']),
    );
    result.latelySale = arrsEachSum(deliveryOfficeAllListData.map(item => item['lately_sale']));
  }

  if (deliveryDateListData && deliveryDateListData.length > 0) {
    var filterdDeliveryDate = deliveryDateListData;
    var customerCntObj = {
      total_customer_cnt: deliveryDateListData[0]['total_customer_cnt'],
      total_re_customer_cnt: deliveryDateListData[0]['total_re_customer_cnt'],
      total_new_customer_cnt: deliveryDateListData[0]['total_new_customer_cnt'],
    };
    // 주문내역 날짜선택한 경우
    if (filterDate) {
      filterdDeliveryDate = deliveryDateListData.filter(item => item['pay_date'] === filterDate);
      if (filterdDeliveryDate[0]) {
        customerCntObj['total_customer_cnt'] = filterdDeliveryDate[0]['customer_cnt'];
        customerCntObj['total_re_customer_cnt'] = filterdDeliveryDate[0]['re_customer_cnt'];
        customerCntObj['total_new_customer_cnt'] = filterdDeliveryDate[0]['new_customer_cnt'];
      }
    }

    if (viewType === 2) {
      result.officeTotalSum = customerCntObj['total_customer_cnt'];
      result.officeReSum = customerCntObj['total_re_customer_cnt'];
      result.officeNewSum = customerCntObj['total_new_customer_cnt'];
      result.officeUnverifiedSum = 0;
    } else {
      result.officeTotalSum = sumItems(
        filterdDeliveryDate,
        getDataKeyByViewType(viewType, 'total'),
      );
      result.officeReSum = sumItems(filterdDeliveryDate, getDataKeyByViewType(viewType, 're'));
      result.officeNewSum = sumItems(filterdDeliveryDate, getDataKeyByViewType(viewType, 'new'));
      result.officeUnverifiedSum = sumItems(
        filterdDeliveryDate,
        getDataKeyByViewType(viewType, 'unverified'),
      );
    }

    result.sumOfficeOrderCustomer = sumItems(filterdDeliveryDate, getDataKeyByViewType(1, 'total')); // 건 1
    result.sumOfficeCustomerCount = customerCntObj['total_customer_cnt']; // 고객 2
    result.sumOfficeOrderSale = sumItems(filterdDeliveryDate, getDataKeyByViewType(3, 'total')); // 금액 3

    result.officeLatelyOrder = getFields(deliveryDateListData, getDataKeyByViewType(1, 'total'));
    result.officeLatelyCustomer = getFields(deliveryDateListData, getDataKeyByViewType(2, 'total'));
    result.officeLatelySale = getFields(deliveryDateListData, getDataKeyByViewType(3, 'total'));
  }

  return result;
};

export const getInnerCircleOfficeValue = (
  orderMarkers,
  viewType,
  range,
  orderFreqCount,
  orderFreqType,
  wholeDistance,
  detailOrderViewDate,
) => {
  var innerCircleDeliveryList =
    orderMarkers &&
    orderMarkers.filter(
      item =>
        item['distance'] >= range[0] &&
        item['distance'] <= range[1] &&
        filterRangeCount(item, orderFreqCount) &&
        filterOrderViewType(item, orderFreqType),
    );

  if (wholeDistance >= range[1]) {
    innerCircleDeliveryList = innerCircleDeliveryList.filter(item => item['distance'] > 0);
  }

  if (detailOrderViewDate) {
    innerCircleDeliveryList = innerCircleDeliveryList.filter(
      item => item['pay_date'] === detailOrderViewDate,
    );
  }

  var result = '';
  switch (viewType) {
    case 1:
      result = sumItems(innerCircleDeliveryList, 'sale_count');
      break;
    case 2:
      result = groupCount(
        innerCircleDeliveryList.filter(item => item['tdu_sno'] > 0),
        'tdu_sno',
      );
      break;
    case 3:
      result = sumItems(innerCircleDeliveryList, 'pay_amount');
      break;
    default:
      break;
  }

  return result ? mComma(result) : '0';
};

export const getTopMenu = (details, cnt) => {
  if (!details) {
    return null;
  }

  var tmp = {};
  details.map(item => {
    if (!item['menu_sno']) {
      return null;
    }

    var key = item['brand_menu_name'] ? item['brand_menu_name'] : item['menu_name'];
    var trimKey = removeSpace(key);

    for (var i = 0; i < DELIVERY_FILTER_WORDS.length; i++) {
      if (trimKey.indexOf(DELIVERY_FILTER_WORDS[i]) !== -1) {
        return null;
      }
    }

    if (tmp[key]) {
      tmp[key] += item['menu_sale_cnt'];
    } else {
      tmp[key] = item['menu_sale_cnt'];
    }

    return null;
  });

  var sortable = [];
  for (var item in tmp) {
    sortable.push([item, tmp[item]]);
  }

  var result = objectDesc2(tmp).slice(0, cnt);
  var total = sumItems(result, 'value');

  result.map(item => {
    item['percent'] = comparePercent(item['value'], total, 1);
    return null;
  });

  return result;
};

export const getTotalOrderPayAmount = list => {
  if (!list) {
    return false;
  }

  let result = 0;

  Object.keys(list).map(key => {
    const data = list[key];
    if (data && data[0]) {
      result += data[0]['pay_amount'];
    }

    return null;
  });

  return result;
};

export const getIsMenuFilter = (menuInfo, selectMenuList) => {
  return menuInfo !== null || (selectMenuList && selectMenuList.length > 0);
};

export const filterShowableOffices = (searchParams = {}, targetList = [], key = 'office_sno') => {
  if (!searchParams || !targetList) {
    return [];
  }

  const arr = arrayToObject(targetList, key);

  let result = [];

  if (searchParams.selectOfficeList.length === 0) {
    return targetList;
  } else {
    searchParams.selectOfficeList.map(key => {
      if (arr[key]) {
        result.push(arr[key]);
      }
      return null;
    });
  }

  return result;
};

// Region Data (deliveryTipType, orderChannelKeys 에 맞게 generate)
export const getSumDeliveryRegionData = (targetList = [], deliveryTipType, orderChannelKeys, isIncludeEtcData) => {
  let regionData = [];
  let totalData = {
    deliveryAmount: 0, // 주문 매출
    deliveryCount: 0, // 주문 건수
    deliveryFeeAmount: 0, // 배달비
    deliveryFeeAvg: 0, // 배달비 평균
    deliveryFeeCount: 0, // 배달비가 있는 주문건수 (평균배달비 계산용)
    diffDeliveryAmount: 0,
    diffDeliveryCount: 0,
    diffDeliveryFeeAmount: 0,
    diffDeliveryFeeAvg: 0,
    diffDeliveryFeeCount: 0,
  };
  
  if (isIncludeEtcData) {
    totalData = {
      ...totalData,
      ...{
        includeEtcDeliveryCount: 0,
        includeEtcDeliveryAmount: 0,
        includeEtcDeliveryFeeAmount: 0,
        includeEtcDeliveryFeeCount: 0,
        includeEtcDeliveryFeeAvg: 0,
        diffIncludeEtcDeliveryCount: 0,
        diffIncludeEtcDeliveryAmount: 0,
        diffIncludeEtcDeliveryFeeAmount: 0,
        diffIncludeEtcDeliveryFeeCount: 0,
        diffIncludeEtcDeliveryFeeAvg: 0,
      },
    };
  }

  targetList.map((item) => {
    let dongData = {
      deliveryAmount: 0, // 주문 매출
      deliveryCount: 0, // 주문 건수
      deliveryFeeAmount: 0, // 배달비
      deliveryFeeAvg: 0, // 배달비 평균
      deliveryFeeCount: 0, // 배달비가 있는 주문건수 (평균배달비 계산용)
      diffDeliveryAmount: 0,
      diffDeliveryCount: 0,
      diffDeliveryFeeAmount: 0,
      diffDeliveryFeeAvg: 0,
      diffDeliveryFeeCount: 0,
    };

    if (item.active) {
      let filteredList = item.deliveryDetail.filter(obj =>
        orderChannelKeys.some(v => v === obj.orderApp),
      );

      dongData.deliveryAmount = sumItems(filteredList, 'deliveryAmount');
      dongData.deliveryCount = sumItems(filteredList, 'deliveryCount');
      dongData.deliveryFeeCount = sumItems(filteredList, 'deliveryFeeCount');
      dongData.deliveryFeeAmount = sumItems(filteredList, 'deliveryFeeAmount');
      dongData.deliveryFeeAvg = getAverage(dongData.deliveryFeeAmount, dongData.deliveryFeeCount);

      dongData.diffDeliveryAmount = sumItems(filteredList, 'diffDeliveryAmount');
      dongData.diffDeliveryCount = sumItems(filteredList, 'diffDeliveryCount');
      dongData.diffDeliveryFeeCount = sumItems(filteredList, 'diffDeliveryFeeCount');
      dongData.diffDeliveryFeeAmount = sumItems(filteredList, 'diffDeliveryFeeAmount');
      dongData.diffDeliveryFeeAvg = getAverage(dongData.diffDeliveryFeeAmount, dongData.diffDeliveryFeeCount);

      if (isIncludeEtcData) {
        dongData['includeEtcDeliveryCount'] = sumItems(filteredList, 'includeEtcDeliveryCount');
        dongData['includeEtcDeliveryAmount'] = sumItems(filteredList, 'includeEtcDeliveryAmount');
        dongData['includeEtcDeliveryFeeAmount'] = sumItems(filteredList, 'includeEtcDeliveryFeeAmount');
        dongData['includeEtcDeliveryFeeCount'] = sumItems(filteredList, 'includeEtcDeliveryFeeCount');
        dongData['includeEtcDeliveryFeeAvg'] = getAverage(dongData['includeEtcDeliveryFeeAmount'], dongData['includeEtcDeliveryFeeCount']);

        dongData['diffIncludeEtcDeliveryCount'] = sumItems(filteredList, 'diffIncludeEtcDeliveryCount');
        dongData['diffIncludeEtcDeliveryAmount'] = sumItems(filteredList, 'diffIncludeEtcDeliveryAmount');
        dongData['diffIncludeEtcDeliveryFeeAmount'] = sumItems(filteredList, 'diffIncludeEtcDeliveryFeeAmount');
        dongData['diffIncludeEtcDeliveryFeeCount'] = sumItems(filteredList, 'diffIncludeEtcDeliveryFeeCount');
        dongData['diffIncludeEtcDeliveryFeeAvg'] = getAverage(dongData['diffIncludeEtcDeliveryFeeAmount'], dongData['diffIncludeEtcDeliveryFeeCount']);
      }

      // 배달비를 포함해서 주기 떄문에 배달비 제외시 마이너스 시킴
      if (deliveryTipType === 1) {
        dongData.deliveryAmount -= dongData.deliveryFeeAmount;
        dongData.diffDeliveryAmount -= dongData.diffDeliveryFeeAmount;

        if (isIncludeEtcData) {
          dongData['includeEtcDeliveryAmount'] -= dongData['includeEtcDeliveryFeeAmount'];
          dongData['diffIncludeEtcDeliveryAmount'] -= dongData['diffIncludeEtcDeliveryFeeAmount'];
        }
      }
    }

    regionData.push({...item, ...dongData});

    totalData.deliveryCount += dongData.deliveryCount;
    totalData.deliveryAmount += dongData.deliveryAmount;
    totalData.deliveryFeeAmount += dongData.deliveryFeeAmount;
    totalData.deliveryFeeCount += dongData.deliveryFeeCount;

    totalData.diffDeliveryCount += dongData.diffDeliveryCount;
    totalData.diffDeliveryAmount += dongData.diffDeliveryAmount;
    totalData.diffDeliveryFeeAmount += dongData.diffDeliveryFeeAmount;
    totalData.diffDeliveryFeeCount += dongData.diffDeliveryFeeCount;

    if (isIncludeEtcData) {
      totalData['includeEtcDeliveryCount'] += dongData['includeEtcDeliveryCount'];
      totalData['includeEtcDeliveryAmount'] += dongData['includeEtcDeliveryAmount'];
      totalData['includeEtcDeliveryFeeAmount'] += dongData['includeEtcDeliveryFeeAmount'];
      totalData['includeEtcDeliveryFeeCount'] += dongData['includeEtcDeliveryFeeCount'];

      totalData['diffIncludeEtcDeliveryCount'] += dongData['diffIncludeEtcDeliveryCount'];
      totalData['diffIncludeEtcDeliveryAmount'] += dongData['diffIncludeEtcDeliveryAmount'];
      totalData['diffIncludeEtcDeliveryFeeAmount'] += dongData['diffIncludeEtcDeliveryFeeAmount'];
      totalData['diffIncludeEtcDeliveryFeeCount'] += dongData['diffIncludeEtcDeliveryFeeCount'];
    }
  });

  totalData.deliveryFeeAvg = getAverage(totalData.deliveryFeeAmount, totalData.deliveryFeeCount);
  totalData.diffDeliveryFeeAvg = getAverage(totalData.diffDeliveryFeeAmount, totalData.diffDeliveryFeeCount);

  if (isIncludeEtcData) {
    totalData.includeEtcDeliveryFeeAvg = getAverage(totalData.includeEtcDeliveryFeeAmount, totalData.includeEtcDeliveryFeeCount);
    totalData.diffIncludeEtcDeliveryFeeAvg = getAverage(totalData.diffIncludeEtcDeliveryFeeAmount, totalData.diffIncludeEtcDeliveryFeeCount);
  }

  return {officeData: totalData, regionData: regionData};
};

// 전체지도 마커 전용 데이터
export const getSumDeliveryOfficeData = (officeDatas = {}, deliveryTipType) => {
  let data = {
    deliveryCount: 0,
    deliveryAmount: 0,
    deliveryFeeAmount: 0,
    order_sale: 0,
    order_customer: 0,
  };

  officeDatas.map(item => {
    data.deliveryCount += item.deliveryCount;
    data.deliveryAmount += item.deliveryAmount;
    data.deliveryFeeAmount += item.deliveryFeeAmount;
  });

  // 배달비를 포함해서 주기 떄문에 배달비 제외시 마이너스 시킴
  if (deliveryTipType === 1) {
    data.deliveryAmount -= data.deliveryFeeAmount;
  }
  // for marker clustering
  data.order_sale = data.deliveryAmount;
  data.order_customer = data.deliveryCount;

  return data;
};

export const convertStackedChartDataForRegion = (data, type, compareData) => {
  let resultChartDataList = [];

  if (data && type) {
    const keyInfo = {
      deliveryAmountKey: type === 'total' ? 'includeEtcDeliveryAmount' : 'deliveryAmount',
      deliveryCountKey: type === 'total' ? 'includeEtcDeliveryCount' : 'deliveryCount',
      deliveryFeeAmountKey: type === 'total' ? 'includeEtcDeliveryFeeAmount' : 'deliveryFeeAmount',
      deliveryFeeCountKey: type === 'total' ? 'includeEtcDeliveryFeeCount' : 'deliveryFeeCount',
      deliveryFeeAvgKey: type === 'total' ? 'includeEtcDeliveryFeeAvg' : 'deliveryFeeAvg',
      diffDeliveryAmountKey: type === 'total' ? 'diffIncludeEtcDeliveryAmount' : 'diffDeliveryAmount',
      diffDeliveryCountKey: type === 'total' ? 'diffIncludeEtcDeliveryCount' : 'diffDeliveryCount',
      diffDeliveryFeeAmountKey: type === 'total' ? 'diffIncludeEtcDeliveryFeeAmount' : 'diffDeliveryFeeAmount',
      diffDeliveryFeeAvgKey: type === 'total' ? 'diffIncludeEtcDeliveryFeeAvg' : 'diffDeliveryFeeAvg',
      diffDeliveryFeeCountKey: type === 'total' ? 'diffIncludeEtcDeliveryFeeCount' : 'diffDeliveryFeeCount',
    };

    let resultChartData = {
      name: '',
      total: 0,
      diffTotal: 0,
      barYMaxValue: 0,
      diffBarYMaxValue: 0,
      barList: [
        {
          dataKey: 'total',
          stackId: 'a',
          value: 0,
          fill: '#38D29B',
        },
        {
          dataKey: 'barYMaxValue',
          stackId: 'a',
          value: 0,
          fill: '',
        },
        {
          dataKey: 'diffTotal',
          stackId: 'b',
          value: 0,
          fill: '#D9DDE5',
        },
        {
          dataKey: 'diffBarYMaxValue',
          stackId: 'b',
          value: 0,
          fill: '',
        },
      ],
      tooltipData: {
        list: [
          {
            label: '배달비 전체',
            value: 0,
          },
          {
            label: '배달비 평균',
            value: 0,
          },
        ],
        main: {
          label: '',
          value: 0,
          originValue: 0,
        },
        compare: '',
      },
    };

    if (data.length > 0) {
      data.map(item => {
        const resultChartDataWithItem = resultChartData;
        const correctionValue = 0.5;
        const barYMaxValue = item[keyInfo.deliveryAmountKey]*correctionValue;  // 그래프 total 값 보정을 위한 값
        const diffBarYMaxValue = barYMaxValue;  // 그래프 total 값 보정을 위한 값

        resultChartDataWithItem.name = item.regionName;
        resultChartDataWithItem.total = item[keyInfo.deliveryFeeAmountKey];
        resultChartDataWithItem.diffTotal = item[keyInfo.diffDeliveryFeeAmountKey];
        resultChartDataWithItem['barYMaxValue'] = barYMaxValue;
        resultChartDataWithItem['diffBarYMaxValue'] = diffBarYMaxValue;

        resultChartDataWithItem.barList = [
          {
            dataKey: keyInfo.deliveryFeeAmountKey,
            stackId: 'a',
            value: item[keyInfo.deliveryFeeAmountKey],
            fill: '#38D29B',
          },
          {
            dataKey: 'barYMaxValue',
            stackId: 'a',
            value: item[keyInfo.deliveryAmountKey],
            fill: '',
          },
          {
            dataKey: keyInfo.diffDeliveryFeeAmountKey,
            stackId: 'b',
            value: item[keyInfo.diffDeliveryFeeAmountKey],
            fill: '#D9DDE5',
          },
          {
            dataKey: 'diffBarYMaxValue',
            stackId: 'b',
            value: item[keyInfo.diffDeliveryAmountKey],
            fill: '',
          },
        ];

        resultChartDataWithItem.tooltipData =  {
          list: [
            {
              label: '배달비 전체',
              value: mComma(item[keyInfo.deliveryFeeAmountKey]) + '원',
            },
            {
              label: '배달비 평균',
              value: mComma(item[keyInfo.deliveryFeeAvgKey]) + '원',
            },
          ],
          main: {
            label: comparePercent(item[keyInfo.deliveryFeeAmountKey], item[keyInfo.deliveryAmountKey]) + '%',
            value: mComma(item[keyInfo.deliveryAmountKey]) + '원',
            originValue: item[keyInfo.deliveryAmountKey],
          },
          compare: strCompareData(
            compareData.diffStartDate,
            compareData.diffEndDate,
            item[keyInfo.deliveryFeeAmountKey],
            item[keyInfo.diffDeliveryFeeAmountKey],
          ),
        };

        resultChartDataList.push({...item, ...resultChartDataWithItem});
      })
    } else {
      resultChartData.active = true;
      resultChartDataList.push(resultChartData);
    }
  }
  return resultChartDataList;
};

const strCompareData = (diffStartData, diffEndData, value, diffValue) => {
  const startDateStr = diffStartData.split('-');
  const endDateStr = diffEndData.split('-');

  let result = { label: `${parseInt(startDateStr[1])}월 (${dateToStr2(diffStartData, 6)}~${endDateStr[2]})` };

  const diffPercent = parseFloat(comparePercent(value - diffValue, value));
  if (diffPercent > 0) {
    result['value'] = diffPercent + '% 상승';
    result['color'] = "#e84545";
  } else if (diffPercent === 0) {
    result['value'] = '변동없음';
    result['color'] = "#e84545";
  } else {
    result['value'] = diffPercent + '% 감소';
    result['color'] = "#30b1ff";
  }

  return result;
};