import { commonConstants, globalCount, menuConstants } from '../constants';
import {
  dateDiff,
  dateToStr,
  endOfMonthDay,
  hypenToDot,
  localDataLoad,
  setLocalData,
  startOfMonthDay,
  subDate,
} from '../helpers';
import { commonService } from '../services';

export const commonActions = {
  searchParams,
  setFilterParams,
  setOfficeRange,
  setIsActiveAdPin,
  setDetailOrderViewDate,
  setRangePercentageByViewType,
  setMaxDistance,
  setDeliveryOfficeSno,
  setDeliveryRangeCount,
  setDeliveryOrderViewType,
  setAdvertisementFilterType,
  setMenuOptionType,
  reverseGeoCode,
  alertMessage,
  navRerender,
  officeManagementExcelGuideStep,
  userManagementExcelGuideStep,
  setManagementEidtMode,
  activeTooltip,
  setMainOfficeFilter,
  setDeliveryViewType,
  setMenuSaleViewType,
  setMapFullSize,
  setChartTooltipZindex,
  setCustomerReviewViewType,
  activeNavItemOnly,
  masterManagerState,
  dongSalesDataList,
  dongSalesParams,
  setMobileOfficeTabDetectingScroll,
  setIsRealTime,
  setCombineAlarmList,
  setCloseMenuItemSlide,
  setIsSlideShow,
  setIsMobilePopupShow,
  setIsMobileCommonPopupShow,
};

function searchParams(
  activeTab,
  startDate,
  endDate,
  sortKey = '',
  orderBy,
  range,
  selectOrderChannelList,
  selectPayTypeList,
  selectOfficeList,
  selectMenuList,
  selectUserList,
  selectCategoryList,
  selectedAreaList,
  orderApp,
) {
  var searchParams = localDataLoad('searchParams');

  var beforeActiveTab = searchParams ? searchParams.activeTab : null;

  // 이전 지표가 아래탭일 경우 날짜를 다시 초기화
  if (
    (beforeActiveTab === 'ReviewOffice' ||
      beforeActiveTab === 'ReviewBrand' ||
      beforeActiveTab === 'RSSOffice' ||
      beforeActiveTab === 'RSSBrand' ||
      beforeActiveTab === 'DeliveryOrderApp' ||
      beforeActiveTab === 'SalesWeekDay' ||
      beforeActiveTab === 'MenuWeekDay' ||
      beforeActiveTab === 'DeliveryWeekDay') &&
    activeTab !== 'ReviewOffice' &&
    activeTab !== 'ReviewBrand' &&
    activeTab !== 'RSSOffice' &&
    activeTab !== 'RSSBrand' &&
    activeTab !== 'DeliveryOrderApp' &&
    activeTab !== 'SalesWeekDay' &&
    activeTab !== 'MenuWeekDay' &&
    activeTab !== 'DeliveryWeekDay'
  ) {
    startDate = subDate('', 7);
    endDate = subDate('', 1);
  }

  var diffDate = dateDiff(startDate, endDate);

  var diffStartDate = subDate(startDate, diffDate + 1);
  var diffEndDate = subDate(endDate, diffDate + 1);

  var result = {
    activeTab: activeTab,
    startDate: startDate,
    endDate: endDate,
    diffStartDate: diffStartDate,
    diffEndDate: diffEndDate,
    searchDateStr: hypenToDot(dateToStr(startDate, 'MM-DD')) + ' ~ ' + hypenToDot(dateToStr(endDate, 'MM-DD')),
    diffDateStr: hypenToDot(dateToStr(diffStartDate, 'MM-DD')) + ' ~ ' + hypenToDot(dateToStr(diffEndDate, 'MM-DD')),
    sortKey: sortKey,
    orderBy: orderBy,
    range: range,
    selectOfficeList: selectOfficeList,
    selectMenuList: selectMenuList,
    selectOrderChannelList: selectOrderChannelList,
    selectPayTypeList: selectPayTypeList,
    selectUserList: selectUserList,
    selectCategoryList: selectCategoryList,
    selectedAreaList: selectedAreaList,
    orderApp: orderApp,
  };

  setLocalData('searchParams', result);

  return { type: commonConstants.SET_SEARCH_PARAMS, result };
}

function setFilterParams(
  {
    selectOfficeList,
    selectMenuList,
    selectUserList,
    selectCategoryList,
    selectedAreaList,
    selectMaterialList,
    selectOrderChannelList,
    selectPayTypeList,
    orderApp,
    startDate,
    endDate,
    sortKey,
    orderBy,
    salesViewType,
    siteType,
    deliveryTipType,
    range,
    diffStartDate,
    diffEndDate,
    standardSaleTime,
  },
  init = false,
) {
  let result = init ? {} : { ...localDataLoad('searchParams') };

  let searchDate = localDataLoad('url_search_date');
  if (searchDate) {
    // YYYY-mm-dd
    if (searchDate.length === 10) {
      startDate = searchDate;
      endDate = searchDate;
    }

    // YYYY-mm
    if (searchDate.length === 7) {
      startDate = startOfMonthDay(searchDate);
      endDate = endOfMonthDay(searchDate);
    }

    setLocalData('url_search_date', null);
  }

  if (selectOrderChannelList) {
    result = { ...result, ...{ selectOrderChannelList: selectOrderChannelList } };
  }
  if (selectPayTypeList) {
    result = { ...result, ...{ selectPayTypeList: selectPayTypeList } };
  }
  if (selectOfficeList) {
    result = { ...result, ...{ selectOfficeList: selectOfficeList } };
  }
  if (selectMenuList) {
    result = { ...result, ...{ selectMenuList: selectMenuList } };
  }
  if (selectUserList) {
    result = { ...result, ...{ selectUserList: selectUserList } };
  }
  if (selectCategoryList) {
    result = { ...result, ...{ selectCategoryList: selectCategoryList } };
  }
  if (selectedAreaList) {
    result = { ...result, ...{ selectedAreaList: selectedAreaList } };
  }
  if (selectMaterialList) {
    result = { ...result, ...{ selectMaterialList: selectMaterialList } };
  }
  if (orderApp || orderApp === 0) {
    result = { ...result, ...{ orderApp: orderApp } };
  }
  if (sortKey && orderBy) {
    result = { ...result, ...{ sortKey: sortKey, orderBy: orderBy } };
  }
  if ([0, 1, 2].includes(salesViewType)) {
    result = { ...result, ...{ salesViewType: salesViewType } };
  }
  if ([0, 1, 2, 3].includes(siteType)) {
    result = { ...result, ...{ siteType: siteType } };
  }
  if (deliveryTipType || deliveryTipType === 0) {
    result = { ...result, ...{ deliveryTipType: deliveryTipType } };
  }

  if (standardSaleTime || standardSaleTime === 0) {
    result = { ...result, ...{ standardSaleTime: standardSaleTime } };
  }

  if (range) {
    result = { ...result, ...{ range: range } };
  }
  if (startDate && endDate) {
    diffStartDate = diffStartDate ? diffStartDate : subDate(startDate, dateDiff(startDate, endDate) + 1);
    diffEndDate = diffEndDate ? diffEndDate : subDate(endDate, dateDiff(startDate, endDate) + 1);

    result = {
      ...result,
      ...{
        startDate: startDate,
        endDate: endDate,
        diffStartDate: diffStartDate,
        diffEndDate: diffEndDate,
        searchDateStr: hypenToDot(dateToStr(startDate, 'MM-DD')) + ' ~ ' + hypenToDot(dateToStr(endDate, 'MM-DD')),
        diffDateStr:
          hypenToDot(dateToStr(diffStartDate, 'MM-DD')) + ' ~ ' + hypenToDot(dateToStr(diffEndDate, 'MM-DD')),
      },
    };
  }


  if (!result['selectPayTypeList']) {
    result['selectPayTypeList'] = [];
  }
  if (!result['selectOrderChannelList']) {
    result['selectOrderChannelList'] = [];
  }
  if (!result['selectCategoryList']) {
    result['selectCategoryList'] = [];
  }
  if (!result['selectMenuList']) {
    result['selectMenuList'] = [];
  }
  if (!result['selectOfficeList']) {
    result['selectOfficeList'] = [];
  }
  if (!result['selectUserList']) {
    result['selectUserList'] = [];
  }
  if (!result['selectedAreaList']) {
    result['selectedAreaList'] = [];
  }
  if (!result['selectMaterialList']) {
    result['selectMaterialList'] = [];
  }
  if (!result['range']) {
    result['range'] = [-1, -1];
  }
  if (!result['salesViewType'] && result['salesViewType'] !== 0) {
    result['salesViewType'] = 2;
  }
  if (!result['siteType']) {
    result['siteType'] = 0;
  }
  if (!result['orderApp'] && result['orderApp'] !== 0) {
    result['orderApp'] = -1;
  }
  if (!result['deliveryTipType']) {
    result['deliveryTipType'] = 0;
  }

  if (!result['standardSaleTime']) {
    result['standardSaleTime'] = 0;
  }

  setLocalData('searchParams', result);

  return { type: commonConstants.SET_SEARCH_PARAMS, result };
}

function setOfficeRange(range) {
  var result = range;

  return { type: commonConstants.SET_OFFICE_RANGE, result };
}

function setIsActiveAdPin(value) {
  var result = value;

  return { type: commonConstants.SET_IS_ACTIVE_AD_PIN, result };
}

function setDetailOrderViewDate(value) {
  var result = value;

  return { type: commonConstants.SET_DETAIL_ORDER_VIEW_DATE, result };
}

function setRangePercentageByViewType(value) {
  var result = value;
  return { type: commonConstants.SET_RANGE_PERCENTAGE_BY_VIEWTYPE, result };
}

function setMaxDistance(max) {
  var result = max;
  return { type: commonConstants.SET_MAX_DISTANCE, result };
}

function setDeliveryOfficeSno(officeSno) {
  var result = officeSno;

  return { type: commonConstants.SET_DELIVERY_OFFICE_SNO, result };
}

function setDeliveryRangeCount(value) {
  var result = value;

  return { type: commonConstants.SET_DELIVERY_RANGE_COUNT, result };
}

function setDeliveryOrderViewType(index) {
  var result = index;

  return { type: commonConstants.SET_DELIVERY_ORDER_VIEW_TYPE, result };
}

function setAdvertisementFilterType(index) {
  var result = index;

  return { type: commonConstants.SET_ADVERTISEMENT_FILTER_TYPE, result };
}

function setMenuOptionType(value) {
  var result = value;

  return { type: commonConstants.SET_MENU_OPTION_TYPE, result };
}

function reverseGeoCode(x, y) {
  return dispatch => {
    dispatch(request());

    commonService.reverseGeoCode(x, y).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: commonConstants.REVERSE_GEOCODE_RESET };
  }
  function success(result) {
    return { type: commonConstants.REVERSE_GEOCODE_SUCCESS, result };
  }
  function failure(error) {
    return { type: commonConstants.REVERSE_GEOCODE_FAILURE, error };
  }
}

function alertMessage(
  title,
  message,
  type = 0,
  contents = null,
  action = '',
  closeType = 0,
  modalWidth,
  isBackDrop = false,
) {
  var result = {
    title: title,
    message: message,
    type: type,
    contents: contents,
    action: action,
    closeType: closeType,
    modalWidth: modalWidth,
    isBackDrop: isBackDrop,
  };

  return { type: commonConstants.SET_ALERT_MESSAGE, result };
}

function navRerender() {
  var result = {
    count: globalCount(),
  };

  return { type: commonConstants.NAV_RERENDER, result };
}

function officeManagementExcelGuideStep(v) {
  var result = v;

  return { type: commonConstants.OFFICE_MANAGEMENT_EXCEL_GUIDE_STEP, result };
}

function userManagementExcelGuideStep(v) {
  var result = v;

  return { type: commonConstants.USER_MANAGEMENT_EXCEL_GUIDE_STEP, result };
}

function setManagementEidtMode(v) {
  var result = v;

  return { type: commonConstants.SET_MANAGEMENT_EDITMODE, result };
}

function activeTooltip(tooltipID, ms = 3000) {
  var result = {
    tooltipID: tooltipID,
    ms: ms,
  };

  return { type: commonConstants.ACTIVE_TOOLTIP, result };
}

function setMainOfficeFilter(key, type) {
  var result = {
    key: key,
    type: type,
  };

  return { type: commonConstants.SET_MAIN_OFFICE_FILTER, result };
}

function setDeliveryViewType(viewType, name) {
  var result = {
    viewType: viewType,
    name: name,
  };

  return { type: commonConstants.SET_DELIVERY_VIEW_TYPE, result };
}

function setMenuSaleViewType(viewType, name) {
  var result = {
    viewType: viewType,
    name: name,
  };

  return { type: commonConstants.SET_MENU_SALE_VIEW_TYPE, result };
}

function setMapFullSize(isMapFull) {
  var result = {
    isMapFull: isMapFull,
  };

  return { type: commonConstants.SET_MAP_FULL_SIZE, result };
}

function setChartTooltipZindex(value) {
  var result = {
    zIndex: value,
  };

  return { type: commonConstants.SET_CHART_TOOLTIP_ZINDEX, result };
}

function setCustomerReviewViewType(viewType, name) {
  var result = {
    viewType: viewType,
    name: name,
  };

  return { type: commonConstants.SET_CUSTOMER_REVIEW_VIEW_TYPE, result };
}

// 특정 네비메뉴만 active 시킬때 사용
function activeNavItemOnly(value) {
  let result = {
    navItem: value,
  };

  return { type: commonConstants.ACTIVE_NAV_ITEM_ONLY, result };
}

// 마스터 관리자인데 등록된 매장이 없는 state 관리
function masterManagerState(state) {
  let result = {
    state: state,
  };

  return { type: commonConstants.MASTER_MANAGER_STATE, result };
}

function dongSalesDataList(list) {
  let result = {
    result: list,
  };

  return { type: commonConstants.SET_DONG_SALES_DATA_LIST, result };
}

function dongSalesParams({ orderChannelKeys, orderApp, selectOfficeList }) {
  let result = {};

  if (localDataLoad('dongSalesParams')) {
    result = localDataLoad('dongSalesParams');
  }

  if (orderChannelKeys) {
    result = { ...result, ...{ orderChannelKeys: orderChannelKeys } };
  }

  if (orderApp || orderApp === 0) {
    result = { ...result, ...{ orderApp: orderApp } };
  }

  if (selectOfficeList) {
    result = { ...result, ...{ selectOfficeList: selectOfficeList } };
  }

  if (!result['orderChannelKeys']) {
    result['orderChannelKeys'] = [];
  }

  if (!result['orderApp'] && result['orderApp'] !== 0) {
    result['orderApp'] = -1;
  }

  if (!result['selectOfficeList']) {
    result['selectOfficeList'] = [];
  }

  setLocalData('dongSalesParams', result);

  return { type: commonConstants.SET_DONG_SALES_PARAMS, result };
}

function setMobileOfficeTabDetectingScroll(value) {
  let result = value;

  return { type: commonConstants.SET_MOBILE_OFFICE_TAB_DETECTING_SCROLL, result };
}

function setIsRealTime(value) {
  let result = value;

  return { type: commonConstants.SET_IS_REAL_TIME, result };
}

function setCombineAlarmList(list) {
  let result = { list };

  return { type: commonConstants.SET_COMBINE_ALARM_LIST, result };
}

function setCloseMenuItemSlide(value) {
  let result = value;

  return { type: commonConstants.SET_CLOSE_MENU_ITEM_SLIDE, result };
}

function setIsSlideShow(isSlideShow) {
  return { type: commonConstants.SET_IS_SLIDE_SHOW, isSlideShow };
}

function setIsMobilePopupShow(isMobilePopupShow) {
  return { type: commonConstants.SET_IS_MOBILE_POPUP_SHOW, isMobilePopupShow };
}

function setIsMobileCommonPopupShow(isMobileCommonPopupShow) {
  return { type: commonConstants.SET_IS_MOBILE_COMMON_POPUP_SHOW, isMobileCommonPopupShow };
}
