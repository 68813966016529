import React, { Component, Fragment } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, UncontrolledButtonDropdown } from 'reactstrap';
import { authActions, commonActions, officeActions } from '../../../actions';
import defaultBrand from '../../../assets/utils/images/default_brand.png';
import city3 from '../../../assets/utils/images/dropdown-header/city3.jpg';
import ChatbotCustom from '../../../components/Chatbot/ChatbotCustom';
import EditEmailModal from '../../../components/Modal/EditEmailModal';
import EditPasswordModal from '../../../components/Modal/EditPasswordModal';
import EditPhoneNumberModal from '../../../components/Modal/EditPhoneNumberModal';
import ProgressDot from '../../../components/ProgressBar/ProgressDot';
import { BUBBLE_FLAG, DELIVERY_ACCOUNT_LINK_INFO, GA_EVENT_CONST, POS_INSTALL_STATE } from '../../../constants';
import { getMyUserData, isSystemLink, setHypenPhoneNum, setLocalData, trackEvent } from '../../../helpers';
import { Withdraw } from '../../../pages/Mobile/Withdraw';
import ChannelChat from '../../../helpers/ChannelChat';
import WithdrawCancelModal from '../../../pages/Mobile/Withdraw/Container/WithdrawCancelModal';
import { withRouter } from 'react-router-dom';
import IMAGES from '../../../assets/utils/images';

export const openProfile = () => {
  let popUpElement = document.getElementById('profile-popup');
  if (popUpElement) {
    popUpElement.style.display = 'block';
  }
};
class UserProfileBox extends Component {
  constructor(props) {
    super(props);

    const userData = getMyUserData(false);

    this.state = {
      active: false,
      logout: false,
      mobileModal: false,
      emailModal: false,
      passwordModal: false,
      dropdown: false,
    };

    if (userData && userData['auth_type'] === 'D1') {
      this.props.dispatch(officeActions.getOrderAppOfficeOwner(userData['brand_code'], userData['user_sno']));
    }

    this.handleLogout = this.handleLogout.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleCloseDropDown = this.handleCloseDropDown.bind(this);
    this.handleWithdraw = this.handleWithdraw.bind(this);
    this.handleWithdrawCancel = this.handleWithdrawCancel.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleCloseModal);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleCloseModal);
  }

  handleLogout() {
    this.props.dispatch(commonActions.officeManagementExcelGuideStep(BUBBLE_FLAG.logout));
    setLocalData('officeManagementExcelGuideStep', BUBBLE_FLAG.logout);

    this.setState({ logout: true });
    setTimeout(() => {
      this.props.dispatch(authActions.logout(false));
    }, 100);
  }

  toggle(e) {
    const {
      target: { name },
    } = e;

    switch (name) {
      case 'mobile':
        this.setState({ mobileModal: !this.state.mobileModal });
        break;
      case 'email':
        this.setState({ emailModal: !this.state.emailModal });
        break;
      case 'password':
        this.setState({ passwordModal: !this.state.passwordModal });
        break;
      default:
        break;
    }
  }

  handleCloseModal = event => {
    const popUpElement = document.getElementById('profile-popup');
    const { target } = event;

    if (popUpElement && !popUpElement.contains(target)) {
      popUpElement.style.display = 'none';
    }
  };

  handleCloseDropDown() {
    var element = document.getElementById('dummy');

    let popUpElement = document.getElementById('profile-popup');
    popUpElement.style.display = 'none';

    if (element) {
      element.click();
    }
  }

  handleWithdraw() {
    const { dispatch } = this.props;
    const userData = getMyUserData(false);

    if (userData && userData['auth_type'] === 'D1' && userData['company_type'] === '3') {
      dispatch(commonActions.alertMessage(null, null, null, <Withdraw isResizeWidth={true} />));
    } else {
      ChannelChat.setSupportBot(27113);
      this.handleCloseDropDown();
    }
  }

  handleWithdrawCancel() {
    const { dispatch } = this.props;
    dispatch(commonActions.alertMessage(null, null, null, <WithdrawCancelModal />));
  }

  render() {
    const { logout, mobileModal, emailModal, passwordModal } = this.state;
    const { orderAppData, myUserData: userData } = this.props;

    if (logout === true) {
      return <Redirect to={'/auth/login'} />;
    }

    if (!userData) {
      return null;
    }

    const btnStyle = {
      backgroundColor: '#adb5bd',
      border: 'none',
    };

    const { location } = this.props;
    if (location.state === 'pcWithdrawalCancel') {
      openProfile();
    }

    return (
      <Fragment>
        {/* 드롭다운을 닫기위해 더미 엘리먼트 생성 */}
        <div id="dummy" />
        <UncontrolledButtonDropdown
          onClick={() => {
            trackEvent(GA_EVENT_CONST['C1203']);
          }}
        >
          <DropdownToggle color="link" className="p-0" onClick={this.dropdown}>
            <div className="profile-cover">
              <img
                id="clipped"
                width={36}
                className=""
                src={userData['brand_image_url'] ? userData['brand_image_url'] : defaultBrand}
                alt=""
              />
              <svg viewBox="0 0 36 36">
                <defs>
                  <clipPath id="cross">
                    <path
                      d="M16.9486 0.5H19.0514C24.8249 0.5 28.9057 1.80496 31.5505 4.44974C34.1952 7.09453 35.5 11.1754 35.5 16.9486V19.0514C35.5 24.8249 34.1952 28.9058 31.5505 31.5505C28.9058 34.1952 24.8249 35.5 19.0514 35.5H16.9486C11.1748 35.5 7.09397 34.1952 4.44933 31.5505C1.80469 28.9058 0.5 24.8249 0.5 19.0514V16.9486C0.5 11.1754 1.80469 7.09452 4.44933 4.44974C7.09398 1.80496 11.1748 0.5 16.9486 0.5Z"
                      stroke="black"
                      strokeOpacity="0.1"
                    />
                  </clipPath>
                </defs>
              </svg>

              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="img-cover"
              >
                <path
                  d="M16.9486 0.5H19.0514C24.8249 0.5 28.9057 1.80496 31.5505 4.44974C34.1952 7.09453 35.5 11.1754 35.5 16.9486V19.0514C35.5 24.8249 34.1952 28.9058 31.5505 31.5505C28.9058 34.1952 24.8249 35.5 19.0514 35.5H16.9486C11.1748 35.5 7.09397 34.1952 4.44933 31.5505C1.80469 28.9058 0.5 24.8249 0.5 19.0514V16.9486C0.5 11.1754 1.80469 7.09452 4.44933 4.44974C7.09398 1.80496 11.1748 0.5 16.9486 0.5Z"
                  stroke="black"
                  strokeOpacity="0.1"
                />
              </svg>
            </div>
          </DropdownToggle>
          <DropdownMenu right className="pb-0 rm-pointers dropdown-menu-lg" id="profile-popup">
            <div className="dropdown-menu-header">
              <div className="dropdown-menu-header-inner bg-secondary">
                <div className="menu-header-image opacity-2" style={{ backgroundImage: 'url(' + city3 + ')' }} />
                <div className="menu-header-content text-left">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left mr-3">
                        <img
                          width={36}
                          className="rounded-circle"
                          src={userData['brand_image_url'] ? userData['brand_image_url'] : defaultBrand}
                          alt=""
                        />
                      </div>
                      <div className="widget-content-left">
                        <div className="widget-heading">{userData['user_name']}</div>
                        <div className="widget-subheading opacity-8">{userData['auth_name']}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {userData['auth_type'] === 'D1' &&
              !userData['pos_sno'] &&
              POS_INSTALL_STATE[userData['pos_install_state']] && (
                <div className="account-area">
                  <NavItem>
                    <NavLink style={{ padding: '7px 20px' }}>
                      <div style={{ font: '0.73333rem', color: '#6c757d', width: '7em' }}>설치현황</div>
                      <div className="">{POS_INSTALL_STATE[userData['pos_install_state']]['msg']}</div>
                      {POS_INSTALL_STATE[userData['pos_install_state']]['installable'] && (
                        <button
                          className="ml-auto badge badge-secondary action-button"
                          style={btnStyle}
                          onClick={() => {
                            window.location.href = '#/mobile/officeOwnerMap';
                          }}
                        >
                          설치
                        </button>
                      )}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink style={{ padding: '7px 20px' }}>
                      <div style={{ font: '0.73333rem', color: '#6c757d', width: '7em', minWidth: '98px' }}>{''}</div>
                      <ProgressDot
                        style={{ width: '100%' }}
                        progressState={POS_INSTALL_STATE[userData['pos_install_state']]['value']}
                        itemLength={5}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem className="nav-item-divider" />
                </div>
              )}

            {isSystemLink() === false && (
              <div style={{ paddingBottom: '16px' }}>
                <PerfectScrollbar>
                  <Nav vertical>
                    <NavItem>
                      <NavLink style={{ padding: '7px 20px' }}>
                        <div style={{ font: '0.73333rem', color: '#6c757d', width: '7em' }}>휴대전화번호</div>
                        <div>{setHypenPhoneNum(userData['user_phone'])} </div>
                        <button
                          name="mobile"
                          className="ml-auto badge badge-secondary action-button"
                          style={btnStyle}
                          onClick={this.toggle}
                        >
                          수정
                        </button>
                        {mobileModal && <EditPhoneNumberModal modal={mobileModal} toggle={this.toggle} />}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink style={{ padding: '7px 20px' }}>
                        <div style={{ font: '0.73333rem', color: '#6c757d', width: '7em' }}>이메일 주소</div>
                        {userData['email']}
                        <button
                          name="email"
                          className="ml-auto badge badge-secondary action-button"
                          style={btnStyle}
                          onClick={this.toggle}
                        >
                          수정
                        </button>
                        {emailModal && (
                          <EditEmailModal dataType="email" backdrop="static" modal={emailModal} toggle={this.toggle} />
                        )}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink style={{ padding: '7px 20px' }}>
                        <div style={{ font: '0.73333rem', color: '#6c757d', width: '7em' }}>패스워드</div>
                        <div className="mt-1-5">**********</div>
                        <button
                          name="password"
                          className="ml-auto badge badge-secondary action-button"
                          style={btnStyle}
                          onClick={this.toggle}
                        >
                          수정
                        </button>
                        {passwordModal && <EditPasswordModal modal={passwordModal} toggle={this.toggle} />}
                      </NavLink>
                    </NavItem>
                  </Nav>
                </PerfectScrollbar>
              </div>
            )}

            {/* 매출계정정보 */}
            {userData['auth_type'] === 'D1' && orderAppData && orderAppData['orderAppObj'] && (
              <div className="account-area">
                <NavItem>
                  <NavLink style={{ padding: '7px 20px' }}>
                    <div style={{ font: '0.73333rem', color: '#6c757d', width: '7em' }}>매출계정정보</div>
                  </NavLink>
                </NavItem>
                {Object.values(DELIVERY_ACCOUNT_LINK_INFO).map((item, i) => {
                  let findLinkInfo = orderAppData['orderAppObj'][item['orderApp']];
                  if (!findLinkInfo) {
                    return null;
                  }

                  return (
                    <NavItem>
                      <NavLink style={{ padding: '7px 20px' }}>
                        <div className="" style={{ display: 'flex', alignItems: 'center', lineHeight: '1' }}>
                          {item['labelName']}
                        </div>

                        <div className="ml-auto" style={{ display: 'flex' }}>
                          {findLinkInfo['statusMsg'] && (
                            <div style={{ color: '#E64249', marginRight: '12px' }}>
                              <img
                                style={{ marginBottom: '2px', marginRight: '4px' }}
                                src={IMAGES['redNoticeIcon']}
                                alt=""
                              />
                              <span>{findLinkInfo['statusMsg']}</span>
                            </div>
                          )}
                          <button
                            className="badge badge-secondary action-button"
                            style={btnStyle}
                            onClick={() => {
                              window.location.href = `#/mobile/accountLink?c=${item['orderApp']}`;
                              this.handleCloseDropDown();
                            }}
                          >
                            수정
                          </button>
                        </div>
                      </NavLink>
                    </NavItem>
                  );
                })}

                {(!orderAppData['orderAppObj'] ||
                  Object.values(DELIVERY_ACCOUNT_LINK_INFO).length !==
                    Object.values(orderAppData['orderAppObj']).length) && (
                  <NavItem>
                    <NavLink style={{ padding: '4px 20px' }}>
                      <div className="add">+ 계정정보 추가</div>
                      <button
                        className="ml-auto badge badge-secondary action-button"
                        style={btnStyle}
                        onClick={() => {
                          window.location.href = `#/mobile/accountLink?c=${0}`;
                          this.handleCloseDropDown();
                        }}
                      >
                        추가
                      </button>
                    </NavLink>
                  </NavItem>
                )}
              </div>
            )}
            <Nav vertical>
              {isSystemLink() === false && <NavItem className="nav-item-divider" />}
              <NavItem>
                <NavLink style={{ padding: '7px 20px' }}>
                  <div style={{ font: '0.73333rem', color: '#6c757d', width: '7em' }}></div>
                  <button
                    className="ml-auto badge badge-secondary action-button logout"
                    onClick={this.handleLogout}
                    style={btnStyle}
                  >
                    로그아웃
                  </button>
                </NavLink>
              </NavItem>
            </Nav>
            <div className="control-wrap">
              <div className="customer-service" onClick={() => (window.location.href = '#/service/notice/')}>
                <div className="text">
                  앳트래커 고객센터<span className="" data-count="0"></span>
                </div>
              </div>
              <ChatbotCustom />
            </div>
            <div className="fsize-0-7 opacity-6 desc-wrap">
              <span className="desc">※ 서비스 이용중지를 원하시면 아래 버튼을 통해 문의해주세요.</span>
              {userData['withdrawal_ymd'] ? (
                <a
                  className="badge badge-secondary pointer"
                  style={{
                    background: '#adb5bd',
                    display: 'inline-block',
                    margin: '7px 0 10px 0',
                  }}
                  onClick={this.handleWithdrawCancel}
                >
                  서비스 해지/탈퇴 취소 신청
                </a>
              ) : (
                <a
                  className="badge badge-secondary pointer"
                  style={{
                    background: '#adb5bd',
                    display: 'inline-block',
                    margin: '7px 0 10px 0',
                  }}
                  onClick={this.handleWithdraw}
                >
                  서비스 해지/탈퇴 신청
                </a>
              )}
            </div>
            <div className="close-area" onClick={() => this.handleCloseDropDown()}>
              닫기
            </div>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { checkLoginState } = state.checkLoginState;
  const { getOrderAppOfficeOwner: orderAppData } = state.getOrderAppOfficeOwner;
  const { myUserData } = state.myUserData;

  return {
    checkLoginState,
    orderAppData,
    myUserData,
  };
}

export default withRouter(connect(mapStateToProps)(UserProfileBox));
