import React from 'react';
import DaumPostcode from 'react-daum-postcode';

// https://www.npmjs.com/package/react-daum-postcode
class PostCode extends React.Component {
  constructor(props) {
    super(props);

    this.handleBack = this.handleBack.bind(this);
    this.handleAddress = this.handleAddress.bind(this);
  }

  handleBack() {
    this.props.onSearch();
  }

  handleAddress(data) {
    var addressData = {
      jibunAddress: '', // 지번 주소
      roadAddress: '', // 도로명 주소
      detailAddress: '', // 상세 주소
      addrCol1: '', // 광역자치단체
      addrCol2: '', // 기초자치단체
      addrCol3: '', // 행정시, 일반구
      addrCol4: '', // 읍, 면
      addrCol5: '', // 동, 리
    };

    addressData.roadAddress = data.roadAddress ? data.roadAddress : data.autoRoadAddress;
    addressData.jibunAddress = data.jibunAddress ? data.jibunAddress : data.autoJibunAddress;

    var strJibun = addressData.jibunAddress;
    var splitJibun = strJibun.split(' ');

    splitJibun.splice(-1, 1);

    addressData.addrCol1 = splitJibun[0];
    if (addressData.addrCol1 === '제주') {
      addressData.addrCol3 = splitJibun[1];
      addressData.addrCol4 = splitJibun[2];
      addressData.addrCol5 = splitJibun[3];
    } else if (addressData.addrCol1 === '세종') {
      addressData.addrCol4 = splitJibun[1];
      addressData.addrCol5 = splitJibun[2];
    } else {
      splitJibun.forEach((item, index) => {
        var lastStr = item.substr(item.length - 1);
        if (lastStr === '읍' || lastStr === '면') {
          addressData.addrCol4 = item;
          return;
        }

        if (lastStr === '동' || lastStr === '리') {
          addressData.addrCol5 = item;
          return;
        }

        if (index === 1) {
          addressData.addrCol2 = item;
        } else if (index === 2) {
          addressData.addrCol3 = item;
        }
      });
    }

    this.props.onSearch({ addressData: addressData });
  }

  render() {
    return (
      <DaumPostcode height="600" shorthand={false} onComplete={this.handleAddress} />
    );
  }
}

export default PostCode;