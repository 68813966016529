import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { brandActions, officeActions } from '../../actions';
import IMAGES from '../../assets/utils/images';
import arrow from '../../assets/utils/images/arrow16black.svg';
import { isMobile } from '../../constants';
import { getMyUserData } from '../../helpers';
import { PATHS } from '../../constants/Routes';
import { openProfile } from '../../Layout/AppHeader/Components/UserProfileBox';
import { VALIDATION_STAUTS } from '../PosRegisterForm/PosForm';

const STORAGE_NAME = 'toastAccountLinkError';

const ToastAccountLinkError = ({ viewType = '' }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userData = getMyUserData(false);
  if (!userData) {
    return null;
  }

  const { getOrderAppOfficeOwner: orderAppData, loading: loading1 } = useSelector(
    state => state.getOrderAppOfficeOwner,
  );
  const { getOrderAppOfficeStatus: orderAppOfficeStatus, loading: loading2 } = useSelector(
    state => state.getOrderAppOfficeStatus,
  );
  const { posOwnerAccount } = useSelector(state => state.getPosOwnerAccount);

  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    if (!orderAppData && !loading1 && userData['auth_type'] === 'D1') {
      dispatch(officeActions.getOrderAppOfficeOwner(userData['brand_code'], userData['user_sno']));
      dispatch(officeActions.getPosOwnerAccount(userData['brand_code'], userData['user_sno']));
    }

    if (
      !orderAppOfficeStatus &&
      !loading2 &&
      (userData['levels'] > 300 || (userData['levels'] >= 200 && userData['accessAccountFunc']))
    ) {
      dispatch(brandActions.getOrderAppOfficeStatus(userData['brand_code']));
    }
  }, [orderAppData, orderAppOfficeStatus]);

  useEffect(() => {
    let tmp = false;
    if (orderAppData && orderAppData['orderAppObj']) {
      Object.values(orderAppData['orderAppObj']).map(v => {
        if (v['isInvalid'] === 1 || v['storeCnt'] === 0) {
          tmp = true;
        }

        return null;
      });
    }

    if (orderAppOfficeStatus) {
      if (orderAppOfficeStatus['linkErrorChannelCnt'] + orderAppOfficeStatus['linkErrorOfficeCnt'] > 0) {
        tmp = true;
      }
    }

    if (posOwnerAccount && posOwnerAccount.length > 0) {
      posOwnerAccount.map(v => {
        if (v['validation_status'] === VALIDATION_STAUTS['DENY']) {
          tmp = true;
        }
        return null;
      });
    }

    const expireDay = parseInt(localStorage.getItem(STORAGE_NAME));
    let today = new Date();
    if (expireDay && today.getTime() < expireDay) {
      tmp = false;
    }

    setIsShow(tmp);
  }, [orderAppData, orderAppOfficeStatus, posOwnerAccount]);

  const handleClose = () => {
    let expire = new Date();
    expire.setDate(expire.getDate() + 1);
    expire.setHours(0, 0, 0, 0);
    localStorage.setItem(STORAGE_NAME, expire.getTime());

    setIsShow(false);
  };

  const onClickView = () => {
    if (isMobile() === true && userData['levels'] > 300) {
      // 프랜차이즈 모바일 x
      return;
    } else if (
      isMobile() === false &&
      (userData['levels'] > 300 || (userData['levels'] >= 200 && userData['accessAccountFunc']))
    ) {
      // 프랜차이즈 PC -> 연동페이지 이동
      history.push(PATHS.manageOrderApp.url);
    } else if (isMobile() === true && userData['auth_type'] === 'D1') {
      // 점주 모바일 -> 마이페이지
      history.push(PATHS.mobileMypage.url);
    } else if (isMobile() === false && userData['auth_type'] === 'D1') {
      // 점주 PC -> 모달
      openProfile();
    }
  };

  if (isShow === false) {
    return null;
  }

  // 모바일
  if (viewType === 'mobile' && isMobile() && userData['auth_type'] === 'D1') {
    return (
      <MobileContainer>
        <img className="triangle" src={IMAGES['redTriangle']} alt="" />
        <img className="close-btn" onClick={handleClose} src={IMAGES['iconATBubbleTooltipClose']} alt="" />
        <div>
          연동 오류 계정이 있습니다. 정상적인 매출
          <br />
          데이터 수집을 위해 계정 정보 수정이 필요합니다.
        </div>
        <button onClick={onClickView}>
          <span>연동 오류 계정</span> 확인하기
        </button>
      </MobileContainer>
    );
  }

  // PC
  return (
    <>
      {viewType === 'pc' && isMobile() === false && (
        <Container>
          <div className="desc">
            <img src={IMAGES['inverseRedNoticeIcon']} alt="" />
            <span>연동 오류 계정</span>이 있습니다. 정상적인 매출 데이터 수집을 위해 계정 정보 수정이 필요합니다.
          </div>
          <div className="right-btn">
            <button className="later-btn" onClick={handleClose}>
              다음에 볼게요
            </button>
            <Link className="check-btn" onClick={onClickView}>
              연동 오류 계정 확인하기
            </Link>
          </div>
        </Container>
      )}
    </>
  );
};

export default ToastAccountLinkError;

const MobileContainer = styled.div`
  width: calc(100vw - 16px) !important;
  height: unset !important;
  max-width: 540px;
  background-color: #e15252f5;
  position: absolute;
  top: 44px;
  right: -6px;
  color: #fefefe;
  padding: 32px 16px 32px 16px;
  border-radius: 16px;

  font-family: Pretendard;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;

  .triangle {
    position: absolute;
    top: -10px;
    right: 9px;
    width: 26px;
    height: 20px;
  }

  .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 16px;
    height: 16px;
    opacity: 0.4;
  }

  button {
    margin-left: auto;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.7);
    padding: 12px 28px;
    border-radius: 24px;
    color: #ffffff;
    margin-top: 16px;
    text-align: center;
    font-size: 15px;
    font-weight: 700;

    span {
      color: #fff500;
    }
  }
`;

const Container = styled.div`
  width: calc(100vw - 260px);
  height: 70px;
  margin-left: 202px;
  background: #e15252;
  flex-direction: column;
  bottom: 0;
  border-radius: 16px;
  margin-top: 0;
  padding: 0 40px;
  transform: translateY(0);
  justify-content: center;

  .desc {
    display: inline-block;
    font-size: 13px;
    color: white;
    font-weight: 600;
    letter-spacing: -0.65px;
    white-space: break-spaces;
    padding: 26px 0;

    img {
      margin-bottom: 2px;
      margin-right: 4px;
    }

    span {
      color: #fff500;
    }
  }

  .right-btn {
    float: right;
    padding: 16px 0px;
  }

  .check-btn {
    color: #262626;
    background: #fefefe;
    border-radius: 4px;
    right: 32px;
    top: 0;
    bottom: 0;
    &:after {
      display: inline-flex;
      margin-left: 4px;
      width: 16px;
      height: 16px;
      background: url(${arrow}) no-repeat center;
      content: '';
    }
  }

  button,
  a {
    display: inline-flex;
    border: none;
    background: none;
    letter-spacing: -0.2px;
    color: rgba(255, 255, 255, 0.5);
    height: 38px;
    align-items: center;
    font-size: 13px;
    padding: 0 12px 0 18px;
    justify-content: center;
    font-weight: 700;
    cursor: pointer;
  }

  .later-btn {
    padding-right: 24px;
  }
`;
