import { combineReducers } from 'redux';
import { authConstants } from '../constants';
import {
  changeEmail,
  changeMobile,
  checkAuthNumber,
  checkLoginState,
  login,
  brandLogin,
  resetPassword,
  sendPhoneAuthNumber,
  systemErrorLog,
  validPhoneNum,
  ghostInit,
  checkAdminAccount,
  checkJandiBrandAccessAuth,
  validMobileAuth,
  alarmPermissionStatus,
  appInfo,
  setAppPushOption,
  setAuthInfo,
} from './Auth';
import {
  noticeConfirm,
  noticeCreate,
  noticeDisable,
  noticeDisableComment,
  noticeEdit,
  noticeList,
  noticeReadList,
  noticeWriteComment,
  allBrandList,
  getFranchiseOfficeStatus,
  getFranchiseOfficeStatusVer2,
  getOrderAppOfficeStatus,
  checkSignUpLinkClick,
  insertMultiOfficeManagerInfo,
  // insertSingleOfficeManagerInfo,
  // editOfficeManagerInfo,
  // manageFranchiseOfficeStatus,
  // checkOfficeClosing,
  // removeFranchiseOffice,
} from './Brand';
import {
  alertMessage,
  reverseGeoCode,
  searchParams,
  setDeliveryOfficeSno,
  setDeliveryOrderViewType,
  setAdvertisementFilterType,
  setDeliveryRangeCount,
  setOfficeRange,
  setIsActiveAdPin,
  setDetailOrderViewDate,
  setMenuOptionType,
  setRangePercentageByViewType,
  setMaxDistance,
  navRerender,
  officeManagementExcelGuideStep,
  userManagementExcelGuideStep,
  setManagementEidtMode,
  activeTooltip,
  setMainOfficeFilter,
  setDeliveryViewType,
  setMenuSaleViewType,
  setMapFullSize,
  setChartTooltipZindex,
  setCustomerReviewViewType,
  activeNavItemOnly,
  masterManagerState,
  dongSalesDataList,
  dongSalesParams,
  setMobileOfficeTabDetectingScroll,
  setIsRealTime,
  setCombineAlarmList,
  setCloseMenuItemSlide,
  setIsSlideShow,
  setIsMobilePopupShow,
  mobileCommonPopup,
} from './Common';
import {
  materialList,
  oneMaterialDateListData,
  oneMaterialMenuHeaderData,
  oneMaterialMenuListData,
  oneMaterialOfficeHeaderData,
  oneMaterialOfficeListData,
  useMaterialDateListData,
  useMaterialHeaderData,
  useMaterialListData,
  useMaterialTotalData,
  useMaterialLately,
} from './Material';
import {
  allMenuEngineering,
  brandMenuList,
  categoryList,
  deleteCategory,
  deliveryMenuHeaderData,
  deliveryMenuListData,
  deliveryMenuOptionListData,
  disableBrandMenu,
  disableRetailMenu,
  editBrandMenu,
  editCategoryName,
  mainMenuData,
  menuDateListData,
  menuEngineeringOffice,
  menuLastReceipt,
  oneMenuDateListData,
  oneMenuOfficeHeaderData,
  oneMenuOfficeListData,
  oneMenuSaleWeekDayListData,
  oneMenuDeliveryWeekDayListData,
  recommendUnlinkMenuData,
  registBrandMenu,
  saleMenuHeaderData,
  saleMenuListData,
  saleMenuTotalData,
  unlinkMenu,
  menuSales,
  diffMenuSales,
  menuSalesByDaily,
  optionMenuSales,
  diffOptionMenuSales,
  oneMenuOptions,
  oneOptionMenuMains,
  oneMenuOffices,
  diffOneMenuOffices,
  menuSalesSumByDaily,
  optionMenuSalesSumByDaily,
  categorySales,
  oneCategoryOffices,
  tabState,
  getSuggestMenus,
} from './Menu';
import {
  allOfficeList,
  areaList,
  deliveryDateListData,
  deliveryWeekDayListData,
  oneOfficeDeliveryWeekDayListData,
  deliveryHeaderData,
  deliveryMarkerDetail,
  deliveryOfficeAllListData,
  deliveryOfficeListData,
  deliveryOfficeMarker,
  deliveryOrderAppDateListData,
  deliveryTotalData,
  mainDeliveryData,
  mainSaleData,
  managedOfficeList,
  posSignalList,
  saleDateListData,
  saleOfficeHeaderData,
  saleOfficeListData,
  saleTotalData,
  saleWeekDayListData,
  oneOfficeSaleWeekDayListData,
  inRangeDeliveryDateListData,
  deliveryCustomerRating,
  editDeliveryCustomerTypeBit,
  addDeliveryCustomerComment,
  deleteDeliveryCustomerComment,
  oneCustomerDeliveryDetail,
  dailySales,
  timeSales,
  weekdaySales,
  officeSales,
  officeSalesByDaily,
  orderAppSales,
  specificDateSales,
  specificTimeSales,
  setOrderAppAccount,
  getOrderAppOfficeList,
  getOrderAppOfficeOwner,
  getStoreList,
  mappingStore,
  dongSalesData,
  totalDeliveryDongSales,
  allBrandSales,
  monthlySales,
  quarterlySales,
  yearlySales,
  specificMonthSales,
  specificQuarterSales,
  specificYearSales,
  setMultiOrderAppAccount,
  getOfficeStatus,
  alertList,
  orderDetailList,
  readAlert,
  setSkipAccountLink,
  getPosOwnerAccount,
  linkPosAccount,
} from './Office';
import {
  excelDeliveryDayView,
  excelDeliveryLocationView,
  excelDeliveryMonthView,
  excelDownloadBySearch,
  excelMaterialDateView,
  excelMaterialMonthView,
  excelMenuDayView,
  excelMenuMonthView,
  excelMenuTimeView,
  excelSaleDateView,
  excelSaleDetailView,
  excelSaleMonthView,
  excelSaleTimeView,
  monthlyReportDeliveryData,
  monthlyReportDeliveryOrderAppData,
  monthlyReportHeaderData,
  monthlyReportMenuSalesData,
  monthlyReportPDFUpload,
  monthlyReportSalesData,
  reportBookmark,
  reportList,
  reportRemove,
} from './Report';
import {
  brandRemoveContents,
  brandReviewHeaderData,
  brandReviewListData,
  brandRssHeaderData,
  brandRssKeywordEdit,
  brandRssKeywordList,
  brandRssListData,
  mainReviewData,
} from './Review';
import ThemeOptions from './ThemeOptions';
import {
  companyUserList,
  completeTutorial,
  franchiseBrandList,
  myUserData,
  registUser,
  serviceNoticeList,
  getFaqList,
  userProfileChange,
  getUserList,
  createMultiUser,
  createSingleUser,
  inviteSingleUser,
  inviteMultiUser,
  editUserInfo,
  disableUser,
  editUserAuth,
  editUserAuthInfo,
  withdrawalRequest,
  withdrawalCancel,
} from './User';
import {
  getMasterPaymentInfo,
  getPaymentTableData,
  getPayMethodList,
  getFilterList,
  getFilterStoreOrMethod,
  getMobileOwnerPaymentInfo,
  updatePaymentMethod,
  getFilterYear,
  handleModalStep,
  handleSelectStore,
  moOwnerPaybill,
  setSearchtextInput,
  handlePostState,
  setPaymentPlan,
  getPaymentPlan,
  getRegistInfo,
  registPaymentInfo,
  getHistory,
  singlePayment,
  checkUserOrderApp,
} from './Payment';

import {
  officeReviewList,
  orderAppReviewList,
  specificReviewList,
  mobileReviewList,
  mobileTodayReviewList,
  mobileTodaySpecificReviewList,
  mobileSpecificReviewList,
  insertAnswer,
  deleteAnswer,
  autoAnswerList,
  updateAutoAnswer,
  insertAutoAnswer,
  deleteAutoAnswer,
  specificReview,
  reviewPrevUrl,
  syncReviewList,
} from './CustomerReview';

import {
  officeInfo,
  distanceToggle,
  setDimmedOfficeFilter,
  setOfficeView,
  getAreaLocationPinListData,
  getOfficeLocationPinListData,
  pinInfo,
  activeMarkerInfo,
  activeChannels,
  maxDeliveryBounds,
  advertisementFilter,
  getAdvertisementMarkerData,
  advertisementMarkerData,
} from './Advertisement';

const appReducer = combineReducers({
  ThemeOptions,

  // common
  searchParams,
  setOfficeRange,
  setIsActiveAdPin,
  setDetailOrderViewDate,
  setMenuOptionType,
  setRangePercentageByViewType,
  setMaxDistance,
  setDeliveryOfficeSno,
  setDeliveryRangeCount,
  setDeliveryOrderViewType,
  setAdvertisementFilterType,
  reverseGeoCode,
  alertMessage,
  navRerender,
  officeManagementExcelGuideStep,
  userManagementExcelGuideStep,
  setManagementEidtMode,
  activeTooltip,
  setMainOfficeFilter,
  setDeliveryViewType,
  setMenuSaleViewType,
  setMapFullSize,
  setChartTooltipZindex,
  setCustomerReviewViewType,
  activeNavItemOnly,
  masterManagerState,
  dongSalesDataList,
  dongSalesParams,
  setMobileOfficeTabDetectingScroll,
  setIsRealTime,
  setCombineAlarmList,
  setCloseMenuItemSlide,
  setIsSlideShow,
  setIsMobilePopupShow,
  mobileCommonPopup,

  // auth
  login,
  brandLogin,
  validPhoneNum,
  validMobileAuth,
  sendPhoneAuthNumber,
  checkAuthNumber,
  resetPassword,
  checkLoginState,
  systemErrorLog,
  changeEmail,
  changeMobile,
  ghostInit,
  checkAdminAccount,
  checkJandiBrandAccessAuth,
  appInfo,
  alarmPermissionStatus,
  setAppPushOption,
  setAuthInfo,

  // brand
  noticeList,
  noticeCreate,
  noticeEdit,
  noticeWriteComment,
  noticeDisableComment,
  noticeConfirm,
  noticeReadList,
  noticeDisable,
  allBrandList,
  getFranchiseOfficeStatus,
  getFranchiseOfficeStatusVer2,
  getOrderAppOfficeStatus,
  checkSignUpLinkClick,
  insertMultiOfficeManagerInfo,
  // insertSingleOfficeManagerInfo,
  // editOfficeManagerInfo,
  // manageFranchiseOfficeStatus,
  // checkOfficeClosing,
  // removeFranchiseOffice,

  // material
  materialList,
  useMaterialHeaderData,
  useMaterialListData,
  useMaterialDateListData,
  useMaterialTotalData,
  oneMaterialOfficeHeaderData,
  oneMaterialOfficeListData,
  oneMaterialDateListData,
  oneMaterialMenuHeaderData,
  oneMaterialMenuListData,
  useMaterialLately,

  // menu
  brandMenuList,
  categoryList,
  saleMenuHeaderData,
  saleMenuListData,
  menuDateListData,
  deliveryMenuHeaderData,
  deliveryMenuListData,
  deliveryMenuOptionListData,
  allMenuEngineering,
  menuEngineeringOffice,
  oneMenuOfficeHeaderData,
  oneMenuOfficeListData,
  oneMenuDateListData,
  oneMenuSaleWeekDayListData,
  oneMenuDeliveryWeekDayListData,
  mainMenuData,
  saleMenuTotalData,
  editCategoryName,
  disableBrandMenu,
  unlinkMenu,
  editBrandMenu,
  menuLastReceipt,
  recommendUnlinkMenuData,
  registBrandMenu,
  deleteCategory,
  disableRetailMenu,
  menuSales,
  diffMenuSales,
  menuSalesByDaily,
  optionMenuSales,
  diffOptionMenuSales,
  oneMenuOptions,
  oneOptionMenuMains,
  oneMenuOffices,
  diffOneMenuOffices,
  menuSalesSumByDaily,
  optionMenuSalesSumByDaily,
  categorySales,
  oneCategoryOffices,
  tabState,
  getSuggestMenus,

  // office
  managedOfficeList,
  allOfficeList,
  areaList,
  saleOfficeHeaderData,
  saleDateListData,
  saleWeekDayListData,
  saleOfficeListData,
  deliveryHeaderData,
  deliveryOfficeListData,
  deliveryDateListData,
  deliveryWeekDayListData,
  oneOfficeDeliveryWeekDayListData,
  deliveryOfficeAllListData,
  deliveryOfficeMarker,
  deliveryMarkerDetail,
  mainSaleData,
  mainDeliveryData,
  saleTotalData,
  deliveryTotalData,
  posSignalList,
  deliveryOrderAppDateListData,
  oneOfficeSaleWeekDayListData,
  inRangeDeliveryDateListData,
  deliveryCustomerRating,
  editDeliveryCustomerTypeBit,
  addDeliveryCustomerComment,
  deleteDeliveryCustomerComment,
  oneCustomerDeliveryDetail,
  dailySales,
  timeSales,
  weekdaySales,
  officeSales,
  officeSalesByDaily,
  orderAppSales,
  specificDateSales,
  specificTimeSales,
  setOrderAppAccount,
  getOrderAppOfficeList,
  getOrderAppOfficeOwner,
  getStoreList,
  mappingStore,
  dongSalesData,
  totalDeliveryDongSales,
  allBrandSales,
  monthlySales,
  quarterlySales,
  yearlySales,
  specificMonthSales,
  specificQuarterSales,
  specificYearSales,
  setMultiOrderAppAccount,
  getOfficeStatus,
  alertList,
  orderDetailList,
  readAlert,
  setSkipAccountLink,
  getPosOwnerAccount,
  linkPosAccount,

  // Report
  excelDownloadBySearch,
  excelSaleDateView,
  excelSaleMonthView,
  excelSaleTimeView,
  excelMenuDayView,
  excelMenuMonthView,
  excelMenuTimeView,
  excelSaleDetailView,
  excelMaterialDateView,
  excelMaterialMonthView,
  excelDeliveryDayView,
  excelDeliveryMonthView,
  excelDeliveryLocationView,
  reportList,
  reportBookmark,
  reportRemove,

  monthlyReportHeaderData,
  monthlyReportSalesData,
  monthlyReportMenuSalesData,
  monthlyReportDeliveryData,
  monthlyReportDeliveryOrderAppData,
  monthlyReportPDFUpload,

  // Review
  brandReviewHeaderData,
  brandReviewListData,
  brandRssHeaderData,
  brandRssListData,
  brandRssKeywordList,
  brandRssKeywordEdit,
  brandRemoveContents,
  mainReviewData,

  // User
  companyUserList,
  franchiseBrandList,
  userProfileChange,
  myUserData,
  serviceNoticeList,
  getFaqList,
  completeTutorial,
  registUser,
  getUserList,
  createMultiUser,
  createSingleUser,
  inviteSingleUser,
  inviteMultiUser,
  editUserInfo,
  disableUser,
  editUserAuth,
  editUserAuthInfo,
  withdrawalRequest,
  withdrawalCancel,

  //Payment
  getMasterPaymentInfo,
  getPaymentTableData,
  getPayMethodList,
  getFilterList,
  getFilterStoreOrMethod,
  getFilterYear,
  getMobileOwnerPaymentInfo,
  updatePaymentMethod,
  handleModalStep,
  handleSelectStore,
  moOwnerPaybill,
  setSearchtextInput,
  handlePostState,
  setPaymentPlan,
  getPaymentPlan,
  checkUserOrderApp,

  // CustomerReview
  officeReviewList,
  orderAppReviewList,
  specificReviewList,
  mobileReviewList,
  mobileTodayReviewList,
  mobileTodaySpecificReviewList,
  mobileSpecificReviewList,
  insertAnswer,
  deleteAnswer,
  autoAnswerList,
  updateAutoAnswer,
  insertAutoAnswer,
  deleteAutoAnswer,
  specificReview,
  reviewPrevUrl,
  syncReviewList,

  // Advertisement
  officeInfo,
  distanceToggle,
  activeMarkerInfo,
  setDimmedOfficeFilter,
  setOfficeView,
  getAreaLocationPinListData,
  getOfficeLocationPinListData,
  pinInfo,
  activeChannels,
  maxDeliveryBounds,
  advertisementFilter,
  advertisementMarkerData,

  // call API for mobile mypage&payment
  getRegistInfo,
  registPaymentInfo,
  getHistory,
  singlePayment,
});

const rootReducer = (state, action) => {
  if (action.type === authConstants.LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
