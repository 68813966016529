import classnames from 'classnames';
import React from 'react';
import { isMobile } from '../../constants';
import { getMyUserData } from '../../helpers';
import ChannelChat from '../../helpers/ChannelChat';
import { dateDiff, dateToStr2 } from '../../helpers/DateConverter';

const ToastPeriod = () => {
  // accept_date : 서비스 날짜, demo_expired_date : 무료체험 종료 날짜
  const userData = getMyUserData(false);
  if (!userData) {
    return null;
  }

  const startDate = userData['accept_date'];
  const endDate = userData['demo_expired_date'];

  const TODAY = new Date();
  let REMAIN_DATE = 0; // 무료체험 기간 남은 날짜
  let DAY_STATE = true; // 무료체험 날짜가 7일 남았을 경우 FALSE, 안 남았을 경우 TRUE
  let DATE = dateDiff(TODAY, dateToStr2(endDate, 3)); // 남은 무료체험기간 : 무료체험 기간 - 오늘
  let DURING_DATE = dateDiff(TODAY, dateToStr2(startDate, 3));

  // 무료체험기간이 남았을 경우
  if (dateToStr2(TODAY, 3) < dateToStr2(endDate, 3)) {
    // 7일전 일때
    if (DATE + 1 <= 7) {
      REMAIN_DATE = DATE + 1;
      DAY_STATE = false;
    } else {
      // 8일 이상 남았을때
      DAY_STATE = true;
    }
  }
  // 무료체험기간이 지났을 경우
  else {
    REMAIN_DATE = -1;
  }
  return (
    <>
      {REMAIN_DATE !== -1 && (
        <div div className={classnames(`toast-ui period is-ani`, DAY_STATE === false ? 'is-date' : '')}>
          <div className="desc">
            <div className="left">
              {DAY_STATE === true ? (
                <>
                  무료체험기간 <strong>{DURING_DATE}일차</strong> 입니다.
                </>
              ) : (
                <>
                  {isMobile() === false ? (
                    <>
                      무료체험 기간 만료일 <strong>{REMAIN_DATE}일전</strong> 입니다.
                    </>
                  ) : (
                    <>
                      무료체험기간 <strong>종료 {REMAIN_DATE}일전</strong>
                    </>
                  )}
                </>
              )}
            </div>
            <div className="right">
              {DAY_STATE === true ? (
                <>
                  <strong>체험 {DURING_DATE}일</strong>
                  <span>
                    ( 무료체험 {DATE}일 남음 / {dateToStr2(startDate, 3)}일 서비스 이용 시작 )
                  </span>
                </>
              ) : (
                <>
                  {isMobile() === false ? (
                    <strong className="pc-layer">기간이 만료되면 서비스 이용이 종료됩니다.</strong>
                  ) : (
                    <>
                      <strong>{REMAIN_DATE}일 후 서비스가 종료</strong>됩니다.
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          {DAY_STATE === true ? (
            <></>
          ) : (
            <>
              {isMobile() === false ? (
                <div className="sub-desc">( 무료체험 7일 남음 / {dateToStr2(startDate, 3)}일 가입 )</div>
              ) : (
                <>
                  <div className="sub-desc">( 무료체험 7일 남음 / {dateToStr2(startDate, 3)}일 서비스이용 시작 )</div>
                </>
              )}
              <button className="inquiry-btn" onClick={() => ChannelChat.setSupportBot(23020)}>
                정식 서비스 전환문의
              </button >
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ToastPeriod;
