import React from 'react';
import { Col, CustomInput, Row } from 'reactstrap';
import { deepClone } from '../../helpers';
import { BasicBtn } from '../Buttons';
import './officelistmodal.scss';
import styled from 'styled-components';

const OfifceListModal = ({ list = [], selected = [], onSelect = () => {}, onClose = () => {} }) => {
  const handleClose = () => {
    onClose(true);
  };

  const selectCheckbox = value => {
    var tmp = deepClone(selected);
    var index = tmp.indexOf(value);
    if (index !== -1) {
      tmp.splice(index, 1);
    } else {
      tmp.push(value);
    }

    if (tmp.length > 15) {
      alert('최대 15개의 스토어만 체크 가능합니다.');
      return;
    }

    onSelect(tmp);
  };

  if (!list || list.length <= 0) {
    return null;
  }

  return (
    <>
      <div className="popup flex">
        <div className="office-list-modal">
          <div className="list-wrap p-1">
            {list.map(store => {
              return (
                <Row key={store['storeIdx']} className={'m-0'}>
                  <Col className={'p-0'}>
                    <CustomInput
                      className=""
                      type="checkbox"
                      id={store['storeIdx']}
                      checked={selected.includes(store['storeIdx'])}
                      onChange={() => selectCheckbox(store['storeIdx'])}
                      label={store['storeName']}
                    />
                  </Col>
                </Row>
              );
            })}
          </div>
          <div className="btn-wrap">
            <BasicBtn size="regular" onClick={handleClose}>
              완료
            </BasicBtn>
          </div>
        </div>
      </div>
    </>
  );
};

export default OfifceListModal;
