import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Question from '../../assets/utils/images/question.svg';
import Checked from '../../assets/utils/images/icon_check_circle_active_blue.svg';
import Close from '../../assets/utils/images/close16.svg';
import { isSelfEmployed } from '../../helpers/CommonUtil';
import { getMyUserData, isMarketbomLink } from '../../helpers';
import { userService } from '../../services';
import { useDispatch } from 'react-redux';
import { userActions } from '../../actions';

const FunnelCheck = () => {
  const dispatch = useDispatch();
  const userData = getMyUserData(false);

  const [activeLabel, setActiveLabel] = useState('');
  const [showPopup, setShowPopup] = useState(true);

  const items = [
    {
      class: 'google-search',
      title: '구글 검색',
    },
    {
      class: 'facebook',
      title: '페이스북',
    },
    {
      class: 'naver',
      title: '네이버 검색',
    },
    {
      class: 'insta',
      title: '인스타그램',
    },
    {
      class: 'daum',
      title: '다음 검색',
    },
    {
      class: 'youtube',
      title: '유튜브',
    },
    {
      class: 'kakao',
      title: '카카오톡 배너',
    },
    {
      class: 'barogo',
      title: '바로고',
    },
    {
      class: 'cafe',
      title: '아프니까 사장이다',
    },
    {
      class: 'fordeo',
      title: '포디오',
    },
    {
      class: 'google-AD',
      title: '구글 광고',
    },
    {
      class: 'introduction',
      title: '지인 소개',
    },
    {
      class: 'media',
      title: '미디어/뉴스 기사',
    },
    {
      class: 'etc',
      title: '기타',
    },
  ];

  const handleLabelClick = labelClass => {
    setActiveLabel(labelClass);
  };

  useEffect(() => {
    setShowPopup(userData && userData['inflow_flag'] === 1);
  }, []);

  const handleClose = buttonFlag => {
    userService.setInflow(buttonFlag, userData['user_sno'], activeLabel).then(() => {
      dispatch(userActions.myUserData(userData['user_sno'], userData['service_type']));
    });

    setShowPopup(false);
  };

  if (!showPopup || !isSelfEmployed() || isMarketbomLink()) {
    return null;
  }

  return (
    <Modal>
      <div className="header">
        <div className="close-btn" onClick={() => handleClose('close')}></div>
        <div className="flex-column">
          <div className="icon">
            <img src={Question} />
          </div>
          <div>보다 나은 앳트래커 서비스를 위한 질문 하나~!</div>
        </div>
        <div className="title">
          <div>사장님은 어떤 경로를 통해</div>
          <div>
            <span>앳트래커</span>를 이용하시게 되었나요?
          </div>
        </div>
      </div>
      <div className="checkbox-wrap">
        <div>
          {items.map((item, idx) => {
            return (
              <>
                <label
                  key={idx}
                  htmlFor={item.title}
                  className={activeLabel === item.title ? 'active' : ''}
                  onClick={() => handleLabelClick(item.title)}
                >
                  {item.title}
                  <input
                    type="radio"
                    name="funnel-type"
                    id={item.title}
                    value={item.title}
                    checked={activeLabel === item.title}
                  />
                </label>
              </>
            );
          })}
        </div>
      </div>
      <div className="submit-btn" onClick={() => handleClose('accept')}>
        확인
      </div>
    </Modal>
  );
};
export default FunnelCheck;

const Modal = styled.div`
  width: calc(100% - 16px);
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 100;
  height: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 32px;
  background: rgba(68, 71, 73, 0.96);
  backdrop-filter: blur(4px);
  padding: 16px 16px 24px;
  color: #fefefe;
  font-weight: 200;
  line-height: 140%;

  .header {
    font-size: 14px;
    font-weight: 400;
    padding: 8px 8px 0;
    display: flex;
    gap: 8px;
    flex-direction: column;

    .close-btn {
      position: absolute;
      right: 20px;
      top: 20px;
      background-image: url(${Close});
      width: 16px;
      height: 16px;
      content: '';
    }

    .flex-column {
      padding: 8px 0;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: 1px dashed rgba(255, 255, 255, 0.5);
      border-radius: 12px;
      text-align: center;
      height: 80px;
      padding: 8px;
      font-size: 15px;
      font-weight: 600;
      span {
        color: #fff500;
      }
    }
  }

  .checkbox-wrap {
    padding: 0 8px 8px 8px;
    font-size: 14px;
    font-weight: 300;

    > div {
      padding: 12px 0 12px 8px;
      display: grid;
      grid-gap: 12px 16px;
      grid-template-columns: 1fr 1fr;
    }

    label {
      margin: 0;
      position: relative;
      padding-left: 30px;
      display: flex;
      height: 24px;
      align-items: center;

      &::before {
        border: 1px solid #afb5bd;
        border-radius: 50%;
        content: ' ';
        width: 24px;
        height: 24px;
        display: block;
        position: absolute;
        left: 0;
      }
      input[type='radio'] {
        position: absolute;
        left: 0;
        display: none;
      }

      &.active {
        &::after {
          background: url(${Checked}) no-repeat center;
          background-size: 26px 26px;
          content: ' ';
          width: 26px;
          height: 26px;
          display: block;
          position: absolute;
          left: -1px;
          top: -1px;
          z-index: 1;
        }
        &::before {
          display: none;
        }
      }
    }
  }

  .submit-btn {
    width: 100%;
    padding: 16px 32px 15px;
    text-align: center;
    border-radius: 24px;
    background: #686cff;
    font-size: 15px;
    font-weight: 500;
  }
`;
