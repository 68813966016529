import { atom } from 'recoil';
import { USER_ATOM_KEY } from '../../constants/atomKeys';

export const additionalPageInfoState = atom({
  key: USER_ATOM_KEY.ADDITIONAL_PAGE_INFO,
  default: {
    isPageActive: false,
    additionalType: 'A',
    handleEvent: () => {},
  },
});

export const additionalAddressInfoState = atom({
  key: USER_ATOM_KEY.ADDITIONAL_ADDRESS_INFO,
  default: {
    address: '',
    addressData: '',
    detailAddress: '',
  },
});

export const additionalBusinessInfoState = atom({
  key: USER_ATOM_KEY.ADDITIONAL_BUSINESS_INFO,
  default: {
    businessNum: '',
    businessAccess: false,
    businessFile: null,
  },
});
