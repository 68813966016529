import { authHeader, handleResponse, setLocalData, toSha256 } from '../helpers';
import { checkReactNativeWebView, setNotifyBadgeCount, setUserId } from '../helpers/AppWebView';
import ChannelService from '../helpers/ChannelChat';
import WebSocket from '../helpers/WebSocket';

export const authService = {
  login,
  logout,
  brandLogin,

  validPhoneNum,
  sendPhoneAuthNumber,
  checkAuthNumber,
  resetPassword,
  checkLoginState,
  systemErrorLog,
  changeEmail,
  changeMobile,
  ghostInit,
  checkAdminAccount,
  agreePolicy,
  checkJandiBrandAccessAuth,
  checkAccountToken,
  checkBusinessNum,
  checkInvitedPhoneNum,
  checkPromotionCode,
  checkTouchingQR,
  getNiceEncrypt,

  systemLinkValidToken,
  completeAgreement,
  completePhoneVerfication,

  // marketboro
  getMarketboroLoginURL,

  // marketbom
  marketbomLinkInfo,
  marketbomCompleteLink,

  setAppPushOption,
};

function logout(callLogout) {
  setUserId();

  let keepDataKeys = [
    'delivery_tip_info_confirm_date',
    'disableToastNoticeIdx',
    'isCheckedPostTooltip',
    'isNotInstallPos',
    'isRedirectToMain',
    'isOpenNoticeTooltip',
    'isNoticeConfirmHide',
    'adModalNoShow',
  ];
  let keepData = [];
  keepDataKeys.map(key => {
    keepData.push(localStorage.getItem(key));
    return null;
  });

  localStorage.clear();

  keepData.map((data, i) => {
    if (data === 'null') {
      localStorage.removeItem(keepDataKeys[i]);
      return null;
    }

    if (data === null) {
      localStorage.removeItem(keepDataKeys[i]);
    } else {
      localStorage.setItem(keepDataKeys[i], data);
    }
    return null;
  });

  // 채널톡 관련 쿠키값 초기화
  document.cookie.split(';').forEach(function(c) {
    var name = c.split('=')[0].trim();
    if (name.indexOf('ch-session-') !== -1 || name.indexOf('ch-veil-id') !== -1) {
      document.cookie = name + '=;expires=' + new Date().toUTCString() + ';path=/';
    }
  });

  ChannelService.reboot();

  // app badge count reset
  if (checkReactNativeWebView()) {
    setNotifyBadgeCount(0);
  }
}

function login(userID, password, serviceType, kakaoKey, deviceType, localToken) {
  let encodePassword = toSha256(password);

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      phoneNumber: userID,
      password: encodePassword,
      serviceType: serviceType,
      kakaoKey: kakaoKey,
      deviceType: deviceType,
      localToken: localToken,
    }),
  };

  return fetch('/auth/login', requestOptions)
    .then(handleResponse)
    .then(data => {
      if (data.result.rtn === 1) {
        setLocalData('user', data.result);
        if (data.result.token) {
          setLocalData('token_' + data.result.phoneNumber + '_' + data.result.serviceType, data.result.token);
          WebSocket.checkUserToken(data.result.token);
        }
      }

      return data;
    });
}

function brandLogin(userID, token) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userID: userID,
      token: token,
    }),
  };

  return fetch('/auth/brandLogin', requestOptions)
    .then(handleResponse)
    .then(data => {
      if (data.result.rtn === 1) {
        setLocalData('user', data.result);
        if (data.result.token) {
          setLocalData('token_' + data.result.phoneNumber + '_' + data.result.serviceType, data.result.token);
        }
      }

      return data;
    });
}

function validPhoneNum(phoneNumber, serviceType) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      phoneNumber: phoneNumber,
      serviceType: serviceType,
    }),
  };

  return fetch('/auth/validPhoneNum', requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function sendPhoneAuthNumber(phoneNumber, locationServiceType) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      phoneNumber: phoneNumber,
      locationServiceType: locationServiceType,
    }),
  };

  return fetch('/auth/sendPhoneAuthNumber', requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function checkAuthNumber(token, authNumber) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      token: token,
      authNumber: authNumber,
    }),
  };

  return fetch('/auth/checkAuthNumber', requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function resetPassword(phoneNumber, password) {
  let encodePassword = toSha256(password);

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      phoneNumber: phoneNumber,
      password: encodePassword,
    }),
  };

  return fetch('/auth/resetPassword', requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function checkLoginState(userSno) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
    }),
  };

  return fetch('/auth/checkLoginState', requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function systemErrorLog(host, path, message) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      host: host,
      path: path,
      message: message,
    }),
  };

  return fetch('/auth/systemErrorLog', requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function changeEmail(userSno, email) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      email: email,
    }),
  };

  return fetch('/auth/changeEmail', requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function changeMobile(userSno, mobile) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      mobile: mobile,
    }),
  };

  return fetch('/auth/changeMobile', requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function ghostInit(companyCode, brandCode, userSno) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      companyCode: companyCode,
      brandCode: brandCode,
      userSno: userSno,
    }),
  };

  return fetch('/auth/ghostInit', requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function checkAdminAccount(userID, password, otp) {
  let encodePassword = toSha256(password);

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userID: userID,
      password: encodePassword,
      token: otp,
    }),
  };

  return fetch('/auth/checkAdminAccount', requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function agreePolicy(userSno, checkList) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      checkList: checkList,
    }),
  };

  return fetch('/auth/agreePolicy', requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function checkJandiBrandAccessAuth(userSno, officeSno, token) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      officeSno: officeSno,
      token: token,
    }),
  };

  return fetch('/auth/checkJandiBrandAccessAuth', requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function checkAccountToken(userSno, token) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      token: token,
    }),
  };

  return fetch('/auth/checkAccountToken', requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function checkBusinessNum(businessNum, checkDuplication) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      businessNum: businessNum,
      checkDuplication: checkDuplication,
    }),
  };

  return fetch('/auth/checkBusinessNum', requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function checkInvitedPhoneNum(phoneNumber) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      phoneNumber: phoneNumber,
    }),
  };

  return fetch('/auth/checkInvitedPhoneNum', requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function checkPromotionCode(promotionCode) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      promotionCode: promotionCode,
    }),
  };

  return fetch('/auth/checkPromotionCode', requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function checkTouchingQR(qr) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      qr: qr,
    }),
  };

  return fetch('/auth/checkTouchingQR', requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function getNiceEncrypt(returnUrl, errorUrl) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      ReturnUrl: returnUrl,
      ErrorUrl: errorUrl,
    }),
  };

  return fetch('/auth/getNiceEncrypt', requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function systemLinkValidToken(token, route = '') {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      token: token,
    }),
  };

  return fetch(`/v2/${route}/validateToken`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function completeAgreement(userID, checkList, phoneNumber, route = '') {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userID: userID,
      checkList: checkList,
      phoneNumber: phoneNumber,
    }),
  };

  return fetch(`/v2/${route}/completeAgreement`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function completePhoneVerfication(userSno, phoneNumber) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      phoneNumber: phoneNumber,
    }),
  };

  return fetch('/auth/completePhoneVerfication', requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function setAppPushOption(userSno, isInit, optionType, optionValue) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      isInit: isInit,
      optionType: optionType,
      optionValue: optionValue,
    }),
  };

  return fetch('/auth/setAppPushOption', requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function getMarketboroLoginURL(userSno) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
    }),
  };

  return fetch('/v2/marketboro/getLoginURL', requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function marketbomLinkInfo(token) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      token: token,
    }),
  };

  return fetch('/v2/marketbom/getLinkInfo', requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function marketbomCompleteLink({ token, officeName, email }) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({ token, officeName, email }),
  };

  return fetch('/v2/marketbom/completeLink', requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
