import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PutNewMobile from './PutNewMobile';
import VerifyCodeModal from './VerifyCodeModal';

class EditPhoneNumberModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modal: props.modal,
      changedMobile: '',
      step: 1,
    }

    this.handleRegistStep = this.handleRegistStep.bind(this);
  }

  handleRegistStep(step, param = {}) {
    if (param['changedMobile'] !== undefined) {
      this.setState({ changedMobile: param['changedMobile'] });
    }

    this.setState({ step: step });
  }

  render() {

    let { step, changedMobile, modal } = this.state;

    return (
      <Fragment>
        {
          step === 1 && <PutNewMobile step={step} modal={modal} onRegistStep={this.handleRegistStep} toggle={this.props.toggle} />
        }
        {
          step === 2 && <VerifyCodeModal step={step} modal={modal} onRegistStep={this.handleRegistStep} changedMobile={changedMobile} toggle={this.props.toggle} />
        }
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { validPhoneNum } = state.validPhoneNum;

  return {
    validPhoneNum,
  };
}

export default connect(mapStateToProps)(EditPhoneNumberModal);