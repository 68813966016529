import React from 'react';
import styled from 'styled-components';
import { content } from './TTPFranchiseStoreContent';

export const TTPFranchiseStore = props => {
  const { brandName } = props;

  let name = brandName ? brandName : '[가맹점이 소속된 프랜차이즈 본사]';

  return <Wrapper dangerouslySetInnerHTML={{ __html: content(name) }} />;
};

const Wrapper = styled.div`
  p,
  li,
  div,
  span {
    line-height: 1.6 !important;
  }

  span.c7 {
    margin-bottom: 16px;
    display: inline-block;
  }

  span.c5 {
    margin: 16px 0 8px;
    display: inline-block;
  }

  padding: 40px 32px 32px;
`;
