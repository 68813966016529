import React, { useState } from 'react';
import styled from 'styled-components';
import { setHypenBusinessNum } from '../../../helpers';
import { authService } from '../../../services';
import ATInput from './ATInput';
import classNames from 'classnames';
import { MOBILE_AUTH_SUCCESS } from '../../../pages/UserPages/MobileAuth/Helper';
import inputCheck from '../../../assets/utils/images/input-check-none.svg';
import { useDispatch } from 'react-redux';
import { commonActions } from '../../../actions';
import ChannelChat from '../../../helpers/ChannelChat';

const MobileBusinessNumInput = props => {
  const {
    getValue = () => {},
    onValid = () => {},
    mobileAuthState,
    autoFocus = false,
    checkDuplication = false,
  } = props;

  const dispatch = useDispatch();

  const CHECK_INIT = {
    state: -1,
    message: '',
  };
  const [value, setValue] = useState(null);
  const [checkResult, setCheckResult] = useState(CHECK_INIT);

  const onChange = v => {
    setValue(v.value);
    getValue(v);

    setCheckResult(CHECK_INIT);
    onValid(false);
  };

  const handleValid = async () => {
    let validCheck = await authService.checkBusinessNum(value, checkDuplication);

    if (checkDuplication && validCheck && validCheck['rtn'] === 101) {
      dispatch(
        commonActions.alertMessage(
          '사업자번호 중복',
          '동일한 사업자번호가 존재합니다.\n사업자 번호를 확인 후 다시 가입해 주세요.',
          null,
          null,
          () => ChannelChat.openChat(),
          null,
          '320px',
        ),
      );
    }

    if (validCheck && validCheck['result']) {
      setCheckResult(validCheck['result']);
      onValid(validCheck['result']['state'] === 1);

      if (validCheck['result']['state'] === 1) {
        alert('사업자 정상확인 되었습니다.');
      }
    }
  };

  return (
    <Wrapper>
      <div className="flex">
        <ATInput
          name="businessNum"
          type="text"
          className={classNames(
            'inputwrap',
            checkResult['message'] ? 'is-false' : '',
            checkResult['state'] === -1 ? 'no-valid' : '',
            checkResult['state'] === 1 && 'is-disabled is-readonly',
          )}
          dataText="사업자 등록번호"
          required
          readonly={checkResult['state'] === 1 || mobileAuthState != MOBILE_AUTH_SUCCESS}
          useNumberFormat={true}
          formatter={v => setHypenBusinessNum(v)}
          getValue={v => onChange(v)}
          forceShowErrorMsg={checkResult['message']}
          style={{ width: '100%' }}
          autoFocus={autoFocus}
        />

        {checkResult['state'] === 1 ? (
          <div className={classNames('btn-action is-disabled')}>인증완료</div>
        ) : (
          <div
            className={classNames('btn-action pointer')}
            onClick={mobileAuthState == MOBILE_AUTH_SUCCESS ? handleValid : undefined}
          >
            인증하기
          </div>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  margin-top: 24px;

  .inputwrap {
    width: calc(100% - 88px);
  }

  .btn-action {
    height: 100%;
    margin-left: 9px;
    padding: 0 12px;
    word-break: keep-all;
    background: #9598ff;
    border-radius: 12px;
    color: white;
    line-height: 40px;
    border: none;
    &.is-disabled {
      background: #dee2e4;
      pointer-events: none;
    }
  }

  .inputwrap {
    &.is-readonly {
      .input-place {
        color: rgb(210, 212, 214);

        &:after {
          color: rgb(210, 212, 214);
        }
      }

      &:after {
        background: none !important;

        .input-place {
          z-index: 0;
        }
      }
    }

    &.is-disabled {
      .input-place {
        z-index: 1;
      }

      input {
        background-color: #f4f5f6 !important;
      }

      input:valid {
        background-color: #f4f5f6 !important;
        background-image: url(${inputCheck}) !important;
        background-repeat: no-repeat;
      }
    }
  }
`;

export default MobileBusinessNumInput;
