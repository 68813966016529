import React from 'react';
import './loadingItem.scss';

const LoadingItem = () => {
  return (
    <div className="ani-loading-container">
      <div className="loader">
        <div className="loading-spokes">
          <div className="spoke-container">
            <div className="spoke"></div>
          </div>
          <div className="spoke-container">
            <div className="spoke"></div>
          </div>
          <div className="spoke-container">
            <div className="spoke"></div>
          </div>
          <div className="spoke-container">
            <div className="spoke"></div>
          </div>
          <div className="spoke-container">
            <div className="spoke"></div>
          </div>
          <div className="spoke-container">
            <div className="spoke"></div>
          </div>
          <div className="spoke-container">
            <div className="spoke"></div>
          </div>
          <div className="spoke-container">
            <div className="spoke"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingItem;
