import { ORDER_FREQ_COUNT } from '../components/Styled/Containers/Location/helper';
import { commonConstants } from '../constants';
import { arrayToObject, deepClone, getMyUserData, getUnixTime, sortDesc } from '../helpers';

export function searchParams(state = {}, action) {
  switch (action.type) {
    case commonConstants.SET_SEARCH_PARAMS:
      return {
        searchParams: action.result,
      };
    default:
      return state;
  }
}

export function setOfficeRange(state = {}, action) {
  switch (action.type) {
    case commonConstants.SET_OFFICE_RANGE_RESET:
      return {
        setOfficeRange: null,
      };
    case commonConstants.SET_OFFICE_RANGE:
      return {
        setOfficeRange: action.result,
      };
    default:
      return state;
  }
}

export function setIsActiveAdPin(state = {}, action) {
  switch (action.type) {
    case commonConstants.SET_IS_ACTIVE_AD_PIN:
      return {
        setIsActiveAdPin: action.result,
      };
    default:
      return state;
  }
}

export function setDetailOrderViewDate(state = { setDetailOrderViewDate: '' }, action) {
  switch (action.type) {
    case commonConstants.SET_DETAIL_ORDER_VIEW_DATE:
      return {
        setDetailOrderViewDate: action.result,
      };
    default:
      return state;
  }
}

export function setRangePercentageByViewType(state = {}, action) {
  switch (action.type) {
    case commonConstants.SET_RANGE_PERCENTAGE_BY_VIEWTYPE:
      return {
        setRangePercentageByViewType: action.result,
      };
    default:
      return state;
  }
}

export function setMaxDistance(state = {}, action) {
  switch (action.type) {
    case commonConstants.SET_MAX_DISTANCE_RESET:
      return {
        setMaxDistance: null,
      };
    case commonConstants.SET_MAX_DISTANCE:
      return {
        setMaxDistance: action.result,
      };
    default:
      return state;
  }
}

export function setDeliveryOfficeSno(state = {}, action) {
  switch (action.type) {
    case commonConstants.SET_DELIVERY_OFFICE_SNO:
      return {
        setDeliveryOfficeSno: action.result,
      };
    default:
      return state;
  }
}

export function setDeliveryRangeCount(state = { setDeliveryRangeCount: ORDER_FREQ_COUNT.moreOne.value }, action) {
  switch (action.type) {
    case commonConstants.SET_DELIVERY_RANGE_COUNT:
      return {
        setDeliveryRangeCount: action.result,
      };
    default:
      return state;
  }
}

export function setDeliveryOrderViewType(state = { setDeliveryOrderViewType: 0 }, action) {
  // 0: 전체, 신규, 재주문
  switch (action.type) {
    case commonConstants.SET_DELIVERY_ORDER_VIEW_TYPE:
      return {
        setDeliveryOrderViewType: action.result ? action.result : 0,
      };
    default:
      return state;
  }
}

export function setAdvertisementFilterType(state = { setAdvertisementFilterType: 'Ratio' }, action) {
  // 0: 전체, 신규, 재주문
  switch (action.type) {
    case commonConstants.SET_ADVERTISEMENT_FILTER_TYPE:
      return {
        setAdvertisementFilterType: action.result ? action.result : 'Ratio',
      };
    default:
      return state;
  }
}

export function setMenuOptionType(state = { setMenuOptionType: 1 }, action) {
  switch (action.type) {
    case commonConstants.SET_MENU_OPTION_TYPE:
      return {
        setMenuOptionType: action.result ? action.result : 1,
      };
    default:
      return state;
  }
}

export function reverseGeoCode(state = {}, action) {
  switch (action.type) {
    case commonConstants.REVERSE_GEOCODE_RESET:
      return {
        deliveryTotalData: null,
      };
    case commonConstants.REVERSE_GEOCODE_REQUEST:
      return {
        loading: true,
      };
    case commonConstants.REVERSE_GEOCODE_SUCCESS:
      return {
        reverseGeoCode: action.result['result'],
      };
    case commonConstants.REVERSE_GEOCODE_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function alertMessage(state = {}, action) {
  switch (action.type) {
    case commonConstants.SET_ALERT_MESSAGE:
      return {
        alertMessage: action.result,
      };
    default:
      return state;
  }
}

export function navRerender(state = {}, action) {
  switch (action.type) {
    case commonConstants.NAV_RERENDER:
      return {
        navRerender: action.result,
      };
    default:
      return state;
  }
}
export function officeManagementExcelGuideStep(
  state = {
    officeManagementExcelGuideStep: null,
  },
  action,
) {
  switch (action.type) {
    case commonConstants.OFFICE_MANAGEMENT_EXCEL_GUIDE_STEP:
      // step1 (1), step2 (2), 엑셀업로드한적있다 (3) 닫도록 (4), 열도록 (5)
      return {
        officeManagementExcelGuideStep: action.result,
      };
    default:
      return state;
  }
}

export function userManagementExcelGuideStep(
  state = {
    userManagementExcelGuideStep: null,
  },
  action,
) {
  switch (action.type) {
    case commonConstants.OFFICE_MANAGEMENT_EXCEL_GUIDE_STEP:
      // step1 (1), step2 (2), 엑셀업로드한적있다 (3) 닫도록 (4), 열도록 (5)
      return {
        userManagementExcelGuideStep: action.result,
      };
    default:
      return state;
  }
}

export function setManagementEidtMode(
  state = {
    setManagementEidtMode: false,
  },
  action,
) {
  switch (action.type) {
    case commonConstants.SET_MANAGEMENT_EDITMODE:
      return {
        setManagementEidtMode: action.result,
      };
    default:
      return state;
  }
}

export function activeTooltip(state = {}, action) {
  switch (action.type) {
    case commonConstants.ACTIVE_TOOLTIP_RESET:
      return {
        activeTooltip: null,
      };
    case commonConstants.ACTIVE_TOOLTIP:
      return {
        activeTooltip: action.result,
      };
    default:
      return state;
  }
}

export function setMainOfficeFilter(state = {}, action) {
  switch (action.type) {
    case commonConstants.SET_MAIN_OFFICE_FILTER:
      return {
        key: action.result['key'],
        type: action.result['type'],
      };
    default:
      return state;
  }
}

export function setDeliveryViewType(state = {}, action) {
  switch (action.type) {
    case commonConstants.SET_DELIVERY_VIEW_TYPE:
      return {
        viewType: action.result['viewType'],
        name: action.result['name'],
      };
    default:
      return state;
  }
}

export function setMenuSaleViewType(state = {}, action) {
  switch (action.type) {
    case commonConstants.SET_MENU_SALE_VIEW_TYPE:
      return {
        viewType: action.result['viewType'],
        name: action.result['name'],
      };
    default:
      return state;
  }
}

export function setMapFullSize(state = {}, action) {
  switch (action.type) {
    case commonConstants.SET_MAP_FULL_SIZE:
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 10);

      return {
        isMapFull: action.result['isMapFull'],
      };
    default:
      return state;
  }
}

export function setChartTooltipZindex(state = {}, action) {
  switch (action.type) {
    case commonConstants.SET_CHART_TOOLTIP_ZINDEX:
      return {
        chartTooltipZindex: action.result['zIndex'],
      };
    default:
      return state;
  }
}

export function setCustomerReviewViewType(state = {}, action) {
  switch (action.type) {
    case commonConstants.SET_CUSTOMER_REVIEW_VIEW_TYPE:
      return {
        viewType: action.result['viewType'],
        name: action.result['name'],
      };
    default:
      return state;
  }
}

// 특정 네비메뉴만 active 시킬때 사용
export function activeNavItemOnly(state = {}, action) {
  switch (action.type) {
    case commonConstants.ACTIVE_NAV_ITEM_ONLY:
      return {
        navItem: action.result['navItem'],
      };
    default:
      return state;
  }
}

// 마스터 관리자인데 등록된 매장이 없는 state 관리
export function masterManagerState(state = {}, action) {
  switch (action.type) {
    case commonConstants.MASTER_MANAGER_STATE:
      return {
        masterManagerState: action.result['state'],
      };
    default:
      return state;
  }
}

export function dongSalesDataList(state = {}, action) {
  switch (action.type) {
    case commonConstants.SET_DONG_SALES_DATA_LIST:
      return {
        dongSalesDataList: action.result['result'],
      };
    default:
      return state;
  }
}

export function dongSalesParams(state = {}, action) {
  switch (action.type) {
    case commonConstants.SET_DONG_SALES_PARAMS:
      return {
        dongSalesParams: action.result,
      };
    default:
      return state;
  }
}

export function setMobileOfficeTabDetectingScroll(state = {}, action) {
  switch (action.type) {
    case commonConstants.SET_MOBILE_OFFICE_TAB_DETECTING_SCROLL:
      return {
        isSetScroll: action.result,
      };
    default:
      return state;
  }
}

export function setIsRealTime(state = {}, action) {
  switch (action.type) {
    case commonConstants.SET_IS_REAL_TIME:
      let userData = getMyUserData(false);
      return {
        isRealTime: getUnixTime(),
        refreshRealTimeData: userData && userData['auth_type'] === 'D1' ? action.result : undefined,
      };
    default:
      return state;
  }
}

export function setCombineAlarmList(state = {}, action) {
  switch (action.type) {
    case commonConstants.SET_COMBINE_ALARM_LIST:
      let newAlertList = action.result['list'];
      let tmp = deepClone(state.setCombineAlarmList);
      let tmpLastReadIdx = {};

      let newObj = arrayToObject(newAlertList, 'tabType');

      Object.keys(newObj).map(tab => {
        let item = newObj[tab];
        if (tmp[tab]) {
          const uniqueData = item['alert'].filter(
            (value, index, self) => index === self.findIndex(v => v.alertIdx === value.alertIdx),
          );

          tmp[tab] = uniqueData;
        } else {
          tmp[tab] = item['alert'];
        }

        tmp[tab].map(v => (v['isRead'] = v['alertIdx'] <= item['lastReadIdx'] ? 1 : 0));
        tmp[tab] = sortDesc(tmp[tab], 'alertIdx');

        tmpLastReadIdx[tab] = item['lastReadIdx'];

        return null;
      });

      return {
        setCombineAlarmList: tmp,
        lastReadIdxTabs: tmpLastReadIdx,
      };
    default:
      return state;
  }
}

export function setCloseMenuItemSlide(state = {}, action) {
  switch (action.type) {
    case commonConstants.SET_CLOSE_MENU_ITEM_SLIDE:
      return {
        isCloseMenuItemSlide: action.result,
      };
    default:
      return state;
  }
}

export function resetOrderMenuToggle(state = {}, action) {
  switch (action.type) {
    case commonConstants.RESET_ORDER_MENU_TOGGLE:
      return {
        isResetOrderMenuToggle: action.result,
      };
    default:
      return state;
  }
}

export function setIsSlideShow(state = {}, action) {
  switch (action.type) {
    case commonConstants.SET_IS_SLIDE_SHOW:
      return {
        isSlideShow: action.isSlideShow,
      };
    default:
      return state;
  }
}

export function setIsMobilePopupShow(state = { isMobilePopupShow: true }, action) {
  switch (action.type) {
    case commonConstants.SET_IS_MOBILE_POPUP_SHOW:
      return {
        isMobilePopupShow: action.isMobilePopupShow,
      };
    default:
      return state;
  }
}

export function mobileCommonPopup(state = { isMobileCommonPopupShow: false }, action) {
  switch (action.type) {
    case commonConstants.SET_IS_MOBILE_COMMON_POPUP_SHOW:
      return {
        isMobileCommonPopupShow: action.isMobileCommonPopupShow,
      };
    default:
      return state;
  }
}
