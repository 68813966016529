import { getMyUserData, setLocalData } from './LoadData';
import { trackEvent, validation } from '../helpers';
import { rules } from './FormValidator';
import { toSha256 } from './Converter';
import { checkHiddenChannelChat } from './AuthValidation';
import { changeToYMD } from './DateConverter';
import { GA_EVENT_CONST, isMobile } from '../constants';
import { getProductCodeName, setMobileViewPort } from './CommonUtil';
import { authService } from '../services';

/**
 * @module ChannelChat
 */

const PLUGIN_KEY = 'd3d6f11b-919e-4207-9223-68b17b4c822e';
var currentProfile = undefined;
export var isChannelChatBoot = false;

const showChat = () => {
  let domain = window.location.host;
  if (domain.indexOf('/dev/') !== -1 || window.location.host.indexOf('localhost') !== -1) {
    return false;
  }

  return true;
};

const setBrandName = (authType, companyName, brandName, officeName) => {
  companyName = companyName ? companyName : '';
  brandName = brandName ? brandName : '';
  officeName = officeName ? officeName : '';

  // 매장담당자: '브랜드명'
  if (authType === 'D1') {
    return brandName;
  }

  // 마스터관리자&본점관리자: '컴퍼니명 브랜드명'
  return brandName.includes(companyName) ? brandName : companyName + ' ' + brandName;
};

class ChannelService {
  constructor() {
    this.loadScript();
    this.isShowChat = showChat();
  }

  loadScript = () => {
    var w = window;

    if (w.ChannelIO) {
      return (window.console.error || window.console.log || function() {})('ChannelIO script included twice.');
    }
    var ch = function() {
      ch.c(arguments);
    };
    ch.q = [];
    ch.c = function(args) {
      ch.q.push(args);
    };
    w.ChannelIO = ch;
    function l() {
      if (w.ChannelIOInitialized) {
        return;
      }

      w.ChannelIOInitialized = true;
      var s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
      s.charset = 'UTF-8';
      var x = document.getElementsByTagName('script')[0];

      x.parentNode.insertBefore(s, x);
    }
    if (document.readyState === 'complete') {
      l();
    } else if (window.attachEvent) {
      window.attachEvent('onload', l);
    } else {
      window.addEventListener('DOMContentLoaded', l, false);
      window.addEventListener('load', l, false);
    }
  };

  onBoot = profileSetting => {
    isChannelChatBoot = true;
    currentProfile = profileSetting;

    if (this.isShowChat === false) {
      this.hideChat();
    }
  };

  shutdown = () => {
    window.ChannelIO('shutdown');
  };

  init = profileSetting => {
    window.ChannelIO('shutdown');
    setTimeout(() => {
      window.ChannelIO(
        'boot',
        {
          pluginKey: PLUGIN_KEY,
          ...profileSetting,
          hideChannelButtonOnBoot: true,
        },
        (_err, user) => {
          authService.systemErrorLog('channel', '', _err);

          if (user && user['alert']) {
            setLocalData('channelChatBadge', user['alert']);
          }

          this.onBoot(profileSetting);
          window.ChannelIO('hideChannelButton');
        },
      );

      window.ChannelIO('onBadgeChanged', unreadCount => {
        setLocalData('channelChatBadge', unreadCount);
      });

      window.ChannelIO('onShowMessenger', () => {
        if (!isMobile()) {
          return;
        }

        window.ChannelIO('resetPage');

        setTimeout(() => {
          const chMobileMessenger = document.getElementsByClassName('ch-mobile-messenger');

          if (chMobileMessenger.length > 0) {
            chMobileMessenger[0].style.paddingTop = 'env(safe-area-inset-top)';
          }

          setMobileViewPort();
        }, 100);
      });

      window.ChannelIO('onHideMessenger', () => {
        window.ChannelIO('resetPage');
        setMobileViewPort();
      });
    }, 2000);
  };

  boot = (force = false) => {
    if (!window.ChannelIO) {
      return;
    }

    if (checkHiddenChannelChat() === false) {
      window.ChannelIO('shutdown');
      return;
    }

    var userData = getMyUserData(false);
    var profileSetting = userData
      ? {
          memberId: toSha256(userData['user_sno']),
          profile: {
            name: userData['user_name'],
            mobileNumber: validation(userData['user_phone'], rules['phoneNum']) ? userData['user_phone'] : '',
            email: userData['email'],
            userSno: userData['user_sno'],
            brand: setBrandName(
              userData['auth_type'],
              userData['company_name'],
              userData['brand_name'],
              userData['office_name'],
            ),
            isRetail:
              userData['company_type'] === '3'
                ? '자영업자'
                : userData['auth_type'] === 'D1'
                ? '가맹점주 '
                : '프랜차이즈',
            signupDate: changeToYMD(userData['reg_date']),
            authName: userData['auth_name'],
            alliance: userData['alliance_name'],
            shop: userData['auth_type'] === 'D1' ? userData['office_name'] : '',
            pricing_plans: getProductCodeName(),
            billingStatus: userData['billing_status_name'],
          },
        }
      : { profile: null };

    if (isChannelChatBoot === false) {
      this.init(profileSetting);
      return;
    }

    if (userData && currentProfile['profile'] && userData['user_sno'] !== currentProfile['profile']['userSno']) {
      this.init(profileSetting);
      return;
    }

    if (force === true) {
      this.init(profileSetting);
    }
  };

  reboot = () => {
    this.boot(true);
  };

  hideChat = () => {
    window.ChannelIO('hideChannelButton');
    this.isShowChat = false;
  };

  showChat = () => {
    window.ChannelIO('showChannelButton');
    this.isShowChat = true;
  };

  showMessenger = () => {
    trackEvent(GA_EVENT_CONST['C2008']);
    window.ChannelIO('openChat');
    this.isShowChat = true;
  };

  openChat = () => {
    trackEvent(GA_EVENT_CONST['C2008']);
    window.ChannelIO('openChat');
  };

  // 서포트봇 실행함수
  // NOTE : 관리자 페이지에서 페이지 타켓 조건을 추가해야 사용할 수 있음 (e.g : 페이지 다음을 포함 support-bots/{botNumber})
  setSupportBot = async (botNumber = 0) => {
    if (botNumber <= 0) {
      return;
    }
    trackEvent(GA_EVENT_CONST['C2008']);

    window.ChannelIO('setPage', `support-bots/${botNumber}`);
    window.ChannelIO('openWorkflow', botNumber);
  };
}

export default new ChannelService();
