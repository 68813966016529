import { brandConstants } from '../constants';
import { brandService } from '../services';

export const brandActions = {
  noticeList,
  noticeOneLoad,
  noticeCreate,
  noticeEdit,
  noticeWriteComment,
  noticeDisable,
  noticeDisableComment,
  noticeConfirm,
  noticeReadList,
  allBrandList,

  getFranchiseOfficeStatus,
  getFranchiseOfficeStatusVer2,
  getOrderAppOfficeStatus,
  checkSignUpLinkClick,
  insertMultiOfficeManagerInfo,
  // insertSingleOfficeManagerInfo,
  // editOfficeManagerInfo,
  // manageFranchiseOfficeStatus,
  // checkOfficeClosing,
};

function noticeList(userSno, brandCode, limitStart, limitCount) {
  return dispatch => {
    dispatch(request());

    brandService.noticeList(userSno, brandCode, limitStart, limitCount).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: brandConstants.NOTICE_LIST_REQUEST };
  }
  function success(result) {
    return { type: brandConstants.NOTICE_LIST_SUCCESS, result };
  }
  function failure(error) {
    return { type: brandConstants.NOTICE_LIST_FAILURE, error };
  }
}

function noticeOneLoad(brandCode, noticeSno) {
  return dispatch => {
    dispatch(request());

    brandService.noticeOneLoad(brandCode, noticeSno).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: brandConstants.NOTICE_ONE_LOAD_REQUEST };
  }
  function success(result) {
    return { type: brandConstants.NOTICE_ONE_LOAD_SUCCESS, result };
  }
  function failure(error) {
    return { type: brandConstants.NOTICE_ONE_LOAD_FAILURE, error };
  }
}

function noticeCreate(
  noticeSno,
  userSno,
  brandCode,
  noticeType,
  title,
  contents,
  officeSnoList,
  userSnoList,
  filePaths,
) {
  return dispatch => {
    dispatch(request());

    brandService
      .noticeCreate(
        noticeSno,
        userSno,
        brandCode,
        noticeType,
        title,
        contents,
        officeSnoList,
        userSnoList,
        filePaths,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: brandConstants.NOTICE_CREATE_REQUEST };
  }
  function success(result) {
    return { type: brandConstants.NOTICE_CREATE_SUCCESS, result };
  }
  function failure(error) {
    return { type: brandConstants.NOTICE_CREATE_FAILURE, error };
  }
}

function noticeEdit(
  noticeSno,
  userSno,
  brandCode,
  noticeType,
  title,
  contents,
  officeSnoList,
  userSnoList,
  filePaths,
) {
  return dispatch => {
    dispatch(request());

    brandService
      .noticeEdit(
        noticeSno,
        userSno,
        brandCode,
        noticeType,
        title,
        contents,
        officeSnoList,
        userSnoList,
        filePaths,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: brandConstants.NOTICE_EDIT_REQUEST };
  }
  function success(result) {
    return { type: brandConstants.NOTICE_EDIT_SUCCESS, result };
  }
  function failure(error) {
    return { type: brandConstants.NOTICE_EDIT_FAILURE, error };
  }
}

function noticeWriteComment(noticeSno, userSno, brandCode, comment) {
  return dispatch => {
    dispatch(request());

    brandService.noticeWriteComment(noticeSno, userSno, brandCode, comment).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: brandConstants.NOTICE_WRITE_COMMENT_REQUEST };
  }
  function success(result) {
    return { type: brandConstants.NOTICE_WRITE_COMMENT_SUCCESS, result };
  }
  function failure(error) {
    return { type: brandConstants.NOTICE_WRITE_COMMENT_FAILURE, error };
  }
}

function noticeDisableComment(userSno, commentIdx) {
  return dispatch => {
    dispatch(request());

    brandService.noticeDisableComment(userSno, commentIdx).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: brandConstants.NOTICE_DISABLE_COMMENT_REQUEST };
  }
  function success(result) {
    return { type: brandConstants.NOTICE_DISABLE_COMMENT_SUCCESS, result };
  }
  function failure(error) {
    return { type: brandConstants.NOTICE_DISABLE_COMMENT_FAILURE, error };
  }
}

function noticeConfirm(userSno, noticeSno) {
  return dispatch => {
    dispatch(request());

    brandService.noticeConfirm(userSno, noticeSno).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: brandConstants.NOTICE_CONFIRM_REQUEST };
  }
  function success(result) {
    return { type: brandConstants.NOTICE_CONFIRM_SUCCESS, result };
  }
  function failure(error) {
    return { type: brandConstants.NOTICE_CONFIRM_FAILURE, error };
  }
}

function noticeReadList(userSno, brandCode) {
  return dispatch => {
    dispatch(request());

    brandService.noticeReadList(userSno, brandCode).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: brandConstants.NOTICE_READ_LIST_REQUEST };
  }
  function success(result) {
    return { type: brandConstants.NOTICE_READ_LIST_SUCCESS, result };
  }
  function failure(error) {
    return { type: brandConstants.NOTICE_READ_LIST_FAILURE, error };
  }
}

function noticeDisable(userSno, noticeSno) {
  return dispatch => {
    dispatch(request());

    brandService.noticeDisable(userSno, noticeSno).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: brandConstants.NOTICE_DISABLE_REQUEST };
  }
  function success(result) {
    return { type: brandConstants.NOTICE_DISABLE_SUCCESS, result };
  }
  function failure(error) {
    return { type: brandConstants.NOTICE_DISABLE_FAILURE, error };
  }
}

function allBrandList() {
  return dispatch => {
    dispatch(request());

    brandService.allBrandList().then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: brandConstants.ALL_BRAND_LIST_REQUEST };
  }
  function success(result) {
    return { type: brandConstants.ALL_BRAND_LIST_SUCCESS, result };
  }
  function failure(error) {
    return { type: brandConstants.ALL_BRAND_LIST_FAILURE, error };
  }
}

// 매장 관리
function getFranchiseOfficeStatus(brandCode) {
  // 가맹점 리스트 출력
  return dispatch => {
    dispatch(request());

    brandService.getFranchiseOfficeStatus(brandCode).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: brandConstants.GET_FRANCHISE_OFFICE_STATUS_REQUEST };
  }
  function success(result) {
    return { type: brandConstants.GET_FRANCHISE_OFFICE_STATUS_SUCCESS, result };
  }
  function failure(error) {
    return { type: brandConstants.GET_FRANCHISE_OFFICE_STATUS_FAILURE, error };
  }
}

function getFranchiseOfficeStatusVer2(brandCode, user_sno) {
  // 가맹점 리스트 출력
  return dispatch => {
    dispatch(request());

    brandService.getFranchiseOfficeStatusVer2(brandCode, user_sno).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: brandConstants.GET_FRANCHISE_OFFICE_STATUS_REQUEST_VER2 };
  }
  function success(result) {
    return { type: brandConstants.GET_FRANCHISE_OFFICE_STATUS_SUCCESS_VER2, result };
  }
  function failure(error) {
    return { type: brandConstants.GET_FRANCHISE_OFFICE_STATUS_FAILURE_VER2, error };
  }
}

function getOrderAppOfficeStatus(brandCode) {
  // 가맹점 리스트 출력
  return dispatch => {
    dispatch(request());

    brandService.getOrderAppOfficeStatus(brandCode).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: brandConstants.GET_ORDERAPP_OFFICE_STATUS_REQUEST };
  }
  function success(result) {
    return { type: brandConstants.GET_ORDERAPP_OFFICE_STATUS_SUCCESS, result };
  }
  function failure(error) {
    return { type: brandConstants.GET_ORDERAPP_OFFICE_STATUS_FAILURE, error };
  }
}

function checkSignUpLinkClick(brandCode, phoneNumber) {
  // 회원가입 페이지url에서 brandCode, phoneNumber있으면 서버API 호출 보내기
  return dispatch => {
    dispatch(request());

    brandService.checkSignUpLinkClick(brandCode, phoneNumber).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: brandConstants.CHECK_SIGN_UP_LINK_CLICK_REQUEST };
  }
  function success(result) {
    return { type: brandConstants.CHECK_SIGN_UP_LINK_CLICK_SUCCESS, result };
  }
  function failure(error) {
    return { type: brandConstants.CHECK_SIGN_UP_LINK_CLICK_FAILURE, error };
  }
}

function insertMultiOfficeManagerInfo(companyCode, brandCode, list, isUpload = false) {
  // 엑셀 일괄등록 리스트 : 엑셀 리스트
  return dispatch => {
    dispatch(request());

    brandService.insertMultiOfficeManagerInfo(companyCode, brandCode, list, isUpload).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: brandConstants.INSERT_MULTI_OFFICE_MANAGER_INFO_REQUEST };
  }
  function success(result) {
    return { type: brandConstants.INSERT_MULTI_OFFICE_MANAGER_INFO_SUCCESS, result };
  }
  function failure(error) {
    return { type: brandConstants.INSERT_MULTI_OFFICE_MANAGER_INFO_FAILURE, error };
  }
}

// function insertSingleOfficeManagerInfo(companyCode, brandCode, userName, officeName, phone, email) {
//   // 단일 추가
//   return dispatch => {
//     dispatch(request());

//     brandService
//       .insertSingleOfficeManagerInfo(companyCode, brandCode, userName, officeName, phone, email)
//       .then(
//         result => {
//           dispatch(success(result));
//         },
//         error => {
//           dispatch(failure(error));
//         },
//       );
//   };

//   function request() {
//     return { type: brandConstants.INSERT_SINGLE_OFFICE_MANAGER_INFO_REQUEST };
//   }
//   function success(result) {
//     return { type: brandConstants.INSERT_SINGLE_OFFICE_MANAGER_INFO_SUCCESS, result };
//   }
//   function failure(error) {
//     return { type: brandConstants.INSERT_SINGLE_OFFICE_MANAGER_INFO_FAILURE, error };
//   }
// }

// function editOfficeManagerInfo(idx, officeName, userName, phone, email) {
//   // 매장 정보 수정
//   return dispatch => {
//     dispatch(request());

//     brandService.editOfficeManagerInfo(idx, officeName, userName, phone, email).then(
//       result => {
//         dispatch(success(result));
//       },
//       error => {
//         dispatch(failure(error));
//       },
//     );
//   };

//   function request() {
//     return { type: brandConstants.EDIT_OFFICE_MANAGER_INFO_REQUEST };
//   }
//   function success(result) {
//     return { type: brandConstants.EDIT_OFFICE_MANAGER_INFO_SUCCESS, result };
//   }
//   function failure(error) {
//     return { type: brandConstants.EDIT_OFFICE_MANAGER_INFO_FAILURE, error };
//   }
// }

// function manageFranchiseOfficeStatus(
//   brandCode,
//   dispatcherUserSno,
//   officeName,
//   userName,
//   phoneNumber,
//   email,
//   status,
// ) {
//   return dispatch => {
//     dispatch(request());
//     brandService
//       .manageFranchiseOfficeStatus(
//         brandCode,
//         dispatcherUserSno,
//         officeName,
//         userName,
//         phoneNumber,
//         email,
//         status,
//       )
//       .then(
//         result => {
//           dispatch(success(result));
//         },
//         error => {
//           dispatch(failure(error));
//         },
//       );
//   };

//   function request() {
//     return { type: brandConstants.MANAGE_FRANCHISE_OFFICE_STATUS_REQUEST };
//   }
//   function success(result) {
//     return { type: brandConstants.MANAGE_FRANCHISE_OFFICE_STATUS_SUCCESS, result };
//   }
//   function failure(error) {
//     return { type: brandConstants.MANAGE_FRANCHISE_OFFICE_STATUS_FAILURE, error };
//   }
// }

// function checkOfficeClosing(brandCode, phoneNumber) {
//   return dispatch => {
//     dispatch(request());

//     brandService.checkOfficeClosing(brandCode, phoneNumber).then(
//       result => {
//         dispatch(success(result));
//       },
//       error => {
//         dispatch(failure(error));
//       },
//     );
//   };

//   function request() {
//     return { type: brandConstants.CHECK_OFFICE_CLOSING_REQUEST };
//   }
//   function success(result) {
//     return { type: brandConstants.CHECK_OFFICE_CLOSING_SUCCESS, result };
//   }
//   function failure(error) {
//     return { type: brandConstants.CHECK_OFFICE_CLOSING_FAILURE, error };
//   }
// }
