import classnames from 'classnames';
import React from 'react';
import styled from 'styled-components';

const ProgressDot = props => {
  const { progressState = 1, style, itemLength = 4, textObject = [], progressDotColor, progressRedDot = false } = props;

  const getProgressStyle = (value, currState) => {
    if (value < currState) {
      return 'is-done';
    } else if (value === currState) {
      if (progressRedDot === true) {
        return 'is-doing-red';
      } else {
        return 'is-doing';
      }
    } else {
      return '';
    }
  };

  return (
    <div style={{ ...style }}>
      <ResProgress
        className="res-progress"
        data-state={progressState}
        state={progressRedDot ? progressState - 1 : progressState}
        itemLength={itemLength}
        dotColor={progressDotColor}
        redDot={progressRedDot}
      >
        {itemLength &&
          [...Array(itemLength)].map((_n, index) => {
            return (
              <li className={classnames('res-progress__item', getProgressStyle(index + 1, progressState))}>
                <span className="number">{index + 1 === progressState ? progressState : ''}</span>
                <span className="text">{textObject && textObject[index] && textObject[index].text}</span>
              </li>
            );
          })}
      </ResProgress>
    </div>
  );
};

export default ProgressDot;

const ResProgress = styled.ol`
  &:before {
    width: calc(${props => ((props.state - 1) / (props.itemLength - 1)) * 95}%) !important;
    background-color: ${props => (props.dotColor ? props.dotColor : '#ebecee')};
  }
  &:after {
    position: absolute;
    right: 0;
    left: 0;
    height: 2px;
    top: 10px;
    background: #ebecee;
    z-index: 0;
    content: '';
  }
  li {
    text-align: center;
    position: relative;
    &.is-done {
      .number {
        width: 12px;
        height: 12px;
        border: 2px solid ${props => (props.dotColor ? props.dotColor : '#ebecee')};
        background: ${props => (props.redDot ? '#ebecee' : '#fff')};
        overflow: hidden;
      }
    }
    &.is-doing {
      .number {
        width: 24px;
        height: 24px;
        background: ${props => (props.dotColor ? props.dotColor : '#ebecee')};
        font-size: 15px;
      }
      .text {
        font-size: 14px;
      }
    }
    &.is-doing-red {
      .number {
        width: 24px;
        height: 24px;
        background: rgba(230, 66, 73, 1);
        font-size: 15px;
      }
      .text {
        font-size: 14px;
      }
    }
    .number {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      background: #ebecee;
      color: white;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      z-index: 1;
      font-size: 0;

      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 24px;
    }
    .text {
      position: absolute;
      display: block;
      bottom: -26px;
      font-size: 0;
      left: 50%;
      margin: auto;
      white-space: nowrap;
      color: #404252;
      transform: translateX(-50%);
    }
  }
`;
