import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import classNames from 'classnames';
import './progressBar.scss';

/**
 *
 * @param { 보여줄 칸 갯수: itemLength = number 타입으로 줄것! /
 * 총 걸리는 시간: timer /
 * classname : classname 추가 시 사용 /
 * 쿠팡이츠 : 13.224초, 요기요 : 7.018초, 배민 10.059초,
 * option으로 delay(다음 칸이 보여지는 시간)를 줄수있지만 잘사용안함 } props
 * @returns
 */
const AniProgressBar = props => {
  const { delay = 0.2, itemLength = 10, timer, classname } = props;

  return (
    <div className={classNames('', classname)}>
      <div className="ani-progressbar">
        {itemLength &&
          [...Array(itemLength)].map((n, index) => {
            return <Itemlist className="ani-progress-item" itemLength={itemLength} timer={timer}></Itemlist>;
          })}
      </div>
    </div>
  );
};

const Itemlist = styled.div`
  ${props => {
    const loopRender = (i, t, len) => {
      return `
        &:nth-child(${i + 1}) {
          animation-delay: ${((i + 1) * t) / len}s;
        }
      `;
    };

    let str = '';
    for (let index = 0; index < props.itemLength; index++) {
      str += loopRender(index, props.timer, props.itemLength);
    }
    return str;
  }}
  width: 100%;
  height: 100%;
  background: #e6e9eb;
  animation: ${keyframes`
    0% {background: #686CFF;}
    100% {background: #686CFF;}`} ${props => props.timer}s forwards;
  border-radius: 4px;
`;

export default AniProgressBar;
