// react
import React, { useRef } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { modalInfoState } from '../../recoil/common/atoms';

// component
import ModalPortal from './ModalPortal';

// util
import { isMobile } from '../../constants';

// style
import styled, { css } from 'styled-components';

const Modal = () => {
  const {
    isOpen,
    isKeyboardVisible,
    title = '제목',
    content = null,
    cancel = null,
    confirm = null,
    backdrop,
    bodyClassName = '',
  } = useRecoilValue(modalInfoState);
  const resetModalInfo = useResetRecoilState(modalInfoState);

  const modalRef = useRef(null);

  const handleEvent = event => {
    resetModalInfo();

    if (event) {
      event();
    }
  };

  return (
    <ModalPortal>
      {isOpen && (
        <Container isKeyboardVisible={isKeyboardVisible} onClick={backdrop}>
          <Body ref={modalRef} onClick={e => e.stopPropagation()} bodyClassName={bodyClassName}>
            {/* 제목 영역 */}
            <Header>{title}</Header>

            {/* 내용 영역 */}
            {content !== null && <Content>{content}</Content>}

            {/* 버튼 영역 */}
            <Footer>
              {/* 취소 버튼 */}
              {cancel !== null && (
                <Button theme={'white'} style={cancel.cancelClass} onClick={() => handleEvent(cancel.handleEvent)}>
                  {cancel.text ? cancel.text : '취소'}
                </Button>
              )}

              {/* 확인 버튼 */}
              {confirm !== null && (
                <Button style={confirm.confirmClass} onClick={() => handleEvent(confirm.handleEvent)}>{confirm.text ? confirm.text : '확인'}</Button>
              )}

              {/* 버튼 prop 없을 시 */}
              {cancel === null && confirm === null && <Button onClick={resetModalInfo}>확인</Button>}
            </Footer>
          </Body>
        </Container>
      )}
    </ModalPortal>
  );
};

export default Modal;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 26px;
  padding: 24px 16px;
  border-radius: 24px;
  background-color: #ffffff;
  ${props => props.bodyClassName && props.bodyClassName}
`;

const Header = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #28282e;
`;

const Button = styled.button`
  width: 91px;
  height: 47px;

  background-color: #686cff;
  border: 1px solid #686cff;
  border-radius: 24px;

  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  color: #fefefe;

  ${props =>
    props.theme === 'white' &&
    css`
      background-color: #ffffff;
      border: 1px solid ${isMobile() ? '#c7c7cb' : '#686CFF'};
      color: ${isMobile() ? '#8e9096' : '#686CFF'};
    `}
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  z-index: 9999;

  ${props =>
    props.isKeyboardVisible &&
    css`
      align-items: flex-end;

      ${Body} {
        margin-bottom: 80px;
      }
    `}

  ${isMobile()
    ? css`
        background-color: #f4f6f8cc;

        ${Body} {
          width: 90%;
          min-height: 200px;
          box-shadow: 0 0 16px 0 #00000014;
        }
      `
    : css`
        background-color: #26262666;

        ${Body} {
          width: 380px;
          min-height: 170px;
          border-radius: 12px;
          gap: 16px;
        }

        ${Header} {
          font-size: 16px;
          font-weight: 600;
        }

        ${Button} {
          width: 63px;
          height: 33px;

          font-weight: 600;
          font-size: 13.5px;
        }
      `}
`;

const Content = styled.div`
  padding: 0 24px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
