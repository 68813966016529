import { officeConstants } from '../constants';
import { officeService } from '../services';
import { paymentPlanProcess } from '../helpers/PaymentPlanProcess';

export const officeActions = {
  managedOfficeList,
  allOfficeList,
  areaList,
  saleOfficeHeaderData,
  saleDateListData,
  saleOfficeListData,
  saleWeekDayListData,
  oneOfficeSaleWeekDayListData,
  deliveryHeaderData,
  deliveryOfficeListData,
  deliveryDateListData,
  deliveryWeekDayListData,
  oneOfficeDeliveryWeekDayListData,
  deliveryOfficeAllListData,
  deliveryOfficeMarker,
  deliveryMarkerDetail,
  mainSaleData,
  mainDeliveryData,
  saleTotalData,
  deliveryTotalData,
  posSignalList,
  deliveryOrderAppDateListData,
  inRangeDeliveryDateListData,
  deliveryCustomerRating,
  editDeliveryCustomerTypeBit,
  addDeliveryCustomerComment,
  deleteDeliveryCustomerComment,
  oneCustomerDeliveryDetail,
  alertList,
  orderDetailList,
  readAlert,
  setSkipAccountLink,

  // v2
  dailySales,
  timeSales,
  weekdaySales,
  officeSales,
  officeSalesByDaily,
  orderAppSales,
  specificDateSales,
  specificTimeSales,
  setOrderAppAccount,
  getOrderAppOfficeList,
  getOrderAppOfficeOwner,
  getStoreList,
  mappingStore,
  dongSalesData,
  resetDongSalesData,
  totalDeliveryDongSales,
  resetTotalDeliveryDongSales,
  allBrandSales,
  monthlySales,
  quarterlySales,
  yearlySales,
  specificMonthSales,
  specificQuarterSales,
  specificYearSales,
  setMultiOrderAppAccount,
  getOfficeStatus,

  // pos scraping
  getPosOwnerAccount,
  linkPosAccount,
};

function managedOfficeList(userSno, brandCode, serviceType) {
  return dispatch => {
    dispatch(request());

    officeService.managedOfficeList(userSno, brandCode, serviceType).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: officeConstants.MANAGED_OFFICE_LIST_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.MANAGED_OFFICE_LIST_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.MANAGED_OFFICE_LIST_FAILURE, error };
  }
}

function allOfficeList(companyCode) {
  return dispatch => {
    dispatch(request());

    officeService.allOfficeList(companyCode).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: officeConstants.ALL_OFFICE_LIST_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.ALL_OFFICE_LIST_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.ALL_OFFICE_LIST_FAILURE, error };
  }
}

function areaList(brandCode, userSno) {
  return dispatch => {
    dispatch(request());

    officeService.areaList(brandCode, userSno).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: officeConstants.AREA_LIST_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.AREA_LIST_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.AREA_LIST_FAILURE, error };
  }
}

function saleOfficeHeaderData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoQuery,
) {
  return dispatch => {
    dispatch(request());

    officeService
      .saleOfficeHeaderData(
        userSno,
        brandCode,
        serviceType,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        officeSnoQuery,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: officeConstants.SALE_OFFICE_HEADER_DATA_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.SALE_OFFICE_HEADER_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.SALE_OFFICE_HEADER_DATA_FAILURE, error };
  }
}

function saleDateListData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoQuery,
  officeName,
) {
  return dispatch => {
    dispatch(request());

    officeService
      .saleDateListData(
        userSno,
        brandCode,
        serviceType,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        officeSnoQuery,
        officeName,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: officeConstants.SALE_DATE_LIST_DATA_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.SALE_DATE_LIST_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.SALE_DATE_LIST_DATA_FAILURE, error };
  }
}

function saleOfficeListData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  limitStart,
  limitCount,
  sortKey,
  officeSnoQuery,
) {
  return dispatch => {
    dispatch(request());
    limitStart === 0 && dispatch(reset());

    officeService
      .saleOfficeListData(
        userSno,
        brandCode,
        serviceType,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        limitStart,
        limitCount,
        sortKey,
        officeSnoQuery,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function reset() {
    return { type: officeConstants.SALE_OFFICE_LIST_DATA_RESET };
  }
  function request() {
    return { type: officeConstants.SALE_OFFICE_LIST_DATA_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.SALE_OFFICE_LIST_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.SALE_OFFICE_LIST_DATA_FAILURE, error };
  }
}

function saleWeekDayListData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoQuery,
) {
  return dispatch => {
    dispatch(request());

    officeService
      .saleWeekDayListData(
        userSno,
        brandCode,
        serviceType,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        officeSnoQuery,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: officeConstants.SALE_WEEK_DAY_LIST_DATA_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.SALE_WEEK_DAY_LIST_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.SALE_WEEK_DAY_LIST_DATA_FAILURE, error };
  }
}

function oneOfficeSaleWeekDayListData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoQuery,
) {
  return dispatch => {
    dispatch(request());

    officeService
      .oneOfficeSaleWeekDayListData(
        userSno,
        brandCode,
        serviceType,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        officeSnoQuery,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: officeConstants.ONE_OFFICE_SALE_WEEK_DAY_LIST_DATA_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.ONE_OFFICE_SALE_WEEK_DAY_LIST_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.ONE_OFFICE_SALE_WEEK_DAY_LIST_DATA_FAILURE, error };
  }
}

function deliveryHeaderData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoQuery,
  menuSnoQuery,
  dlType,
) {
  return dispatch => {
    dispatch(request());

    officeService
      .deliveryHeaderData(
        userSno,
        brandCode,
        serviceType,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        officeSnoQuery,
        menuSnoQuery,
        dlType,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: officeConstants.DELIVERY_HEADER_DATA_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.DELIVERY_HEADER_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.DELIVERY_HEADER_DATA_FAILURE, error };
  }
}

function deliveryOfficeListData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  limitStart,
  limitCount,
  sortKey,
  officeSnoQuery,
  menuSnoQuery,
  dlType,
  menuName,
  noReCustomer,
) {
  return dispatch => {
    dispatch(request());
    limitStart === 0 && dispatch(reset());

    officeService
      .deliveryOfficeListData(
        userSno,
        brandCode,
        serviceType,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        limitStart,
        limitCount,
        sortKey,
        officeSnoQuery,
        menuSnoQuery,
        dlType,
        menuName,
        noReCustomer,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function reset() {
    return { type: officeConstants.DELIVERY_OFFICE_LIST_DATA_RESET };
  }
  function request() {
    return { type: officeConstants.DELIVERY_OFFICE_LIST_DATA_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.DELIVERY_OFFICE_LIST_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.DELIVERY_OFFICE_LIST_DATA_FAILURE, error };
  }
}

function deliveryDateListData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoQuery,
  dlType,
  officeName,
  menuSnoQuery,
  menuName,
  noReCustomer,
) {
  return dispatch => {
    dispatch(request());

    officeService
      .deliveryDateListData(
        userSno,
        brandCode,
        serviceType,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        officeSnoQuery,
        dlType,
        officeName,
        menuSnoQuery,
        menuName,
        noReCustomer,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: officeConstants.DELIVERY_DATE_LIST_DATA_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.DELIVERY_DATE_LIST_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.DELIVERY_DATE_LIST_DATA_FAILURE, error };
  }
}

function deliveryWeekDayListData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoQuery,
  menuSnoQuery,
  dlType,
) {
  return dispatch => {
    dispatch(request());

    officeService
      .deliveryWeekDayListData(
        userSno,
        brandCode,
        serviceType,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        officeSnoQuery,
        menuSnoQuery,
        dlType,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: officeConstants.DELIVERY_WEEK_DAY_LIST_DATA_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.DELIVERY_WEEK_DAY_LIST_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.DELIVERY_WEEK_DAY_LIST_DATA_FAILURE, error };
  }
}

function oneOfficeDeliveryWeekDayListData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoQuery,
  menuSnoQuery,
  dlType,
) {
  return dispatch => {
    dispatch(request());

    officeService
      .deliveryWeekDayListData(
        userSno,
        brandCode,
        serviceType,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        officeSnoQuery,
        menuSnoQuery,
        dlType,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: officeConstants.ONE_OFFICE_DELIVERY_WEEK_DAY_LIST_DATA_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.ONE_OFFICE_DELIVERY_WEEK_DAY_LIST_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.ONE_OFFICE_DELIVERY_WEEK_DAY_LIST_DATA_FAILURE, error };
  }
}
function deliveryOfficeAllListData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  menuSnoQuery,
  officeSnoQuery,
  dlType,
) {
  return dispatch => {
    if (paymentPlanProcess()) {
      dispatch(reset());
    } else {
      dispatch(request());

      officeService
        .deliveryOfficeAllListData(
          userSno,
          brandCode,
          serviceType,
          startDate,
          endDate,
          menuSnoQuery,
          officeSnoQuery,
          dlType,
        )
        .then(
          result => {
            dispatch(success(result));
          },
          error => {
            dispatch(failure(error));
          },
        );
    }
  };

  function reset() {
    return { type: officeConstants.DELIVERY_OFFICE_ALL_LIST_DATA_RESET };
  }
  function request() {
    return { type: officeConstants.DELIVERY_OFFICE_ALL_LIST_DATA_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.DELIVERY_OFFICE_ALL_LIST_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.DELIVERY_OFFICE_ALL_LIST_DATA_FAILURE, error };
  }
}

function deliveryOfficeMarker(brandCode, userSno, officeSno, startDate, endDate, menuSnoQuery, dlType) {
  return dispatch => {
    if (paymentPlanProcess()) {
      dispatch(reset());
    } else {
      dispatch(request());

      officeService.deliveryOfficeMarker(brandCode, userSno, officeSno, startDate, endDate, menuSnoQuery, dlType).then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
    }
  };

  function reset() {
    return { type: officeConstants.DELIVERY_OFFICE_MARKER_RESET };
  }
  function request() {
    return { type: officeConstants.DELIVERY_OFFICE_MARKER_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.DELIVERY_OFFICE_MARKER_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.DELIVERY_OFFICE_MARKER_FAILURE, error };
  }
}

function deliveryMarkerDetail(deliverySno, officeSno, startDate, endDate, menuSnoQuery, dlType) {
  return dispatch => {
    dispatch(request());

    officeService.deliveryMarkerDetail(deliverySno, officeSno, startDate, endDate, menuSnoQuery, dlType).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: officeConstants.DELIVERY_MARKER_DETAIL_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.DELIVERY_MARKER_DETAIL_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.DELIVERY_MARKER_DETAIL_FAILURE, error };
  }
}

function inRangeDeliveryDateListData(
  userSno,
  brandCode,
  startDate,
  endDate,
  selectedOfficeSno,
  dlType,
  range,
  isFullRange,
  orderViewType,
  rangeCount,
  menuSnoQuery,
  trigger, // API 호출 체크용 - 지울 것
  needTotalChannels,
) {
  return dispatch => {
    if (paymentPlanProcess()) {
      dispatch(reset());
    } else {
      dispatch(request());

      officeService
        .inRangeDeliveryDateListData(
          userSno,
          brandCode,
          startDate,
          endDate,
          selectedOfficeSno,
          dlType,
          range,
          isFullRange,
          orderViewType,
          rangeCount,
          menuSnoQuery,
          trigger,
          needTotalChannels,
        )
        .then(
          result => {
            dispatch(success(result));
          },
          error => {
            dispatch(failure(error));
          },
        );
    }
  };

  function reset() {
    return { type: officeConstants.IN_RANGE_DELIVERY_DATE_LIST_DATA_RESET };
  }
  function request() {
    return { type: officeConstants.IN_RANGE_DELIVERY_DATE_LIST_DATA_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.IN_RANGE_DELIVERY_DATE_LIST_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.IN_RANGE_DELIVERY_DATE_LIST_DATA_FAILURE, error };
  }
}

function mainSaleData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  contentsBoolList,
) {
  return dispatch => {
    dispatch(request());

    officeService
      .mainSaleData(userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate, contentsBoolList)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: officeConstants.MAIN_SALE_DATA_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.MAIN_SALE_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.MAIN_SALE_DATA_FAILURE, error };
  }
}

function mainDeliveryData(userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate, dlType) {
  return dispatch => {
    dispatch(request());

    officeService
      .mainDeliveryData(userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate, dlType)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: officeConstants.MAIN_DELIVERY_DATA_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.MAIN_DELIVERY_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.MAIN_DELIVERY_DATA_FAILURE, error };
  }
}

function saleTotalData(userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate) {
  return dispatch => {
    dispatch(request());

    officeService.saleTotalData(userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: officeConstants.SALE_TOTAL_DATA_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.SALE_TOTAL_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.SALE_TOTAL_DATA_FAILURE, error };
  }
}

function deliveryTotalData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  dlType,
  menuSno,
  menuName,
) {
  return dispatch => {
    dispatch(request());

    officeService
      .deliveryTotalData(
        userSno,
        brandCode,
        serviceType,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        dlType,
        menuSno,
        menuName,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: officeConstants.DELIVERY_TOTAL_DATA_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.DELIVERY_TOTAL_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.DELIVERY_TOTAL_DATA_FAILURE, error };
  }
}

function posSignalList(userSno, brandCode, serviceType) {
  return dispatch => {
    dispatch(request());

    officeService.posSignalList(userSno, brandCode, serviceType).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: officeConstants.POS_SIGNAL_LIST_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.POS_SIGNAL_LIST_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.POS_SIGNAL_LIST_FAILURE, error };
  }
}

function deliveryOrderAppDateListData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoQuery,
) {
  return dispatch => {
    dispatch(request());

    officeService
      .deliveryOrderAppDateListData(
        userSno,
        brandCode,
        serviceType,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        officeSnoQuery,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: officeConstants.DELIVERY_ORDER_APP_DATE_LIST_DATA_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.DELIVERY_ORDER_APP_DATE_LIST_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.DELIVERY_ORDER_APP_DATE_LIST_DATA_FAILURE, error };
  }
}

function deliveryCustomerRating(userSno, customerSno) {
  return dispatch => {
    dispatch(request());

    officeService.deliveryCustomerRating(userSno, customerSno).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: officeConstants.DELIVERY_CUSTOMER_RATING_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.DELIVERY_CUSTOMER_RATING_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.EDIT_DELIVERY_CUSTOMER_TYPE_BIT_REQUEST, error };
  }
}

function editDeliveryCustomerTypeBit(userSno, customerSno, typeBit) {
  return dispatch => {
    dispatch(request());

    officeService.editDeliveryCustomerTypeBit(userSno, customerSno, typeBit).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: officeConstants.EDIT_DELIVERY_CUSTOMER_TYPE_BIT_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.EDIT_DELIVERY_CUSTOMER_TYPE_BIT_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.EDIT_DELIVERY_CUSTOMER_TYPE_BIT_FAILURE, error };
  }
}
function addDeliveryCustomerComment(userSno, customerSno, comment) {
  return dispatch => {
    dispatch(request());

    officeService.addDeliveryCustomerComment(userSno, customerSno, comment).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: officeConstants.ADD_DELIVERY_CUSTOMER_COMMENT_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.ADD_DELIVERY_CUSTOMER_COMMENT_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.ADD_DELIVERY_CUSTOMER_COMMENT_FAILURE, error };
  }
}

function deleteDeliveryCustomerComment(userSno, customerSno, commentSno) {
  return dispatch => {
    dispatch(request());

    officeService.deleteDeliveryCustomerComment(userSno, customerSno, commentSno).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: officeConstants.DELETE_DELIVERY_CUSTOMER_COMMENT_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.DELETE_DELIVERY_CUSTOMER_COMMENT_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.DELETE_DELIVERY_CUSTOMER_COMMENT_FAILURE, error };
  }
}

function oneCustomerDeliveryDetail(officeSno, customerSno, startDate, endDate, orderApp) {
  return dispatch => {
    dispatch(request());

    officeService.oneCustomerDeliveryDetail(officeSno, customerSno, startDate, endDate, orderApp).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: officeConstants.ONE_CUSTOMER_DELIVERY_DETAIL_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.ONE_CUSTOMER_DELIVERY_DETAIL_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.ONE_CUSTOMER_DELIVERY_DETAIL_FAILURE, error };
  }
}

function dailySales(
  getType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoList,
  siteType,
  deliveryTipType,
  payTypeList,
  orderChannelList,
  standardSaleTime,
) {
  return dispatch => {
    dispatch(request());

    officeService
      .dailySales(
        getType,
        userSno,
        brandCode,
        orderApp,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        officeSnoList,
        siteType,
        deliveryTipType,
        payTypeList,
        orderChannelList,
        standardSaleTime,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: officeConstants.DAILY_SALES_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.DAILY_SALES_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.DAILY_SALES_FAILURE, error };
  }
}

function timeSales(
  getType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoList,
  siteType,
  deliveryTipType,
  payTypeList,
  orderChannelList,
) {
  return dispatch => {
    dispatch(request());

    officeService
      .timeSales(
        getType,
        userSno,
        brandCode,
        orderApp,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        officeSnoList,
        siteType,
        deliveryTipType,
        payTypeList,
        orderChannelList,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: officeConstants.TIME_SALES_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.TIME_SALES_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.TIME_SALES_FAILURE, error };
  }
}

function weekdaySales(
  getType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoList,
  siteType,
  deliveryTipType,
  payTypeList,
  orderChannelList,
  standardSaleTime,
) {
  return dispatch => {
    dispatch(request());

    officeService
      .weekdaySales(
        getType,
        userSno,
        brandCode,
        orderApp,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        officeSnoList,
        siteType,
        deliveryTipType,
        payTypeList,
        orderChannelList,
        standardSaleTime,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: officeConstants.WEEKDAY_SALES_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.WEEKDAY_SALES_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.WEEKDAY_SALES_FAILURE, error };
  }
}

function officeSales(
  getType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoList,
  siteType,
  deliveryTipType,
  payTypeList,
  orderChannelList,
  standardSaleTime,
) {
  return dispatch => {
    dispatch(request());

    officeService
      .officeSales(
        getType,
        userSno,
        brandCode,
        orderApp,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        officeSnoList,
        siteType,
        deliveryTipType,
        payTypeList,
        orderChannelList,
        standardSaleTime,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: officeConstants.OFFICE_SALES_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.OFFICE_SALES_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.OFFICE_SALES_FAILURE, error };
  }
}

function officeSalesByDaily(
  userSno,
  brandCode,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoList,
  deliveryTipType,
  payTypeList,
  orderChannelList,
  standardSaleTime,
) {
  return dispatch => {
    dispatch(request());

    officeService
      .officeSalesByDaily(
        userSno,
        brandCode,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        officeSnoList,
        deliveryTipType,
        payTypeList,
        orderChannelList,
        standardSaleTime,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: officeConstants.OFFICE_SALES_DAILY_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.OFFICE_SALES_DAILY_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.OFFICE_SALES_DAILY_FAILURE, error };
  }
}

function orderAppSales(
  userSno,
  brandCode,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoList,
  deliveryTipType,
  getType,
  standardSaleTime,
) {
  return dispatch => {
    dispatch(request());

    officeService
      .orderAppSales(
        userSno,
        brandCode,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        officeSnoList,
        deliveryTipType,
        getType,
        standardSaleTime,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: officeConstants.ORDER_APP_SALES_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.ORDER_APP_SALES_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.ORDER_APP_SALES_FAILURE, error };
  }
}

function specificDateSales(userSno, brandCode, date, officeSnoList, deliveryTipType, standardSaleTime) {
  return dispatch => {
    dispatch(request());

    officeService.specificDateSales(userSno, brandCode, date, officeSnoList, deliveryTipType, standardSaleTime).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: officeConstants.SPECIFIC_DATE_SALES_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.SPECIFIC_DATE_SALES_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.SPECIFIC_DATE_SALES_REQUEST, error };
  }
}

function specificTimeSales(
  userSno,
  brandCode,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  time,
  officeSnoList,
  deliveryTipType,
) {
  return dispatch => {
    dispatch(request());

    officeService
      .specificTimeSales(
        userSno,
        brandCode,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        time,
        officeSnoList,
        deliveryTipType,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: officeConstants.SPECIFIC_TIME_SALES_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.SPECIFIC_TIME_SALES_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.SPECIFIC_TIME_SALES_FAILURE, error };
  }
}

function setOrderAppAccount(officeStatusIdx, id, pwd, orderApp, isOneId, isUsed, orderAppAccountIdx, isInit) {
  return dispatch => {
    dispatch(request());

    officeService
      .setOrderAppAccount(officeStatusIdx, id, pwd, orderApp, isOneId, isUsed, orderAppAccountIdx, isInit)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: officeConstants.SET_ORDER_APP_ACCOUNT_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.SET_ORDER_APP_ACCOUNT_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.SET_ORDER_APP_ACCOUNT_FAILURE, error };
  }
}

function getOrderAppOfficeList(brandCode, checkIdxList) {
  return dispatch => {
    dispatch(request());

    officeService.getOrderAppOfficeList(brandCode, checkIdxList).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: officeConstants.GET_ORDER_APP_OFFICE_LIST_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.GET_ORDER_APP_OFFICE_LIST_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.GET_ORDER_APP_OFFICE_LIST_FAILURE, error };
  }
}

function getOrderAppOfficeOwner(brandCode, userSno) {
  return dispatch => {
    dispatch(request());

    officeService.getOrderAppOfficeOwner(brandCode, userSno).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: officeConstants.GET_ORDER_APP_OFFICE_OWNER_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.GET_ORDER_APP_OFFICE_OWNER_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.GET_ORDER_APP_OFFICE_OWNER_FAILURE, error };
  }
}

function getStoreList(idx) {
  return dispatch => {
    dispatch(request());

    officeService.getStoreList(idx).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: officeConstants.GET_STORE_LIST_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.GET_STORE_LIST_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.GET_STORE_LIST_FAILURE, error };
  }
}

function mappingStore(idx, storeIdxList) {
  return dispatch => {
    dispatch(request());

    officeService.mappingStore(idx, storeIdxList).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: officeConstants.MAPPING_STORE_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.MAPPING_STORE_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.MAPPING_STORE_FAILURE, error };
  }
}

function dongSalesData(userSno, brandCode, officeSno, startDate, endDate, diffStartDate, diffEndDate) {
  return dispatch => {
    dispatch(request());

    officeService.dongSalesData(userSno, brandCode, officeSno, startDate, endDate, diffStartDate, diffEndDate).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: officeConstants.DONG_SALES_DATA_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.DONG_SALES_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.DONG_SALES_DATA_FAILURE, error };
  }
}

function resetDongSalesData() {
  return dispatch => {
    dispatch(resetItem());

    function resetItem() {
      return { type: officeConstants.DONG_SALES_DATA_RESET };
    }
  };
}

function totalDeliveryDongSales(
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoList,
) {
  return dispatch => {
    dispatch(request());

    officeService
      .totalDeliveryDongSales(
        userSno,
        brandCode,
        orderApp,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        officeSnoList,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: officeConstants.TOTAL_DONG_SALES_DATA_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.TOTAL_DONG_SALES_DATA_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.TOTAL_DONG_SALES_DATA_FAILURE, error };
  }
}

function resetTotalDeliveryDongSales() {
  return dispatch => {
    dispatch(resetItem());

    function resetItem() {
      return { type: officeConstants.TOTAL_DONG_SALES_DATA_RESET };
    }
  };
}

function allBrandSales(userSno, startDate, endDate) {
  return dispatch => {
    dispatch(request());

    officeService.allBrandSales(userSno, startDate, endDate).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: officeConstants.ALL_BRAND_SALES_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.ALL_BRAND_SALES_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.ALL_BRAND_SALES_FAILURE, error };
  }
}

function monthlySales(
  getType,
  siteType,
  deliveryTipType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoList,
  payTypeList,
  orderChannelList,
  standardSaleTime,
) {
  return dispatch => {
    dispatch(request());

    officeService
      .monthlySales(
        getType,
        siteType,
        deliveryTipType,
        userSno,
        brandCode,
        orderApp,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        officeSnoList,
        payTypeList,
        orderChannelList,
        standardSaleTime,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: officeConstants.MONTHLY_SALES_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.MONTHLY_SALES_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.MONTHLY_SALES_FAILURE, error };
  }
}

function quarterlySales(
  getType,
  siteType,
  deliveryTipType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoList,
  payTypeList,
  orderChannelList,
  standardSaleTime,
) {
  return dispatch => {
    dispatch(request());

    officeService
      .quarterlySales(
        getType,
        siteType,
        deliveryTipType,
        userSno,
        brandCode,
        orderApp,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        officeSnoList,
        payTypeList,
        orderChannelList,
        standardSaleTime,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: officeConstants.QUARTERLY_SALES_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.QUARTERLY_SALES_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.QUARTERLY_SALES_FAILURE, error };
  }
}

function yearlySales(
  getType,
  siteType,
  deliveryTipType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoList,
  payTypeList,
  orderChannelList,
  standardSaleTime,
) {
  return dispatch => {
    dispatch(request());

    officeService
      .yearlySales(
        getType,
        siteType,
        deliveryTipType,
        userSno,
        brandCode,
        orderApp,
        startDate,
        endDate,
        diffStartDate,
        diffEndDate,
        officeSnoList,
        payTypeList,
        orderChannelList,
        standardSaleTime,
      )
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: officeConstants.YEARLY_SALES_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.YEARLY_SALES_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.YEARLY_SALES_FAILURE, error };
  }
}

function specificMonthSales(brandCode, deliveryTipType, officeSnoList, userSno, dateYm, standardSaleTime) {
  return dispatch => {
    dispatch(request());

    officeService.specificMonthSales(brandCode, deliveryTipType, officeSnoList, userSno, dateYm, standardSaleTime).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: officeConstants.SPECIFIC_MONTH_SALES_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.SPECIFIC_MONTH_SALES_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.SPECIFIC_MONTH_SALES_FAILURE, error };
  }
}

function specificQuarterSales(brandCode, deliveryTipType, officeSnoList, userSno, dateYq, standardSaleTime) {
  return dispatch => {
    dispatch(request());

    officeService
      .specificQuarterSales(brandCode, deliveryTipType, officeSnoList, userSno, dateYq, standardSaleTime)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: officeConstants.SPECIFIC_QUARTER_SALES_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.SPECIFIC_QUARTER_SALES_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.SPECIFIC_QUARTER_SALES_FAILURE, error };
  }
}

function specificYearSales(brandCode, deliveryTipType, officeSnoList, userSno, year, standardSaleTime) {
  return dispatch => {
    dispatch(request());

    officeService.specificYearSales(brandCode, deliveryTipType, officeSnoList, userSno, year, standardSaleTime).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: officeConstants.SPECIFIC_YEAR_SALES_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.SPECIFIC_YEAR_SALES_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.SPECIFIC_YEAR_SALES_FAILURE, error };
  }
}

function setMultiOrderAppAccount(excelData) {
  return dispatch => {
    dispatch(request());

    officeService.setMultiOrderAppAccount(excelData).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: officeConstants.SET_MULTI_ORDER_APP_ACCOUNT_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.SET_MULTI_ORDER_APP_ACCOUNT_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.SET_MULTI_ORDER_APP_ACCOUNT_FAILURE, error };
  }
}

function getOfficeStatus(brandCode, userSno, officeSno) {
  return dispatch => {
    dispatch(request());
    officeService.getOfficeStatus(brandCode, userSno, officeSno).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: officeConstants.GET_OFFICE_STATUS_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.GET_OFFICE_STATUS_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.GET_OFFICE_STATUS_FAILURE, error };
  }
}

function alertList(brandCode, userSno, alertIdx) {
  return dispatch => {
    dispatch(request());

    officeService.alertList(brandCode, userSno, alertIdx).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: officeConstants.ALERT_LIST_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.ALERT_LIST_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.ALERT_LIST_FAILURE, error };
  }
}

function orderDetailList(userSno, brandCode, orderIdx) {
  return dispatch => {
    if (paymentPlanProcess()) {
      dispatch(reset());
    } else {
      dispatch(request());

      officeService.orderDetailList(userSno, brandCode, orderIdx).then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
    }
  };

  function reset() {
    return { type: officeConstants.ORDER_DETAIL_LIST_REQUEST };
  }
  function request() {
    return { type: officeConstants.ORDER_DETAIL_LIST_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.ORDER_DETAIL_LIST_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.ORDER_DETAIL_LIST_FAILURE, error };
  }
}

function readAlert(userSno, brandCode, notiType, lastReadIdx) {
  return dispatch => {
    dispatch(request());

    officeService.readAlert(userSno, brandCode, notiType, lastReadIdx).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: officeConstants.READ_ALERT_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.READ_ALERT_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.READ_ALERT_FAILURE, error };
  }
}

function setSkipAccountLink(userSno, flag, isUsedSite) {
  return dispatch => {
    dispatch(request());

    officeService.setSkipAccountLink(userSno, flag, isUsedSite).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: officeConstants.SET_SKIP_ACCOUNT_LINK_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.SET_SKIP_ACCOUNT_LINK_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.SET_SKIP_ACCOUNT_LINK_FAILURE, error };
  }
}

function getPosOwnerAccount(brandCode, userSno) {
  return dispatch => {
    dispatch(request());

    officeService.getPosOwnerAccount(brandCode, userSno).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: officeConstants.GET_POS_OWNER_ACCOUNT_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.GET_POS_OWNER_ACCOUNT_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.GET_POS_OWNER_ACCOUNT_FAILURE, error };
  }
}

function linkPosAccount({
  brandCode,
  userSno,
  credentialIdx,
  platform,
  etcPlatform,
  userId,
  userPw,
  officeStatusIdx,
  disabled,
}) {
  return dispatch => {
    dispatch(request());

    officeService
      .linkPosAccount({
        brandCode,
        userSno,
        credentialIdx,
        platform,
        etcPlatform,
        userId,
        userPw,
        officeStatusIdx,
        disabled,
      })
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));
        },
      );
  };

  function request() {
    return { type: officeConstants.LINK_POS_ACCOUNT_REQUEST };
  }
  function success(result) {
    return { type: officeConstants.LINK_POS_ACCOUNT_SUCCESS, result };
  }
  function failure(error) {
    return { type: officeConstants.LINK_POS_ACCOUNT_FAILURE, error };
  }
}
