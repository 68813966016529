import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { rules, validation } from '../../../helpers';
import inputCheck from '../../../assets/utils/images/input-check.svg';
import inputFalse from '../../../assets/utils/images/input-false.svg';
import filterarrow from '../../../assets/utils/images/filterarrow16.svg';
import inputNone from '../../../assets/utils/images/input-check-none.svg';
import pollish from '../../../assets/utils/images/pollish.svg';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';

// NOTE: 틀린 값일 경우 is-false 클래스 추가
// NOTE: disabled 상태일때 is-disabled 클래스 추가
const ATInput = props => {
  const {
    type = 'text',
    name = 'example',
    inputmode = 'text',
    disabled = false,
    dataText = '',
    isRequire = true,
    defaultValue = '',
    getValue = () => {},
    toMatchValue = null, // 비밀번호 재확인
    matchErrorMsg = '동일하게 입력해주세요.',
    errorMsg = '',
    infoMsg = '',
    placeholder = '',
    maxLength = 50,
    className = '',
    required = '',
    readonly = '',
    autoFocus = false,
    handleKeyPress = () => {},
    onChangeInput = () => {},
    onFocusInput = () => {},
    onBlurInput = () => {},
    useNumberFormat = false,
    formatter = () => {},
    onValid = () => {},
    onClick = () => {},
    style = {},
    id = '',
    forceShowErrorMsg = '',
    astro = true,
    emailValid,
    refs,
    hasErrorStyle = false,
    caretColor = '',
    useClear = false, // input에 입력한값 초기화 하는 버튼 사용여부
  } = props;

  const inputRef = useRef();

  const [value, setValue] = useState('');
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    onValid(value && getErrorMsg() === null ? true : false);
  }, [value]);

  function onChange(e) {
    setValue(e.target.value);

    getValue({
      name: e.target.name,
      value: e.target.value,
    });

    onChangeInput && onChangeInput(e);
  }

  const handleFocus = bool => {
    // ClearBtn의 클릭 이벤트의 실행 순서를 보장하기 위해 setTimeout 적용
    setTimeout(() => {
      setIsFocus(bool);
    }, 10);
  };

  function getErrorMsg() {
    let _err = null;

    switch (true) {
      case value.length === 0 && isRequire === true:
        _err = errorMsg;
        break;
      case toMatchValue !== null && toMatchValue !== value:
        _err = matchErrorMsg;
        break;
      case value.length > 0 && type === 'email' && validation(value, rules['email']) === false:
        _err = errorMsg;
        break;
      case value.length > 0 && type === 'password' && validation(value, rules['password']) === false:
        _err = errorMsg;
        break;
      case value.length > 0 && name === 'phoneNumber' && validation(value, rules['phoneNum']) === false:
        _err = errorMsg;
        break;
      default:
        break;
    }

    return _err;
  }

  function printErrorMessage() {
    if (forceShowErrorMsg) {
      return <div className="input-valid-msg">{forceShowErrorMsg}</div>;
    }

    let _err = getErrorMsg();

    if (!_err || !showErrorMsg) {
      if (infoMsg) {
        if (hasErrorStyle) {
          return <div className="input-valid-msg">{infoMsg}</div>;
        } else {
          return <div className="input-info-msg">{infoMsg}</div>;
        }
      } else {
        return null;
      }
    }

    return <div className="input-valid-msg">{_err}</div>;
  }

  let isFalse = infoMsg === '' ? printErrorMessage() : getErrorMsg() && showErrorMsg && printErrorMessage();

  return (
    <Wrapper
      className={classNames(className, {
        'is-readonly': readonly,
        'is-false': isFalse,
      })}
      type={type}
      emailValid={emailValid}
      ref={inputRef}
      onClick={onClick}
      astro={astro}
      caretColor={caretColor}
      style={{ ...style }}
    >
      {useNumberFormat ? (
        <NumberFormat
          disabled={disabled}
          type={type}
          inputmode={inputmode}
          name={name}
          value={disabled ? defaultValue : value}
          onChange={onChange}
          onFocus={() => {
            onFocusInput();
            setShowErrorMsg(false);
            handleFocus(true);
          }}
          onBlur={() => {
            getValue({
              name: name,
              value: value,
            });
            onBlurInput(value);
            setShowErrorMsg(true);
            handleFocus(false);
          }}
          onKeyPress={e => {
            e.key === 'Enter' && handleKeyPress(value);
          }}
          autoComplete="off"
          autoFocus={autoFocus}
          placeholder={placeholder}
          maxLength={maxLength}
          required={required}
          format={val => {
            return formatter(val);
          }}
        />
      ) : (
        <input
          disabled={disabled}
          type={type}
          name={name}
          value={disabled ? defaultValue : value}
          onChange={onChange}
          onFocus={() => {
            onFocusInput();
            setShowErrorMsg(false);
            handleFocus(true);
          }}
          id={id}
          onBlur={() => {
            getValue({
              name: name,
              value: value,
            });
            onBlurInput(value);
            setShowErrorMsg(true);
            handleFocus(false);
          }}
          onKeyPress={e => {
            e.key === 'Enter' && handleKeyPress(value);
          }}
          autoComplete="off"
          autoFocus={autoFocus}
          ref={refs}
          placeholder={placeholder}
          maxLength={maxLength}
          required={required}
          readOnly={readonly}
          style={{ paddingRight: className.includes('inputwrap') === true ? '32px' : '' }}
        />
      )}

      {useClear &&
        isFocus === true &&
        value.length > 0 &&
        !isFalse &&
        !['is-false', 'is-disabled'].some(v => className.includes(v)) && <ClearBtn onClick={() => setValue('')} />}

      {!value && disabled === false && dataText !== '' && <span className="input-place">{dataText}</span>}
      {printErrorMessage()}
    </Wrapper>
  );
};

const Astro = css`
  margin-left: 4px;
  color: #d55151;
  font-size: 14px;
  font-weight: 600;
  transform: translateY(2px);
  content: '*';
`;

const Wrapper = styled.div`
  &.inputwrap {
    input[type='text'],
    input[type='email'],
    input[type='password'] {
      width: 100%;
      border-radius: 12px;
      height: 40px;
      line-height: 1;
      background: transparent;
      border: 1px solid #d0d5d9;
      padding: 0 0 0 16px;
      font-size: 14px;
      box-sizing: border-box;
      caret-color: ${props => (props.caretColor ? props.caretColor : '')};

      &:valid {
        ~ .input-place {
          display: none;
        }
      }
      &:focus {
        border: 2px solid #686cff;
        box-shadow: none;
        ~ .input-place {
          color: #dbdcdd;
        }
        &::placeholder {
          color: #dbdcdd;
        }
      }
      &:first-child {
        margin-top: 0;
      }
      &::placeholder {
        color: #4b5056;
        font-size: 14px;
        font-family: 'pre';
      }
    }
    input[type='file'] {
      position: absolute;
      opacity: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
  input {
    position: relative;
    width: 100%;
    height: 38px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    padding: 6px 10px;
    font-size: 13px;
    color: #4b5056;
    font-weight: 500;
    z-index: 1;
    &:focus {
      box-shadow: 0 0 0 0.2rem rgb(77 94 255 / 25%);
    }
    &::placeholder {
      color: #ced4d9;
    }
    &.click-trigger-type {
      pointer-events: none;
    }
    &:disabled {
      opacity: 0.7;
      background-color: #fafbfb;
      color: #c6c6c6;
    }
    &.auth-num-input {
      width: 88px;
      margin-top: 4px;
    }
    &.file-uploader {
      opacity: 0;
      position: absolute;
      width: 100% !important;
      height: 100%;
      z-index: 1;
      cursor: pointer;
    }
  }
  &.is-readonly {
    input {
      pointer-events: none;
    }
    &:after {
      position: absolute;
      right: 16px;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 16px;
      height: 16px;
      background: url(${inputCheck}) no-repeat !important;
      content: '';
    }
  }
  &.select {
    &:after {
      position: absolute;
      background: url(${filterarrow}) no-repeat !important;
      background-size: contain;
      top: 0;
      bottom: 0px;
      right: 12px;
      margin: auto;
      width: 24px;
      height: 24px;
      content: '';
    }
  }
  &.inputwrap {
    position: relative;
    &.is-no-chk {
      input {
        &:focus {
          outline: none;
        }
        &:valid {
          background: transparent !important;
        }
      }
    }
    input {
      &:focus {
        outline: none;
      }
      &:valid:not(:focus) {
        background: ${props => {
          if (props.type === 'email') {
            if (!props.emailValid) {
              return `none !important`;
            } else {
              return `transparent url(${inputCheck}) no-repeat !important;`;
            }
          } else {
            return `transparent url(${inputCheck}) no-repeat !important;`;
          }
        }};
        background-position: calc(100% - 12px) 9px !important;
      }
    }
    + .input-valid-msg {
      color: #e4a3a5;
      font-size: 13.5px;
      text-align: center;
      margin: -2px 0 5px 0px;
    }
    .input-valid-msg {
      font-size: 14px;
      margin-left: 6px;
      color: #eaa8a8;
    }
  }
  &.inputwrap.no-valid {
    position: relative;
    input {
      &:valid {
        background: none !important;
      }
    }
  }

  &.is-false {
    input {
      background: url(${inputFalse}) no-repeat !important;
      background-position: calc(100% - 12px) 10px !important;
      &:valid {
        background: url(${inputFalse}) no-repeat !important;
        background-position: calc(100% - 12px) 10px !important;
      }
    }
  }
  &.is-disabled {
    background: #f4f5f6;
    input {
      opacity: 1;
      color: #d2d4d6;
      pointer-events: none;
      background: #f4f5f6 url(${inputNone}) no-repeat !important;
      background-position: calc(100% - 12px) 10px !important;
    }
  }
  &.is-select {
    input[type='text'] {
      border: 2px solid #686cff !important;
    }
  }
  &.none-click {
    &.is-disabled {
      input {
        background: #fff url(${inputNone}) no-repeat !important;
        background-position: calc(100% - 12px) 10px !important;
      }
    }
    input {
      pointer-events: none;
    }
  }
  &.select {
    &.is-disabled {
      pointer-events: none;
      &:after {
        position: absolute;
        background: url(${filterarrow}) no-repeat !important;
        background-size: contain;
        top: 0;
        bottom: 0px;
        right: 12px;
        margin: auto;
        width: 24px;
        height: 24px;
        z-index: 1;
        opacity: 0.4;
        content: '';
      }
      input {
        color: #ccced2;
        background: #f4f6f8 !important;
        background-position: calc(100% - 12px) 10px !important;
      }
      .input-place {
        z-index: 1;
        color: #ccced2;
      }
    }
    input {
      pointer-events: none;
    }
  }
  .input-valid-msg {
    margin-top: 9px;
    margin-left: 12px;
    font-size: 14px;
    color: #d54b4b;
  }
  .input-info-msg {
    margin-top: 9px;
    margin-left: 6px;
    font-size: 14px;
    color: #a5a7aa;
  }
  .input-place {
    position: absolute;
    font-family: 'pre';
    display: flex;
    align-items: center;
    height: 40px;
    left: 19px;
    top: 0;
    font-size: 14px;
    color: #4b5056;
    z-index: 0;
    &:after {
      ${props => props.astro === true && Astro}
    }
  }
`;

const ClearBtn = styled.span`
  position: absolute;
  right: 14px;
  top: 14px;
  width: 12px;
  height: 12px;
  z-index: 1;
  background: url(${pollish}) no-repeat;
`;

export default ATInput;
