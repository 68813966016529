import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Input } from 'reactstrap';
import styled, { css } from 'styled-components';
import { authActions, commonActions } from '../actions';
import { arrayContainsArray, deepClone, getMyUserData } from '../helpers';
import { Advertisment } from '../pages/PolicyPages/component/Advertisment';
import { Privacy } from '../pages/PolicyPages/component/Privacy';
import { PrivacyUse } from '../pages/PolicyPages/component/PrivacyUse';
import { TTPFranchiseStore } from '../pages/PolicyPages/component/TTPFranchiseStore';
import { authService } from '../services';

const CheckPolicy = () => {
  const dispatch = useDispatch();

  const [tab, setTab] = useState(2);

  const [checkList, setCheckList] = useState([]);

  let userData = getMyUserData(false);

  const POLICY_OBJ = {
    2: {
      name: '개인정보 처리방침',
      value: 2,
      content: <Privacy />,
      isRequired: true,
      checkText: '개인정보 처리방침을 확인했습니다.',
    },
    3: {
      name: '개인정보 수집•이용',
      value: 3,
      content: <PrivacyUse />,
      isRequired: true,
      checkText: '개인정보 수집 및 이용에 동의합니다.',
    },
    4: {
      name: '개인정보 제3자 제공',
      value: 4,
      content: <TTPFranchiseStore brandName={getMyUserData(false) ? getMyUserData(false)['brand_name'] : ''} />,
      isRequired: true,
      checkText: '개인정보의 제3자 제공에 동의합니다.',
    },
    5: {
      name: '마케팅 정보 수신',
      value: 5,
      content: <Advertisment />,
      isRequired: false,
      checkText: '마케팅 정보 수신에 동의합니다.',
    },
  };

  let POLICY_LIST =
    userData && userData.auth_type === 'D1' && userData.company_type === '2'
      ? [POLICY_OBJ[2], POLICY_OBJ[3], POLICY_OBJ[4], POLICY_OBJ[5]]
      : [POLICY_OBJ[2], POLICY_OBJ[3], POLICY_OBJ[5]];

  const checkConfirm = () => {
    let result = arrayContainsArray(
      POLICY_LIST.filter(v => v['isRequired']).map(v => v['value']),
      checkList,
    );

    return result;
  };

  const selectCheckbox = value => {
    var tmp = deepClone(checkList);
    var index = tmp.indexOf(value);
    if (index !== -1) {
      tmp.splice(index, 1);
    } else {
      tmp.push(value);
    }

    setCheckList(tmp);
  };

  async function submit() {
    let userSno = getMyUserData()['user_sno'];
    await authService.agreePolicy(userSno, checkList);

    dispatch(authActions.checkLoginState(userSno));
    dispatch(commonActions.alertMessage(null));
  }

  let findPolicy = POLICY_LIST.find(v => v['value'] === tab);

  return (
    <Wrapper>
      <div style={{ padding: '24px 0 20px', textAlign: 'center' }}>
        <strong>
          개인정보 수집•이용동의 및 마케팅 정보 수신 동의 안내
          <br />
        </strong>
        <div style={{ fontSize: '11px', lineHeight: '1.7', opacity: '0.7' }}>
          개인정보 수집•이용동의 및 마케팅 정보 수신 동의를 확인 후 이용 부탁 드립니다.
          <br />
        </div>
      </div>
      <MainTabWrapper>
        {POLICY_LIST.map(policy => {
          return (
            <MainTabItem active={tab === policy['value']} onClick={() => setTab(policy['value'])}>
              <div>{policy['name']}</div>
            </MainTabItem>
          );
        })}
      </MainTabWrapper>
      <ContentsWrapper className="custom-scrollbar vertical">{findPolicy && findPolicy['content']}</ContentsWrapper>
      <div className="divider" />
      <div style={{ margin: '0 0 16px 24px' }}>
        {POLICY_LIST.map(policy => {
          return (
            <div className="form-check">
              <Input
                name="check"
                id={'check' + policy['value']}
                type="checkbox"
                className="form-check-input"
                checked={checkList.includes(policy['value'])}
                onChange={() => selectCheckbox(policy['value'])}
                style={{ marginTop: '10px' }}
              />
              <label htmlFor={'check' + policy['value']} className="form-check-label" style={{ fontSize: '14px' }}>
                {(policy['isRequired'] ? '(필수)' : '(선택)') + ' ' + policy['checkText']}
              </label>
            </div>
          );
        })}
      </div>
      <FooterButton className={checkConfirm() === false && 'is-disabled'} onClick={submit}>
        확인
      </FooterButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-height: 80vh;
`;

const MainTabWrapper = styled.div`
  display: flex;
  text-align: center;
  padding: 0 8px;
`;

const ContentsWrapper = styled.div`
  max-height: 30vh;
  overflow: auto;
`;

const MainTabItem = styled.div`
  display: table;
  height: 46px;
  line-height: 1.2;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 13px;
  text-align: center;
  letter-spacing: -0.045em;
  color: #232323;
  opacity: 0.8;
  border: 1px #000000 solid;
  cursor: pointer;
  width: 50%;
  padding: 0 4px;

  ${props => {
    return props.active
      ? css`
          border-bottom: 1px #ffffff solid;
        `
      : css`
          border-left: 1px #e5e5e5 solid;
          border-right: 1px #e5e5e5 solid;
          border-top: 1px #e5e5e5 solid;
        `;
  }}

  div {
    display: table-cell;
    vertical-align: middle;
  }
`;

const FooterButton = styled.button`
  width: 100%;
  word-break: keep-all;
  background: #686cff;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  color: white;
  height: 48px;
  border: none;

  &.is-disabled {
    background: #dee2e4;
    pointer-events: none;
  }
`;

export default CheckPolicy;
