import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { getMyUserData } from '../../helpers';
import ConfirmCodeModal from './ConfirmCodeModal';
import SetNewPasswordModal from './SetNewPasswordModal';
import ConfirmMobile from './ConfirmMobile';

class EditPasswordModal extends React.Component {
  constructor(props) {
    super(props)

    const { user_phone: mobile } = getMyUserData();

    this.state = {
      modal: props.modal,
      mobile: mobile,
      step: 1,
    }

    this.handleRegistStep = this.handleRegistStep.bind(this);
  }

  handleRegistStep(step, param = {}) {
    if (param['mobile'] !== undefined) {
      this.setState({ mobile: param['mobile'] });
    }

    this.setState({ step: step });
  }

  render() {

    let { step, mobile, modal } = this.state;

    return (
      <Fragment>
        {
          step === 1 && <ConfirmMobile step={step} modal={modal} onRegistStep={this.handleRegistStep} mobile={mobile} toggle={this.props.toggle} />
        }
        {
          step === 2 && <ConfirmCodeModal step={step} modal={modal} onRegistStep={this.handleRegistStep} mobile={mobile} toggle={this.props.toggle} />
        }
        {
          step === 3 && <SetNewPasswordModal step={step} modal={modal} onRegistStep={this.handleRegistStep} mobile={mobile} toggle={this.props.toggle} />
        }
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { validPhoneNum } = state.validPhoneNum;

  return {
    validPhoneNum,
  };
}

export default connect(mapStateToProps)(EditPasswordModal);