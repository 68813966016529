import { globalCount, reportConstants } from "../constants";

export function excelDownloadBySearch(state = {}, action) {
  switch (action.type) {
    case reportConstants.EXCEL_DOWNLOAD_BY_SEARCH_REQUEST:
      return {
        loading: true
      };
    case reportConstants.EXCEL_DOWNLOAD_BY_SEARCH_SUCCESS:
      return {
        excelDownloadBySearch: globalCount(),
      };
    case reportConstants.EXCEL_DOWNLOAD_BY_SEARCH_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function excelSaleDateView(state = {}, action) {
  switch (action.type) {
    case reportConstants.EXCEL_SALE_DATE_VIEW_REQUEST:
      return {
        loading: true
      };
    case reportConstants.EXCEL_SALE_DATE_VIEW_SUCCESS:
      return {
        excelSaleDateView: action.result['result']
      };
    case reportConstants.EXCEL_SALE_DATE_VIEW_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function excelSaleMonthView(state = {}, action) {
  switch (action.type) {
    case reportConstants.EXCEL_SALE_MONTH_VIEW_REQUEST:
      return {
        loading: true
      };
    case reportConstants.EXCEL_SALE_MONTH_VIEW_SUCCESS:
      return {
        excelSaleMonthView: action.result['result']
      };
    case reportConstants.EXCEL_SALE_MONTH_VIEW_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function excelSaleTimeView(state = {}, action) {
  switch (action.type) {
    case reportConstants.EXCEL_SALE_TIME_VIEW_REQUEST:
      return {
        loading: true
      };
    case reportConstants.EXCEL_SALE_TIME_VIEW_SUCCESS:
      return {
        excelSaleTimeView: action.result['result']
      };
    case reportConstants.EXCEL_SALE_TIME_VIEW_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function excelSaleDetailView(state = {}, action) {
  switch (action.type) {
    case reportConstants.EXCEL_SALE_DETAIL_VIEW_REQUEST:
      return {
        loading: true
      };
    case reportConstants.EXCEL_SALE_DETAIL_VIEW_SUCCESS:
      return {
        excelSaleDetailView: action.result['result'],
        totalSumArr: action.result['total_sum_arr'],
        cardSumArr: action.result['card_sum_arr'],
        cashSumArr: action.result['cash_sum_arr'],
        cardCancelSumArr: action.result['card_cancel_sum_arr'],
        cashCancelSumArr: action.result['cash_cancel_sum_arr'],
        discountSumArr: action.result['discount_sum_arr'],
      };
    case reportConstants.EXCEL_SALE_DETAIL_VIEW_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function excelMenuDayView(state = {}, action) {
  switch (action.type) {
    case reportConstants.EXCEL_MENU_DAY_VIEW_REQUEST:
      return {
        loading: true
      };
    case reportConstants.EXCEL_MENU_DAY_VIEW_SUCCESS:
      return {
        excelMenuDayView: action.result['result']
      };
    case reportConstants.EXCEL_MENU_DAY_VIEW_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function excelMenuMonthView(state = {}, action) {
  switch (action.type) {
    case reportConstants.EXCEL_MENU_MONTH_VIEW_REQUEST:
      return {
        loading: true
      };
    case reportConstants.EXCEL_MENU_MONTH_VIEW_SUCCESS:
      return {
        excelMenuMonthView: action.result['result']
      };
    case reportConstants.EXCEL_MENU_MONTH_VIEW_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function excelMenuTimeView(state = {}, action) {
  switch (action.type) {
    case reportConstants.EXCEL_MENU_TIME_VIEW_REQUEST:
      return {
        loading: true
      };
    case reportConstants.EXCEL_MENU_TIME_VIEW_SUCCESS:
      return {
        excelMenuTimeView: action.result['result']
      };
    case reportConstants.EXCEL_MENU_TIME_VIEW_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function excelMaterialDateView(state = {}, action) {
  switch (action.type) {
    case reportConstants.EXCEL_MATERIAL_DATE_VIEW_REQUEST:
      return {
        loading: true
      };
    case reportConstants.EXCEL_MATERIAL_DATE_VIEW_SUCCESS:
      return {
        excelMaterialDateView: action.result['result']
      };
    case reportConstants.EXCEL_MATERIAL_DATE_VIEW_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function excelMaterialMonthView(state = {}, action) {
  switch (action.type) {
    case reportConstants.EXCEL_MATERIAL_MONTH_VIEW_REQUEST:
      return {
        loading: true
      };
    case reportConstants.EXCEL_MATERIAL_MONTH_VIEW_SUCCESS:
      return {
        excelMaterialMonthView: action.result['result']
      };
    case reportConstants.EXCEL_MATERIAL_MONTH_VIEW_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function excelMaterialTimeView(state = {}, action) {
  switch (action.type) {
    case reportConstants.EXCEL_MATERIAL_TIME_VIEW_REQUEST:
      return {
        loading: true
      };
    case reportConstants.EXCEL_MATERIAL_TIME_VIEW_SUCCESS:
      return {
        excelMaterialTimeView: action.result['result']
      };
    case reportConstants.EXCEL_MATERIAL_TIME_VIEW_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function excelDeliveryDayView(state = {}, action) {
  switch (action.type) {
    case reportConstants.EXCEL_DELIVERY_DAY_VIEW_REQUEST:
      return {
        loading: true
      };
    case reportConstants.EXCEL_DELIVERY_DAY_VIEW_SUCCESS:
      return {
        excelDeliveryDayView: action.result['result']
      };
    case reportConstants.EXCEL_DELIVERY_DAY_VIEW_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function excelDeliveryMonthView(state = {}, action) {
  switch (action.type) {
    case reportConstants.EXCEL_DELIVERY_MONTH_VIEW_REQUEST:
      return {
        loading: true
      };
    case reportConstants.EXCEL_DELIVERY_MONTH_VIEW_SUCCESS:
      return {
        excelDeliveryMonthView: action.result['result']
      };
    case reportConstants.EXCEL_DELIVERY_MONTH_VIEW_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function monthlyReportDeliveryOrderAppData(state = {}, action) {
  switch (action.type) {
    case reportConstants.MONTHLY_REPORT_DELIVERY_ORDER_APP_REQUEST:
      return {
        loading: true
      };
    case reportConstants.MONTHLY_REPORT_DELIVERY_ORDER_APP_SUCCESS:
      return {
        monthlyReportDeliveryOrderAppData: action.result['result']
      };
    case reportConstants.MONTHLY_REPORT_DELIVERY_ORDER_APP_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function excelDeliveryLocationView(state = {}, action) {
  switch (action.type) {
    case reportConstants.EXCEL_DELIVERY_LOCATION_VIEW_REQUEST:
      return {
        loading: true
      };
    case reportConstants.EXCEL_DELIVERY_LOCATION_VIEW_SUCCESS:
      return {
        excelDeliveryLocationView: action.result['result'],
        totalPayCount: action.result['total_pay_count'],
        totalMenuSale: action.result['total_menu_sale'],
        totalMenuSaleCount: action.result['total_menu_sale_count'],
      };
    case reportConstants.EXCEL_DELIVERY_LOCATION_VIEW_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function reportList(state = {}, action) {
  switch (action.type) {
    case reportConstants.REPORT_LIST_REQUEST:
      return {
        loading: true
      };
    case reportConstants.REPORT_LIST_SUCCESS:
      return {
        reportList: action.result['result']
      };
    case reportConstants.REPORT_LIST_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function reportBookmark(state = {}, action) {
  switch (action.type) {
    case reportConstants.REPORT_BOOKMARK_REQUEST:
      return {
        loading: true
      };
    case reportConstants.REPORT_BOOKMARK_SUCCESS:
      return {
        reportBookmark: {
          result: action.result['result'],
          globalCount: globalCount(),
        }
      };
    case reportConstants.REPORT_BOOKMARK_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function reportRemove(state = {}, action) {
  switch (action.type) {
    case reportConstants.REPORT_REMOVE_REQUEST:
      return {
        loading: true
      };
    case reportConstants.REPORT_REMOVE_SUCCESS:
      return {
        reportRemove: {
          result: action.result['result'],
          globalCount: globalCount(),
        }
      };
    case reportConstants.REPORT_REMOVE_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function monthlyReportHeaderData(state = {}, action) {
  switch (action.type) {
    case reportConstants.MONTHLY_REPORT_HEADER_REQUEST:
      return {
        loading: true
      };
    case reportConstants.MONTHLY_REPORT_HEADER_SUCCESS:
      return {
        monthlyReportHeaderData: action.result['result'],
      };
    case reportConstants.MONTHLY_REPORT_HEADER_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function monthlyReportSalesData(state = {}, action) {
  switch (action.type) {
    case reportConstants.MONTHLY_REPORT_SALES_REQUEST:
      return {
        loading: true
      };
    case reportConstants.MONTHLY_REPORT_SALES_SUCCESS:
      return {
        monthlyReportSalesData: action.result['result'],
      };
    case reportConstants.MONTHLY_REPORT_SALES_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function monthlyReportMenuSalesData(state = {}, action) {
  switch (action.type) {
    case reportConstants.MONTHLY_REPORT_MENU_SALES_REQUEST:
      return {
        loading: true
      };
    case reportConstants.MONTHLY_REPORT_MENU_SALES_SUCCESS:
      return {
        monthlyReportMenuSalesData: action.result['result'],
      };
    case reportConstants.MONTHLY_REPORT_MENU_SALES_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function monthlyReportDeliveryData(state = {}, action) {
  switch (action.type) {
    case reportConstants.MONTHLY_REPORT_DELIVERY_REQUEST:
      return {
        loading: true
      };
    case reportConstants.MONTHLY_REPORT_DELIVERY_SUCCESS:
      return {
        monthlyReportDeliveryData: action.result['result'],
      };
    case reportConstants.MONTHLY_REPORT_DELIVERY_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function monthlyReportPDFUpload(state = {}, action) {
  switch (action.type) {
    case reportConstants.MONTHLY_REPORT_UPLOAD_REQUEST:
      return {
        loading: true
      };
    case reportConstants.MONTHLY_REPORT_UPLOAD_SUCCESS:
      return {
        monthlyReportPDFUpload: action.result['result'],
      };
    case reportConstants.MONTHLY_REPORT_UPLOAD_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}