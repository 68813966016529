import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import { isMobile } from '../../constants';
import ChannelChat from '../../helpers/ChannelChat';
import AccountcertifyModal from './AccountcertifyModal';

export const POSConfirm = (modal, callback = () => {}, linkCnt = 1) => {
  return new Promise(function(resolve) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <POSConfirmComponent
            modal={modal}
            onConfirm={v => {
              resolve(v);
              onClose();
              callback(v);
            }}
            linkCnt={linkCnt}
          />
        );
      },
      willUnmount: () => {},
      afterClose: () => {},
    });
  });
};

const modalStyle = {
  all: {
    position: 'absolute',
    left: '50%',
    top: '45%',
    minWidth: isMobile() ? '300px' : '350px',
    maxWidth: 350,
    width: isMobile() ? '90%' : 'auto',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    fontSize: '1.15em',
    lineHeight: '1.8em',
    margin: '0',
    borderRadius: '24px',
  },
  body: {
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: isMobile() ? '48px 20px 40px 20px' : '48px 32px 40px 32px',
  },
  pText: {
    padding: '1rem',
    wordBreak: 'keep-all',
    marginBottom: '0',
  },
  title: {
    fontSize: '2em',
  },
  closeBtn: {
    position: 'absolute',
    top: '16px',
    right: '16px',
    backgroundColor: '#fff',
    border: 'none',
    color: 'black',
    fontSize: '1.5em',
    fontWeight: '100',
    padding: '0.2em',
    opacity: '0.5',
    zIndex: '1',
  },
  closeText: {
    fontSize: '0.95em',
  },
  footerButton: {
    padding: '14px 31px 14px 32px',
    borderRadius: '24px',
    backgroundColor: '#686CFF',
    height: isMobile() ? 47 : 'auto',
    lineHeight: 'normal',
    marginBottom: 10,
  },
  closeButton: {
    backgroundColor: 'white',
    color: '#8E9096',
  },
  titleText: {
    fontSize: '20px',
    lineHeight: '24px',
    paddingBottom: '0.6rem',
    color: '#000',
  },
  smallText: {
    opacity: '0.5',
    color: '#28282E',
    fontSize: '13.5px',
    lineHeight: '19.58px',
  },
  helpText: {
    textDecoration: 'underline',
  },
};

const POSConfirmComponent = props => {
  const { modal, onConfirm = () => {}, linkCnt } = props;

  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {}, []);

  const handleConfirm = confirm => {
    onConfirm && onConfirm(confirm);

    setIsOpen(false);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <>
      {modal === 1 && (
        <Modal color="primary" isOpen={true} style={modalStyle.all} className="posModal">
          <ModalBody style={modalStyle.body}>
            <div className="pt-2 pb-4 lh-1-6">
              <div className="fw-7" style={modalStyle.titleText}>
                총 <span className="text-primary">{linkCnt}</span>
                개의 POS 연동
              </div>

              <small className="fw-5" style={modalStyle.smallText}>
                앳트래커 현장 매출 데이터는 <br />
                총 5개의 POS 매출 연동을 제공합니다. <br />
                해당 POS만 연동할까요? <br />
                - <br />
                (‘아니요’ 선택시 추가 POS 계정을 등록합니다.)
              </small>
            </div>

            <Button
              className="fsize-1-2 ml-0-5 mr-0-5"
              style={{ ...modalStyle.footerButton, ...modalStyle.closeButton }}
              color="light"
              onClick={() => handleConfirm(false)}
            >
              아니요
            </Button>
            <Button
              className="fsize-1-2 ml-0-5 mr-0-5"
              style={modalStyle.footerButton}
              color="primary"
              onClick={() => handleConfirm(true)}
            >
              네, 연동할게요.
            </Button>
          </ModalBody>
        </Modal>
      )}

      {modal === 2 && (
        <Modal color="primary" isOpen={true} style={modalStyle.all} className="posModal">
          <ModalBody style={modalStyle.body}>
            <div className="pt-2 pb-4 lh-1-6">
              <div className="fw-7" style={modalStyle.titleText}>
                POS 연동에 실패했습니다.
              </div>
              <small className="fw-5" style={modalStyle.smallText}>
                계정정보를 다시 확인 후 입력해 주시고,
                <br />
                정상인 경우 잠시후 재 입력 해주시기 바랍니다.
              </small>
            </div>

            <Button
              className="fsize-1-2 ml-0-5 mr-0-5"
              style={{ ...modalStyle.footerButton, ...modalStyle.closeButton }}
              color="light"
              onClick={() => handleConfirm()}
            >
              재시도
            </Button>
            <Button
              className="fsize-1-2 ml-0-5 mr-0-5"
              style={modalStyle.footerButton}
              color="primary"
              onClick={() => handleConfirm(false)}
            >
              나중에 다시
            </Button>

            <div className="pt-3">
              <a
                onClick={() => ChannelChat.openChat()}
                className="fw-5"
                style={{ ...modalStyle.smallText, ...modalStyle.helpText }}
              >
                계속 이 창이 보인다면?
              </a>
            </div>
          </ModalBody>
        </Modal>
      )}

      {modal === 3 && (
        <Modal color="primary" isOpen={true} style={modalStyle.all} className="posModal">
          <ModalBody style={modalStyle.body}>
            <div className="pt-2 pb-4 lh-1-6">
              <div className="fw-7" style={modalStyle.titleText}>
                POS 연동이 완료되었습니다.
              </div>
              <div className="fw-5" style={modalStyle.smallText}>
                이달의 POS의 데이터가 서비스에 반영되는데,
                <br />
                1~2일 정도가 소요될 수 있습니다.
              </div>
            </div>

            {/* <Button
              className="fsize-1-2 ml-0-5 mr-0-5"
              style={{ ...modalStyle.footerButton, ...modalStyle.closeButton }}
              color="light"
              onClick={() => handleConfirm(false)}
            >
              취소
            </Button> */}
            <Button
              className="fsize-1-2 ml-0-5 mr-0-5"
              style={modalStyle.footerButton}
              color="primary"
              onClick={() => handleConfirm(false)}
            >
              확인
            </Button>
          </ModalBody>
        </Modal>
      )}

      {modal === 4 && (
        <Modal color="primary" isOpen={true} style={modalStyle.all} className="posModal">
          <ModalBody style={modalStyle.body}>
            <div className="pt-2 pb-4 lh-1-6">
              <div className="fw-7" style={modalStyle.titleText}>
                POS 연동 요청
              </div>
              <div className="fw-5" style={modalStyle.smallText}>
                아직 계정 연동이 준비중인 POS입니다. <br />
                빠르게 연동 개발이 진행될 수 있도록 하겠습니다.
              </div>
            </div>

            {/* <Button
              className="fsize-1-2 ml-0-5 mr-0-5"
              style={{ ...modalStyle.footerButton, ...modalStyle.closeButton }}
              color="light"
              onClick={() => handleConfirm(false)}
            >
              재시도
            </Button> */}
            <Button
              className="fsize-1-2 ml-0-5 mr-0-5"
              style={modalStyle.footerButton}
              color="primary"
              onClick={() => handleConfirm(false)}
            >
              확인
            </Button>

            <div className="pt-3">
              <a
                onClick={() => ChannelChat.openChat()}
                className="fw-5"
                style={{ ...modalStyle.smallText, ...modalStyle.helpText }}
              >
                계속 이 창이 보인다면?
              </a>
            </div>
          </ModalBody>
        </Modal>
      )}

      {modal === 5 && (
        <Modal color="primary" isOpen={true} style={modalStyle.all} className="posModal">
          <AccountcertifyModal timer={30} useBtn={false} />
        </Modal>
      )}
    </>
  );
};
