import { authHeader } from '../../helpers';
import { useMutation } from 'react-query';

const updateApi = async parameter => {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(parameter),
  };

  const response = await fetch('/office/updateRetailOfficeBaseData', requestOptions);

  return response.json();
};

export const useAdditionalUserInfoMutation = () => {
  return useMutation(parameter => updateApi(parameter));
};
