import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import IMAGES from '../../../assets/utils/images';

const ATRadioGroup = props => {
  const {
    renderKey = 0,
    idx = 0,
    inputDefaultValue = -1,
    radioIcon = [IMAGES.iconCheckCircleDefault, IMAGES.iconCheckCircleActive], //  default, active,
    radioWidth = '20px',
    marginFromRadio = '4px',
    labelLineHeight = null,
    labelStyle = null,
    gutter = null,
    className = '',
    style = null,
    list = ['옵션1', '옵션2'],
    handleRadioInput = () => {},
  } = props;

  const [checkedValue, setCheckedValue] = useState(inputDefaultValue);

  useEffect(() => {
    setCheckedValue(inputDefaultValue);
  }, [renderKey]);

  return (
    <Wrapper
      radioIcon={radioIcon}
      radioWidth={radioWidth}
      marginFromRadio={marginFromRadio}
      labelStyle={labelStyle}
      labelLineHeight={labelLineHeight}
      gutter={gutter}
      className={className}
      style={style}
    >
      {list.map((item, i) => (
        <div className={classNames('radio-item')} key={idx + '_' + i}>
          <input
            readOnly
            type="radio"
            id={idx + '_' + i}
            name={idx + '_' + i}
            value={checkedValue}
            checked={checkedValue === i}
            onClick={() => {
              setCheckedValue(i);
              handleRadioInput && handleRadioInput(idx, i);
            }}
          />
          <label htmlFor={idx + '_' + i}>{item}</label>
        </div>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  ${props => props.style && { ...props.style }}

  .radio-item {
    label {
      margin-bottom: 0;
    }
    // default ui
    ${props =>
      props.gutter &&
      css`
        & + .radio-item {
          margin-left: ${props => props.gutter + 'px'};
        }
      `}
    input[type='radio'] {
      &:checked,
      &:not(:checked) {
        position: absolute;
        left: -9999px;
      }

      &:checked + label,
      &:not(:checked) + label {
        position: relative;
        padding-left: ${props => `calc(${props.radioWidth} + ${props.marginFromRadio || '0px'})` || '18px'}; // + 10
        line-height: ${props => `calc(${props.radioWidth} + ${props.labelLineHeight || '0px'})` || '18px'};
        font-size: 13px;
        font-weight: 700;
        color: #495057
          ${props =>
            props.labelStyle &&
            css`
               {
                ...props.labelStyle;
              }
            `};
        cursor: pointer;
      }

      &:checked + label:before,
      &:not(:checked) + label:before {
        /* radio - default */
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: ${props => props.radioWidth || '18px'};
        height: ${props => props.radioWidth || '18px'};
        background-image: url(${props => props.radioIcon[0]});
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
      }

      &:checked + label:after,
      &:not(:checked) + label:after {
        /* radio - active */
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: ${props => props.radioWidth || '18px'};
        height: ${props => props.radioWidth || '18px'};
        background-image: url(${props => props.radioIcon[1]});
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
      }

      &:not(:checked) + label:after {
        opacity: 0;
      }

      &:checked + label:after {
        opacity: 1;
      }
    }
  }
`;

export default ATRadioGroup;
