import React, { Fragment } from 'react';
import isEqual from 'react-fast-compare';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { Col, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { authActions } from '../../actions';
import { getMyUserData, rules, validation, getCurrentTime, localDataLoad, setLocalData } from '../../helpers';
import { CountDown } from '../CountDown';

class ConfirmCodeModal extends React.Component {
  constructor(props) {
    super(props)

    var userData = getMyUserData();

    this.state = {
      userSno: userData['user_sno'],
      inputCode: '',
      authCode: '',
      authToken: undefined,
      modal: props.modal,
      mobile: props.mobile,
      isCorrect: false,
    }

    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this)
    this.handleEnter = this.handleEnter.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSendPhoneAuthNumber = this.handleSendPhoneAuthNumber.bind(this)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    var returnState = {};

    if (prevState.inputCode.length === 4) {
      returnState.isCorrect = true;
    } else {
      returnState.isCorrect = false;
    };

    if (nextProps.sendPhoneAuthNumber !== undefined && !isEqual(nextProps.sendPhoneAuthNumber, prevState.sendPhoneAuthNumber)) {
      returnState.sendPhoneAuthNumber = nextProps.sendPhoneAuthNumber;
      if (nextProps.sendPhoneAuthNumber.result) {
        returnState.authToken = nextProps.sendPhoneAuthNumber.result;
      }
    }

    if (nextProps.checkAuthNumber !== undefined && !isEqual(nextProps.checkAuthNumber, prevState.checkAuthNumber)) {
      returnState.checkAuthNumber = nextProps.checkAuthNumber;
      if (nextProps.checkAuthNumber['result'] === 1) {
        nextProps.onRegistStep(nextProps.step + 1)
      } else {
        alert('인증번호가 만료되었거나, 잘못 입력하셨습니다. 다시 확인해주세요.')
      }
    }

    return returnState ? returnState : null;
  }

  componentDidMount() {
    // modal 자체 버그때문에 modal창이 두번 생성되고, 
    // 첫번째 modal은 backdrop 속성이 적용되지 않아 강제로 클릭이벤트를 발생시키도록 수정
    setTimeout(() => {
      var element = document.getElementById("dummy");
      if (element) {
        element.click();
      }
    }, 10);

    const curTime = getCurrentTime();
    const lastAuthTime = localDataLoad('lastAuthTime');

    if (lastAuthTime && (curTime < lastAuthTime + (2 * 1000))) {
      return;
    }

    this.handleSendPhoneAuthNumber(this.state.mobile);
    setLocalData('lastAuthTime', curTime);
  }

  componentDidUpdate() {
    const { authCode, inputCode } = this.state;

    if (authCode !== inputCode && inputCode.length === 4) {
      this.setState({ authCode: inputCode });
    }
  }

  toggle() {
    this.setState({ modal: !this.state.modal });

    this.props.toggle({ target: { name: "password" } })
  }

  handleChange(e) {
    e.preventDefault();
    this.setState({ inputCode: e.target.value })
  }

  handleEnter(e) {
    if (e.key === 'Enter') {
      this.handleSubmit();
    }
  }

  handleSendPhoneAuthNumber(phoneNumber, recount = false) {
    if (phoneNumber) {
      this.props.dispatch(authActions.sendPhoneAuthNumber(phoneNumber, 2));
    }

    if (recount === true) {
      this.refs.refCountDown.reCount();
    }
  }

  handleSubmit() {
    const { inputCode, authCode, authToken } = this.state
    if (!validation(inputCode, rules.num)) {
      return;
    }
    if (authToken) {
      this.props.dispatch(authActions.checkAuthNumber(authToken, authCode));
    }
  }

  render() {

    const { inputNum, isCorrect } = this.state

    const modalStyle = {
      header: {
        paddingBottom: "4px"
      },
      isTrue: {
        backgroundColor: "#adb5bd",
        marginBottom: "4px"
      },
      isFalse: {
        backgroundColor: "#adb5bd",
        marginBottom: "4px",
        opacity: "0.5",
        cursor: "default"
      },
      footer: {
        textAlign: "right",
        display: "block",
        height: "72px"
      },
      countDown: {
        marginLeft: "4px"
      }
    }

    return (
      <Fragment>
        <Modal isOpen={this.state.modal} className={this.props.className} toggle={this.toggle} backdrop="static">
          <div id="dummy" />
          <ModalHeader toggle={this.toggle}>인증번호 입력</ModalHeader>
          <ModalBody>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <div className="modal-usr-guide">
                    인증번호를 입력하세요.
                  </div>
                  <NumberFormat
                    className="form-control"
                    type="text"
                    name="Input num"
                    value={inputNum}
                    placeholder="인증번호 입력"
                    maxLength="4"
                    onChange={this.handleChange}
                    onKeyPress={this.handleEnter}
                    autoComplete="off"
                  />
                </FormGroup>
              </Col>
            </Row>
            <div style={modalStyle.countDown}>
              <CountDown ref='refCountDown' />
              <a href="javascript:void(0);" className="text-primary container" onClick={() => {
                if (window.confirm('인증번호를 재발송 하시겠습니까?')) {
                  this.handleSendPhoneAuthNumber(this.state.mobile, true)
                }
              }}>인증번호 재발송 요청</a>
            </div>
          </ModalBody>
          <ModalFooter style={modalStyle.footer}>
            <button className="ml-auto badge badge-secondary modal-edt-btn" style={isCorrect ? modalStyle.isTrue : modalStyle.isFalse} onClick={isCorrect ? this.handleSubmit : undefined}>인증하기</button>
          </ModalFooter>
        </Modal>
      </Fragment >
    )
  }
}

function mapStateToProps(state) {
  const { sendPhoneAuthNumber } = state.sendPhoneAuthNumber;
  const { checkAuthNumber } = state.checkAuthNumber;

  return {
    sendPhoneAuthNumber,
    checkAuthNumber,
  };
}

export default connect(mapStateToProps)(ConfirmCodeModal);