import React, { Fragment } from 'react';
import isEqual from 'react-fast-compare';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { Col, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { authActions } from '../../actions';
import { getMyUserData, replaceHypen, rules, setHypenPhoneNum, validation } from '../../helpers';
import { authConstants } from '../../constants';
import styled from 'styled-components';

class PutNewMobile extends React.Component {
  constructor(props) {
    super(props);

    var userData = getMyUserData();

    this.state = {
      userSno: userData['user_sno'],
      inputMobile: userData['user_phone'],
      modal: props.modal,
      changedMobile: undefined,
      step: props.step,
      isCorrect: false,
    };

    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    const inputMobile = replaceHypen(prevState.inputMobile);

    if (validation(inputMobile, rules.phoneNum) && inputMobile.length === 11) {
      returnState.isCorrect = true;
    } else {
      returnState.isCorrect = false;
    }

    if (nextProps.validPhoneNum !== undefined && !isEqual(nextProps.validPhoneNum, prevState.validPhoneNum)) {
      let validPhoneNum = nextProps.validPhoneNum;
      returnState.validPhoneNum = validPhoneNum;

      if (prevState.modal) {
        if (validPhoneNum.result === 0) {
          nextProps.onRegistStep(nextProps.step + 1, { changedMobile: prevState.inputMobile });
        } else if (validPhoneNum.result === 1) {
          alert('이미 등록된 전화번호 입니다.');
        }
      }
    }

    return returnState ? returnState : null;
  }

  componentDidMount() {
    // modal 자체 버그때문에 modal창이 두번 생성되고,
    // 첫번째 modal은 backdrop 속성이 적용되지 않아 강제로 클릭이벤트를 발생시키도록 수정
    setTimeout(() => {
      var element = document.getElementById('dummy');
      if (element) {
        element.click();
      }
    }, 10);
  }

  componentWillUnmount() {
    this.props.dispatch({ type: authConstants.RESETSTATE });
  }

  toggle() {
    this.setState({ modal: !this.state.modal });

    this.props.toggle({ target: { name: 'mobile' } });
  }

  handleChange(e) {
    e.preventDefault();
    this.setState({ inputMobile: e.target.value });
  }

  handleEnter(e) {
    if (e.key === 'Enter') {
      this.handleSubmit();
    }
  }

  handleSubmit() {
    const inputMobile = replaceHypen(this.state.inputMobile);

    if (!validation(inputMobile, rules.phoneNum)) {
      return;
    }
    this.props.dispatch(authActions.validPhoneNum(inputMobile, 2));
  }

  render() {
    const { inputMobile, modal, isCorrect } = this.state;

    const closeBtn = (
      <button className="close" onClick={this.toggle}>
        &times;
      </button>
    );

    const modalStyle = {
      header: {
        paddingBottom: '4px',
      },
      isTrue: {
        backgroundColor: '#adb5bd',
        marginBottom: '4px',
      },
      isFalse: {
        backgroundColor: '#adb5bd',
        marginBottom: '4px',
        opacity: '0.5',
        cursor: 'default',
      },
      footer: {
        textAlign: 'right',
        display: 'block',
        height: '72px',
      },
    };

    return (
      <Fragment>
        <CustomModal isOpen={modal} toggle={this.toggle} className={this.props.className} backdrop="static">
          <div id="dummy" />
          <ModalHeader close={closeBtn} toggle={this.toggle}>
            휴대전화번호 변경
          </ModalHeader>
          <ModalBody style={modalStyle.header}>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <div className="modal-usr-guide">변경할 휴대전화 번호를 입력하세요.</div>
                  <NumberFormat
                    className="form-control"
                    type="text"
                    name="Change mobile"
                    value={inputMobile}
                    placeholder="휴대전화번호 변경"
                    onChange={this.handleChange}
                    onKeyPress={this.handleEnter}
                    format={val => setHypenPhoneNum(val)}
                    autoComplete="off"
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter style={modalStyle.footer}>
            <button
              className="ml-auto badge badge-secondary modal-edt-btn"
              style={isCorrect ? modalStyle.isTrue : modalStyle.isFalse}
              onClick={isCorrect ? this.handleSubmit : undefined}
            >
              인증번호 받기
            </button>
          </ModalFooter>
        </CustomModal>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { validPhoneNum } = state.validPhoneNum;

  return {
    validPhoneNum,
  };
}

export default connect(mapStateToProps)(PutNewMobile);

const CustomModal = styled(Modal)`
  .modal-content {
    top: env(safe-area-inset-top);
  }
`;
