import { atom } from 'recoil';
import { COMMON_ATOM_KEY } from '../../constants/atomKeys';

export const visibilityFlagState = atom({
  key: COMMON_ATOM_KEY.VISIBILITY_FLAG,
  default: 'visible',
});

export const modalInfoState = atom({
  key: COMMON_ATOM_KEY.MODAL_INFO,
  default: {
    isOpen: false,
    isKeyboardVisible: false,
    title: '',
    content: null,
    cancel: null,
    confirm: null,
    backdrop: () => {},
    bodyClassName: ''
  },
});
