import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import helpIcon from '../../../../assets/utils/images/icon_help.svg';
import informationIcon from '../../../../assets/utils/images/Information.svg';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { isSelfEmployed } from '../../../../helpers/CommonUtil';

const HelpButton = () => {
  const history = useHistory();

  const locationHash = window.location.hash;
  const pushLocation = locationHash.includes('mypage') ? '/mobile/help' : '/mobile/help-guide';
  const linkIcon = locationHash.includes('mypage') ? helpIcon : informationIcon;

  const handleLinkToHelp = () => {
    history.push(pushLocation);
  };

  const accessLocationList = ['#/mobile/mypage', '#/mobile/help'];
  const accessLocation = () => {
    return accessLocationList.includes(locationHash);
  };

  return (
    <>
      {isSelfEmployed() && accessLocation() && (
        <HelpLink onClick={handleLinkToHelp}>
          <img src={linkIcon} alt={''} />
        </HelpLink>
      )}
    </>
  );
};

export default HelpButton;

const HelpLink = styled(Link)`
  position: absolute;
  right: 16px;
`;
