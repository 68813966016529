import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { officeActions } from '../../actions';
import { officeConstants } from '../../constants';
import {
  addDate,
  arrayToObject,
  comparePercent,
  dateToStr2,
  getConcatQuery,
  getMyUserData,
  getOrderAppFilter,
  getPayTypeFilter,
  mComma,
  sortAsc,
  sortDesc,
  subDate,
  sumItems,
} from '../../helpers';
import { useTranslation } from '../../locales';
import './dayDeliveryModal.scss';
import DiffPercent from '../Styled/DiffPercent/DiffPercent';

// TODO: 칼라 정의를 어디에서 할지 고민
// scss에서 map으로 돌릴경우, 장 : 사용하기 더 편함, 단 : 유동적이지 않음.
// js에서 정의할 경우, 장 : 유동적인 대처 가능, 단 : 항목안에 색상값 추가

const DayDeliveryModal = ({
  searchDate,
  startDate,
  endDate,
  type = '',
  onClose = () => {},
  onSelectDate = () => {},
}) => {
  const dispatch = useDispatch();
  const t = useTranslation();
  const ref = useRef();

  const { specificDateSales } = useSelector(state => state.specificDateSales);
  const { searchParams } = useSelector(state => state.searchParams);
  const { salesViewType, selectOfficeList, deliveryTipType, siteType, orderApp, standardSaleTime } = searchParams;

  useEffect(() => {
    window.addEventListener('click', checkClickOuter);

    return () => {
      dispatch({ type: officeConstants.SPECIFIC_DATE_SALES_RESET });
      window.removeEventListener('click', checkClickOuter);
    };
  }, []);

  useEffect(() => {
    if (searchDate && searchParams) {
      let userData = getMyUserData();
      dispatch(
        officeActions.specificDateSales(
          userData['user_sno'],
          userData['brand_code'],
          searchDate,
          getConcatQuery(selectOfficeList),
          deliveryTipType,
          standardSaleTime,
        ),
      );
    }
  }, [searchDate]);

  const checkClickOuter = e => {
    if (ref && ref.current && !ref.current.contains(e.target)) {
      onClose();
    }
  };

  const selectNextDate = () => {
    if (searchDate === endDate) {
      return null;
    }

    onSelectDate(addDate(searchDate, 1));
  };
  const selectPrevDate = () => {
    if (searchDate === startDate) {
      return null;
    }

    onSelectDate(subDate(searchDate, 1));
  };

  if (!specificDateSales) {
    return null;
  }

  let orderAppList = getOrderAppFilter(false);
  let orderAppObj = arrayToObject(orderAppList, 'value');

  let siteTypeList = getPayTypeFilter(false);
  let siteTypeObj = arrayToObject(siteTypeList, 'orderApp');

  let deliveryList = sortDesc(specificDateSales['deliverySaleList'], 'payAmount')
    .map(item => {
      let orderAppInfo = orderAppObj[item['orderApp']];
      if (!orderAppInfo) {
        return null;
      }

      let disable = (salesViewType !== 0 && salesViewType !== 2) || (orderApp !== -1 && orderApp !== item['orderApp']);

      return {
        orderApp: item['orderApp'],
        payAmount: item['payAmount'],
        orderCnt: item['orderCnt'],
        color: disable ? '#b9becfB3' : orderAppInfo['color'],
        shape: orderAppInfo['shape'],
        name: orderAppInfo['name'],
        percent: parseFloat(comparePercent(item['payAmount'], specificDateSales['totPayAmount'], 2)),
        disable: disable,
      };
    })
    .filter(v => v);

  let siteList = sortDesc(specificDateSales['siteSaleList'], 'payAmount')
    .map(item => {
      let siteTypeInfo = siteTypeObj[item['payType']];
      if (!siteTypeInfo) {
        return null;
      }

      let disable = (salesViewType !== 1 && salesViewType !== 2) || siteType !== 0;

      return {
        payType: item['payType'],
        payAmount: item['payAmount'],
        orderCnt: item['orderCnt'],
        color: disable ? '#b9becfB3' : siteTypeInfo['color'],
        shape: siteTypeInfo['shape'],
        name: siteTypeInfo['name'],
        percent: parseFloat(comparePercent(item['payAmount'], specificDateSales['totPayAmount'], 2)),
        disable: disable,
      };
    })
    .filter(v => v);

  let mergedList =
    specificDateSales &&
    sortAsc(
      deliveryList.concat(siteList).filter(v => v['percent'] > 0),
      'disable',
    );

  let totalPrice = sumItems(
    mergedList.filter(v => v['disable'] === false),
    'payAmount',
  );
  let totalCnt = sumItems(
    mergedList.filter(v => v['disable'] === false),
    'orderCnt',
  );

  return (
    <>
      <div className="popup ab2 dim6">
        <div className="dim" />
        <div ref={ref} className="day-delivery-wrap">
          <div className={classNames('day-delivery', type)}>
            <div className="day-area content-padding">
              <div className="day">{dateToStr2(searchDate, 9)}</div>
              <div
                className={classNames('left-btn ctrl-btn', { 'is-end': searchDate === startDate })}
                onClick={selectPrevDate}
              />
              <div
                className={classNames('right-btn ctrl-btn', { 'is-end': searchDate === endDate })}
                onClick={selectNextDate}
              />
            </div>
            <div className="title content-padding">
              <div className="total-price" data-end={t['지표-통화']}>
                {mComma(totalPrice)}
              </div>
              <div className="total-count">{mComma(totalCnt) + t['지표-건']}</div>
              {salesViewType === 2 && (
                <DiffPercent className="text" dateText="전일" percent={specificDateSales['diffPercentPayAmount']} />
              )}
            </div>
            <div className="content-padding">
              <div className="graph">
                {mergedList.map(item => {
                  return <GraphArea width={item['percent']} color={item['color']} />;
                })}
              </div>
            </div>
            <div className="list-area custom-scrollbar vertical">
              {(salesViewType === 1 || salesViewType === 2) && (
                <div className="list-wrap">
                  {salesViewType === 2 && (
                    <div className="total">
                      <div className="left">
                        {t['필터-홀']} (
                        {comparePercent(specificDateSales['siteTotPayAmount'], specificDateSales['totPayAmount'])}
                        %)
                      </div>
                      <div className="right">
                        {mComma(specificDateSales['siteTotPayAmount']) + t['지표-통화']} (
                        {mComma(specificDateSales['siteTotOrderCnt']) + t['지표-건']})
                      </div>
                    </div>
                  )}
                  <ul>
                    {siteList.map(item => {
                      if (item['disable'] === true) {
                        return null;
                      }

                      return (
                        <li key={item['payType']}>
                          <div className="left">
                            <ColororderApp
                              color={item['color']}
                              className={'delivery-color ' + (item['shape'] ? item['shape'] : 'color')}
                            />
                            {item['name']}{' '}
                            <span className="sub-text">
                              {comparePercent(item['payAmount'], specificDateSales['totPayAmount'])}%
                            </span>
                          </div>
                          <div className="right">
                            {mComma(item['payAmount'])}{' '}
                            <span className="sub-text">{mComma(item['orderCnt']) + t['지표-건']}</span>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}

              {(salesViewType === 0 || salesViewType === 2) && (
                <div className="list-wrap">
                  {salesViewType === 2 && (
                    <div className="total">
                      <div className="left">
                        {t['필터-배달']} (
                        {comparePercent(specificDateSales['deliveryTotPayAmount'], specificDateSales['totPayAmount'])}
                        %)
                      </div>
                      <div className="right">
                        {mComma(specificDateSales['deliveryTotPayAmount']) + t['지표-통화']} (
                        {mComma(specificDateSales['deliveryTotOrderCnt']) + t['지표-건']})
                      </div>
                    </div>
                  )}
                  {
                    <ul>
                      {deliveryList.map(item => {
                        if (item['disable'] === true) {
                          return null;
                        }

                        return (
                          <li key={item['orderApp']}>
                            <div className="left">
                              <ColororderApp
                                color={item['color']}
                                className={'delivery-color ' + (item['shape'] ? item['shape'] : 'color')}
                              />
                              {item['name']}
                              <span className="sub-text">
                                {comparePercent(item['payAmount'], specificDateSales['totPayAmount'])}%
                              </span>
                            </div>
                            <div className="right">
                              {mComma(item['payAmount'])}{' '}
                              <span className="sub-text">{mComma(item['orderCnt']) + t['지표-건']}</span>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  }
                </div>
              )}
            </div>
            <div className="btn-wrap content-padding">
              <button className="basic-btn" onClick={onClose}>
                {t['필터-확인']}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ColororderApp = styled.span`
  background: ${props => (props.color ? props.color : '#000')};

  &.donut {
    display: inline-block;
    margin-right: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: transparent;
    position: relative;
    overflow: hidden;
  }

  &.donut:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid ${props => props.color};
    box-sizing: border-box;
  }
`;

const GraphArea = styled.span`
  display: inline-flex;
  height: 100%;
  background: ${props => props.color};
  width: ${props => props.width}%;
  border-right: 1px solid #f4f5f6;
  &:last-child {
    border: none;
  }
`;

export default DayDeliveryModal;
