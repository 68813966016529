import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Col, Form, FormGroup, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import styled from 'styled-components';
import { commonActions, officeActions } from '../../actions';
import arrowDown from '../../assets/utils/images/arrow-down.png';
import { isMobile, officeConstants } from '../../constants';
import { arrayToObject, getMyUserData } from '../../helpers';
import { officeService } from '../../services';
import { BasicBtn } from '../Buttons';
import { POSConfirm } from '../Modal/POSComfirmModal';
import ATInput from '../Styled/form/ATInput';
import ATItemBox from '../Styled/form/ATItemBox';

export const VALIDATION_STAUTS = {
  WAIT: 'wait',
  COMPELETE: 'complete',
  DENY: 'deny',
  UNACTIVE: 'unactive',
};

const PosForm = props => {
  const { numTabs = 5 } = props;

  const userData = getMyUserData(false);
  if (!userData) {
    return null;
  }

  const dispatch = useDispatch();
  const location = useLocation();

  const { posOwnerAccount } = useSelector(state => state.getPosOwnerAccount);
  const { linkPosAccount, loading } = useSelector(state => state.linkPosAccount);
  useSelector(state => state.navRerender);

  const [currentActiveTab, setCurrentActiveTab] = useState(0);
  const [posSelectOptions, setPosSelectOptions] = useState([]);
  const [isSetDefault, setIsSetDefault] = useState(false);
  const [inputValues, setInputValues] = useState([]);

  const [showSelectField, setShowSelectField] = useState(false);

  const posIdRef = useRef([]);
  const posTextRef = useRef([]);
  const agreecheckRef = useRef([]);
  const agreecheckRef2 = useRef([]);

  useEffect(() => {
    dispatch(officeActions.getPosOwnerAccount(userData['brand_code'], userData['user_sno']));

    getPosSelectOptions();

    return () => {
      dispatch({ type: officeConstants.LINK_POS_ACCOUNT_RESET });
    };
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    let tab = parseInt(queryParams.get('p'));
    if (tab >= 0) {
      toggle(tab, true);
    }
  }, [location]);

  useEffect(() => {
    if (posSelectOptions.length > 0 && posOwnerAccount && isSetDefault === false) {
      // init
      let tmp;
      if (posOwnerAccount.length <= 0) {
        makeDefaultValue();
      } else {
        let obj = arrayToObject(posSelectOptions, 'key');
        tmp = posOwnerAccount.map(v => {
          return {
            idx: v.idx,
            platform: v.platform,
            platformName: obj[v.platform]['display_name'],
            accountIdx: v.account_idx,
            etcPlatform: v.etc_platform,
            userId: v.user_id ? v.user_id : '',
            wait: v.validation_status, // wait:처리대기중, complete:연동완료, deny:연동실패, unactive:비활성화
            validationLinkedStatus: 'wait', // wait:처리대기중, complete:연동완료, deny:연동실패, unactive:비활성화
            pw: '',
            isValid: v.validation_status === VALIDATION_STAUTS['DENY'] ? false : true,
            isEdited: false,
          };
        });
        setInputValues(tmp);
      }

      setIsSetDefault(true);
    }
  }, [posOwnerAccount, posSelectOptions]);

  useEffect(() => {
    if (loading) {
      POSConfirm(5);
      return;
    }

    // isEdited 초기화
    let tmp = inputValues;
    tmp.map(v => (v['isEdited'] = false));

    if (linkPosAccount) {
      // 기타 입력
      if (linkPosAccount['rtn'] === 2) {
        POSConfirm(4, handlePOSConfirm);
        return;
      }

      // 연결 실패
      if (linkPosAccount['rtn'] === 0) {
        POSConfirm(2, handlePOSConfirm);
        tmp[currentActiveTab]['validationStatus'] = VALIDATION_STAUTS['DENY'];
        tmp[currentActiveTab]['isValid'] = false;
      }

      // 연결 성공
      if (linkPosAccount['rtn'] === 1) {
        POSConfirm(3, () => (window.location.href = '#/mobile/main'));
        tmp[currentActiveTab]['validationStatus'] = VALIDATION_STAUTS['COMPELETE'];
        tmp[currentActiveTab]['isValid'] = true;
      }
    }

    setInputValues(tmp);
    dispatch(commonActions.navRerender());
  }, [linkPosAccount, loading]);

  const updateInputValues = (
    tab,
    { idx, platform, platformName, accountIdx, etcPlatform, userId, validationStatus, validationLinkedStatus, pw, isValid },
  ) => {
    const fields = { idx, platform, platformName, accountIdx, etcPlatform, userId, validationStatus, validationLinkedStatus, pw, isValid };
    const filteredFields = Object.entries(fields).reduce((acc, [key, value]) => {
      if (value !== undefined) acc[key] = value;
      return acc;
    }, {});

    let tmp = inputValues;
    tmp[tab] = { ...tmp[tab], ...filteredFields, ...{ isEdited: true } };

    setInputValues(tmp);
    dispatch(commonActions.navRerender());
  };

  const makeDefaultValue = () => {
    let tmp = inputValues;

    tmp.push({
      idx: 0,
      platform: '',
      platformName: '',
      accountIdx: 0,
      etcPlatform: '',
      userId: '',
      validationStatus: VALIDATION_STAUTS['WAIT'],
      pw: '',
      isValid: true,
      isEdited: false,
    });

    setInputValues(tmp);

    dispatch(commonActions.navRerender());
  };

  const getPosSelectOptions = async () => {
    let result = await officeService.getPlatformSelectBox();

    setPosSelectOptions(result['result']);
  };

  const toggle = (tab, force = false) => {
    if (
      !force &&
      tab > 0 &&
      (!inputValues[tab - 1] ||
        (inputValues[tab - 1]['validationStatus'] !== VALIDATION_STAUTS['COMPELETE'] &&
          inputValues[tab - 1]['validationStatus'] !== VALIDATION_STAUTS['DENY']))
    ) {
      return;
    }

    if (!inputValues[tab]) {
      makeDefaultValue();
    }

    setCurrentActiveTab(tab);
  };

  const selectPosChange = key => {
    let obj = arrayToObject(posSelectOptions, 'key');

    setShowSelectField(false);
    updateInputValues(currentActiveTab, {
      userId: '',
      pw: '',
      platform: key,
      etcPlatform: '',
      platformName: obj[key]['display_name'],
      validationStatus: VALIDATION_STAUTS['WAIT'],
      isValid: true,
    });

    dispatch(commonActions.navRerender());

    setTimeout(() => {
      if (key === 'etc') {
        posTextRef.current[currentActiveTab].focus();
      } else {
        posIdRef.current[currentActiveTab].focus();
      }
    }, 100);
  };

  const handleChange = e => {
    const { name, value } = e.target;

    let tmp = inputValues[currentActiveTab];

    if (name === 'posid') {
      tmp['userId'] = value;
    }

    if (name === 'password') {
      tmp['pw'] = value;
    }

    if (name === 'postext') {
      tmp['etcPlatform'] = value;
    }

    updateInputValues(currentActiveTab, tmp);
  };

  const handleModal = e => {
    e.preventDefault();

    POSConfirm(1, handlePOSConfirm, inputValues.filter(v => v['isEdited'] === true).length);
  };

  const callLinkPosAccount = async () => {
    dispatch({ type: officeConstants.LINK_POS_ACCOUNT_REQUEST });

    let rtn = 1;
    let toggleIdx = null;

    for (let i = 0; i < inputValues.length; i++) {
      let posData = inputValues[i];

      if (posData['isEdited']) {
        let result = await officeService.linkPosAccount({
          brandCode: userData['brand_code'],
          userSno: userData['user_sno'],
          credentialIdx: posData['idx'],
          platform: posData['platform'],
          etcPlatform: posData['etcPlatform'],
          userId: posData['userId'],
          userPw: posData['pw'],
          officeStatusIdx: userData['franchise_office_status_idx'],
          disabled: posData['validationStatus'] === 'unactive',
        });

        if (result && result['result']) {
          if (result['result']['rtn'] === 0) {
            rtn = 0;
            toggleIdx = i;
          }

          if (result['result']['credentialIdx'] > 0) {
            updateInputValues(i, { idx: result['result']['credentialIdx'] });
          }
        }
      }

      if (i === inputValues.length - 1) {
        if (toggleIdx !== null) {
          toggle(toggleIdx);
        }

        dispatch({ type: officeConstants.LINK_POS_ACCOUNT_SUCCESS, result: { result: { rtn: rtn } } });
      }
    }

    // dispatch(
    //   officeActions.linkPosAccount({
    //     brandCode: userData['brand_code'],
    //     userSno: userData['user_sno'],
    //     credentialIdx: posData['idx'],
    //     platform: posData['platform'],
    //     etcPlatform: posData['etcPlatform'],
    //     userId: posData['userId'],
    //     userPw: posData['pw'],
    //     officeStatusIdx: userData['franchise_office_status_idx'],
    //     disabled: posData['validationStatus'] === 'unactive',
    //   }),
    // );
  };

  const handlePOSConfirm = v => {
    if (v === true) {
      callLinkPosAccount();
    }

    if (v === false) {
      toggle(currentActiveTab > 4 ? currentActiveTab : currentActiveTab + 1, true);
    }
  };

  const isUnactiveFirst = () => {
    if (!inputValues[currentActiveTab]) {
      return false;
    }

    return inputValues[currentActiveTab]['validationStatus'] === VALIDATION_STAUTS['UNACTIVE'];
  };

  const isUnactiveSecond = () => {
    if (!inputValues[currentActiveTab]) {
      return false;
    }

    return inputValues[currentActiveTab]['validationLinkedStatus'] === VALIDATION_STAUTS['UNACTIVE'];
  };

  const isUnactive = () => {
    if (!inputValues[currentActiveTab]) {
      return false;
    }

    return isUnactiveFirst() && isUnactiveSecond();
  };

  const isEtcPos = () => {
    if (!inputValues[currentActiveTab]) {
      return false;
    }

    return inputValues[currentActiveTab]['platform'] === 'etc';
  };

  const getDisabledConfirmBtn = () => {
    let currValue = inputValues[currentActiveTab];

    // 기타 선택, 미입력
    if (isEtcPos() && currValue['etcPlatform']) {
      return false;
    }

    // 포스사 선택, 미입력
    if (!isEtcPos() && !currValue['etcPlatform'] && currValue['userId'] && currValue['pw']) {
      return false;
    }

    // 연동제외
    if (isUnactive()) {
      return false;
    }

    return true;
  };

  let currValue = inputValues[currentActiveTab];
  if (isSetDefault === false || !currValue) {
    return null;
  }

  return (
    <Wrapper>
      <div
        style={{
          position: 'relative',
          zIndex: 9,
        }}
        className={isUnactive() ? 'agreechecked' : 'notagreecheck'}
      >
        <Form className="posForm">
          <div
            className="d-flex justify-content-center"
            style={{
              marginTop: 32,
              // boxShadow: '0px 0px 8px 0px #0000000D',
              borderRadius: '24px',
            }}
          >
            <div
              className={isMobile() ? 'px-2' : 'bg-white px-4 py-5'}
              style={{ borderRadius: '20px', width: 320, boxShadow: '0px 0px 8px 0px #0000000D' }}
            >
              <TabContent activeTab={currentActiveTab}>
                {Array.from(Array(numTabs).keys()).map(idx => (
                  <TabPane tabId={idx} key={idx}>
                    <Row>
                      <Col sm="12">
                        <FormGroup className="position-relative">
                          <Label
                            for={`selectIdTemp${idx}`}
                            className={isMobile() && showSelectField ? 'opacity-1' : ''}
                          >
                            <span className="label">POS사</span>
                            <span className="text-danger"> *</span>
                          </Label>
                          <div className={'selectBox ' + (isMobile() && showSelectField ? 'mobileSelected ' : '')}>
                            <ATInput
                              id={`selectIdTemp${idx}`}
                              type="text"
                              placeholder={currValue['platformName'] || 'POS사를 선택하세요.'}
                              className={
                                showSelectField
                                  ? 'inputwrap pointer selectBoxActive selectPosInput'
                                  : 'inputwrap pointer selectPosInput'
                              }
                              onClick={() => {
                                !isUnactive() && setShowSelectField(!showSelectField);
                              }}
                              readonly
                              defaultValue={currValue['platformName'] || 'POS사를 선택하세요.'}
                              disabled={isUnactive()}
                            />
                            {showSelectField && (
                              <div className={isMobile() ? 'selectPosContainer' : ''}>
                                <ul className="selectOptionBox">
                                  {posSelectOptions.map((item, i) => {
                                    return (
                                      <li key={i} onClick={() => selectPosChange(item['key'])}>
                                        {item['display_name']}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            )}
                          </div>
                          {isEtcPos() && (
                            <ATInput
                              id={`postext${idx}`}
                              type="text"
                              name="postext"
                              maxLength="50"
                              placeholder="POS사 이름을 입력하세요."
                              required
                              onChangeInput={handleChange}
                              className="inputwrap mt-2"
                              readonly={isMobile() && isUnactive()}
                              refs={el => (posTextRef.current[idx] = el)}
                              disabled={isUnactive()}
                              defaultValue={currValue['etcPlatform']}
                            />
                          )}
                        </FormGroup>

                        <FormGroup>
                          <Label for={`posid${idx}`}>
                            <span className="label">계정 ID</span>
                            <span className="text-danger"> *</span>
                          </Label>
                          <ATInput
                            id={`posid${idx}`}
                            type="text"
                            name="posid"
                            maxLength="50"
                            placeholder="계정 ID를 입력하세요."
                            required
                            className={currValue['isValid'] ? 'inputwrap' : 'inputwrap invalidBox'}
                            onChangeInput={handleChange}
                            readonly={isEtcPos() || isUnactive()}
                            disabled={isEtcPos() || isUnactive()}
                            refs={el => (posIdRef.current[idx] = el)}
                            defaultValue={currValue['userId']}
                          />
                        </FormGroup>
                        <FormGroup className={!currValue['isValid'] && 'invalidLastBox'}>
                          <Label for={`password${idx}`}>
                            <span className="label">비밀번호</span>
                            <span className="text-danger"> *</span>
                          </Label>

                          <ATInput
                            id={`password${idx}`}
                            type="password"
                            name="password"
                            maxLength="50"
                            required
                            placeholder="비밀번호를 입력하세요."
                            className={currValue['isValid'] ? 'inputwrap' : 'inputwrap invalidBox'}
                            onChangeInput={handleChange}
                            readonly={isEtcPos() || isUnactive() || !currValue['userId']}
                            disabled={isEtcPos() || isUnactive() || !currValue['userId']}
                            defaultValue={currValue['pw']}
                          />
                        </FormGroup>

                        {!currValue['isValid'] && (
                          <div className="text-center pb-4 fw-6 invalidText">입력된 계정이 일치하지 않습니다.</div>
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                ))}
              </TabContent>

              <Nav tabs className="pt-2">
                {Array.from(Array(numTabs).keys()).map(idx => (
                  <NavItem key={idx}>
                    <NavLink
                      className={classnames({
                        active: currentActiveTab === idx,
                      })}
                      onClick={() => {
                        toggle(idx);
                      }}
                    >
                      <span>{idx + 1}</span>
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </div>
          </div>

          <div
            className="text-center d-flex flex-column justify-content-center"
            style={{
              marginTop: 32,
            }}
          >
            <div className={isMobile() ? 'agreecheck' : ''}>
              <div key={currentActiveTab} className='agreeBox'>
                <div>
                  <ATItemBox
                    checked={isUnactiveSecond()}
                    onChange={() => {
                      if (isUnactiveSecond()) {
                        updateInputValues(currentActiveTab, { validationLinkedStatus: VALIDATION_STAUTS['WAIT'] });
                      } else {
                        updateInputValues(currentActiveTab, { validationLinkedStatus: VALIDATION_STAUTS['UNACTIVE'] });
                      }
                    }}
                    className={
                      'agreecheck ' +
                      (isUnactiveSecond() ? ' agreecheckActive ' : '') +
                      (parseInt(currentActiveTab) === currentActiveTab ? '' : ' d-none ')
                    }
                    type="checkbox"
                    id={`agreechecklinked${currentActiveTab}`}
                    name={`agreechecklinked${currentActiveTab}`}
                    label="본사 마스터 계정으로 일괄 연동합니다."
                    refs={el => (agreecheckRef2.current[currentActiveTab] = el)}
                  />
                </div>

                <div>
                  <ATItemBox
                    checked={isUnactiveFirst()}
                    onChange={() => {
                      if (isUnactiveFirst()) {
                        updateInputValues(currentActiveTab, { validationStatus: VALIDATION_STAUTS['WAIT'] });
                      } else {
                        updateInputValues(currentActiveTab, { validationStatus: VALIDATION_STAUTS['UNACTIVE'] });
                      }
                    }}
                    className={
                      'agreecheck ' +
                      (isUnactiveFirst() ? ' agreecheckActive ' : '') +
                      (parseInt(currentActiveTab) === currentActiveTab ? '' : ' d-none ')
                    }
                    type="checkbox"
                    id={`agreecheck${currentActiveTab}`}
                    name={`agreecheck${currentActiveTab}`}
                    label="현장 POS 데이터는 연동하지 않겠습니다."
                    refs={el => (agreecheckRef.current[currentActiveTab] = el)}
                  />
                  {isMobile() && (
                    <span
                      style={{
                        color: isUnactive() ? '#393939' : '#A6A6A6',
                      }}
                      className={parseInt(currentActiveTab) === currentActiveTab ? 'fw-5' : 'd-none'}
                    >
                      {' '}
                      (연동제외)
                    </span>
                  )}
                </div>
              </div>
            </div>
            
            <div className={isMobile() ? 'mobileBtn' : 'PcBtn'}>
              <BasicBtn size="large" disabled={getDisabledConfirmBtn()} onClick={e => handleModal(e)}>
                {isMobile() ? (
                  <div>
                    <span>{isUnactive() ? '나중에' : isEtcPos() ? '연동요청' : '매출 불러오기'}</span>
                  </div>
                ) : (
                  <div>
                    <span className={isUnactive() ? 'd-none' : ''}>
                      {isEtcPos() ? 'POS기 추가연동요청' : '연동하기'}
                    </span>
                    <span className={isUnactive() ? '' : 'd-none'}>나중에 연동하기</span>
                  </div>
                )}

                <FontAwesomeIcon
                  icon={faChevronRight}
                  className={isMobile() ? 'ml-3' : 'd-none'}
                  style={{ fontSize: 13 }}
                />
              </BasicBtn>
            </div>
          </div>
        </Form>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .posForm .nav-tabs {
    border: unset;
  }

  .posForm .nav-tabs .nav-link {
    border: 1px solid #afb5bd;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #bdc1c7;
    opacity: 0.7;
  }

  .posForm .nav-tabs .nav-link.active {
    background-color: #505ef6;
    color: white !important;
    border: 1px solid #505ef6;
    opacity: 1 !important;
    cursor: default !important;
  }

  .nav-tabs .nav-link:hover {
    color: #bdc1c7 !important;
  }

  .posForm .nav-tabs .nav-link.active:hover {
    color: white !important;
  }

  .posForm .nav.nav-tabs {
    gap: 10px;
    justify-content: center;
    margin-bottom: 0;
  }

  .posForm .input {
    border-radius: 12px;
    border-color: #d0d5d9;
    height: 40px;
  }

  .posForm .basic-btn.large {
    width: 320px;
    height: 59px;
    font-size: 16px;
    font-weight: 700;
    margin: 22px auto;
  }

  .posForm .agreecheck .text {
    font-size: 13.5px;
    font-weight: 500;
    color: #a6a6a6;
  }
  
  .posForm .agreeBox {
    width: max-content;
    text-align: left;
    margin: auto;
  }

  .posForm input::placeholder {
    opacity: 20%;
  }

  .posForm .label {
    font-size: 13px;
    font-weight: 700;
  }

  .posForm .is-readonly:after {
    background: none !important;
  }

  .posForm .selectBoxActive input {
    border: 1px solid #686cff !important;
    outline: 1px solid #686cff !important;
  }

  .posForm .selectOptionBox {
    list-style: none;
    padding: 11px 0px 12px;
    border: 1px solid #d0d5d9;
    border-radius: 12px;
    margin-top: 2px;
    position: absolute;
    width: 100%;
    max-width: 320px;
    background: #fff;
    z-index: 2;
  }

  .posForm .selectOptionBox li {
    cursor: pointer;
    line-height: 40px;
    padding: 4px 1rem;
  }

  .posForm .selectOptionBox li:hover {
    background: #4d52ff0d;
  }

  .posForm .mobileBtn .basic-btn {
    border-radius: 24px;
    width: 166px;
    height: 47px;
  }

  .posForm .invalidLastBox {
    margin-bottom: 0.75rem;
  }

  .posForm .invalidBox input {
    border: 2px solid #d55151 !important;
  }

  .posForm .invalidText {
    font-size: 13.5px;
    color: #d55151;
    opacity: 50%;
  }

  .posForm .selectBox input {
    background-image: url(${arrowDown});
    background-repeat: no-repeat;
    background-position: calc(100% - 1rem) 50%;
    cursor: pointer;
  }

  .posForm .invalidBox input:valid {
    background: none !important;
  }

  .posForm input:focus::placeholder {
    opacity: 0;
  }

  .posForm input:focus {
    border: 1px solid transparent !important;
    outline: 2px solid #686cff !important;
  }

  .posForm .agreecheck.agreecheckActive .text {
    color: #393939 !important;
  }

  .posForm .form-group {
    margin-bottom: 20px;
  }

  .posForm .selectPosContainer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    display: flex;
  }

  .posForm input {
    &:disabled {
      opacity: 1 !important;
    }
  }
`;

export default PosForm;
