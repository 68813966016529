import { createGlobalStyle } from 'styled-components';

import icJoinChkOn from '../../../assets/outsourcing/img/ic_join_chk_on.svg';
import icJoinChkOff from '../../../assets/outsourcing/img/ic_join_chk_off.svg';
import icBack from '../../../assets/outsourcing/img/ic_back.svg';
import icChat from '../../../assets/outsourcing/img/ic_chat.svg';
import icNotice from '../../../assets/outsourcing/img/ic_notice.png';
import icLinkArrow from '../../../assets/outsourcing/img/ic_link_arrow.svg';
import icEdit from '../../../assets/outsourcing/img/ic_edit.svg';
import icAlarm from '../../../assets/outsourcing/img/ic_alarm.svg';
import icAlarm2 from '../../../assets/outsourcing/img/ic_alarm2.svg';
import icError from '../../../assets/outsourcing/img/ic_error.svg';
import icClear from '../../../assets/outsourcing/img/ic_clear.svg';
import icCboxOn from '../../../assets/outsourcing/img/ic_cbox_on.svg';
import icCboxOff from '../../../assets/outsourcing/img/ic_cbox_off.svg';
import icHall from '../../../assets/outsourcing/img/ic_alarm_hall.svg';
import icCoupang from '../../../assets/outsourcing/img/ic_alarm_coupang.svg';
import icBaemin from '../../../assets/outsourcing/img/ic_alarm_baemin.png';
import icYogiyo from '../../../assets/outsourcing/img/ic_alarm_yogiyo.png';
import icBaemin1 from '../../../assets/outsourcing/img/ic_alarm_baemin1.png';
import baemin from '../../../assets/utils/images/baemin_x2.png';
import baemin1 from '../../../assets/utils/images/baeminone_x2.png';
import yogiyo from '../../../assets/utils/images/yogiyo_x2.png';
import telephone from '../../../assets/utils/images/telephone_x2.png';
import coupangeats from '../../../assets/utils/images/coupangeats_x2.png';
import wmpo from '../../../assets/utils/images/wmpo_x2.png';
import specialdelivery from '../../../assets/utils/images/specialdelivery_x2.png';
import smartplace from '../../../assets/utils/images/smartplace_x2.png';
import ddangyo from '../../../assets/utils/images/ddangyo.svg';
import dot from '../../../assets/utils/images/3dot_x2.png';
import card from '../../../assets/utils/images/card_x2.png';
import cash from '../../../assets/utils/images/cash_x2.png';
import shop from '../../../assets/utils/images/shop_x2.svg';
import option_menu from '../../../assets/utils/images/option_menu_x2.png';
import single_menu from '../../../assets/utils/images/single_menu_x2.png';
import shop_menu from '../../../assets/utils/images/shop_menu_x2.png';
import re_delivery_menu from '../../../assets/utils/images/re_delivery_menu_x2.png';
import new_delivery_menu from '../../../assets/utils/images/new_delivery_menu_x2.png';
import missing_addr from '../../../assets/utils/images/missing_addr_x2.png';

const MobileGlobalStyle = createGlobalStyle`

  @font-face {
    font-family: "Pretendard";
    src: url('../../../assets/outsourcing/fonts/Pretendard-T.woff') format("woff");
    font-weight: 200;
    font-style: normal; }

  @font-face {
    font-family: "Pretendard";
    src: url('../../../assets/outsourcing/fonts/Pretendard-L.woff') format("woff");
    font-weight: 300;
    font-style: normal; }

  @font-face {
    font-family: "Pretendard";
    src: url('../../../assets/outsourcing/fonts/Pretendard-R.woff') format("woff");
    font-weight: 400;
    font-style: normal; }

  @font-face {
    font-family: "Pretendard";
    src: url('../../../assets/outsourcing/fonts/Pretendard-M.woff') format("woff");
    font-weight: 500;
    font-style: normal; }

  @font-face {
    font-family: "Pretendard";
    src: url('../../../assets/outsourcing/fonts/Pretendard-SB.woff') format("woff");
    font-weight: 600;
    font-style: normal; }

  @font-face {
    font-family: "Pretendard";
    src: url('../../../assets/outsourcing/fonts/Pretendard-B.woff') format("woff");
    font-weight: 700;
    font-style: normal; }

  @font-face {
    font-family: "Pretendard";
    src: url('../../../assets/outsourcing/fonts/Pretendard-EB.woff') format("woff");
    font-weight: 900;
    font-style: normal; }

  body.franchiseNotice,
  body.account-link,
  body.notice,
  body.tos,
  body.privacy,
  body.alarmSetting,
  body.mobile-alarm,
  body.mypage {
    background-color: #f4f6f8;
    line-height: 1.2;

    @media (max-width: 1024px) {
      html.m-scroll-lock, body.m-scroll-lock {
        overflow: hidden;
        touch-action: none;
        /* 이외의 브라우저 */
        overscroll-behavior: none; }
      html.m-scroll-lock .container, body.m-scroll-lock .container-out {
        touch-action: none;
        /* 이외의 브라우저 */
        overscroll-behavior: none; } }

    &.scrollHidden {
      overflow: hidden;
      touch-action: none;
      /* 이외의 브라우저 */
      overscroll-behavior: none;

      .container-out {
        touch-action: none;
        /* 이외의 브라우저 */
        overscroll-behavior: none;
      }

      * {
        //touch-action: none !important;
      }
    }

    .inner {
      margin: 0 auto;
      padding: 0 16px;
    }

    .overlay:before {
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      background: rgba(0, 0, 0, 0.45);
      position: fixed;
      z-index: 500;
    }

    .container-out {
      position: relative;
      //padding-bottom: 280px;
      min-height: 100%;
      margin: 0 auto;
      overflow: hidden;
      max-width: 560px;
      padding-top: 64px;

      &.full {
        padding: 0;
      }

      &.bg-white {
        background-color: #fff;
      }
    }

    .web {
      display: block; }
    .web.web-inline {
      display: inline; }
    @media (max-width: 1024px) {
      .web {
        display: none; } }

    .mobile {
      display: none; }
    @media (max-width: 1024px) {
      .mobile {
        display: block; } }

    @media (max-width: 1024px) {
      .mobile-enter {
        display: block; } }

    header {
      background-color: #f4f6f8;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      max-width: 560px;
      margin: 0 auto;
      z-index: 500;

      &.type-white {
        border-bottom: 1px solid #dadddf;
        background-color: #fff;
      }

      &.type-gray-border {
        border-bottom: 1px solid #E5E5E5;
        background-color: #fff;
      }

      &.type-white-noborder {
        border: 0px;
        background-color: #fff;
      }

      &.type-purple {
        background-color: #edeffa;
      }
      
      .wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px 0;

        .header-txt {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 32px;
          font-weight: 800;
          font-size: 17px;
        }
        .back-btn {
          display: inline-block;
          position: absolute;
          left: 16px;
          top: 50%;
          transform: translateY(-50%);
          background: url(${icBack}) no-repeat center / cover;
          width: 32px;
          height: 32px;
        }

        .back-icon {
          position: absolute;
          left: 10px;
          top: 15px;
        }
      }
    }

    section.join {
      background-color: #fff;
      padding-bottom: 80px;

      .inner {
        padding: 0 32px;
      }

      .logo-box {
        padding: 32px 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 100%;
        }

        .logo-txt {
          padding-left: 10px;
          font-size: 12px;
          font-weight: 600;
          opacity: 0.3;
        }
      }

      .join-msg-box {
        text-align: center;
        padding: 77px 0 40px;

        .main-box {
          font-size: 22px;
          font-weight: 700;
        }

        .des-box {
          padding-top: 12px;
          opacity: 0.5;
          font-size: 13.5px;
          line-height: 20px;
          font-weight: 500;
          word-break: keep-all;
        }
      }

      .join-list-box {
        .join-item-box {
          & + .join-item-box {
            padding-top: 24px;
            margin-top: 24px;
            border-top: 1px solid rgba(218, 221, 223, 0.5);
          }

          .subject-box {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 14px;

            .subject-txt {
              font-size: 16px;
              line-height: 19px;
              color: #4b5056;
              font-weight: 700;
            }

            .label-txt {
              border-radius: 4px;
              width: 26px;
              height: 17px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              font-size: 10px;
              font-weight: 600;
              margin-left: 6px;

              &.type-1 {
                border: 1px solid rgba(77, 82, 255, 0.3);
                color: #787bf3;
              }
              &.type-2 {
                border: 1px solid rgba(218, 39, 39, 0.3);
                color: #d55151;
              }
            }
          }

          .con-box {
            a.select-btn-box {
              width: 100%;
              padding: 11px 40px 11px 16px;
              position: relative;

              background: url(${icJoinChkOff}) no-repeat right 16px center;

              &:after {
                content: '';
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                position: absolute;
                border: 1px solid #d0d5d9;
                border-radius: 12px;
              }

              &.on {
                background: url(${icJoinChkOn}) no-repeat right 16px center;

                &:after {
                  border: 2px solid #686cff;
                }
              }

              .txt-box {
                font-size: 14px;
                line-height: 16px;
                font-weight: 600;
                color: #4b5056;
              }

              .list-box {
                .row-out {
                  color: #4b5056;
                  font-size: 14px;
                  line-height: 20px;
                  font-weight: 600;

                  .blur {
                    color: #cacbcd;
                  }
                }
              }
            }
          }
        }
      }
    }

    section.my {
      padding: 8px 0;

      .my-form-list {
        .my-form-box {
          & + .my-form-box {
            margin-top: 24px;
          }

          .my-form-head {
            padding-bottom: 12px;
            padding-left: 8px;

            .form-head-txt {
              color: #6c757d;
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              opacity: 0.8;
            }
          }

          .my-form-body {
            padding: 20px;
            background: #ffffff;
            border-radius: 16px;
          }
        }
      }
    }

    article.my-reg-form {
      .row-out {
        padding: 16px 0;
        display: flex;
        align-items: center;

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
        }

        & + .row-out {
          border-top: 1px solid rgba(218, 221, 223, 0.5);
        }

        .subject-box {
          display: inline-flex;
          min-width: 73px;
          color: #6c757d;
          font-size: 14px;
          line-height: 17px;
          font-weight: 500;

          & + .con-box {
            padding-left: 16px;
          }
        }
        .con-box {
          display: flex;
          align-items: center;
          position: relative;
          width: 100%;

          .con-card-box{
            display: flex;
          }

          .con-txt-card{
            opacity: 0.3;
            font-size: 12px;
            margin-left: 8px;
            margin-top: 0px;
          }

          .ic-chat {
            background: url(${icChat}) no-repeat center / cover;
            width: 18px;
            height: 18px;
            margin-right: 8px;
          }

          .ic-notice {
            background: url(${icNotice}) no-repeat center / cover;
            width: 18px;
            height: 18px;
            margin-right: 8px;
          }

          .con-txt {
            font-size: 14px;
            line-height: 17px;
            font-weight: 500;
          }

          .more-btn {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background: url(${icLinkArrow}) no-repeat center / cover;
            width: 24px;
            height: 25px;
          }
        }

        .no-reg-box {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0.3;

          .ic-alarm {
            background: url(${icAlarm}) no-repeat center / cover;
            width: 20px;
            height: 20px;
            margin-right: 3px;
          }

          .txt {
            color: #3c3f43;
            font-size: 14px;
            line-height: 16px;
            font-weight: 500;
          }
        }

        .reg-btn-box {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;

          .reg-btn {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color: #e15252;
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;

            &.type-1 {
              opacity: 0.3;
              color: #3c3f43;
              font-size: 14px;
              line-height: 17px;
              font-weight: 500;
            }
          }
        }
      }
    }

    article.my-current-step {
      .tit-box {
        position: relative;
        margin-bottom: 16px !important;

        .tit-txt {
          color : rgba(60, 63, 67, 1);
          font-size: 14px;
          line-height: 17px;
          font-weight: 500;
        }

        .more-btn {
          position: absolute;
          right: 4px;
          top: 50%;
          transform: translateY(-50%);
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background: url(${icLinkArrow}) no-repeat center / cover;
          width: 24px;
          height: 25px;
        }
      }

      .current-step {
        margin-top: 20px;
        padding: 0 6px;

        .list-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          overflow: hidden;

          .bar {
            flex: 1;
            height: 2px;
            background-color: #c4c4c4;
            margin: 0 -6px;
          }

          .dot-box {
            font-size: 0;
            position: relative;

            &.on {
              & + .bar {
                background-color: #515ef6;
              }

              .dot {
                &:after {
                  border: 2px solid #515ef6;
                  background-color: #fff;
                }
              }
            }

            &.current {
              .dot {
                background-color: #515ef6;
                width: 24px;
                height: 24px;

                &:after {
                  z-index: -1;
                  background-color: #515ef6;
                }
              }
            }

            &:first-child {
              &:after {
                left: 0;
              }
            }

            .dot {
              position: relative;
              z-index: 20;
              width: 24px;
              height: 24px;
              border-radius: 50%;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              color: #fff;
              font-size: 14px;
              font-weight: 700;

              &:after {
                content: '';
                position: absolute;
                left: 50%;
                top: 50%;
                border-radius: 50%;
                transform: translate(-50%, -50%);
                width: 12px;
                height: 12px;
                background-color: #c4c4c4;
              }
            }
          }
        }
      }
    }

    section.pay-info {
      padding: 17px 0;

      .pay-current-box {
        background: #a2a8b8;
        border-radius: 16px;
        padding: 48px 20px 40px;
        position: relative;

        .edit-btn {
          position: absolute;
          right: 12px;
          top: 12px;
          background: url(${icEdit}) no-repeat center / cover;
          width: 24px;
          height: 24px;
        }

        .num-box {
          text-align: center;
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
          color: #fefefe;
        }

        .card-box {
          text-align: center;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          opacity: 0.4;
          color: #fefefe;
        }
      }

      .pay-history-list {
        .history-item-box {
          background-color: #fff;
          padding: 20px;
          margin-top: 16px;
          border-radius: 16px;

          .head-box {
            padding-bottom: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .date-box {
              font-size: 15px;
              line-height: 18px;
              font-weight: 700;
            }

            .state-box {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              padding: 0 4px;
              height: 17px;
              border-radius: 4px;
              font-size: 10px;
              line-height: 12px;

              &.type-1 {
                color: #787bf3;
                border: 1px solid rgba(77, 82, 255, 0.3);
              }

              &.type-2 {
                border: 1px solid rgba(218, 39, 39, 0.3);
                color: #da2727;
              }
            }
          }

          .body-box {
            .row-out {
              display: flex;
              align-items: center;

              & + .row-out {
                margin-top: 16px;
              }

              .subject-box {
                min-width: 73px;
                color: #6c757d;
                font-size: 14px;
                line-height: 16px;
                font-weight: 500;
                opacity: 0.8;
              }

              .con-box {
                padding-left: 16px;
                width: 100%;
                font-size: 14px;
                line-height: 17px;

                .accent {
                  color: #da2727;
                  font-weight: 700;
                }
              }

              .alarm-txt-box {
                display: flex;
                align-items: center;

                .ic-alarm {
                  margin-right: 3px;
                  background: url(${icAlarm2}) no-repeat center / cover;
                  width: 20px;
                  height: 20px;
                }

                .txt {
                  color: #e15252;
                  font-size: 14px;
                  line-height: 16px;
                  font-weight: 500;
                }
              }
            }
          }

          .foot-box {
            margin-top: 16px;
            padding-top: 16px;
            border-top: 1px solid #dadddf;

            .foot-btn {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 41px;
              border-radius: 8px;
              background-color: #686cff;
              color: #fff;
              font-weight: 700;
              font-size: 14px;
            }
          }
        }
      }
    }

    section.pay {
      padding-top: 174px;

      .pay-item-list {
        .pay-item-box {
          & + .pay-item-box {
            margin-top: 30px;
          }

          label {
            cursor: pointer;

            input {
              position: absolute;
              left: -9999px;

              &:checked {
                & ~ .txt {
                  &:after {
                    border: 2px solid #4d5eff;
                  }

                  &:before {
                    background: url(${icJoinChkOn}) no-repeat center / cover;
                  }
                }
              }
            }

            .txt {
              position: relative;
              height: 52px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 700;
              font-size: 15px;

              &:before {
                content: '';
                position: absolute;
                right: 18px;
                top: 50%;
                transform: translateY(-50%);
                background: url(${icJoinChkOff}) no-repeat center / cover;
                width: 24px;
                height: 24px;
              }

              &:after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                border-radius: 16px;
                border: 1px solid #232323;
              }
            }
          }

          .option-box {
            text-align: center;
            padding-top: 17px;
            color: #a4a7ab;
            font-weight: 600;
            font-size: 13px;
          }
        }
      }

      .pay-menu-box {
      }
    }

    section.card {
      .inner {
        padding: 32px;
      }

      .card-form-box {
        .row-out {
          margin-top: 24px;

          .check-input-box {
            padding: 0 16px;
            display: flex;
            align-items: center;
            background: rgba(218, 221, 224, 0.3) url(${icJoinChkOff}) no-repeat center right 16px;
            border: 1px solid #d0d5d9;
            border-radius: 12px;
            height: 40px;

            .txt {
              color: #4b5056;
              opacity: 0.2;
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;
            }

            .ess {
              margin-left: 4px;
              color: #d55151;
              font-size: 14px;
              line-height: 17px;
            }
          }

          .card-msg-box {
            color: #d55151;
            font-size: 14px;
            line-height: 17px;
            font-weight: 600;
            opacity: 0.5;
            padding: 8px 8px 0;
          }

          .card-info-box {
            display: flex;
            align-items: flex-start;
            width: 100%;

            .info-box {
              width: 100%;
              position: relative;

              & + .info-box {
                margin-left: 8px;
              }
            }

            .tbox {
              position: relative;
              overflow: hidden;
              height: 40px;
              width: 100%;
            }
          }

          .card-input-box {
            display: flex;
            align-items: center;
            width: 100%;
            position: relative;
            overflow: hidden;
            padding: 0 16px;
            height: 40px;
            box-shadow: inset 0 0 0 1px #d0d5d9;
            border-radius: 12px;

            > div {
              display: none !important;

              &:nth-child(1) {
                display: block !important;
              }
            }

            &.success {
              box-shadow: inset 0 0 0 1px #d0d5d9 !important;
              background: url(${icJoinChkOn}) no-repeat center right 16px;

              .input-box {
                position: relative;
                &:last-of-type {
                  &:before {
                    content: '*';
                    position: absolute;
                    right: -5px;
                    top: 50%;
                    transform: translateY(-50%);
                    color: #d55151;
                    font-size: 14px;
                    line-height: 17px;
                    font-weight: 700;
                  }
                }
              }

              .clear-btn {
                display: none;
              }

              input {
                caret-color: initial;
              }
            }

            &.error {
              box-shadow: inset 0 0 0 1px #d55151 !important;
              background: url(${icError}) no-repeat center right 16px;

              .input-box {
                position: relative;
                &:last-of-type {
                  &:before {
                    content: '*';
                    position: absolute;
                    right: -5px;
                    top: 50%;
                    transform: translateY(-50%);
                    color: #d55151;
                    font-size: 14px;
                    line-height: 17px;
                    font-weight: 700;
                  }
                }
              }

              .clear-btn {
                display: none;
              }

              input {
                caret-color: #d55151;
              }
            }

            &.on {
              box-shadow: inset 0 0 0 2px #686cff;
              padding-right: 46px;

              > div {
                display: inline-flex !important;
              }

              .input-box {
                input {
                  text-align: center;
                }
              }

              .clear-btn {
                position: absolute;
                right: 16px;
                top: 50%;
                transform: translateY(-50%);
                width: 16px;
                height: 16px;
                background: url(${icClear}) no-repeat center / cover;
              }
            }

            .input-box {
              display: inline-flex;
              width: 100%;
              height: 100%;

              input {
                background: transparent;
                width: 100%;
                height: 100%;
                outline: none;
                color: #4b5056;
                //letter-spacing: 0.2em;
                font-size: 14px;
                line-height: 17px;
                font-weight: 600;

                &::placeholder {
                  color: rgba(75, 80, 86, 0.2);
                }
              }
            }

            .unit-box {
              display: none;
              margin: 0 3px;
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;
            }
          }
        }
      }

      .card-agree-box {
        padding-top: 24px;

        .row-out {
          & + .row-out {
            margin-top: 12px;
          }

          label {
            position: relative;
            display: inline-flex;
            align-items: center;

            input {
              position: absolute;
              left: -9999px;

              &:checked {
                & ~ i {
                  background: url(${icCboxOn}) no-repeat center;
                }
              }
            }

            i {
              background: url(${icCboxOff}) no-repeat center;
              position: absolute;
              left: 0;
              top: 1px;
              width: 20px;
              height: 20px;
            }

            .txt {
              padding-left: 26px;
              line-height: 20px;
              color: #4b5056;

              b {
                color: #505ef6;
                font-weight: 600;
              }

              .view-btn {
                color: #4b5056;
                opacity: 0.7;
                font-size: 12px;
                line-height: 20px;
                text-decoration: underline;
                text-underline-position: under;
                position: relative;
                top: -2px;
                margin-left: 2px;
              }
            }
          }
        }
      }
    }

    article.card-tabs {
      ul.tabs-menu-list {
        display: flex;
        align-items: center;

        li.tabs-menu {
          width: 100%;
          margin-left: -1px;
          display: inline-flex;

          a.tabs-menu-btn {
            width: 100%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            border-radius: 12px 12px 0px 0px;
            border: 1px solid #d0d5d9;
            color: rgba(75, 80, 86, 0.2);

            &.on {
              border: 0;
              background: #686cff;
              color: #fefefe;
            }
          }
        }
      }
    }

    section.alarm {
      .alarm-tabs-list {
        display: flex;

        .tabs-btn-box {
          display: inline-flex;
          width: 100%;

          .tabs-btn {
            padding-top: 3px;
            height: 37px;
            display: inline-flex;
            justify-content: center;
            width: 100%;
            padding-top: 4px;
            border-bottom: 1px solid #e5e5e5;
            font-weight: 700;
            
            &.on {
              border-bottom: 1px solid #4d52ff;

              .txt {
                color: #232323;
              }
            }

            .txt {
              font-size: 14px;
              line-height: 17px;
              position: relative;
              color: rgba(35, 35, 35, 0.4);

              &.new {
                &:after {
                  content: '';
                  position: absolute;
                  right: -8px;
                  top: -1px;
                  width: 5px;
                  height: 5px;
                  background-color: #da2727;
                  border-radius: 50%;
                }
              }
            }
          }
        }
      }

      .alarm-item-list {
        .row-out {
          display: flex;
          align-items: center;
          padding: 12px 20px;
          border-bottom: 1px solid #EDEEEF;;

          &.type-read {
            background: #eaedf2;
            opacity: 0.4;

            .img-box {
              background-color: #eaedf2;
            }
          }

          .img-box {
            margin-right: 12px;
            width: 40px;
            height: 40px;
            background: #ffffff;
            //border: 1px solid rgba(0, 0, 0, 0.1);
            //border-radius: 10px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            //border-radius: 12px;

            .ic {
              width: 40px;
              height: 40px;
            }
            .iconBaemin{
              background: url(${baemin}) no-repeat center / cover;
              background-size: contain;
              width: 20px;
              height: 20.5px;
            }
            .iconBaeminOne{
              background: url(${baemin1}) no-repeat center / cover;
              background-size: contain;
              width: 31px;
              height: 25px;
            }
            .iconYogiyo{
              background: url(${yogiyo}) no-repeat center / cover;
              background-size: contain;
              width: 26px;
              height: 28px;
            }
            .iconTelepone{
              background: url(${telephone}) no-repeat center / cover;
              background-size: contain;
              width: 20px;
              height: 18px;
            }
            .iconCoupang{
              background: url(${coupangeats}) no-repeat center / cover;
              background-size: contain;
              width: 27px;
              height: 30px;
            }
            .iconWmpo{
              background: url(${wmpo}) no-repeat center / cover;
              background-size: contain;
              width: 26px;
              height: 28px;
            }
            .iconSpecialDelivery{
              background: url(${specialdelivery}) no-repeat center / cover;
              background-size: contain;
              width: 26px;
              height: 28px;
            }
            .iconSmartPlace{
              background: url(${smartplace}) no-repeat center / cover;
              background-size: contain;
              width: 26px;
              height: 28px;
            }
            .icon3dot{
              background: url(${dot}) no-repeat center / cover;
              background-size: contain;
              width: 26px;
              height: 28px;
            }
            .iconCard{
              background: url(${card}) no-repeat center / cover;
              background-size: contain;
              width: 26px;
              height: 28px;
            }
            .iconCash{
              background: url(${cash}) no-repeat center / cover;
              background-size: contain;
              width: 26px;
              height: 28px;
            }
            .iconShop{
              background: url(${shop}) no-repeat center / cover;
              background-size: contain;
              width: 20px;
              height: 18px;
            }
            .iconddangyo{
              background: url(${ddangyo}) no-repeat center / cover;
              background-size: contain;
              width: 26px;
              height: 28px;
            }

            .iconOptionMenu{
              background: url(${option_menu}) no-repeat center / cover;
              background-size: contain;
              width: 26px;
              height: 28px;
            }
            .iconSingleMenu{
              background: url(${single_menu}) no-repeat center / cover;
              background-size: contain;
              width: 26px;
              height: 28px;
            }.iconShopMenu{
              background: url(${shop_menu}) no-repeat center / cover;
              background-size: contain;
              width: 26px;
              height: 28px;
            }
            .iconDeliveryMenu{
              background: url(${re_delivery_menu}) no-repeat center / cover;
              background-size: contain;
              width: 26px;
              height: 28px;
            }
            .iconNewDeliveryMenu{
              background: url(${new_delivery_menu}) no-repeat center / cover;
              background-size: contain;
              width: 26px;
              height: 28px;
            }
            .iconMissingAddr{
              background: url(${missing_addr}) no-repeat center / cover;
              background-size: contain;
              width: 26px;
              height: 28px;
            }


            .ic-hall {
              background: url(${icHall}) no-repeat center / cover;
            }
            .ic-coupang {
              background: url(${icCoupang}) no-repeat center / cover;
            }

            .ic-baemin {
              background: url(${icBaemin}) no-repeat center / cover;
            }

            .ic-yogiyo {
              background: url(${icYogiyo}) no-repeat center / cover;
            }

            .ic-baemin1 {
              background: url(${icBaemin1}) no-repeat center / cover;
            }
          }

          .con-box {
            flex: 1;
            display: block;

            .con-txt {
              display: inline;
              font-weight: 500;
              font-size: 15px;
              line-height: 24px;

              b {
                font-weight: 500;
                color: #4d52ff;
              }
            }

            .sub-txt {
              word-break: keep-all;
              display: inline;
              padding-left: 4px;
              color: rgba(38, 38, 38, 0.3);
              font-size: 13px;
              line-height: 24px;
              font-weight: 500;
            }
          }

          .date-box {
            margin-left: 16px;
            font-size: 12px;
            font-weight: 500;
            color: #262626;
            opacity: 0.2;
          }
        }
      }
      .alarm-refresh {
        height: 100%;
      }
    }
  }

  body.mobile-alarm {
    #container {
      background-color: #FFFFFF;
      margin-top: 108px !important;
      
      .container-out {
        padding-top: 0 !important;
      }
    }

    &.none-tab {
      .header-box {
        border-bottom: 1px solid #e5e5e5 !important;
      }
    }
  }
  
  @media (max-width: 767px) {
    body.mypage {
      .app-container {
        background-color: #F4F6F8;

        .container-out {
          padding-top: 65px;
        }
      }
    }
  }
`;

export default MobileGlobalStyle;
