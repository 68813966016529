import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import IMAGES from '../../../assets/utils/images';

const ATCheckBox = props => {
  const {
    inputName = 'value',
    inputDefaultValue = false,
    getValue = () => {},
    labelText = '',
    checkIcon = [IMAGES.iconCheckCircleDefault, IMAGES.iconCheckCircleActive], // default, active
    checkWidth = '25px',
    contents,
  } = props;

  const [value, setValue] = useState(inputDefaultValue);

  useEffect(() => {
    setValue(inputDefaultValue);
  }, [inputDefaultValue]);

  return (
    <Wrapper checkIcon={checkIcon} checkWidth={checkWidth}>
      {labelText}
      {contents}
      <input
        type="checkbox"
        name={inputName}
        checked={value}
        onChange={e => {
          setValue(e.target.checked);
          getValue(e.target.checked);
        }}
      />
      <span className="checkmark"></span>
    </Wrapper>
  );
};

const Wrapper = styled.label`
    /* Customize the label (the container) */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    /* display: ${props => (props.layoutHorizontal ? 'inline-flex' : 'flex')}; */
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    padding-left: ${props => `calc(${props.checkWidth} + 5px)`};
    line-height: ${props => props.checkWidth}; // ${props => `calc(${props.checkWidth} + 2px)`};
    font-size: 13px;
    font-weight: 700;
    cursor: pointer;
    &:hover input ~ .checkmark {
      /* On mouse-over, add a grey background color */
      /* background-color: #ccc; */
    }
    input {
      /* Hide the browser's default checkbox */
      opacity: 0;
      position: absolute;
      height: 0;
      width: 0;
      cursor: pointer;
      &:checked ~ .checkmark {
        /* When the checkbox is checked, add a blue background */
        /* background-color: #2196f3; */
      }
      &:checked ~ .checkmark:after {
        /* Show the checkmark when checked */
        display: flex;
      }
    }
    .checkmark {
      /* Create a custom checkbox */
      position: absolute;
      top: 0;
      left: 0;
      width: ${props => props.checkWidth};
      height: ${props => props.checkWidth};
      background-image: url(${props => props.checkIcon[0]});
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;
      &:after {
        /* Create the checkmark/indicator (hidden when not checked) */
        display: none;
        content: '';
        position: absolute;
      }
      &:after {
        width: 100%;
        height: 100%;
        background-image: url(${props => props.checkIcon[1]});
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
      }
      /* &:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      } */
    }
`;

export default ATCheckBox;
