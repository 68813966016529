import { materialConstants } from '../constants';
import { convertForFindName } from '../helpers';

export function materialList(state = {}, action) {
  switch (action.type) {
    case materialConstants.MATERIAL_LIST_REQUEST:
      return {
        loading: true,
      };
    case materialConstants.MATERIAL_LIST_SUCCESS:
      return {
        materialList: action.result['result'],
        materialNames: convertForFindName(action.result['result'], 'sm_idx', 'material_name'),
      };
    case materialConstants.MATERIAL_LIST_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function useMaterialHeaderData(state = {}, action) {
  switch (action.type) {
    case materialConstants.USE_MATERIAL_HEADER_DATA_REQUEST:
      return {
        loading: true,
      };
    case materialConstants.USE_MATERIAL_HEADER_DATA_SUCCESS:
      return {
        useMaterialHeaderData: action.result['result'],
      };
    case materialConstants.USE_MATERIAL_HEADER_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function useMaterialListData(state = {}, action) {
  switch (action.type) {
    case materialConstants.USE_MATERIAL_LIST_DATA_RESET:
      return {
        useMaterialListData: null,
      };
    case materialConstants.USE_MATERIAL_LIST_DATA_REQUEST:
      return {
        loading: true,
      };
    case materialConstants.USE_MATERIAL_LIST_DATA_SUCCESS:
      return {
        useMaterialListData: {
          ...state.useMaterialListData,
          [action.result['limitStart']]: action.result['result'],
        },
        listData: action.result['result'],
        rowCount: action.result['row_count'],
        headerData: action.result['headerData'],
      };
    case materialConstants.USE_MATERIAL_LIST_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function useMaterialDateListData(state = {}, action) {
  switch (action.type) {
    case materialConstants.USE_MATERIAL_DATE_LIST_DATA_RESET:
      return {
        useMaterialDateListData: null,
      };
    case materialConstants.USE_MATERIAL_DATE_LIST_DATA_REQUEST:
      return {
        loading: true,
      };
    case materialConstants.USE_MATERIAL_DATE_LIST_DATA_SUCCESS:
      return {
        useMaterialDateListData: action.result['result'],
      };
    case materialConstants.USE_MATERIAL_DATE_LIST_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function useMaterialTotalData(state = {}, action) {
  switch (action.type) {
    case materialConstants.USE_MATERIAL_TOTAL_DATA_RESET:
      return {
        useMaterialTotalData: null,
      };
    case materialConstants.USE_MATERIAL_TOTAL_DATA_REQUEST:
      return {
        loading: true,
      };
    case materialConstants.USE_MATERIAL_TOTAL_DATA_SUCCESS:
      return {
        useMaterialTotalData: action.result['result'],
      };
    case materialConstants.USE_MATERIAL_TOTAL_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function oneMaterialOfficeHeaderData(state = {}, action) {
  switch (action.type) {
    case materialConstants.ONE_MATERIAL_OFFICE_HEADER_DATA_REQUEST:
      return {
        loading: true,
      };
    case materialConstants.ONE_MATERIAL_OFFICE_HEADER_DATA_SUCCESS:
      return {
        oneMaterialOfficeHeaderData: action.result['result'],
      };
    case materialConstants.ONE_MATERIAL_OFFICE_HEADER_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function oneMaterialOfficeListData(state = {}, action) {
  switch (action.type) {
    case materialConstants.ONE_MATERIAL_OFFICE_LIST_DATA_RESET:
      return {
        oneMaterialOfficeListData: {},
      };
    case materialConstants.ONE_MATERIAL_OFFICE_LIST_DATA_REQUEST:
      return {
        ...state,
      };
    case materialConstants.ONE_MATERIAL_OFFICE_LIST_DATA_SUCCESS:
      return {
        oneMaterialOfficeListData: {
          ...state.oneMaterialOfficeListData,
          [action.result['limitStart']]: action.result['result'],
        },
        rowCount: action.result['row_count'],
        headerData: action.result['headerData'],
      };
    case materialConstants.ONE_MATERIAL_OFFICE_LIST_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function oneMaterialDateListData(state = {}, action) {
  switch (action.type) {
    case materialConstants.ONE_MATERIAL_DATE_LIST_DATA_RESET:
      return {
        oneMaterialDateListData: null,
      };
    case materialConstants.ONE_MATERIAL_DATE_LIST_DATA_REQUEST:
      return {
        loading: true,
      };
    case materialConstants.ONE_MATERIAL_DATE_LIST_DATA_SUCCESS:
      return {
        oneMaterialDateListData: action.result['result'],
      };
    case materialConstants.ONE_MATERIAL_DATE_LIST_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function oneMaterialMenuHeaderData(state = {}, action) {
  switch (action.type) {
    case materialConstants.ONE_MATERIAL_MENU_HEADER_DATA_RESET:
      return {
        oneMaterialMenuHeaderData: {},
      };
    case materialConstants.ONE_MATERIAL_MENU_HEADER_DATA_REQUEST:
      return {
        loading: true,
      };
    case materialConstants.ONE_MATERIAL_MENU_HEADER_DATA_SUCCESS:
      return {
        oneMaterialMenuHeaderData: action.result['result'],
      };
    case materialConstants.ONE_MATERIAL_MENU_HEADER_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function oneMaterialMenuListData(state = {}, action) {
  switch (action.type) {
    case materialConstants.ONE_MATERIAL_MENU_LIST_DATA_RESET:
      return {
        oneMaterialMenuListData: null,
      };
    case materialConstants.ONE_MATERIAL_MENU_LIST_DATA_REQUEST:
      return {
        ...state,
      };
    case materialConstants.ONE_MATERIAL_MENU_LIST_DATA_SUCCESS:
      return {
        oneMaterialMenuListData: {
          ...state.oneMaterialMenuListData,
          [action.result['limitStart']]: action.result['result'],
        },
        rowCount: action.result['row_count'],
        headerData: action.result['headerData'],
      };
    case materialConstants.ONE_MATERIAL_MENU_LIST_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function useMaterialLately(state = {}, action) {
  switch (action.type) {
    case materialConstants.USE_MATERIAL_LATELY_RESET:
      return {
        useMaterialLately: null,
      };
    case materialConstants.USE_MATERIAL_LATELY_REQUEST:
      return {
        loading: true,
      };
    case materialConstants.USE_MATERIAL_LATELY_SUCCESS:
      return {
        useMaterialLately: action.result['result'],
      };
    case materialConstants.USE_MATERIAL_LATELY_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
