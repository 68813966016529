import React from 'react';
import SystemTooltip from '../../Tooltip/SystemTooltip';
import './datatableitems.scss';

const DataTableBadge = props => {
  const { isSiteFixed, isDeliveryFixed } = props;
  return (
    <div className="data-table-badge">
      {isSiteFixed === 1 && (
        <div className="pos tooltip-wrap">
          <SystemTooltip text="POS 미연동" />
        </div>
      )}
      {isDeliveryFixed === 1 && (
        <div className="delivery tooltip-wrap">
          <SystemTooltip text="배달계정 미연동" />
        </div>
      )}
    </div>
  );
};

export default DataTableBadge;
