import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { authActions, commonActions } from '../../../actions';
import { isSystemLink, replaceHypen, setHypenPhoneNum } from '../../../helpers';
import { REGIST_TYPE, pushSignupGAEventItem } from '../../../pages/Members/SignUp/Helper';
import {
  MOBILE_AUTH_ALREADY_REGIST,
  MOBILE_AUTH_FAIL,
  MOBILE_AUTH_MISMATCH,
  MOBILE_AUTH_NORMAL,
  MOBILE_AUTH_PROGRESS,
  MOBILE_AUTH_SUCCESS,
} from '../../../pages/UserPages/MobileAuth/Helper';
import { authService } from '../../../services';
import ATInput from './ATInput';
import { checkReactNativeWebView, setAppPopupPage } from '../../../helpers/AppWebView';

const ATMobileAuthValidInput = props => {
  const {
    getValue = () => {},
    onValid = () => {},
    defaultValue,
    registType,
    brandKey,
    isValidInvite = false,
    element = null,
    useResultAlert = true,
    isComplete = true,
  } = props;

  const dispatch = useDispatch();

  const { validMobileAuth } = useSelector(state => state.validMobileAuth);
  const { validPhoneNum } = useSelector(state => state.validPhoneNum); // 중복 확인
  const [mobileAuthState, setMobileAuthState] = useState(MOBILE_AUTH_NORMAL);
  const [value, setValue] = useState({
    userName: '',
    phoneNumber: '',
  });

  useEffect(() => {
    // 앱에서 본인인증 작동이 안되는 이슈로인해 앱으로부터 메세지를 전달 받음
    window.addEventListener('message', onMessage);
    document.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
      document.removeEventListener('message', onMessage);
    };
  }, []);

  const onMessage = event => {
    if (!event || !event['data']) {
      return;
    }

    let message = JSON.parse(event['data']);

    if (message['type'] !== 'authResponse') {
      return;
    }

    ProcessChildMessage(message['data']);
  };

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (validMobileAuth) {
      // NICE 모바일 인증 단계 성공
      if (validMobileAuth.result) {
        // 초대받은 휴대전화번호와 일치하지 않은 경우
        if (
          defaultValue &&
          defaultValue.phoneNumber &&
          defaultValue.phoneNumber != '' &&
          replaceHypen(validMobileAuth.result.phoneNumber) != replaceHypen(defaultValue.phoneNumber)
        ) {
          setMobileAuthState(MOBILE_AUTH_MISMATCH);
        } else {
          // 기존에 있는 번호인지 확인후에 set Success
          dispatch(authActions.validPhoneNum(validMobileAuth.result.phoneNumber, 2));

          // 프랜차이즈 점주로 초대받은 사용자인지 체크 후 리다이렉션
          isValidInvite && handleCheckInvite(validMobileAuth.result.phoneNumber);
        }
      } else {
        setMobileAuthState(MOBILE_AUTH_FAIL);
        // TODO: error handling 필요
      }
    }
  }, [validMobileAuth]);

  // 기존 가입한 유저 체크
  // 기존에 있는 번호인지 확인후에 set Success
  useEffect(() => {
    if (validPhoneNum) {
      if (validPhoneNum.result === 0) {
        setMobileAuthState(MOBILE_AUTH_SUCCESS);
      }

      // 이미 가입되어 있는 경우
      if (validPhoneNum.result === 1) {
        setMobileAuthState(MOBILE_AUTH_ALREADY_REGIST);
      }
    }
  }, [validPhoneNum]);

  // status 에 따른 action들
  useEffect(() => {
    handleMobileAuthGAEvent(mobileAuthState);

    if (mobileAuthState === MOBILE_AUTH_ALREADY_REGIST) {
      alertingMsg(
        <>
          이미 등록된 휴대전화번호가 있습니다.
          <br />
          가입 여부를 확인해주세요.
        </>,
        'goMain',
      );
      return;
    }
    if (mobileAuthState === MOBILE_AUTH_MISMATCH) {
      alertingMsg('초대받은 휴대전화번호와 일치하지 않습니다.');
      return;
    }
    if (mobileAuthState === MOBILE_AUTH_FAIL) {
      alertingMsg('본인인증에 실패했습니다.');
      return;
    }

    if (mobileAuthState !== MOBILE_AUTH_SUCCESS) {
      return;
    }

    setValue(validMobileAuth.result);
    getValue({
      mobileAuthState: mobileAuthState,
      userName: validMobileAuth.result.userName,
      phoneNumber: setHypenPhoneNum(validMobileAuth.result.phoneNumber),
    });

    onValid(true);

    alertingMsg('본인인증이 완료되었습니다.');
  }, [mobileAuthState]);

  const alertingMsg = (msg, type) => {
    if (useResultAlert === false) {
      getValue({
        mobileAuthState: mobileAuthState,
        userName: validMobileAuth.result.userName,
        phoneNumber: setHypenPhoneNum(validMobileAuth.result.phoneNumber),
      });
      return;
    }

    if (registType === REGIST_TYPE.selfBiz && mobileAuthState === MOBILE_AUTH_SUCCESS) {
      return;
    }

    let moveAction = type === 'goMain' ? 'goMain' : null;
    dispatch(commonActions.alertMessage(msg, null, null, null, moveAction));
  };

  const onClick = () => {
    setMobileAuthState(MOBILE_AUTH_PROGRESS);
    handleValidMobileAuth();
  };

  // 모바일 본인 인증창 open
  const handleValidMobileAuth = async () => {
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    const returnUrl = `${baseUrl}/auth/getNiceDecrypt`;
    const errorUrl = `${baseUrl}/#/auth/mobileAuth/error`;
    const blankUrl = `${baseUrl}/#/back/blank`;

    if (checkReactNativeWebView()) {
      setAppPopupPage(blankUrl);
      return;
    }

    window.name = 'child_window';
    window.open(
      blankUrl,
      'popupChk',
      'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no',
    );

    const niceEncrypt = await authService.getNiceEncrypt(returnUrl, errorUrl);

    if (niceEncrypt && niceEncrypt['result']) {
      window.ProcessChildMessage = ProcessChildMessage;

      let sForm = document.getElementsByName('formNiceSubmit')[0];
      sForm.action = 'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb';
      sForm.target = 'popupChk';
      sForm.EncodeData.value = niceEncrypt['result'];

      sForm.submit();
    }
  };

  // 본인인증 창 종료시 호출하는 이벤트 function
  const ProcessChildMessage = data => {
    // data exist 성공, data empty 실패
    if (data) {
      // NICE 본인인증 성공 & 기존 가입인지, 전화번호 일치하는지 체크
      dispatch(authActions.validMobileAuth(data));
    } else {
      setMobileAuthState(MOBILE_AUTH_FAIL);
    }
  };

  // status 별 GA 이벤트 관리
  const handleMobileAuthGAEvent = status => {
    switch (status) {
      case MOBILE_AUTH_SUCCESS:
        pushSignupGAEventItem({
          action: '본인인증 성공',
          registType: registType,
          brandKey: brandKey,
          userInfo: validMobileAuth.result,
        });
        break;
      case MOBILE_AUTH_FAIL:
        const gaEventItem = {
          action: '본인인증 실패',
          registType: registType,
          brandKey: brandKey,
        };

        // 초대 가입의 경우 인증 실패해도 번호를 알 수 있음
        // checkSignUpLinkClick API
        if (defaultValue.phoneNumber) {
          gaEventItem['userInfo'] = {
            phoneNumber: defaultValue.phoneNumber,
            userName: '',
          };
        }

        pushSignupGAEventItem(gaEventItem);
        break;
      case MOBILE_AUTH_ALREADY_REGIST:
        pushSignupGAEventItem({
          action: '본인인증 가입 중복',
          registType: registType,
          brandKey: brandKey,
          userInfo: validMobileAuth.result,
        });
        break;
      case MOBILE_AUTH_MISMATCH:
        pushSignupGAEventItem({
          action: '본인인증 초대번호와 불일치',
          registType: registType,
          brandKey: brandKey,
          userInfo: validMobileAuth.result,
        });
        break;
      default:
        break;
    }
  };

  async function handleCheckInvite(value) {
    let validInvite = await authService.checkInvitedPhoneNum(value);
    if (validInvite && validInvite['result'] && validInvite['result']['isRedirect']) {
      let result = validInvite['result'];
      alert(`[${result['brandName']}]에서 초대받은 사용자입니다. 초대받은 링크로 이동합니다.`);

      window.open(result['inviteURL'], '_self');
      window.location.reload();
    }
  }

  return (
    <Wrapper>
      {mobileAuthState == MOBILE_AUTH_SUCCESS && isSystemLink() === false && (
        <ATInput
          name="userName"
          type="text"
          dataText={'사용자 이름'}
          className={'inputwrap is-disabled'}
          required
          readonly
          maxLength="20"
          defaultValue={value.userName}
        />
      )}
      {mobileAuthState == MOBILE_AUTH_SUCCESS && isSystemLink() === false && (
        <div className="mt24">
          <ATInput
            name="phoneNumber"
            type="text"
            dataText={'휴대전화번호'}
            className={`inputwrap is-disabled`}
            required
            readonly
            style={{ width: '100%' }}
            defaultValue={setHypenPhoneNum(value.phoneNumber)}
            formatter={v => setHypenPhoneNum(v)}
          />
        </div>
      )}

      <form name="formNiceSubmit" method="post">
        <input type="hidden" name="m" value="checkplusService" />
        <input type="hidden" name="EncodeData" value="" />
      </form>

      {mobileAuthState != MOBILE_AUTH_SUCCESS && isSystemLink() === false && registType !== REGIST_TYPE.selfBiz && (
        <div className="btn-action-wrapper">
          <button className={classNames('btn-action btn-block')} onClick={onClick}>
            본인인증
          </button>
        </div>
      )}

      {mobileAuthState != MOBILE_AUTH_SUCCESS && isSystemLink() === false && registType === REGIST_TYPE.selfBiz && (
        <NextButton onClick={onClick}>
          <span className="next-txt">시작하기</span>
          <i className="ic ic-next"></i>
        </NextButton>
      )}

      {isSystemLink() === true && !element && <div onClick={onClick}>본인인증 후 시작하기</div>}

      {isSystemLink() === true && element && <div onClick={isComplete && onClick}>{element}</div>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;

  .inputwrap {
    input[type='text'],
    input[type='email'],
    input[type='password'] {
      width: 100%;
      border-radius: 12px;
      height: 40px;
      line-height: 1;
      background: transparent;
      border: 1px solid #d0d5d9;
      padding: 0 0 0 16px;
      font-size: 14px;
      box-sizing: border-box;
      &:valid {
        ~ .input-place {
          display: none;
        }
      }
      &:focus {
        border: 2px solid #686cff;
        box-shadow: none;
        ~ .input-place {
          color: #dbdcdd;
        }
        &::placeholder {
          color: #dbdcdd;
        }
      }
      &:first-child {
        margin-top: 0;
      }
      &::placeholder {
        color: #4b5056;
        font-size: 14px;
        font-family: 'pre';
      }
    }
    input[type='file'] {
      position: absolute;
      opacity: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  .input-auth-wrap {
    margin-top: 12px;
  }

  .input-valid-msg {
    margin-top: 6px;
    margin-left: 6px;
    font-size: 12px;
    font-size: 14px;
    color: #eaa8a8;
  }

  .btn-action {
    padding: 0 12px;
    word-break: keep-all;
    background: #9598ff;
    border-radius: 12px;
    color: white;
    line-height: 40px;
    height: 40px;
    border: none;
    &.is-disabled {
      background: #dee2e4;
      pointer-events: none;
    }
  }
`;

const NextButton = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  width: calc(100% - 48px);
  max-width: 375px;
  padding: 20px 24px;
  text-align: center;
  color: #fff !important;
  background: #686cff;
  font-size: 16px;
  font-weight: 700;
  margin: 44px auto 24px;
  cursor: pointer !important;
`;

export default ATMobileAuthValidInput;
