import { paymentConstants } from '../../../constants/Payment';
export const titleLists = ['7월', '08월', '09월', '10월', '11월', '12월'];
const date = new Date();
export const storeList = [
  {
    storeId: '202208030001', // pk
    storeName: '강남점', // pk
    ownerName: '김영철',
    registerDt: '2022 06 01', //date.getMonth() - 3, -- 5 월부터
    lastpayDt: '2022 06 01',
    stoppedDt: '2022 08 01',
    payState: false,
    unpaidDt: '2022 07 01',
    unpaidPrice: 66000,
    paymentMethod: '점주',
    payMethodId: '20220911002',
    cardCompany: '국민카드',
    cardNum: '4222 2222 2222 2222',
    pw: '55',
    expdt: '0927',
    createDt: '20220803',
    serviceName: '배이직 / 배달분석',
    paybillList: [
      {
        price: 22000,
        cardCompany: '국민카드',
        cardNum: '4222 2222 2222 2222',
        payDt: '2022 06 01',
        createDt: '2022 06 01',
        state: '완납',
        serviceName: '배이직 / 배달분석',
      },
      {
        price: 22000,
        cardCompany: '',
        cardNum: '',
        payDt: '',
        createDt: '2022 07 01',
        state: '미납',
        serviceName: '배이직 / 배달분석',
      },
      {
        price: 22000,
        cardCompany: '',
        cardNum: '',
        payDt: '',
        createDt: '2022 08 01',
        state: '중지',
        serviceName: '배이직 / 배달분석',
      },
      {
        price: 22000,
        cardCompany: '',
        cardNum: '',
        payDt: '',
        createDt: '2022 09 01',
        state: '중지',
        serviceName: '배이직 / 배달분석',
      },
    ],
  },
  {
    storeId: '202208030001', // pk
    storeName: '경남점', // pk
    ownerName: '강호동',
    registerDt: '2022 06 01', //date.getMonth() - 3, -- 5 월부터
    lastpayDt: '2022 08 01',
    stoppedDt: undefined,
    payState: true,
    unpaidDt: '2022 09 01',
    unpaidPrice: 0,
    paymentMethod: '점주',
    payMethodId: '20220911002',
    cardCompany: '국민카드',
    cardNum: '4222 2222 2222 2222',
    pw: '55',
    expdt: '0927',
    createDt: '20220803',
    serviceName: '배이직 / 배달분석',
    paybillList: [
      {
        price: 22000,
        cardCompany: '국민카드',
        cardNum: '4222 2222 2222 2222',
        payDt: '2022 07 01',
        createDt: '2022 07 01',
        state: '완납',
        serviceName: '배이직 / 배달분석',
      },
      {
        price: 22000,
        cardCompany: '국민카드',
        cardNum: '4222 2222 2222 2222',
        payDt: '2022 08 01',
        createDt: '2022 08 01',
        state: '완납',
        serviceName: '배이직 / 배달분석',
      },
      {
        price: 22000,
        cardCompany: '국민카드',
        cardNum: '4222 2222 2222 2222',
        payDt: '2022 09 01',
        createDt: '2022 09 01',
        state: '완납',
        serviceName: '배이직 / 배달분석',
      },
    ],
  },
  {
    storeId: '202208030001', // pk
    storeName: '인천점', // pk
    ownerName: '김영철',
    registerDt: '2022 6 01', //date.getMonth() - 3, -- 5 월부터
    lastpayDt: '2022 08 01',
    stoppedDt: undefined,
    payState: false,
    unpaidDt: '2022 07 01',
    unpaidPrice: 22000,
    paymentMethod: '점주',
    payMethodId: '20220911002',
    cardCompany: '국민카드',
    cardNum: '4222 2222 2222 2222',
    pw: '55',
    expdt: '0927',
    createDt: '20220803',
    serviceName: '배이직 / 배달분석',
    paybillList: [
      {
        price: 22000,
        cardCompany: '국민카드',
        cardNum: '4222 2222 2222 2222',
        payDt: '2022 06 01',
        createDt: '2022 06 01',
        state: '완납',
        serviceName: '배이직 / 배달분석',
      },
      {
        price: 22000,
        cardCompany: '국민카드',
        cardNum: '4222 2222 2222 2222',
        payDt: '2022 07 01',
        createDt: '2022 07 01',
        state: '완납',
        serviceName: '배이직 / 배달분석',
      },
      {
        price: 22000,
        cardCompany: '국민카드',
        cardNum: '4222 2222 2222 2222',
        payDt: '2022 08 01',
        createDt: '2022 08 01',
        state: '완납',
        serviceName: '배이직 / 배달분석',
      },
      {
        price: 22000,
        cardCompany: '국민카드',
        cardNum: '4222 2222 2222 2222',
        payDt: '2022 09 01',
        createDt: '2022 09 01',
        state: '미납',
        serviceName: '배이직 / 배달분석',
      },
    ],
  },
];

export const storeList_2021 = [
  {
    storeId: '202108030001', // pk
    storeName: '강남본점', // pk
    ownerName: '김희철',
    registerDt: '2021 06 01',
    lastpayDt: '2021 06 01',
    stoppedDt: undefined,
    payState: false,
    unpaidDt: 6,
    unpaidPrice: 66000,
    paymentMethod: '점주',
    payMethodId: '20210911002',
    cardCompany: '국민카드',
    cardNum: '4222 2222 2222 2222',
    createDt: '20220803',
    pw: '12',
    expdt: '0825',
    serviceName: '배이직 / 배달분석',
    paybillList: [
      {
        price: 22000,
        cardCompany: '국민카드',
        cardNum: '4222 2222 2222 2222',
        payDt: '2021 06 01',
        state: '완납',
        createDt: '2021 06 01',
        serviceName: '배이직 / 배달분석',
      },
      {
        price: 22000,
        cardCompany: '',
        cardNum: '',
        payDt: '',
        state: '미납',
        createDt: '2021 07 01',
        serviceName: '배이직 / 배달분석',
      },
      {
        price: 22000,
        cardCompany: '',
        cardNum: '',
        payDt: '',
        state: '중지',
        createDt: '2021 08 01',
        serviceName: '배이직 / 배달분석',
      },
      {
        price: 22000,
        cardCompany: '',
        cardNum: '',
        payDt: '',
        state: '중지',
        createDt: '2021 09 01',
        serviceName: '배이직 / 배달분석',
      },
    ],
  },
  {
    storeId: '202108030001', // pk
    storeName: '역삼점', // pk
    ownerName: '김남길',
    registerDt: '2021 06 01',
    lastpayDt: '2021 09 01',
    stoppedDt: undefined,
    payState: true,
    unpaidDt: undefined,
    unpaidPrice: 0,
    paymentMethod: '점주',
    payMethodId: '20210911002',
    cardCompany: '국민카드',
    cardNum: '4222 2222 2222 2222',
    pw: '12',
    expdt: '0825',
    createDt: '20210803',
    serviceName: '배이직 / 배달분석',
    paybillList: [
      {
        price: 22000,
        cardCompany: '국민카드',
        cardNum: '4222 2222 2222 2222',
        payDt: '2021 06 01',
        state: '완납',
        createDt: '2021 06 01',
        serviceName: '배이직 / 배달분석',
      },
      {
        price: 22000,
        cardCompany: '국민카드',
        cardNum: '4222 2222 2222 2222',
        payDt: '2021 07 01',
        state: '완납',
        createDt: '2021 07 01',
        serviceName: '배이직 / 배달분석',
      },
      {
        price: 22000,
        cardCompany: '국민카드',
        cardNum: '4222 2222 2222 2222',
        payDt: '2021 08 01',
        state: '완납',
        createDt: '2021 08 01',
        serviceName: '배이직 / 배달분석',
      },
      {
        price: 22000,
        cardCompany: '국민카드',
        cardNum: '4222 2222 2222 2222',
        payDt: '2021 09 01',
        state: '완납',
        createDt: '2021 09 01',
        serviceName: '배이직 / 배달분석',
      },
    ],
  },
];

export const storeList_2020 = [
  {
    storeId: '202208030001', // pk
    storeName: '강남본점', // pk
    ownerName: '최순자',
    registerDt: '2020 09 01',
    lastpayDt: '',
    stoppedDt: undefined,
    payState: false,
    unpaidDt: 6,
    unpaidPrice: 22000,
    paymentMethod: '미정',
    payMethodId: null,
    cardCompany: '',
    cardNum: '',
    pw: '',
    expdt: '',
    createDt: '2020 09 01',
    serviceName: '배이직 / 배달분석',
    paybillList: [
      {
        price: 22000,
        cardCompany: '국민카드',
        cardNum: '',
        payDt: '',
        state: '대기',
        createDt: '2020 09 01',
        serviceName: '배이직 / 배달분석',
      },
    ],
  },
  {
    storeId: '202208030001', // pk
    storeName: '역삼점', // pk
    ownerName: '윤도현',
    registerDt: '2020 09 01',
    lastpayDt: '',
    stoppedDt: undefined,
    payState: false,
    unpaidDt: undefined,
    unpaidPrice: 22000,
    paymentMethod: '미정',
    payMethodId: null,
    cardCompany: '',
    cardNum: '',
    pw: '',
    expdt: '',
    createDt: '2020 09 01',
    serviceName: '배이직 / 배달분석',
    paybillList: [
      {
        price: 22000,
        cardCompany: '',
        cardNum: '',
        payDt: '',
        state: '대기',
        createDt: '2020 09 01',
        serviceName: '배이직 / 배달분석',
      },
    ],
  },
];

/* ** staticData : pc 관리자의 결제정보 데이터**
 * 연도별로 pc 관리자의 결제정보는 달라질 수 있음
 * 현재는 2022년 기준의 staticData 1개로 하드코딩으로 테스트를 진행
 * 상단 progressbar, graph 의 주요 입력데이터를 가지고있음
 * table 내 header에 보여지는 월 의 정보--> staticData를 기준으로 랜더링
 */
export const staticData = {
  totalPaidPrice: 154000,
  chargedPrice: 242000,
  unpaidPrice: 88000,
  unregiStoreCnt: 0,
  regiStoreCnt: 2,
  waitingStoreCnt: 2,
  monthlyUnpaidCnt: 2,
  serviceStoppedCnt: 0,
  fullPaidStoreCnt: 0,
  cardCompany: '', // pk
  cardNum: '', // pk
  registerDt: '2022 06 01',
  lastpayDt: '',
  currentpayDt: '2022 09 01', // 내달 결제일 정보 (매달 " "일에 다음달 01일으로 업데이트 됩니다.)
};

export const staticData_2020 = {
  totalPaidPrice: 0,
  chargedPrice: 44000,
  unpaidPrice: 44000,
  unregiStoreCnt: 2,
  regiStoreCnt: 0,
  waitingStoreCnt: 0,
  monthlyUnpaidCnt: 2,
  serviceStoppedCnt: 0,
  fullPaidStoreCnt: 0,
  cardCompany: '', // pk
  cardNum: '', // pk
  registerDt: '2022 06 01',
  lastpayDt: '',
  currentpayDt: '', // 내달 결제일 정보 (매달 " "일에 다음달 01일으로 업데이트 됩니다.)
};

export const MobileOwnerStaticData = {
  storeId: '202208030001', // pk
  storeName: '역삼점', // pk
  registerDt: '2022 06 01', //date.getMonth() - 3, -- 5 월부터
  lastpayDt: '2022 10 01',
  stoppedDt: undefined,
  payState: false,
  unpaidDt: undefined,
  paymentMethod: '점주',
  payMethodId: '20220911002',
  cardCompany: '국민카드',
  cardNum: '3333 2222 2222 2222',
  createDt: '20220803',
};

export const moOwnerPaymethod = {
  paymethodId: '20220911001', // pk
  storeId: '', // pk
  cardCompany: '국민카드', // 카드사 pk
  cardNum: '4224 3333 9999 1111', // 카드번호 pk
  expDuration: '0825', // 유효기간
  pw: '12', // 카드비밀번호 앞 2자리
  personalAddre: '', // 주민번호 or 사업자 등록번호
  flag: 0, // 삭제 플레그 -> 삭제시 1, 삭제되지않으면 0
  creatDt: '20220901',
  updateDt: '20220901',
  createUser: 'master',
  updateUser: 'master',
};

export const mobileOwnerPaybillList = [
  {
    price: 22000,
    cardCompany: '국민카드',
    cardNum: '3333 2222 2222 2222',
    payDt: '2022 5 01',
    state: '미결제',
    serviceName: '배이직 / 배달분석',
  },
];

/**** 관리자 전체등록한 결제수단 리스트 ** */
export const paymethodsList = [
  {
    paymethodId: '20220911001', // pk
    storeId: '', // pk
    cardCompany: '국민카드', // 카드사 pk
    cardNum: '4224 3333 9999 1111', // 카드번호 pk
    expDuration: '0825', // 유효기간
    pw: '12', // 카드비밀번호 앞 2자리
    personalAddre: '', // 주민번호 or 사업자 등록번호
    flag: 0, // 삭제 플레그 -> 삭제시 1, 삭제되지않으면 0
    creatDt: '20220901',
    updateDt: '20220901',
    createUser: 'master',
    updateUser: 'master',
  },
  {
    paymethodId: '20220911002', // pk
    storeId: '', // pk
    cardCompany: '신한카드', // 카드사 pk
    cardNum: '8888 3333 9999 1111', // 카드번호 pk
    expDuration: '0828', // 유효기간
    pw: '12', // 카드비밀번호 앞 2자리
    personalAddre: '', // 주민번호 or 사업자 등록번호
    flag: 0, // 삭제 플레그 -> 삭제시 1, 삭제되지않으면 0
    creatDt: '20220901',
    updateDt: '20220901',
    createUser: 'master',
    updateUser: 'master',
  },
];

export const Coupondata = { couponNum: paymentConstants.COUPON, salePrice: 8000 };
