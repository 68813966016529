import { customerReviewConstants } from '../constants';
import { deepClone, getUnixTime } from '../helpers';

export function officeReviewList(state = {}, action) {
  switch (action.type) {
    case customerReviewConstants.OFFICE_REVIEW_LIST_RESET:
      return {
        officeReviewList: undefined,
      };
    case customerReviewConstants.OFFICE_REVIEW_LIST_REQUEST:
      return {
        loading: true,
      };
    case customerReviewConstants.OFFICE_REVIEW_LIST_SUCCESS:
      return {
        officeReviewList: action.result,
        loading: false,
      };
    case customerReviewConstants.OFFICE_REVIEW_LIST_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function orderAppReviewList(state = {}, action) {
  switch (action.type) {
    case customerReviewConstants.ORDER_APP_REVIEW_LIST_RESET:
      return {
        orderAppReviewList: undefined,
      };
    case customerReviewConstants.ORDER_APP_REVIEW_LIST_REQUEST:
      return {
        loading: true,
      };
    case customerReviewConstants.ORDER_APP_REVIEW_LIST_SUCCESS:
      return {
        orderAppReviewList: action.result,
      };
    case customerReviewConstants.ORDER_APP_REVIEW_LIST_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function specificReviewList(state = {}, action) {
  switch (action.type) {
    case customerReviewConstants.SPECIFIC_REVIEW_LIST_RESET:
      return {
        specificReviewList: undefined,
      };
    case customerReviewConstants.SPECIFIC_REVIEW_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case customerReviewConstants.SPECIFIC_REVIEW_LIST_SUCCESS:
      return {
        specificReviewList: action.result,
        loading: false,
      };
    case customerReviewConstants.SPECIFIC_REVIEW_LIST_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function mobileReviewList(state = {}, action) {
  switch (action.type) {
    case customerReviewConstants.MOBILE_REVIEW_LIST_RESET:
      return {
        mobileReviewList: undefined,
      };
    case customerReviewConstants.MOBILE_REVIEW_LIST_REQUEST:
      return {
        loading: true,
      };
    case customerReviewConstants.MOBILE_REVIEW_LIST_SUCCESS:
      return {
        mobileReviewList: action.result,
      };
    case customerReviewConstants.MOBILE_REVIEW_LIST_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function mobileTodayReviewList(state = {}, action) {
  switch (action.type) {
    case customerReviewConstants.MOBILE_TODAY_REVIEW_LIST_RESET:
      return {
        mobileTodayReviewList: undefined,
      };
    case customerReviewConstants.MOBILE_TODAY_REVIEW_LIST_REQUEST:
      return {
        loading: true,
      };
    case customerReviewConstants.MOBILE_TODAY_REVIEW_LIST_SUCCESS:
      return {
        mobileTodayReviewList: action.result,
      };
    case customerReviewConstants.MOBILE_TODAY_REVIEW_LIST_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function mobileTodaySpecificReviewList(state = {}, action) {
  switch (action.type) {
    case customerReviewConstants.MOBILE_TODAY_SPECIFIC_REVIEW_LIST_RESET:
      return {
        mobileTodaySpecificReviewList: undefined,
      };
    case customerReviewConstants.MOBILE_TODAY_SPECIFIC_REVIEW_LIST_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case customerReviewConstants.MOBILE_TODAY_SPECIFIC_REVIEW_LIST_SUCCESS:
      let prev = state.mobilePrevTodaySpecificReviewList
        ? deepClone(state.mobilePrevTodaySpecificReviewList)
        : action.result;
      return {
        mobileTodaySpecificReviewList: action.result,
        mobilePrevTodaySpecificReviewList: prev,
      };
    case customerReviewConstants.MOBILE_TODAY_SPECIFIC_REVIEW_LIST_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function mobileSpecificReviewList(state = {}, action) {
  switch (action.type) {
    case customerReviewConstants.MOBILE_SPECIFIC_REVIEW_LIST_RESET:
      return {
        mobileSpecificReviewList: undefined,
      };
    case customerReviewConstants.MOBILE_SPECIFIC_REVIEW_LIST_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case customerReviewConstants.MOBILE_SPECIFIC_REVIEW_LIST_SUCCESS:
      return {
        mobileSpecificReviewList: action.result,
      };

    case customerReviewConstants.MOBILE_SPECIFIC_REVIEW_LIST_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function insertAnswer(state = {}, action) {
  switch (action.type) {
    case customerReviewConstants.INSERT_ANSWER_RESET:
      return {
        insertAnswer: undefined,
      };
    case customerReviewConstants.INSERT_ANSWER_REQUEST:
      return {
        loading: true,
      };
    case customerReviewConstants.INSERT_ANSWER_SUCCESS:
      return {
        insertAnswer: action.result,
      };
    case customerReviewConstants.INSERT_ANSWER_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function deleteAnswer(state = {}, action) {
  switch (action.type) {
    case customerReviewConstants.DELETE_ANSWER_RESET:
      return {
        deleteAnswer: undefined,
      };
    case customerReviewConstants.DELETE_ANSWER_REQUEST:
      return {
        loading: true,
      };
    case customerReviewConstants.DELETE_ANSWER_SUCCESS:
      return {
        deleteAnswer: action.result,
      };
    case customerReviewConstants.DELETE_ANSWER_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function autoAnswerList(state = {}, action) {
  switch (action.type) {
    case customerReviewConstants.AUTO_ANSWER_LIST_REQUEST:
      return {
        loading: true,
      };
    case customerReviewConstants.AUTO_ANSWER_LIST_SUCCESS:
      return {
        autoAnswerList: action.result['result'],
      };
    case customerReviewConstants.AUTO_ANSWER_LIST_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function updateAutoAnswer(state = {}, action) {
  switch (action.type) {
    case customerReviewConstants.UPDATE_AUTO_ANSWER_RESET:
      return {
        updateAutoAnswer: undefined,
      };
    case customerReviewConstants.UPDATE_AUTO_ANSWER_REQUEST:
      return {
        loading: true,
      };
    case customerReviewConstants.UPDATE_AUTO_ANSWER_SUCCESS:
      return {
        updateAutoAnswer: action.result,
      };
    case customerReviewConstants.UPDATE_AUTO_ANSWER_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function insertAutoAnswer(state = {}, action) {
  switch (action.type) {
    case customerReviewConstants.INSERT_AUTO_ANSWER_RESET:
      return {
        insertAutoAnswer: undefined,
      };
    case customerReviewConstants.INSERT_AUTO_ANSWER_REQUEST:
      return {
        loading: true,
      };
    case customerReviewConstants.INSERT_AUTO_ANSWER_SUCCESS:
      return {
        insertAutoAnswer: action.result,
      };
    case customerReviewConstants.INSERT_AUTO_ANSWER_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function deleteAutoAnswer(state = {}, action) {
  switch (action.type) {
    case customerReviewConstants.DELETE_AUTO_ANSWER_RESET:
      return {
        deleteAutoAnswer: undefined,
      };
    case customerReviewConstants.DELETE_AUTO_ANSWER_REQUEST:
      return {
        loading: true,
      };
    case customerReviewConstants.DELETE_AUTO_ANSWER_SUCCESS:
      return {
        deleteAutoAnswer: action.result,
      };
    case customerReviewConstants.DELETE_AUTO_ANSWER_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function specificReview(state = {}, action) {
  switch (action.type) {
    case customerReviewConstants.SPECIFIC_REVIEW_RESET:
      return {
        specificReview: undefined,
      };
    case customerReviewConstants.SPECIFIC_REVIEW_REQUEST:
      return {
        loading: true,
      };
    case customerReviewConstants.SPECIFIC_REVIEW_SUCCESS:
      return {
        specificReview: action.result['result'],
        syncReviewIdx: action.result['result'] && action.result['result'][0]['reviewIdx']
      };
    case customerReviewConstants.SPECIFIC_REVIEW_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function reviewPrevUrl(state = {}, action) {
  switch (action.type) {
    case customerReviewConstants.SET_REVIEW_PREV_URL:
      return {
        reviewPrevUrl: action.result,
      };
    default:
      return state;
  }
}

export function syncReviewList(state = {}, action) {
  switch (action.type) {
    case customerReviewConstants.SYNC_REVIEW_LIST_RESET:
      return {
        syncReviewList: undefined,
      };
    case customerReviewConstants.SYNC_REVIEW_LIST_REQUEST:
      return {
        loading: true,
      };
    case customerReviewConstants.SYNC_REVIEW_LIST_SUCCESS:
      return {
        syncReviewList: action.result['result'],
        lastSyncTime: getUnixTime(),
        loading: false,
      };
    case customerReviewConstants.SYNC_REVIEW_LIST_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
