import React, { useEffect } from 'react';
import MypageHeader from '../MyPage/Container/MyPageHeader';
import WithdrawModal from './Container/WithdrawModal';
import MobileGlobalStyle from '../../../assets/components/mobile/MobileGlobalStyle';

export const Withdraw = ({ isResizeWidth = false }) => {
  useEffect(() => {
    if (isResizeWidth === true) {
      let commonModalWidth = document.getElementsByClassName('modal-dialog');
      commonModalWidth[0].style.width = 'unset';
    }

    let element = document.getElementById('container');
    element && element.scrollTo({ top: 0 });

    document.body.classList.add('mypage');
    return () => {
      document.body.classList.remove('mypage');
    };
  }, []);
  return (
    <>
      <MobileGlobalStyle />

      <div className="container-out">
        <MypageHeader title={'서비스 해지/탈퇴'} />
        <WithdrawModal />
      </div>
    </>
  );
};
