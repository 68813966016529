import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import IMAGES from '../../assets/utils/images';

const PosTooltip = () => {
  const [isCheckedPostTooltip, setIsCheckedPostTooltip] = useState(localStorage.getItem('isCheckedPostTooltip'));

  const setScreenSize = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  const setCommonIsCheckedPostTooltip = () => {
    setIsCheckedPostTooltip(true);
    localStorage.setItem('isCheckedPostTooltip', true);
  };

  useLayoutEffect(() => {
    setScreenSize();

    window.addEventListener('resize', () => setScreenSize());

    return () => {
      window.removeEventListener('resize', () => setScreenSize());
    };
  }, []);

  return (
    <>
      {!isCheckedPostTooltip && (
        <PosTooltipWrap id="posTooltipWrap">
          <div>
            <div>
              <span className="hilight">실시간 + 홀 매출 분석</span>
              <span className="white-color">에 관한 </span>
              <span className="hilight">팁</span>
              <span className="title" />
            </div>
            <div className="desc">
              실시간 + 홀 매출의 경우, 매장 POS에 기반하여 매출이 분석되므로{' '}
              <span>메인 POS에서 영수증을 꼭!꼭! 필수로 출력</span>
              해야 합니다. 영수증이 출력된 건에 대해서만 매출이 분석된다는 점을 반드시 기억해주세요~!
            </div>
            <div class="accept-btn-wrap">
              <span className="accept-btn" onClick={setCommonIsCheckedPostTooltip}>
                네, 영수증을 꼭 출력할게요!
              </span>
            </div>
          </div>
        </PosTooltipWrap>
      )}
    </>
  );
};

export default PosTooltip;

const PosTooltipWrap = styled.div`
  width: 100%;
  max-width: 540px;
  z-index: 10;
  color: #fff;

  @media (min-width: 560px) {
    max-width: 540px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }

  .hilight {
    color: #f8fd00;
    font-size: 14px;
    font-weight: 700;
  }

  .white-color {
    color: #fff;
    font-weight: 700;
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding: 24px 20px 24px 24px;
    border-radius: 16px;
    background: #686cff;

    .desc {
      font-weight: 400;
      font-size: 13px;
      line-height: 150%;
      margin-bottom: 12px;
      span {
        color: #f8fd00;
      }
    }
    .accept-btn-wrap {
      text-align: right;
      border-radius: 24px;
      border: 1px solid #686cff;
      padding: 6px 0 8px;
      span {
        background: #686cff;
        border: 1px solid rgba(255, 255, 255, 0.7);

        padding: 16px 32px 16px 28px;
        border-radius: 24px;
        color: #fff;
        text-align: center;
        font-family: Pretendard;
        font-size: 13px;
        font-weight: 600;
      }
    }
  }
  .title {
    width: 100%;
    height: 21px;
    background: url(${IMAGES['titletooltip']}) no-repeat;
    margin-top: 2px;
    margin-left: 2px;
    position: absolute;
    width: 20px;
  }
`;
