import { globalCount, reviewConstants } from "../constants";

export function brandReviewHeaderData(state = {}, action) {
  switch (action.type) {
    case reviewConstants.BRAND_REVIEW_HEADER_DATA_REQUEST:
      return {
        loading: true
      };
    case reviewConstants.BRAND_REVIEW_HEADER_DATA_SUCCESS:
      return {
        brandReviewHeaderData: action.result['result']
      };
    case reviewConstants.BRAND_REVIEW_HEADER_DATA_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function brandReviewListData(state = {}, action) {
  switch (action.type) {
    case reviewConstants.BRAND_REVIEW_LIST_DATA_RESET:
      return {
        brandReviewListData: {}
      };
    case reviewConstants.BRAND_REVIEW_LIST_DATA_REQUEST:
      return {
        ...state
      };
    case reviewConstants.BRAND_REVIEW_LIST_DATA_SUCCESS:
      return {
        brandReviewListData: {
          ...state.brandReviewListData,
          [action.result['limitStart']]: action.result['result'],
        },
        rowCount: action.result['row_count'],
      };
    case reviewConstants.BRAND_REVIEW_LIST_DATA_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function brandRssHeaderData(state = {}, action) {
  switch (action.type) {
    case reviewConstants.BRAND_RSS_HEADER_DATA_REQUEST:
      return {
        loading: true
      };
    case reviewConstants.BRAND_RSS_HEADER_DATA_SUCCESS:
      return {
        brandRssHeaderData: action.result['result']
      };
    case reviewConstants.BRAND_RSS_HEADER_DATA_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function brandRssListData(state = {}, action) {
  switch (action.type) {
    case reviewConstants.BRAND_RSS_LIST_DATA_RESET:
      return {
        brandRssListData: {}
      };
    case reviewConstants.BRAND_RSS_LIST_DATA_REQUEST:
      return {
        ...state
      };
    case reviewConstants.BRAND_RSS_LIST_DATA_SUCCESS:
      return {
        brandRssListData: {
          ...state.brandRssListData,
          [action.result['limitStart']]: action.result['result'],
        },
        rowCount: action.result['row_count'],
      };
    case reviewConstants.BRAND_RSS_LIST_DATA_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function brandRssKeywordList(state = {}, action) {
  switch (action.type) {
    case reviewConstants.BRAND_RSS_KEYWORD_LIST_REQUEST:
      return {
        loading: true
      };
    case reviewConstants.BRAND_RSS_KEYWORD_LIST_SUCCESS:
      return {
        brandRssKeywordList: action.result['result']
      };
    case reviewConstants.BRAND_RSS_KEYWORD_LIST_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function brandRssKeywordEdit(state = {}, action) {
  switch (action.type) {
    case reviewConstants.BRAND_RSS_KEYWORD_EDIT_REQUEST:
      return {
        loading: true
      };
    case reviewConstants.BRAND_RSS_KEYWORD_EDIT_SUCCESS:
      return {
        brandRssKeywordEdit: {
          result: action.result['result'],
          globalCount: globalCount(),
        }
      };
    case reviewConstants.BRAND_RSS_KEYWORD_EDIT_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function brandRemoveContents(state = {}, action) {
  switch (action.type) {
    case reviewConstants.BRAND_REMOVE_CONTENTS_REQUEST:
      return {
        loading: true
      };
    case reviewConstants.BRAND_REMOVE_CONTENTS_SUCCESS:
      return {
        brandRemoveContents: {
          result: action.result['result'],
          globalCount: globalCount(),
        }
      };
    case reviewConstants.BRAND_REMOVE_CONTENTS_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function mainReviewData(state = {}, action) {
  switch (action.type) {
    case reviewConstants.MAIN_REVIEW_DATA_REQUEST:
      return {
        loading: true
      };
    case reviewConstants.MAIN_REVIEW_DATA_SUCCESS:
      return {
        mainReviewData: action.result['result']
      };
    case reviewConstants.MAIN_REVIEW_DATA_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}