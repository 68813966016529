import React, { useLayoutEffect } from 'react';
import styled from 'styled-components';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module

import { useDispatch, useSelector } from 'react-redux';
import { commonActions } from '../../actions';
import InstallBtn from '../../assets/utils/images/app-launching/app_install_btn.png';
import Image1 from '../../assets/utils/images/app-launching/mo_app_1.png';
import Image2 from '../../assets/utils/images/app-launching/mo_app_2.png';
import Image3 from '../../assets/utils/images/app-launching/mo_app_3.png';
import NDayBtnClose from '../../assets/utils/images/check_white.svg';
import { AOS_MARKET, IOS_STORE, isMobile } from '../../constants';
import { checkPopupClose, isMarketbomLink } from '../../helpers';
import { checkReactNativeWebView } from '../../helpers/AppWebView';

export const MobileAppInstallPopup = () => {
  const dispatch = useDispatch();
  const { isMobilePopupShow } = useSelector(state => state.setIsMobilePopupShow);
  const isWebView = checkReactNativeWebView();
  const items = [
    {
      src: Image1,
      alt: 'AppLaunchingImg-1',
    },
    {
      src: Image2,
      alt: 'AppLaunchingImg-2',
    },
    {
      src: Image3,
      alt: 'AppLaunchingImg-3',
    },
  ];

  const closeModal = () => {
    dispatch(commonActions.setIsMobilePopupShow(false));
  };

  const closePopup = expireDays => {
    const expire = new Date();
    expire.setTime(expire.getTime() + expireDays * 24 * 60 * 60 * 1000);
    localStorage.setItem('hidePopup', expire.getTime());

    closeModal();
  };

  const oneDayHidePopup = () => {
    closePopup(1);
  };

  const handleInstallButton = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      window.location.href = AOS_MARKET;
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = IOS_STORE;
    } else {
      alert('해당 기기에서는 앱 설치가 지원되지 않습니다.');
    }
  };

  const setThemeColor = isShow => {
    const themeColor = isShow ? '#7A7B7C' : '#F4F6F8';

    document.getElementsByName('theme-color')[0].content = themeColor;
    document.getElementsByName('msapplication-navbutton-color')[0].content = themeColor;
    document.getElementsByName('apple-mobile-web-app-status-bar-style')[0].content = themeColor;
  };

  useLayoutEffect(() => {
    dispatch(commonActions.setIsMobilePopupShow(checkPopupClose()));

    return () => {
      setThemeColor(false);
    };
  }, []);

  useLayoutEffect(() => {
    setThemeColor(isMobilePopupShow);
  }, [isMobilePopupShow]);

  if (!isMobilePopupShow || !isMobile() || isWebView || isMarketbomLink()) {
    return null;
  }

  return (
    <AppInstallPopup id="mobileAppPopup">
      <div className="mobile-popup">
        <div className="close-wrap">
          <div className="N-Day-btn-close" onClick={oneDayHidePopup}>
            오늘 하루 보지 않기
          </div>
          <div className="btn-close" onClick={closeModal}>
            닫기
          </div>
        </div>
        <div className="mobile-popup-wrap">
          <div className="mobile-popup-container">
            <div className="title"></div>
            <div className="content-slide">
              <Swiper
                slidesPerView={1}
                spaceBetween={0}
                autoplay={{ delay: 2700, disableOnInteraction: false }}
                // navigation={{ clickable: true }}
                pagination={{
                  type: 'fraction',
                }}
                scrollbar={false}
                mousewheel={true}
                modules={[Navigation, Pagination, Autoplay]}
                className="mySwiper"
              >
                {items.map((item, idx) => {
                  return (
                    <>
                      <SwiperSlide key={idx}>
                        <img id={item.alt} src={item.src} alt={item.alt} onClick={handleInstallButton} />
                      </SwiperSlide>
                    </>
                  );
                })}
              </Swiper>
              <button className="app-install-btn" onClick={handleInstallButton}>
                <img src={InstallBtn} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </AppInstallPopup>
  );
};

const AppInstallPopup = styled.div`
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;

  .mobile-popup {
    position: absolute;
    bottom: 0;

    @media (min-width: 560px) {
      max-width: 560px;
      margin: 0 auto;
      left: 0;
      right: 0;
    }

    .close-wrap {
      display: flex;
      width: calc(100vw - 40px);
      margin: 12px 20px;
      justify-content: space-between;
      font-size: 15px;
      color: #fff;

      @media (min-width: 560px) {
        max-width: 520px;
      }

      .N-Day-btn-close {
        font-size: 15px;
        padding-left: 22px;
        background-image: url(${NDayBtnClose});
        background-size: 15px;
        background-position: left;
        background-repeat: no-repeat;
      }
    }

    .mobile-popup-wrap {
      width: 100vw;
      margin: 0 auto;
      background: linear-gradient(180deg, rgba(241, 242, 255, 0.5) 0%, #eeefff 100%), #fff;
      border-radius: 24px 24px 0 0;

      @media (min-width: 560px) {
        max-width: 560px;
      }

      img {
        width: calc(100% - 48px);
      }

      .mobile-popup-container {
        width: 100%;
        margin: 0 auto;
        text-align: center;

        .swiper-wrapper {
          padding: 36px 0 0;
        }

        .swiper-pagination {
          width: 32px;
          font-size: 10px;
          letter-spacing: -1px;
          white-space: pre;
          height: 22px;
          top: 20px;
          left: calc(100% - 56px);
          color: #f0f0f0;
          padding: 4px 7px 0 6px;
          border-radius: 12px;
          background-color: #b9babf;
          .swiper-pagination-current {
            color: #fff;
          }
        }

        button {
          border: none;
          background: none;
          width: 100%;
          padding: 0 0 20px;
        }
      }
    }
  }
`;
