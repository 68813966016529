import classNames from 'classnames';
import React, { useState } from 'react';
import { localDataLoad, setLocalData } from '../../helpers';
import styled from 'styled-components';

const ToastInstallPos = () => {
  const [renderCount, setRenderCount] = useState(0);

  const disableToastInstallPos = localDataLoad('disableToastInstallPos');
  const isNotInstallPos = localDataLoad('isNotInstallPos');

  if (document.getElementById('ToastSyncData')) {
    return null;
  }

  const handleClose = () => {
    const time = new Date().getTime();
    const intervalMs = 1000 * 60 * 60 * 24; // 24hour

    setLocalData('disableToastInstallPos', time + intervalMs);

    if (isNotInstallPos) {
      setLocalData('isNotInstallPos', false);
    }

    setRenderCount(renderCount + 1);
  };

  const handleRedirect = () => {
    setLocalData('isRedirectToMain', false);
    window.location.href = '#/mobile/programInstallInfo ';
  };

  const isShow = disableToastInstallPos < new Date().getTime() || isNotInstallPos;

  return (
    <>
      {isShow && (
        <ToastInstallPosContainer
          isNotInstallPos={isNotInstallPos}
          className={classNames('toast-ui ment auto-hide-none bgBlack')}
        >
          <CloseButton className="close" onClick={handleClose} />
          <Desc className="desc" onClick={handleRedirect}>
            {isNotInstallPos ? (
              <>
                아직 고객님의 매장POS에 프로그램 설치가
                <br />
                완료되지 않았거나, 고객님의 POS에서
                <br />
                앳트래커 로그인 정보가 확인되지 않았습니다.
                <br />
                설치를 다시 진행하시겠습니까?
              </>
            ) : (
              <>
                아직 고객님의 매장POS에 프로그램 설치가 <br />
                완료되지 않았습니다. 설치를 진행하시겠습니까?
              </>
            )}
          </Desc>
          {isNotInstallPos && (
            <AcceptButton onClick={handleRedirect}>
              POS에 프로그램을&nbsp;<span>재설치</span>해주세요
            </AcceptButton>
          )}
        </ToastInstallPosContainer>
      )}
    </>
  );
};

export default ToastInstallPos;

const ToastInstallPosContainer = styled.div`
  padding: ${props => (props.isNotInstallPos ? '32px 0' : '24px 0')} !important;
  flex-direction: column;
  align-items: center;
  margin: 0 !important;
  bottom: 0 !important;

  @media (min-width: 560px) {
    max-width: 540px !important;
    margin: 0 auto !important;
    left: 0 !important;
    right: 0 !important;
  }
`;

const Desc = styled.div`
  line-height: 25px !important;
`;

const CloseButton = styled.div`
  top: 16px !important;
  right: 16px !important;
`;

const AcceptButton = styled.button`
  width: fit-content;
  height: 50px !important;
  text-align: center;
  border-radius: 24px !important;
  background: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.7) !important;
  margin: 16px 0 0;
  padding: 16px 31px 16px 32px !important;
  color: #fff !important;
  font-size: 15px !important;
  font-weight: 700 !important;

  span {
    color: #fff500 !important;
  }
`;
