import React, { Component, Fragment } from 'react';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { authActions, commonActions, userActions } from '../actions';
import { getMyUserData } from '../helpers';
import { Redirect } from 'react-router-dom';
import CheckPolicy from './CheckPolicy';
import CheckPhoneVerfication from './CheckPhoneVerfication';
import { setUserId } from '../helpers/AppWebView';
import WebSocket from '../helpers/WebSocket';
import { isMobile } from '../constants';
import styled from 'styled-components';

class CheckLogin extends Component {
  constructor(props) {
    super(props);

    var hashIdx = window.location.hash.indexOf('/#');

    this.state = {
      checkLoginState: undefined,
      stateResult: props.checkLoginState ? props.checkLoginState['result'] : undefined,
      modal: false,
      currentPathName: window.location.hash.substr(hashIdx + 2),
    };

    this.handleCheckLogin = this.handleCheckLogin.bind(this);
    this.onClickGoBack = this.onClickGoBack.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    var returnState = {};

    if (nextProps.checkLoginState !== undefined && !isEqual(nextProps.checkLoginState, prevState.checkLoginState)) {
      returnState.checkLoginState = nextProps.checkLoginState;
      returnState.stateResult = nextProps.checkLoginState['result'];

      if (returnState.stateResult === 9997) {
        nextProps.dispatch(commonActions.alertMessage(null, null, null, <CheckPolicy />, null, 1));
      }

      if (returnState.stateResult === 9995) {
        nextProps.dispatch(
          commonActions.alertMessage(null, null, null, <CheckPhoneVerfication isResizeWidth={true} />, null, 1),
        );
      }
    }

    return returnState ? returnState : null;
  }

  componentDidMount() {
    this.handleCheckLogin();
    this.intervalId = setInterval(this.handleCheckLogin, 60000);
    this.intervalSocket = setInterval(this.checktSocketConnected, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
    clearInterval(this.intervalSocket);
  }

  async handleCheckLogin() {
    var userData = getMyUserData(false);
    if (userData) {
      await this.props.dispatch(userActions.myUserData(userData.user_sno, 2));
    }

    if (userData) {
      userData = getMyUserData(false);
      this.props.dispatch(authActions.checkLoginState(userData['user_sno']));
      setUserId(userData['user_sno']);
    }
  }

  checktSocketConnected() {
    if (isMobile() && WebSocket.checkConnected() === false) {
      WebSocket.reTry();
    }
  }

  onClickGoBack() {
    this.setState({ stateResult: undefined });
    this.setState({ goLogin: true });
  }

  render() {
    const { stateResult, goLogin, currentPathName } = this.state;

    if (goLogin === true) {
      return (
        <Redirect
          to={{
            pathname: '/auth/login',
            state: {
              from: {
                pathname: currentPathName,
                search: '',
              },
            },
          }}
        />
      );
    }

    return !stateResult || stateResult === 1 ? null : (
      <Fragment>
        {stateResult === 9999 && (
          <CustomModal isOpen={true}>
            <ModalHeader>중복로그인 감지</ModalHeader>
            <ModalBody>
              <p>
                다른 기기에서 동일 계정으로 접속이 감지되어 자동 로그아웃 되었습니다.
                <br />
                재로그인 후 이용해주시기 바랍니다.
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" style={{ width: '88px' }} onClick={this.onClickGoBack}>
                확인
              </Button>
            </ModalFooter>
          </CustomModal>
        )}

        {stateResult === 9000 && (
          <CustomModal isOpen={true}>
            <ModalHeader>이용권한 변경</ModalHeader>
            <ModalBody>
              <p>
                서비스 이용 권한이 변경 되었습니다.
                <br />
                재로그인 후 이용해주시기 바랍니다.
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" style={{ width: '88px' }} onClick={this.onClickGoBack}>
                확인
              </Button>
            </ModalFooter>
          </CustomModal>
        )}

        {stateResult === 9998 && (
          <CustomModal isOpen={true}>
            <ModalHeader>계정 기간만료</ModalHeader>
            <ModalBody>
              <p>
                계정 사용기간이 만료되었습니다.
                <br />
                고객센터(1899-8351)로 문의 부탁 드립니다.
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" style={{ width: '88px' }} onClick={this.onClickGoBack}>
                확인
              </Button>
            </ModalFooter>
          </CustomModal>
        )}

        {stateResult === 9996 && (
          <CustomModal isOpen={true}>
            <ModalHeader>로그인 제한</ModalHeader>
            <ModalBody>
              <p>
                브랜드 본사 요청으로 로그인이 제한되었습니다.
                <br />
                매장데이터 확인은 브랜드 본사 담당자에게 문의 부탁 드립니다.
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" style={{ width: '88px' }} onClick={this.onClickGoBack}>
                확인
              </Button>
            </ModalFooter>
          </CustomModal>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { checkLoginState } = state.checkLoginState;

  return {
    checkLoginState,
  };
}

export default connect(mapStateToProps)(CheckLogin);

const CustomModal = styled(Modal)`
  .modal-content {
    top: env(safe-area-inset-top);
  }
`;
