import { menuConstants, globalCount } from '../constants';
import { convertForFindName } from '../helpers';

export function brandMenuList(state = {}, action) {
  switch (action.type) {
    case menuConstants.BRAND_MENU_LIST_REQUEST:
      return {
        loading: true,
      };
    case menuConstants.BRAND_MENU_LIST_SUCCESS:
      return {
        brandMenuList: action.result['result']['brandMenuList'],
        unlinkedMenuList: action.result['result']['unlinkedMenuList'],
        menuNames: convertForFindName(action.result['result']['brandMenuList'], 'brand_menu_sno', 'brand_menu_name'),
      };
    case menuConstants.BRAND_MENU_LIST_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function categoryList(state = {}, action) {
  switch (action.type) {
    case menuConstants.CATEGORY_LIST_REQUEST:
      return {
        loading: true,
      };
    case menuConstants.CATEGORY_LIST_SUCCESS:
      return {
        categoryList: action.result['result'],
      };
    case menuConstants.CATEGORY_LIST_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function saleMenuHeaderData(state = {}, action) {
  switch (action.type) {
    case menuConstants.SALE_MENU_HEADER_DATA_REQUEST:
      return {
        loading: true,
      };
    case menuConstants.SALE_MENU_HEADER_DATA_SUCCESS:
      return {
        saleMenuHeaderData: action.result['result'],
      };
    case menuConstants.SALE_MENU_HEADER_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function saleMenuListData(state = {}, action) {
  switch (action.type) {
    case menuConstants.SALE_MENU_LIST_DATA_RESET:
      return { saleMenuListData: null };
    case menuConstants.SALE_MENU_LIST_DATA_REQUEST:
      return {
        ...state,
      };
    case menuConstants.SALE_MENU_LIST_DATA_SUCCESS:
      return {
        saleMenuListData: {
          ...state.saleMenuListData,
          [action.result['limitStart']]: action.result['result'],
        },
        rowCount: action.result['row_count'],
        headerData: action.result['headerData'],
      };
    case menuConstants.SALE_MENU_LIST_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function menuDateListData(state = {}, action) {
  switch (action.type) {
    case menuConstants.MENU_DATE_LIST_DATA_RESET:
      return {
        menuDateListData: null,
      };
    case menuConstants.MENU_DATE_LIST_DATA_REQUEST:
      return {
        loading: true,
      };
    case menuConstants.MENU_DATE_LIST_DATA_SUCCESS:
      return {
        menuDateListData: action.result['result'],
      };
    case menuConstants.MENU_DATE_LIST_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function deliveryMenuHeaderData(state = {}, action) {
  switch (action.type) {
    case menuConstants.DELIVERY_MENU_HEADER_DATA_RESET:
      return {};
    case menuConstants.DELIVERY_MENU_HEADER_DATA_REQUEST:
      return {
        loading: true,
      };
    case menuConstants.DELIVERY_MENU_HEADER_DATA_SUCCESS:
      return {
        deliveryMenuHeaderData: action.result['result'],
      };
    case menuConstants.DELIVERY_MENU_HEADER_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function deliveryMenuListData(state = {}, action) {
  switch (action.type) {
    case menuConstants.DELIVERY_MENU_LIST_DATA_RESET:
      return { deliveryMenuListData: null };
    case menuConstants.DELIVERY_MENU_LIST_DATA_REQUEST:
      return {
        ...state,
      };
    case menuConstants.DELIVERY_MENU_LIST_DATA_SUCCESS:
      return {
        deliveryMenuListData: {
          ...state.deliveryMenuListData,
          [action.result['limitStart']]: action.result['result'],
        },
        rowCount: action.result['row_count'],
        headerData: action.result['headerData'],
      };
    case menuConstants.DELIVERY_MENU_LIST_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function deliveryMenuOptionListData(state = {}, action) {
  switch (action.type) {
    case menuConstants.DELIVERY_MENU_OPTION_LIST_DATA_RESET:
      return { deliveryMenuOptionListData: null };
    case menuConstants.DELIVERY_MENU_OPTION_LIST_DATA_REQUEST:
      return {
        ...state,
      };
    case menuConstants.DELIVERY_MENU_OPTION_LIST_DATA_SUCCESS:
      return {
        deliveryMenuOptionListData: {
          ...state.deliveryMenuOptionListData,
          [action.result['limitStart']]: action.result['result'],
        },
        rowCount: action.result['row_count'],
        headerData: action.result['headerData'],
      };
    case menuConstants.DELIVERY_MENU_OPTION_LIST_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function allMenuEngineering(state = {}, action) {
  switch (action.type) {
    case menuConstants.ALL_MENU_ENGINEERING_REQUEST:
      return {
        loading: true,
      };
    case menuConstants.ALL_MENU_ENGINEERING_SUCCESS:
      return {
        allMenuEngineering: action.result['result'],
      };
    case menuConstants.ALL_MENU_ENGINEERING_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function menuEngineeringOffice(state = {}, action) {
  switch (action.type) {
    case menuConstants.MENU_ENGINEERING_OFFICE_REQUEST:
      return {
        loading: true,
      };
    case menuConstants.MENU_ENGINEERING_OFFICE_SUCCESS:
      return {
        menuEngineeringOffice: action.result['result'],
      };
    case menuConstants.MENU_ENGINEERING_OFFICE_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function oneMenuOfficeHeaderData(state = {}, action) {
  switch (action.type) {
    case menuConstants.ONE_MENU_OFFICE_HEADER_DATA_REQUEST:
      return {
        loading: true,
      };
    case menuConstants.ONE_MENU_OFFICE_HEADER_DATA_SUCCESS:
      return {
        oneMenuOfficeHeaderData: action.result['result'],
      };
    case menuConstants.ONE_MENU_OFFICE_HEADER_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function oneMenuOfficeListData(state = {}, action) {
  switch (action.type) {
    case menuConstants.ONE_MENU_OFFICE_LIST_DATA_RESET:
      return {};
    case menuConstants.ONE_MENU_OFFICE_LIST_DATA_REQUEST:
      return {
        ...state,
      };
    case menuConstants.ONE_MENU_OFFICE_LIST_DATA_SUCCESS:
      return {
        oneMenuOfficeListData: {
          ...state.oneMenuOfficeListData,
          [action.result['limitStart']]: action.result['result'],
        },
        rowCount: action.result['row_count'],
        headerData: action.result['headerData'],
      };
    case menuConstants.ONE_MENU_OFFICE_LIST_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function oneMenuDateListData(state = {}, action) {
  switch (action.type) {
    case menuConstants.ONE_MENU_DATE_LIST_DATA_REQUEST:
      return {
        loading: true,
      };
    case menuConstants.ONE_MENU_DATE_LIST_DATA_SUCCESS:
      return {
        oneMenuDateListData: action.result['result'],
      };
    case menuConstants.ONE_MENU_DATE_LIST_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function oneMenuSaleWeekDayListData(state = {}, action) {
  switch (action.type) {
    case menuConstants.ONE_MENU_SALE_WEEK_DAY_LIST_DATA_REQUEST:
      return {
        loading: true,
      };
    case menuConstants.ONE_MENU_SALE_WEEK_DAY_LIST_DATA_SUCCESS:
      return {
        oneMenuSaleWeekDayListData: action.result['result'],
      };
    case menuConstants.ONE_MENU_SALE_WEEK_DAY_LIST_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function oneMenuDeliveryWeekDayListData(state = {}, action) {
  switch (action.type) {
    case menuConstants.ONE_MENU_DELIVERY_WEEK_DAY_LIST_DATA_REQUEST:
      return {
        loading: true,
      };
    case menuConstants.ONE_MENU_DELIVERY_WEEK_DAY_LIST_DATA_SUCCESS:
      return {
        oneMenuDeliveryWeekDayListData: action.result['result'],
      };
    case menuConstants.ONE_MENU_DELIVERY_WEEK_DAY_LIST_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function mainMenuData(state = {}, action) {
  switch (action.type) {
    case menuConstants.MAIN_MENU_DATA_REQUEST:
      return {
        loading: true,
      };
    case menuConstants.MAIN_MENU_DATA_SUCCESS:
      return {
        mainMenuData: action.result['result'],
      };
    case menuConstants.MAIN_MENU_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function saleMenuTotalData(state = {}, action) {
  switch (action.type) {
    case menuConstants.SALE_MENU_TOTAL_DATA_RESET:
      return {
        saleMenuTotalData: null,
      };
    case menuConstants.SALE_MENU_TOTAL_DATA_REQUEST:
      return {
        loading: true,
      };
    case menuConstants.SALE_MENU_TOTAL_DATA_SUCCESS:
      return {
        saleMenuTotalData: action.result['result'],
      };
    case menuConstants.SALE_MENU_TOTAL_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function editCategoryName(state = {}, action) {
  switch (action.type) {
    case menuConstants.EDIT_CATEGORY_NAME_RESET:
      return {};
    case menuConstants.EDIT_CATEGORY_NAME_REQUEST:
      return {
        loading: true,
      };
    case menuConstants.EDIT_CATEGORY_NAME_SUCCESS:
      return {
        editCategoryName: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case menuConstants.EDIT_CATEGORY_NAME_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function disableBrandMenu(state = {}, action) {
  switch (action.type) {
    case menuConstants.DISABLE_BRAND_MENU_RESET:
      return {};
    case menuConstants.DISABLE_BRAND_MENU_REQUEST:
      return {
        loading: true,
      };
    case menuConstants.DISABLE_BRAND_MENU_SUCCESS:
      return {
        disableBrandMenu: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case menuConstants.DISABLE_BRAND_MENU_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function unlinkMenu(state = {}, action) {
  switch (action.type) {
    case menuConstants.UNLINK_MENU_RESET:
      return {};
    case menuConstants.UNLINK_MENU_REQUEST:
      return {
        loading: true,
      };
    case menuConstants.UNLINK_MENU_SUCCESS:
      return {
        unlinkMenu: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case menuConstants.UNLINK_MENU_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function editBrandMenu(state = {}, action) {
  switch (action.type) {
    case menuConstants.EDIT_BRAND_MENU_RESET:
      return {};
    case menuConstants.EDIT_BRAND_MENU_REQUEST:
      return {
        loading: true,
      };
    case menuConstants.EDIT_BRAND_MENU_SUCCESS:
      return {
        editBrandMenu: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case menuConstants.EDIT_BRAND_MENU_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function menuLastReceipt(state = {}, action) {
  switch (action.type) {
    case menuConstants.MENU_LAST_RECEIPT_RESET:
      return { menuLastReceipt: null };
    case menuConstants.MENU_LAST_RECEIPT_REQUEST:
      return {
        loading: true,
      };
    case menuConstants.MENU_LAST_RECEIPT_SUCCESS:
      return {
        menuLastReceipt: action.result['result'],
      };
    case menuConstants.MENU_LAST_RECEIPT_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function recommendUnlinkMenuData(state = {}, action) {
  switch (action.type) {
    case menuConstants.RECOMMEND_UNLINK_MENU_DATA_REQUEST:
      return {
        loading: true,
      };
    case menuConstants.RECOMMEND_UNLINK_MENU_DATA_SUCCESS:
      return {
        recommendUnlinkMenuData: action.result['result'],
      };
    case menuConstants.RECOMMEND_UNLINK_MENU_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function registBrandMenu(state = {}, action) {
  switch (action.type) {
    case menuConstants.REGIST_BRAND_MENU_RESET:
      return {};
    case menuConstants.REGIST_BRAND_MENU_REQUEST:
      return {
        loading: true,
      };
    case menuConstants.REGIST_BRAND_MENU_SUCCESS:
      return {
        registBrandMenu: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case menuConstants.REGIST_BRAND_MENU_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function deleteCategory(state = {}, action) {
  switch (action.type) {
    case menuConstants.DELETE_CATEGORY_RESET:
      return {};
    case menuConstants.DELETE_CATEGORY_REQUEST:
      return {
        loading: true,
      };
    case menuConstants.DELETE_CATEGORY_SUCCESS:
      return {
        deleteCategory: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case menuConstants.DELETE_CATEGORY_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function disableRetailMenu(state = {}, action) {
  switch (action.type) {
    case menuConstants.DISABLE_RETAIL_MENU_RESET:
      return {};
    case menuConstants.DISABLE_RETAIL_MENU_REQUEST:
      return {
        loading: true,
      };
    case menuConstants.DISABLE_RETAIL_MENU_SUCCESS:
      return {
        disableRetailMenu: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case menuConstants.DISABLE_RETAIL_MENU_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function menuSales(state = {}, action) {
  switch (action.type) {
    case menuConstants.MENU_SALES_RESET:
      return {
        menuSales: undefined,
      };
    case menuConstants.MENU_SALES_REQUEST:
      return {
        loading: true,
      };
    case menuConstants.MENU_SALES_SUCCESS:
      return {
        menuSales: action.result['result'],
        loading: false,
      };
    case menuConstants.MENU_SALES_FAILURE:
      return {
        error: action.error,
        loading: true,
      };
    default:
      return state;
  }
}

export function diffMenuSales(state = {}, action) {
  switch (action.type) {
    case menuConstants.DIFF_MENU_SALES_RESET:
      return {
        diffMenuSales: undefined,
      };
    case menuConstants.DIFF_MENU_SALES_REQUEST:
      return {
        loading: true,
      };
    case menuConstants.DIFF_MENU_SALES_SUCCESS:
      return {
        diffMenuSales: action.result['result'],
      };
    case menuConstants.DIFF_MENU_SALES_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function menuSalesByDaily(state = {}, action) {
  switch (action.type) {
    case menuConstants.MENU_SALES_BY_DAILY_RESET:
      return {
        menuSalesByDaily: undefined,
      };
    case menuConstants.MENU_SALES_BY_DAILY_REQUEST:
      return {
        loading: true,
      };
    case menuConstants.MENU_SALES_BY_DAILY_SUCCESS:
      return {
        menuSalesByDaily: action.result['result'],
        loading: false,
      };
    case menuConstants.MENU_SALES_BY_DAILY_FAILURE:
      return {
        error: action.error,
        loading: true,
      };
    default:
      return state;
  }
}

export function optionMenuSales(state = {}, action) {
  switch (action.type) {
    case menuConstants.OPTION_MENU_SALES_RESET:
      return {
        optionMenuSales: undefined,
      };
    case menuConstants.OPTION_MENU_SALES_REQUEST:
      return {
        loading: true,
      };
    case menuConstants.OPTION_MENU_SALES_SUCCESS:
      return {
        optionMenuSales: action.result['result'],
      };
    case menuConstants.OPTION_MENU_SALES_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function diffOptionMenuSales(state = {}, action) {
  switch (action.type) {
    case menuConstants.DIFF_OPTION_MENU_SALES_RESET:
      return {
        diffOptionMenuSales: undefined,
      };
    case menuConstants.DIFF_OPTION_MENU_SALES_REQUEST:
      return {
        loading: true,
      };
    case menuConstants.DIFF_OPTION_MENU_SALES_SUCCESS:
      return {
        diffOptionMenuSales: action.result['result'],
      };
    case menuConstants.DIFF_OPTION_MENU_SALES_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function oneMenuOptions(state = {}, action) {
  switch (action.type) {
    case menuConstants.ONE_MENU_OPTIONS_RESET:
      return {
        oneMenuOptions: undefined,
      };
    case menuConstants.ONE_MENU_OPTIONS_REQUEST:
      return {
        loading: true,
      };
    case menuConstants.ONE_MENU_OPTIONS_SUCCESS:
      return {
        oneMenuOptions: action.result['result'],
      };
    case menuConstants.ONE_MENU_OPTIONS_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function oneOptionMenuMains(state = {}, action) {
  switch (action.type) {
    case menuConstants.ONE_OPTION_MENU_OPTIONS_RESET:
      return {
        oneOptionMenuMains: undefined,
      };
    case menuConstants.ONE_OPTION_MENU_OPTIONS_REQUEST:
      return {
        loading: true,
      };
    case menuConstants.ONE_OPTION_MENU_OPTIONS_SUCCESS:
      return {
        oneOptionMenuMains: action.result['result'],
      };
    case menuConstants.ONE_OPTION_MENU_OPTIONS_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function oneMenuOffices(state = {}, action) {
  switch (action.type) {
    case menuConstants.ONE_MENU_OFFICES_RESET:
      return {
        oneMenuOffices: undefined,
      };
    case menuConstants.ONE_MENU_OFFICES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case menuConstants.ONE_MENU_OFFICES_SUCCESS:
      return {
        oneMenuOffices: action.result['result'],
        loading: false,
      };
    case menuConstants.ONE_MENU_OFFICES_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function diffOneMenuOffices(state = {}, action) {
  switch (action.type) {
    case menuConstants.DIFF_ONE_MENU_OFFICES_RESET:
      return {
        diffOneMenuOffices: undefined,
      };
    case menuConstants.DIFF_ONE_MENU_OFFICES_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case menuConstants.DIFF_ONE_MENU_OFFICES_SUCCESS:
      return {
        diffOneMenuOffices: action.result['result'],
      };
    case menuConstants.DIFF_ONE_MENU_OFFICES_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function menuSalesSumByDaily(state = {}, action) {
  switch (action.type) {
    case menuConstants.MENU_SALES_SUM_BY_DAILY_RESET:
      return {
        menuSalesSumByDaily: undefined,
      };
    case menuConstants.MENU_SALES_SUM_BY_DAILY_REQUEST:
      return {
        loading: true,
      };
    case menuConstants.MENU_SALES_SUM_BY_DAILY_SUCCESS:
      return {
        menuSalesSumByDaily: action.result['result'],
      };
    case menuConstants.MENU_SALES_SUM_BY_DAILY_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
export function optionMenuSalesSumByDaily(state = {}, action) {
  switch (action.type) {
    case menuConstants.OPTION_MENU_SALES_SUM_BY_DAILY_RESET:
      return {
        optionMenuSalesSumByDaily: undefined,
      };
    case menuConstants.OPTION_MENU_SALES_SUM_BY_DAILY_REQUEST:
      return {
        loading: true,
      };
    case menuConstants.OPTION_MENU_SALES_SUM_BY_DAILY_SUCCESS:
      return {
        optionMenuSalesSumByDaily: action.result['result'],
      };
    case menuConstants.OPTION_MENU_SALES_SUM_BY_DAILY_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function categorySales(state = {}, action) {
  switch (action.type) {
    case menuConstants.CATEGORY_SALES_RESET:
      return {
        categorySales: undefined,
      };
    case menuConstants.CATEGORY_SALES_REQUEST:
      return {
        loading: true,
      };
    case menuConstants.CATEGORY_SALES_SUCCESS:
      return {
        categorySales: action.result['result'],
      };
    case menuConstants.CATEGORY_SALES_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function oneCategoryOffices(state = {}, action) {
  switch (action.type) {
    case menuConstants.ONE_CATEGORY_OFFICES_RESET:
      return {
        oneCategoryOffices: undefined,
      };
    case menuConstants.ONE_CATEGORY_OFFICES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case menuConstants.ONE_CATEGORY_OFFICES_SUCCESS:
      return {
        oneCategoryOffices: action.result['result'],
        loading: false,
      };
    case menuConstants.ONE_CATEGORY_OFFICES_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function tabState(state = { tabState: 'day', prevTabState: null }, action) {
  switch (action.type) {
    case menuConstants.SET_TAB_STATE:
      return {
        ...state,
        tabState: action.tab,
      };
    case menuConstants.SET_PREV_TAB_STATE:
      return {
        ...state,
        prevTabState: action.tab,
      };
    default:
      return state;
  }
}

export function getSuggestMenus(state = {}, action) {
  switch (action.type) {
    case menuConstants.GET_SUGGEST_MENUS_RESET:
      return {
        suggestMenus: {},
      };
    case menuConstants.GET_SUGGEST_MENUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case menuConstants.GET_SUGGEST_MENUS_SUCCESS:
      return {
        suggestMenus: {
          ...state.suggestMenus,
          ...{ [action.result['result']['menu_sno']]: action.result['result']['menus'] },
        },
        loading: false,
      };
    case menuConstants.GET_SUGGEST_MENUS_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
