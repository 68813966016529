import { brandConstants, globalCount } from '../constants';
import { deepClone } from '../helpers';

export function noticeList(state = {}, action) {
  var prev = state.noticeList ? deepClone(state.noticeList) : [];

  switch (action.type) {
    case brandConstants.NOTICE_LIST_RESET:
      return {};
    case brandConstants.NOTICE_LIST_REQUEST:
    case brandConstants.NOTICE_ONE_LOAD_REQUEST:
      return {
        ...state,
      };
    case brandConstants.NOTICE_LIST_SUCCESS:
      return {
        noticeList: prev.concat(action.result['result']),
        rowCount: action.result['row_count'],
      };
    case brandConstants.NOTICE_ONE_LOAD_SUCCESS:
      if (action.result['result']) {
        var findIndex = prev.findIndex(item => item['notice_sno'] === action.result['result']['notice_sno']);
        if (findIndex !== -1) {
          prev[findIndex] = action.result['result'];
        }
      }

      return {
        noticeList: prev,
        oneLoad: action.result['result'],
      };
    case brandConstants.NOTICE_LIST_FAILURE:
    case brandConstants.NOTICE_ONE_LOAD_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function noticeCreate(state = {}, action) {
  switch (action.type) {
    case brandConstants.NOTICE_CREATE_RESET:
      return {};
    case brandConstants.NOTICE_CREATE_REQUEST:
      return {
        loading: true,
      };
    case brandConstants.NOTICE_CREATE_SUCCESS:
      return {
        noticeCreate: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case brandConstants.NOTICE_CREATE_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function noticeEdit(state = {}, action) {
  switch (action.type) {
    case brandConstants.NOTICE_EDIT_RESET:
      return {};
    case brandConstants.NOTICE_EDIT_REQUEST:
      return {
        loading: true,
      };
    case brandConstants.NOTICE_EDIT_SUCCESS:
      return {
        noticeEdit: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case brandConstants.NOTICE_EDIT_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function noticeWriteComment(state = {}, action) {
  switch (action.type) {
    case brandConstants.NOTICE_WRITE_COMMENT_RESET:
      return {};
    case brandConstants.NOTICE_WRITE_COMMENT_REQUEST:
      return {
        loading: true,
      };
    case brandConstants.NOTICE_WRITE_COMMENT_SUCCESS:
      return {
        noticeWriteComment: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case brandConstants.NOTICE_WRITE_COMMENT_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function noticeDisableComment(state = {}, action) {
  switch (action.type) {
    case brandConstants.NOTICE_DISABLE_COMMENT_RESET:
      return {};
    case brandConstants.NOTICE_DISABLE_COMMENT_REQUEST:
      return {
        loading: true,
      };
    case brandConstants.NOTICE_DISABLE_COMMENT_SUCCESS:
      return {
        noticeDisableComment: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case brandConstants.NOTICE_DISABLE_COMMENT_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function noticeConfirm(state = {}, action) {
  switch (action.type) {
    case brandConstants.NOTICE_CONFIRM_RESET:
      return {};
    case brandConstants.NOTICE_CONFIRM_REQUEST:
      return {
        loading: true,
      };
    case brandConstants.NOTICE_CONFIRM_SUCCESS:
      return {
        noticeConfirm: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case brandConstants.NOTICE_CONFIRM_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function noticeReadList(state = {}, action) {
  switch (action.type) {
    case brandConstants.NOTICE_READ_LIST_REQUEST:
      return {
        loading: true,
      };
    case brandConstants.NOTICE_READ_LIST_SUCCESS:
      return {
        noticeReadList: action.result['result'],
      };
    case brandConstants.NOTICE_READ_LIST_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function noticeDisable(state = {}, action) {
  switch (action.type) {
    case brandConstants.NOTICE_DISABLE_RESET:
      return {};
    case brandConstants.NOTICE_DISABLE_REQUEST:
      return {
        loading: true,
      };
    case brandConstants.NOTICE_DISABLE_SUCCESS:
      return {
        noticeDisable: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case brandConstants.NOTICE_DISABLE_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function allBrandList(state = {}, action) {
  switch (action.type) {
    case brandConstants.ALL_BRAND_LIST_REQUEST:
      return {
        loading: true,
      };
    case brandConstants.ALL_BRAND_LIST_SUCCESS:
      return {
        allBrandList: action.result['result'],
      };
    case brandConstants.ALL_BRAND_LIST_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

//매장 관리
export function getFranchiseOfficeStatus(
  state = {
    loading: null,
    managedDatas: null,
    closedDatas: null,
    fullDatas: null,
  },
  action,
) {
  switch (action.type) {
    case brandConstants.GET_FRANCHISE_OFFICE_STATUS_RESET:
      return {
        loading: null,
        managedDatas: null,
        closedDatas: null,
        fullDatas: null,
      };
    case brandConstants.GET_FRANCHISE_OFFICE_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case brandConstants.GET_FRANCHISE_OFFICE_STATUS_SUCCESS:
      let result = action.result;

      // 기존 가지고있는 status와 분석제외 상태여부를 분리하여 판별 (운영중 0, 분석제외 1)
      let _managedDatas = result.result.filter(r => r.closing === 0);
      let _closedDatas = result.result.filter(r => r.closing === 1);

      return {
        loading: false,
        managedDatas: _managedDatas,
        closedDatas: _closedDatas,
        fullDatas: result.result | null,
        officeCnt: result.officeCnt | 0,
        oneStepCnt: result.oneStepCnt | 0,
        twoStepCnt: result.twoStepCnt | 0,
        threeStepCnt: result.threeStepCnt | 0,
        fourStepCnt: result.fourStepCnt | 0,
        totalSignUpCnt: result.totalSignUpCnt | 0,
        totalInstallCnt: result.totalInstallCnt | 0,
        totalAnalysisCnt: result.totalAnalysisCnt | 0,
        issueCnt: result.issueCnt | 0,
        nonPaymentCnt: result.nonPaymentCnt | 0,
        accountLinkCnt: result.accountLinkCnt | 0,
        totalAccountLinkCnt: result.totalAccountLinkCnt | 0,
        accountErrorCnt: result.accountErrorCnt | 0,
        accountLiveCnt: result.accountLiveCnt | 0,
        DCinstallLiveCnt: result.DCinstallLiveCnt | 0,
        totalLinkedCnt: result.totalLinkedCnt | 0,
      };
    case brandConstants.GET_FRANCHISE_OFFICE_STATUS_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function getFranchiseOfficeStatusVer2(
  state = {
    loading: null,
    headerCounts: null,
    officeList: null,
  },
  action,
) {
  switch (action.type) {
    case brandConstants.GET_FRANCHISE_OFFICE_STATUS_RESET_VER2:
      return {
        loading: null,
        headerCounts: null,
        officeList: null,
      };
    case brandConstants.GET_FRANCHISE_OFFICE_STATUS_REQUEST_VER2:
      return {
        ...state,
        loading: true,
      };
    case brandConstants.GET_FRANCHISE_OFFICE_STATUS_SUCCESS_VER2:
      let result = action.result;

      // 기존 가지고있는 status와 분석제외 상태여부를 분리하여 판별 (운영중 0, 분석제외 1)

      return {
        loading: false,
        headerCounts: result.headerCounts,
        officeList: result.officeList
      };
    case brandConstants.GET_FRANCHISE_OFFICE_STATUS_FAILURE_VER2:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function getOrderAppOfficeStatus(state = {}, action) {
  switch (action.type) {
    case brandConstants.GET_ORDERAPP_OFFICE_STATUS_REQUEST:
      return {
        loading: true,
      };
    case brandConstants.GET_ORDERAPP_OFFICE_STATUS_SUCCESS:
      return {
        getOrderAppOfficeStatus: action.result['result'],
      };
    case brandConstants.GET_ORDERAPP_OFFICE_STATUS_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function checkSignUpLinkClick(state = {}, action) {
  switch (action.type) {
    case brandConstants.CHECK_SIGN_UP_LINK_CLICK_REQUEST:
      return {
        loading: true,
      };
    case brandConstants.CHECK_SIGN_UP_LINK_CLICK_SUCCESS:
      return {
        checkSignUpLinkClick: action.result['result'],
      };
    case brandConstants.CHECK_SIGN_UP_LINK_CLICK_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function insertMultiOfficeManagerInfo(state = {}, action) {
  switch (action.type) {
    case brandConstants.INSERT_MULTI_OFFICE_MANAGER_INFO_REQUEST:
      return {
        loading: true,
      };
    case brandConstants.INSERT_MULTI_OFFICE_MANAGER_INFO_SUCCESS:
      let result = action.result;

      return {
        loading: false,
        insertMultiOfficeManagerInfo: result,
      };
    case brandConstants.INSERT_MULTI_OFFICE_MANAGER_INFO_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

// export function insertSingleOfficeManagerInfo(state = {}, action) {
//   switch (action.type) {
//     case brandConstants.INSERT_SINGLE_OFFICE_MANAGER_INFO_REQUEST:
//       return {
//         loading: true,
//       };
//     case brandConstants.INSERT_SINGLE_OFFICE_MANAGER_INFO_SUCCESS:
//       // 리턴으로 들어오는 rtn으로 중복검사 99: 매장명 또는 휴대폰 중복
//       return {
//         loading: false,
//         isSingleAddValid: action.result['result'],
//       };
//     case brandConstants.INSERT_SINGLE_OFFICE_MANAGER_INFO_FAILURE:
//       return {
//         error: action.error,
//       };
//     default:
//       return state;
//   }
// }

// export function editOfficeManagerInfo(state = {}, action) {
//   // edit, 중복체크 관련 flag 받기

//   switch (action.type) {
//     case brandConstants.EDIT_OFFICE_MANAGER_INFO_REQUEST:
//       return {
//         loading: true,
//         franchiseOfficeStatus: null,
//       };
//     case brandConstants.EDIT_OFFICE_MANAGER_INFO_SUCCESS:
//       return {
//         loading: false,
//         franchiseOfficeStatus: action.result['result'],
//       };
//     case brandConstants.EDIT_OFFICE_MANAGER_INFO_FAILURE:
//       return {
//         error: action.error,
//       };
//     default:
//       return state;
//   }
// }

// export function manageFranchiseOfficeStatus(state = {}, action) {
//   switch (action.type) {
//     case brandConstants.MANAGE_FRANCHISE_OFFICE_STATUS_REQUEST:
//       return {
//         loading: true,
//         changeStatus: null,
//       };
//     case brandConstants.MANAGE_FRANCHISE_OFFICE_STATUS_SUCCESS:
//       return {
//         loading: false,
//         changeStatus: action.result['result'],
//         shortUrl: action.result['result'],
//       };
//     case brandConstants.MANAGE_FRANCHISE_OFFICE_STATUS_FAILURE:
//       return {
//         error: action.error,
//       };
//     default:
//       return state;
//   }
// }

// export function checkOfficeClosing(state = {}, action) {
//   // 맞게 바꿔야함
//   switch (action.type) {
//     case brandConstants.CHECK_OFFICE_CLOSING_REQUEST:
//       return {
//         loading: true,
//       };
//     case brandConstants.CHECK_OFFICE_CLOSING_SUCCESS:
//       return {
//         loading: false,
//         franchiseOfficeStatus: action.result['result'],
//       };
//     case brandConstants.CHECK_OFFICE_CLOSING_FAILURE:
//       return {
//         error: action.error,
//       };
//     default:
//       return state;
//   }
// }

// export function removeFranchiseOffice(state = {}, action) {
//   switch (action.type) {
//     case brandConstants.REMOVE_FRANCHISE_OFFICE_REQUEST:
//       return {
//         loading: false,
//         removeFranchiseOffice: null,
//       };
//     case brandConstants.REMOVE_FRANCHISE_OFFICE_SUCCESS:
//       return {
//         loading: action.error,
//         removeFranchiseOffice: action.result['result'],
//       };
//     case brandConstants.REMOVE_FRANCHISE_OFFICE_FAILURE:
//       return {
//         error: action.error,
//       };
//     default:
//       return state;
//   }
// }
