import React, { Fragment } from 'react';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { Col, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { authActions } from '../../actions';
import { authConstants } from '../../constants';
import { getMyUserData, rules, setMyUserData, validation } from '../../helpers';
import styled from 'styled-components';

class EditEmailModal extends React.Component {
  constructor(props) {
    super(props);

    var userData = getMyUserData();

    this.state = {
      userSno: userData['user_sno'],
      inputEmail: userData['email'],
      modal: props.modal,
      changeEmail: undefined,
      isCorrect: false,
    };

    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    var returnState = {};

    if (validation(prevState.inputEmail, rules.email)) {
      returnState.isCorrect = true;
    } else {
      returnState.isCorrect = false;
    }

    if (nextProps.changeEmail !== undefined && !isEqual(nextProps.changeEmail, prevState.changeEmail)) {
      var changeEmail = nextProps.changeEmail;
      var userData = getMyUserData();

      returnState.changeEmail = changeEmail;
      if (changeEmail && changeEmail.result === 1) {
        alert('이메일이 변경되었습니다.');
        nextProps.dispatch({ type: authConstants.CHANGE_EMAIL_RESET });
        userData['email'] = prevState.inputEmail;
        setMyUserData(userData);
      }
    }

    return returnState ? returnState : null;
  }

  componentDidMount() {
    // modal 자체 버그때문에 modal창이 두번 생성되고,
    // 첫번째 modal은 backdrop 속성이 적용되지 않아 강제로 클릭이벤트를 발생시키도록 수정
    setTimeout(() => {
      var element = document.getElementById('dummy');
      if (element) {
        element.click();
      }
    }, 10);
  }

  toggle() {
    this.setState({ modal: !this.state.modal });

    this.props.toggle({ target: { name: 'email' } });
  }

  handleChange(e) {
    e.preventDefault();
    this.setState({ inputEmail: e.target.value });
  }

  handleEnter(e) {
    if (e.key === 'Enter') {
      this.handleSubmit();
    }
  }

  handleSubmit() {
    const { userSno, inputEmail } = this.state;

    if (!validation(inputEmail, rules.email)) {
      alert('입력한 이메일을 확인해 주세요.');
      return;
    }
    if (window.confirm('이메일을 변경 하시겠습니까?')) {
      this.props.dispatch(authActions.changeEmail(userSno, inputEmail));
    }
  }

  render() {
    const { inputEmail, isCorrect } = this.state;

    const btnStyle = {
      isTrue: {
        backgroundColor: '#adb5bd',
        marginBottom: '4px',
      },
      isFalse: {
        backgroundColor: '#adb5bd',
        marginBottom: '4px',
        opacity: '0.5',
        cursor: 'default',
      },
    };
    const footerStyle = {
      textAlign: 'right',
      display: 'block',
      height: '72px',
    };

    return (
      <Fragment>
        <CustomModal isOpen={this.state.modal} className={this.props.className}>
          <div id="dummy" />
          <ModalHeader toggle={this.toggle}>이메일 변경</ModalHeader>
          <ModalBody style={{ paddingBottom: '4px' }}>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <div className="modal-usr-guide">변경할 이메일을 입력하세요.</div>
                  <Input
                    type="email"
                    name="Change Email"
                    value={inputEmail}
                    maxLength="50"
                    placeholder="이메일 변경"
                    onChange={this.handleChange}
                    onKeyPress={this.handleEnter}
                    autoComplete="false"
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter style={footerStyle}>
            <button
              className="ml-auto badge badge-secondary modal-edt-btn"
              style={isCorrect ? btnStyle.isTrue : btnStyle.isFalse}
              onClick={isCorrect ? this.handleSubmit : undefined}
            >
              변경하기
            </button>
          </ModalFooter>
        </CustomModal>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { changeEmail } = state.changeEmail;

  return {
    changeEmail,
  };
}

export default connect(mapStateToProps)(EditEmailModal);

const CustomModal = styled(Modal)`
  .modal-content {
    top: env(safe-area-inset-top);
  }
`;
