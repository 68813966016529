import React, { Component, Fragment } from 'react';
import { concatZeroToTime } from '../helpers';

const defaultTimeLimit = 180;

export class CountDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCount: props.time ? props.time : defaultTimeLimit,
    };

    this.timer = this.timer.bind(this);
  }

  timer() {
    this.setState({ currentCount: this.state.currentCount - 1 });
    if (this.state.currentCount < 1) {
      this.props.timeout && this.props.timeout();
      clearInterval(this.intervalId);
    }
  }

  componentDidMount() {
    this.intervalId = setInterval(this.timer, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  reCount() {
    clearInterval(this.intervalId);

    this.setState({ currentCount: this.props.time ? this.props.time : defaultTimeLimit });
    this.intervalId = setInterval(this.timer, 1000);
  }

  render() {
    const { type } = this.props;

    return (
      <Fragment>
        {!type && (
          <Fragment>
            <span>
              {this.state.currentCount > 0 && (
                <span>
                  {concatZeroToTime(Math.floor(this.state.currentCount / 60)) +
                    ':' +
                    concatZeroToTime(this.state.currentCount % 60) +
                    ' 후 인증번호 만료'}
                </span>
              )}

              {this.state.currentCount <= 0 && <span color="text-danger">인증 시간 초과</span>}
            </span>
          </Fragment>
        )}

        {type === 1 && (
          <Fragment>
            <span>
              {this.state.currentCount > 0 && (
                <span>
                  {concatZeroToTime(Math.floor(this.state.currentCount / 60)) +
                    ':' +
                    concatZeroToTime(this.state.currentCount % 60)}
                </span>
              )}
            </span>
          </Fragment>
        )}
      </Fragment>
    );
  }
}
