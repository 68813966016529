export const PATHS = {
  main: {
    url: '',
  },
  authLogin: {
    url: '/auth/login',
  },
  authForgotPassword: {
    url: '/auth/forgot-password',
  },
  authBrandLogin: {
    url: '/auth/brandLogin',
  },
  authJandiOutgoing: {
    url: '/auth/jandi-outgoing',
  },
  authMobileAuth: {
    url: '/auth/mobileAuth',
  },
  authMobileAuthSuccess: {
    url: '/auth/mobileAuth/success',
  },
  authMobileAuthError: {
    url: '/auth/mobileAuth/error',
  },
  dashboardIndicators: {
    url: '/dashboard/indicators',
  },
  dashboardDelivery: {
    url: '/dashboard/delivery',
  },
  dashboardReview: {
    url: '/dashboard/review',
  },
  dashboardMaterial: {
    url: '/dashboard/material',
  },
  deliveryShop: {
    url: '/delivery/shop',
  },
  deliveryDate: {
    url: '/delivery/date',
  },
  deliveryMenu: {
    url: '/delivery/menu',
  },
  deliveryCh: {
    url: '/delivery/ch',
  },
  deliveryLoc: {
    url: '/delivery/loc',
  },
  materialResource: {
    url: '/material/resource',
  },
  materialDate: {
    url: '/material/date',
  },
  officeDetailSales: {
    url: '/officeDetail/sales',
  },
  menuDetailSales: {
    url: '/menuDetail/sales',
  },
  indicatorsShop: {
    url: '/indicators/shop',
  },
  indicatorsDate: {
    url: '/indicators/date',
  },
  indicatorsMenu: {
    url: '/indicators/menu',
  },
  analysisDateSelectOffice: {
    url: '/analysis/date:select_office',
  },
  analysisDate: {
    url: '/analysis/date',
  },
  analysisTime: {
    url: '/analysis/time',
  },
  analysisWeekday: {
    url: '/analysis/weekday',
  },
  analysisOffice: {
    url: '/analysis/office',
  },
  analysisChannel: {
    url: '/analysis/channel',
  },
  analysisMenu: {
    url: '/analysis/menu',
  },
  analysisLocation: {
    url: '/analysis/location',
  },
  analysisLocationPin: {
    url: '/analysis/locationPin',
  },
  analysisLocationDong: {
    url: '/analysis/locationDong',
  },
  analysisAdvertisement: {
    url: '/analysis/advertisement',
  },
  analysisMonth: {
    url: '/analysis/month',
  },
  analysisQuarter: {
    url: '/analysis/quarter',
  },
  analysisYear: {
    url: '/analysis/year',
  },
  analysisCategory: {
    url: '/analysis/category',
  },
  analysisMaterialDate: {
    url: '/analysis/materialDate',
  },
  analysisMaterialItem: {
    url: '/analysis/materialItem',
  },
  analysisCustomerReviewOffice: {
    url: '/analysis/customerReviewOffice',
  },
  analysisCustomerReviewChannel: {
    url: '/analysis/customerReviewChannel',
  },
  analysisAutomatic: {
    url: '/analysis/automatic',
  },
  analysisAutomaticmonth: {
    url: '/analysis/automaticmonth',
  },
  analysisExcelReport: {
    url: '/analysis/excelReport',
  },
  analysisRegularReport: {
    url: '/analysis/regularReport',
  },
  analysisOtherPage: {
    url: '/analysis/otherPage',
  },
  mobileMain: {
    url: '/mobile/main',
  },
  mobileOfficeOwnerAnalysis: {
    url: '/mobile/officeOwnerAnalysis',
  },
  mobileOfficeOwnerMap: {
    url: '/mobile/officeOwnerMap',
  },
  mobileAccountLink: {
    url: '/mobile/accountLink',
  },
  mobileSignupComplete: {
    url: '/mobile/signupComplete',
  },
  mobileAlarm: {
    url: '/mobile/alarm',
  },
  mobileTransformView: {
    url: '/mobile/transformView',
  },
  mobileMypage: {
    url: '/mobile/mypage',
  },
  mobilePaymentRegist: {
    url: '/mobile/paymentRegist',
  },
  mobilePaymentInfo: {
    url: '/mobile/paymentInfo',
  },
  mobilePaymentPlan: {
    url: '/mobile/paymentPlan',
  },
  mobilePayment: {
    url: '/mobile/payment',
  },
  mobilePaymentCard: {
    url: '/mobile/paymentCard',
  },
  mobilePaymentCardRegist: {
    url: '/mobile/paymentCardRegist',
  },
  mobileCardRegist: {
    url: '/mobile/cardRegistration',
  },
  mobileProgramInstallInfo: {
    url: '/mobile/programInstallInfo',
  },
  mobileCustomerReview: {
    url: '/mobile/customerReview',
  },
  mobileCustomerAutoReview: {
    url: '/mobile/customerAutoReview',
  },
  mobileCustomerReviewAnswer: {
    url: '/mobile/customerReviewAnswer',
  },
  mobileCustomerSpecificReview: {
    url: '/mobile/customerSpecificReview',
  },
  mobileWithdraw: {
    url: '/mobile/withdraw',
  },
  mobileAlarmSetting: {
    url: '/mobile/alarm-setting',
  },
  mobileHelp: {
    url: '/mobile/help',
  },
  mobileHelpGuide: {
    url: '/mobile/help-Guide',
  },
  mobileFranchiseNotice: {
    url: '/mobile/franchiseNotice',
  },
  materialDetailUse: {
    url: '/materialDetail/use',
  },
  reportExcel: {
    url: '/report/excel',
  },
  reportDownload: {
    url: '/report/download',
  },
  backAdminSignup: {
    url: '/back/admin-signup',
  },
  backAccountOTP: {
    url: '/back/account-otp',
  },
  backAdminShadowLogin: {
    url: '/back/admin-shadow-login',
  },
  backBillingTest: {
    url: '/back/billing-test',
  },
  backPayment: {
    url: '/back/payment',
  },
  backBlank: {
    url: '/back/blank',
  },
  serviceBrandNotice: {
    url: '/service/brandNotice',
  },
  serviceBrandDetailNotice: {
    url: '/service/brandDetailNotice',
  },
  serviceBrandRegist: {
    url: '/service/brandRegist',
  },
  serviceBrandEdit: {
    url: '/service/brandEdit',
  },
  serviceNotice: {
    url: '/service/notice',
  },
  serviceTermsOfService: {
    url: '/service/termsOfService',
  },
  servicePrivacyPolicy: {
    url: '/service/privacyPolicy',
  },
  serviceServiceGuide: {
    url: '/service/serviceGuide',
  },
  serviceTermsOfPayment: {
    url: '/service/termsOfPayment',
  },
  devUITestDefault: {
    url: '/devUITest/default',
  },
  redirectTouchingQr: {
    url: '/redirect/touching-qr',
  },
  redirectWithdrawlCancel: {
    url: '/redirect/withdrawcancel',
  },
  policy: {
    url: '/policy',
  },
  eventPromotionBlog: {
    url: '/event/promotion-blog',
  },
  eventPromotionGoogle: {
    url: '/event/promotion-google',
  },
  eventPromotionWmpo: {
    url: '/event/promotion-wmpo',
  },
  previewRealTimeOrder: {
    url: '/preview/realTimeOrder',
  },
  previewMenu: {
    url: '/preview/menu',
  },
  previewLocation: {
    url: '/preview/location',
  },
  previewCustomerReviewChannel: {
    url: '/preview/customerReviewChannel',
  },
  previewMaterialDate: {
    url: '/preview/materialDate',
  },
  previewLocationPin: {
    url: '/preview/locationPin',
  },
  previewReport: {
    url: '/preview/report',
  },
  manageOffice: {
    url: '/manage/office',
  },
  manageMenu: {
    url: '/manage/menu',
  },
  manageMaterial: {
    url: '/manage/material',
  },
  manageRecipe: {
    url: '/manage/recipe',
  },
  manageUser: {
    url: '/manage/user',
  },
  manageOrderApp: {
    url: '/manage/orderApp',
  },
  managePayment: {
    url: '/manage/payment',
  },
  membersSignup: {
    url: '/members/signup',
  },
  membersDownload: {
    url: '/members/download',
  },
  membersPromotionSignup: {
    url: '/members/promotionSignup',
  },
  membersThecheck: {
    url: '/members/thecheck',
  },
  membersSignupComplete: {
    url: '/members/signupComplete',
  },
  membersCollector: {
    url: '/members/collector',
  },
  membersWait: {
    url: '/members/wait',
  },
  membersWmpo: {
    url: '/members/wmpo',
  },
  membersFcdaum: {
    url: '/members/fcdaum',
  },
  membersOfficeAccount: {
    url: '/members/officeAccount',
  },
  marketboro: {
    url: '/marketboro/marketboroguide',
  },
  marketboroAgree: {
    url: '/marketboro/marketboroAgree',
  },
  marketbom: {
    url: '/marketbom/link',
  },
  mobileMarketboroAgree: {
    url: '/marketboro/mobileMarketboroAgree',
  },
  posMyInfo: {
    url: '/pos/myinfo',
  },
  posRegister: {
    url: '/pos/posRegister',
  },
  posCheckInfo: {
    url: '/pos/checkInfo',
  },
};
