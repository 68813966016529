import iconOfficeManageAlert from './icon_officeManage_alert.png';
import iconOfficeManageCancel from './icon_officeManage_cancel.png';
import iconOfficeManageDoc from './icon_officeManage_doc.jpg';
import iconOfficeManageOk from './icon_officeManage_ok.jpg';
import iconOfficeManagePrinter from './icon_officeManage_printer.png';
import iconOfficeManageRadio from './icon_officeManage_radio.png';
import iconOfficeManageSave from './icon_officeManage_save.png';
import iconOfficeManageWrite from './icon_officeManage_write.png';
import iconOfficeManageAdd from './icon_officeManage_add.svg';
import iconATBubbleTooltipClose from './ATBubbleTooltip-close.png';
import iconATBubbleTooltipStar from './ATBubbleTooltip-star.png';
import checkboxBlueCircle from './checkbox_blue_circle.png';
import editCircle from './edit_circle.png';
import editWhite from './edit_white.png';
import emojiBad from './emoji_bad.png';
import emojiNormal from './emoji_normal.png';
import emojiVIP from './emoji_VIP.png';
import iconOfficeManageDelay from './iconOfficeManageDelay.png';
import iconOfficeManageDisable from './iconOfficeManageDisable.png';
import iconOfficeManageLazy from './iconOfficeManageLazy.png';
import iconCheckCircleActive from './icon_check_circle_active.svg';
import iconCheckCircleDefault from './icon_check_circle_default.svg';
import iconCustomerMemoDelete from './icon_customer_memo_delete.png';
import iconMemoBlack from './icon_memo_black.svg';
import iconMeMoBlue from './icon_memo_blue.svg';
import iconUserManageAlert from './icon_officeManage_alert.svg';
import iconOfficeManageCloseWirte from './icon_officeManage_closeWrite.svg';
import iconUserManageOk from './icon_officeManage_ok.svg'; // jpg
import iconUserManageOkDimmed from './icon_officeManage_ok_dimmed.svg';
import iconSignUpCheckActive from './icon_signUp_check_active.png';
import iconSignUpCheckDefault from './icon_signUp_check_default.png';
import signUpAttrackerLogo from './logo-inverse.png';
import signUpSliderImage1 from './originals/login01.jpg';
import signUpSliderImage2 from './originals/login02.jpg';
import signUpSliderImage3 from './originals/login03.jpg';
import chatIconBlack from './chat_icon_black.png';
import wmpoSignout from './wmpo-signout.svg';
import iconLock from './icon-lock.svg';
import wmpofirstStep from './firstStep.svg';
import wmpoSecondStep from './secondStep.svg';
import iconLeft from './leftIcon.svg';
import iconRight from './rightIcon.svg';
import iconwmpoBtn from './IconwmpoBtn.svg';
import BILogo from './BILogo.svg';
import iconLeftDisable from './disableLeftIcon.svg';
import iconRightDisable from './disableRightIcon.svg';
import wmpoRegister from './wmpoRegister.svg';
import spinner from './spinner.gif';
import pageNotFound from './404Img.png';
import pageNotFoundLeftIcon from './404leftBtn.svg';
import pageNotFoundRightIcon from './404rightBtn.svg';
import negativeNew from './negative.svg';
import authNextIcon from './authNextIcon.svg';
import officeCheckBtn from './officeCheckBtn.svg';
import checkedOfficeBtn from './checkedOfficeBtn.svg';
import checkedAreaBtn from './checkedAreaBtn.svg';
import progressCheck from './progressCheck.svg';
import iconBorder from './iconBorder.svg';
import cardAddBtn from './cardAddBtn.svg';
import installLock from './installLock.svg';
import posIntall from './pos_install.svg';
import dataProcessing from './data_processing.svg';
import dataError from './data_error.svg';
import IconCheck from './icon_check_circle_default_2.svg';
import IconChecked from './icon_check_circle_active_blue.svg';
import disableCheckbox from './disableCheckbox.svg';
import cardRegistIcon from './cardRegistIcon.svg';
import titletooltip from './titletooltip.svg';
import checkboxOn from './Checkbox_m_on.svg';
import errorPin from './errorPin.svg';
import timeIcon from './timeIcon.svg';
import iconPosStore from '../images/icons/iconPosStore.svg';
import iconPosdelivery from '../images/icons/iconPosdelivery.svg';
import iconAppdelivery from '../images/icons/icon_app_delivery.svg'
import filterCheck from './filterCheck.svg'
import noticeNew from '../images/icons/noticeNew.svg';
import noticeMsg from '../images/icons/noticeMsg.svg';
import noticeCheck from '../images/icons/noticeCheck.svg';
import undefinedCheck from '../images/icons/undefinedCheck.svg';
import noticeTitle from './noticeTitle.svg';
import downloadFile from './downloadFile.svg';
import emptyCheck from './emptyCheck.svg';
import iconCheckActive from '../images/icons/icon_check_active.svg';
import registNotice from './registNotice.svg';
import noticeArrowBtn from './noticeArrowBtn.svg';
import noticeMsgIcon from './noticeMsgIcon.svg';
import emptyNotice from './emptyNotice.svg';
import allChecked from './allChecked.svg';
import checkedNotice from './checkedNotice.svg'
import editDelete from './editDelete.svg'
import tooltipIcon from './tooltipIcon.svg'
import tooltipChannelTalkIcon from './tooltipChaneltalk.svg'
import oneMonth from './oneMonth2.svg';
import oneMonthBg from './oneMonthBg.svg';
import closeAutoReview from './closeAutoReview.svg';
import noneAutoReview from './noneAutoReview.svg';
import autoReview from './autoReview.svg';
import redNoticeIcon from './redNoticeIcon.svg';
import inverseRedNoticeIcon from './inverseRedNoticeIcon.svg';
import autoReviewOffice from './autoReviewOffice.svg';
import closeShop from './closeShop.svg';
import emptyChannel from './emptyChannel.svg';
import loadIcon from './loadIcon.svg';
import mainMoreIcon from './mainMoreIcon.svg';
import homeIcon from './homeIcon.svg';
import adIcon from './adIcon.svg';
import materialIcon from './materialIcon.svg';
import moreIcon from './moreIcon.svg';

import filterSelect from './filter-select.svg';
import filterSelectBlue from './filter-select-blue.svg';
import redTriangle from './redTriangle.svg';
import grayTriangle from './grayTriangle.svg';


const IMAGES = {
  filterCheck,
  oneMonth,
  oneMonthBg,
  tooltipIcon,
  tooltipChannelTalkIcon,
  iconOfficeManageWrite,
  iconOfficeManageAlert,
  iconOfficeManageDoc,
  iconOfficeManageOk,
  iconOfficeManagePrinter,
  iconOfficeManageRadio,
  iconOfficeManageSave,
  iconOfficeManageCancel,
  iconOfficeManageAdd,
  iconOfficeManageCloseWirte,
  iconATBubbleTooltipClose,
  iconATBubbleTooltipStar,
  iconSignUpCheckActive,
  iconSignUpCheckDefault,
  signUpSliderImage1,
  signUpSliderImage2,
  signUpSliderImage3,
  signUpAttrackerLogo,
  iconCheckCircleDefault,
  iconCheckCircleActive,
  iconUserManageAlert,
  iconUserManageOk,
  iconUserManageOkDimmed,
  iconCustomerMemoDelete,
  editCircle,
  checkboxBlueCircle,
  emojiBad,
  emojiVIP,
  iconOfficeManageDisable,
  emojiNormal,
  editWhite,
  iconOfficeManageLazy,
  iconOfficeManageDelay,
  iconMeMoBlue,
  iconMemoBlack,
  chatIconBlack,
  wmpoSignout,
  iconLock,
  wmpofirstStep,
  wmpoSecondStep,
  iconLeft,
  iconRight,
  iconwmpoBtn,
  BILogo,
  iconLeftDisable,
  iconRightDisable,
  wmpoRegister,
  spinner,
  pageNotFound,
  pageNotFoundLeftIcon,
  pageNotFoundRightIcon,
  negativeNew,
  authNextIcon,
  officeCheckBtn,
  checkedOfficeBtn,
  checkedAreaBtn,
  progressCheck,
  iconBorder,
  cardAddBtn,
  installLock,
  posIntall,
  dataProcessing,
  dataError,
  IconCheck,
  IconChecked,
  disableCheckbox,
  cardRegistIcon,
  titletooltip,
  checkboxOn,
  errorPin,
  timeIcon,
  iconPosStore,
  iconPosdelivery,
  iconAppdelivery,
  noticeNew,
  noticeMsg,
  noticeCheck,
  undefinedCheck,
  noticeTitle,
  downloadFile,
  emptyCheck,
  iconCheckActive,
  registNotice,
  noticeArrowBtn,
  noticeMsgIcon,
  emptyNotice,
  allChecked,
  checkedNotice,
  editDelete,
  filterSelect,
  filterSelectBlue,
  closeAutoReview,
  noneAutoReview,
  autoReview,
  redNoticeIcon,
  inverseRedNoticeIcon,
  autoReviewOffice,
  closeShop,
  emptyChannel,
  redTriangle,
  grayTriangle,
  emptyChannel,
  loadIcon,
  mainMoreIcon,
  homeIcon,
  adIcon,
  materialIcon,
  moreIcon
};

export default IMAGES;
