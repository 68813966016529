import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module
import BtnClose from '../../assets/utils/images/closeGray.svg';
import checkBox from '../../assets/utils/images/check_bg_default.svg';
import checkBoxChecked from '../../assets/utils/images/check_bg_blue.svg';
import HeadTxt from '../../assets/utils/images/app-launching/AppHeadTxt.png';
import Image1 from '../../assets/utils/images/app-launching/AppImg_1.png';
import Image2 from '../../assets/utils/images/app-launching/AppImg_2.png';
import Image3 from '../../assets/utils/images/app-launching/AppImg_3.png';
import IconNext from '../../assets/utils/images/marketboro/next.svg';
import IconPrev from '../../assets/utils/images/marketboro/prev.svg';

export const ModalAppLaunching = () => {
  const [isModal, setisModal] = useState(true);
  const checkboxref = useRef(null);
  const items = [
    {
      src: Image1,
      alt: 'AppLaunchingImg-1',
    },
    {
      src: Image2,
      alt: 'AppLaunchingImg-2',
    },
    {
      src: Image3,
      alt: 'AppLaunchingImg-3',
    },
  ];
  const closeModal = expireDays => {
    let expire = new Date();
    expire.setTime(expire.getTime() + expireDays * 7 * 24 * 60 * 60 * 1000);
    localStorage.setItem('modalNoShow', expire.getTime());
  };
  const checkModalClose = () => {
    const expireDay = localStorage.getItem('modalNoShow');
    let today = new Date();
    if (today.getTime() > expireDay) {
      return false;
    } else {
      return true;
    }
  };
  const NDayModalHideBtn = () => {
    closeModal(1);
  };
  useEffect(() => {
    checkModalClose() ? setisModal(false) : setisModal(true);
  }, []);

  return (
    <>
      {isModal && (
        <AppLaunchingModal id="appLaunchingModal">
          <div className="preview-container page-content-container mt0">
            <div className="preview-center page-content-area">
              <div className="preview-guide-container">
                <div
                  className="btn-close"
                  onClick={() => {
                    setisModal(false);
                  }}
                ></div>
                <div className="sub-title">
                  <img src={HeadTxt} />
                </div>
                <div className="content-slide">
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={0}
                    autoplay={{ delay: 2700, disableOnInteraction: false, stopOnLastSlide: true }}
                    navigation={{ clickable: true }}
                    pagination={{ clickable: true }}
                    scrollbar={false}
                    mousewheel={true}
                    modules={[Navigation, Pagination, Autoplay]}
                    className="mySwiper"
                  >
                    {items.map((item, idx) => {
                      return (
                        <SwiperSlide key={idx}>
                          <img id={item.alt} src={item.src} alt={item.alt} />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                  <div className="modal-footer-wrap" onClick={NDayModalHideBtn}>
                    <input ref={checkboxref} id="modalcheckBox" type="checkbox" value="check" />
                    <label for="modalcheckBox"></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AppLaunchingModal>
      )}
    </>
  );
};
const AppLaunchingModal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 101;
  width: 100%;
  height: 100%;
  background-color: rgba(168, 171, 192, 0.6);
  .modal-footer-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 18px;
    background-color: rgba(255, 255, 255, 0.98);
    border-radius: 0 0 12px 12px;
    input {
      cursor: pointer;
    }
    input[type='checkbox'] {
      display: none;
    }
    input[type='checkbox'] + label {
      width: 20px;
      height: 20px;
      position: relative;
      cursor: pointer;
      left: 0;
      content: '';
      background-image: url(${checkBox});
      background-size: 20px 20px;
      background-repeat: no-repeat;
      background-position: center;
      &:after {
        content: '7일간 열지 않기';
        white-space: nowrap;
        position: relative;
        left: 15px;
        bottom: 1px;
      }
    }
    input[type='checkbox']:checked + label {
      background-image: url(${checkBoxChecked});
      background-size: 20px 20px;
      background-repeat: no-repeat;
      background-position: center;
    }
    input[type='checkbox']:checked {
      fill: #fff;
      opacity: 1;
    }
    label {
      margin-bottom: 0;
      padding-left: 12px;
      cursor: pointer;
    }
  }
  .hidden {
    display: none;
  }
  .visible {
    display: block !important;
  }
  .preview-container {
    max-height: unset;
    height: calc(100vh - 56px);
    min-height: calc(100vh - 136px);
    padding: 0px;
    .preview-center {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px;
    }
  }
  .page-content-container {
    background: unset;
  }
  .page-content-area {
    background: unset;
  }
  .preview-guide-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #edeffa;
    box-shadow: 0px 6.82667px 13.65333px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    padding-top: 56px;
    border-radius: 12px;
    width: 640px;
    .btn-close {
      background-image: url(${BtnClose});
      position: absolute;
      top: 16px;
      right: 16px;
      width: 14px;
      height: 14px;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
    }
    .video-container {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 100;
      width: 100%;
      height: 100%;
      border-radius: 14px;
      iframe {
        position: relative;
        border-radius: 14px;
        width: 100%;
        height: 406px;
      }
    }
  }
  .preview-title {
    color: #313132;
    font-size: 32px;
    font-weight: 900;
    letter-spacing: -0.84px;
    text-align: center;
  }
  .sub-title {
    color: #15cc74;
    font-weight: 800;
    font-size: 18px;
    text-align: center;
    margin-bottom: 16px;
    img {
      width: 229px;
    }
  }
  .content-txt {
    color: #313132;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 28px;
    opacity: 0.8;
    text-align: center;
    .point-color {
      color: #4d51ed;
    }
  }
  .preview-content {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: #262626;
    margin: 0px;
    text-align: center;
    span {
      color: #c9c9c9;
    }
  }
  .content-slide {
    text-align: center;
    .swiper {
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 640px;
    }
    .swiper-wrapper {
      text-align: center;
      align-items: center;
      border-radius: 24px;
      .swiper-slide {
        img {
          z-index: 233;
          width: 482px;
        }
      }
    }
    .swiper-button-prev {
      background-image: url(${IconPrev});
      left: 24px;
      background-size: 20px 20px;
      background-color: #fff;
      border-radius: 50%;
      top: 40%;
      width: 36px;
      height: 36px;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.13);
      border: 1px solid rgba(0, 0, 0, 0.15);
      background-repeat: no-repeat;
      background-position: center;
      z-index: 11;
      &.swiper-button-disabled {
        box-shadow: unset;
      }
      &::after {
        display: none;
        right: 24px;
      }
    }
    .swiper-button-next {
      background-image: url(${IconNext});
      background-size: 20px 20px;
      background-color: #fff;
      top: 40%;
      right: 24px;
      border-radius: 50%;
      width: 36px;
      height: 36px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.13);
      background-repeat: no-repeat;
      background-position: center;
      z-index: 11;
      &.swiper-button-disabled {
        box-shadow: unset;
      }
      &::after {
        display: none;
      }
    }
    .swiper-pagination {
      top: -7px;
      height: 0;
      .swiper-pagination-bullet-active {
        background-color: #262626;
      }
    }
  }
  .service-submit {
    background: rgba(255, 255, 255, 0.5);
    border: 2px solid #666af0;
    border-radius: 48px;
    color: #666af0;
    padding: 14px 32px 15px 40px;
    font-weight: 700;
    font-size: 15px;
    display: flex;
    align-items: center;
    line-height: 22px;
  }
  .service-submit-icon {
    margin-left: 15px;
  }
`;
