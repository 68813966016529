import classNames from 'classnames';
import React, { useState } from 'react';
import styled from 'styled-components';
import './form.scss';

const ATItemBox = ({ label, inputDefaultValue = false, className, id, disabled, checked, onChange = {} }) => {
  const [value, setValue] = useState(inputDefaultValue);

  return (
    <>
      <LabelWrapper className={classNames('custom-check', className)}>
        <input
          type="checkbox"
          id={id}
          className="custom-check-input"
          name="value"
          checked={checked}
          disabled={disabled}
        />
        <label htmlFor={id} className="custom-check-label" onClick={onChange}>
          <span className="text">{label}</span>
        </label>
      </LabelWrapper>
    </>
  );
};

const LabelWrapper = styled.div`
  .custom-check-input {
    &:before {
    }
  }
`;

export default ATItemBox;
