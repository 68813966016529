import { globalCount, officeConstants } from '../constants';
import { convertForFindName, getMyUserData } from '../helpers';

export function managedOfficeList(state = {}, action) {
  switch (action.type) {
    case officeConstants.MANAGED_OFFICE_LIST_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.MANAGED_OFFICE_LIST_SUCCESS:
      let result = action.result['result'];
      let userData = getMyUserData(false);

      // 처음 생성되어 매장이 없는 마스터관리자인 경우,
      // 튜토리얼 진행 여부에 따라 [서비스안내] or [매장 관리] 페이지로 리다이렉트
      // if (result && result.length === 0 && userData.levels >= 400) {
      //   if (isCompleteTutorial(TUTORIAL_STATUS_INDEX.FRANCHISE_SERVICE_GUIDE)) {
      //     window.location.href = '#/manage/office';
      //   } else {
      //     window.location.href = '#/service/serviceGuide';
      //   }
      // }

      return {
        managedOfficeList: result,
        officeNames: convertForFindName(result, 'office_sno', 'partner_name'),
        loading: false,
      };
    case officeConstants.MANAGED_OFFICE_LIST_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function allOfficeList(state = {}, action) {
  switch (action.type) {
    case officeConstants.ALL_OFFICE_LIST_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.ALL_OFFICE_LIST_SUCCESS:
      return {
        allOfficeList: action.result['result'],
      };
    case officeConstants.ALL_OFFICE_LIST_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function areaList(state = {}, action) {
  switch (action.type) {
    case officeConstants.AREA_LIST_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.AREA_LIST_SUCCESS:
      return {
        areaList: action.result['result'],
      };
    case officeConstants.AREA_LIST_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function saleOfficeHeaderData(state = {}, action) {
  switch (action.type) {
    case officeConstants.RESETSTATE:
      return {};
    case officeConstants.SALE_OFFICE_HEADER_DATA_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.SALE_OFFICE_HEADER_DATA_SUCCESS:
      return {
        saleOfficeHeaderData: action.result['result'],
      };
    case officeConstants.SALE_OFFICE_HEADER_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function saleDateListData(state = {}, action) {
  switch (action.type) {
    case officeConstants.SALE_DATE_LIST_DATA_RESET:
      return {
        saleDateListData: null,
      };
    case officeConstants.SALE_DATE_LIST_DATA_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.SALE_DATE_LIST_DATA_SUCCESS:
      return {
        saleDateListData: action.result['result'],
      };
    case officeConstants.SALE_DATE_LIST_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function saleWeekDayListData(state = {}, action) {
  switch (action.type) {
    case officeConstants.SALE_WEEK_DAY_LIST_DATA_RESET:
      return {
        saleWeekDayListData: undefined,
      };
    case officeConstants.SALE_WEEK_DAY_LIST_DATA_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.SALE_WEEK_DAY_LIST_DATA_SUCCESS:
      return {
        saleWeekDayListData: action.result['result'],
      };
    case officeConstants.SALE_WEEK_DAY_LIST_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function oneOfficeSaleWeekDayListData(state = {}, action) {
  switch (action.type) {
    case officeConstants.ONE_OFFICE_SALE_WEEK_DAY_LIST_DATA_RESET:
      return {
        oneOfficeSaleWeekDayListData: undefined,
      };
    case officeConstants.ONE_OFFICE_SALE_WEEK_DAY_LIST_DATA_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.ONE_OFFICE_SALE_WEEK_DAY_LIST_DATA_SUCCESS:
      return {
        oneOfficeSaleWeekDayListData: action.result['result'],
      };
    case officeConstants.ONE_OFFICE_SALE_WEEK_DAY_LIST_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function saleOfficeListData(state = {}, action) {
  switch (action.type) {
    case officeConstants.SALE_OFFICE_LIST_DATA_RESET:
      return {
        saleOfficeListData: null,
      };
    case officeConstants.SALE_OFFICE_LIST_DATA_REQUEST:
      return {
        ...state,
      };
    case officeConstants.SALE_OFFICE_LIST_DATA_SUCCESS:
      return {
        saleOfficeListData: {
          ...state.saleOfficeListData,
          [action.result['limitStart']]: action.result['result'],
        },
        rowCount: action.result['row_count'],
        headerData: action.result['headerData'],
      };
    case officeConstants.SALE_OFFICE_LIST_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function deliveryHeaderData(state = {}, action) {
  switch (action.type) {
    case officeConstants.RESETSTATE:
      return {};
    case officeConstants.DELIVERY_HEADER_DATA_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.DELIVERY_HEADER_DATA_SUCCESS:
      return {
        deliveryHeaderData: action.result['result'],
      };
    case officeConstants.DELIVERY_HEADER_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function deliveryOfficeListData(state = {}, action) {
  switch (action.type) {
    case officeConstants.DELIVERY_OFFICE_LIST_DATA_RESET:
      return {
        deliveryOfficeListData: null,
      };
    case officeConstants.DELIVERY_OFFICE_LIST_DATA_REQUEST:
      return {
        ...state,
      };
    case officeConstants.DELIVERY_OFFICE_LIST_DATA_SUCCESS:
      return {
        deliveryOfficeListData: {
          ...state.deliveryOfficeListData,
          [action.result['limitStart']]: action.result['result'],
        },
        rowCount: action.result['row_count'],
        headerData: action.result['headerData'],
      };
    case officeConstants.DELIVERY_OFFICE_LIST_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function deliveryDateListData(state = {}, action) {
  switch (action.type) {
    case officeConstants.DELIVERY_DATE_LIST_DATA_RESET:
      return {};
    case officeConstants.DELIVERY_DATE_LIST_DATA_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.DELIVERY_DATE_LIST_DATA_SUCCESS:
      return {
        deliveryDateListData: action.result['result'],
      };
    case officeConstants.DELIVERY_DATE_LIST_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function inRangeDeliveryDateListData(state = {}, action) {
  switch (action.type) {
    case officeConstants.IN_RANGE_DELIVERY_DATE_LIST_DATA_RESET:
      return {
        inRangeDeliveryDateListData: [],
        inRangeDeliveryDateListDataDetails: [],
        inRangeSelectedOfficeSno: 0,
        totalChannels: [],
      };
    case officeConstants.IN_RANGE_DELIVERY_DATE_LIST_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case officeConstants.IN_RANGE_DELIVERY_DATE_LIST_DATA_SUCCESS:
      return {
        loading: false,
        inRangeDeliveryDateListData: action.result['result'],
        inRangeDeliveryDateListDataDetails: action.result['details'],
        inRangeSelectedOfficeSno: action.result['office_sno'],
        totalChannels: action.result['total_channels'],
      };
    case officeConstants.IN_RANGE_DELIVERY_DATE_LIST_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function deliveryWeekDayListData(state = {}, action) {
  switch (action.type) {
    case officeConstants.DELIVERY_WEEK_DAY_LIST_DATA_RESET:
      return { deliveryWeekDayListData: null };
    case officeConstants.DELIVERY_WEEK_DAY_LIST_DATA_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.DELIVERY_WEEK_DAY_LIST_DATA_SUCCESS:
      return {
        deliveryWeekDayListData: action.result['result'],
      };
    case officeConstants.DELIVERY_WEEK_DAY_LIST_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function oneOfficeDeliveryWeekDayListData(state = {}, action) {
  switch (action.type) {
    case officeConstants.ONE_OFFICE_DELIVERY_WEEK_DAY_LIST_DATA_RESET:
      return {
        oneOfficeDeliveryWeekDayListData: undefined,
      };
    case officeConstants.ONE_OFFICE_DELIVERY_WEEK_DAY_LIST_DATA_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.ONE_OFFICE_DELIVERY_WEEK_DAY_LIST_DATA_SUCCESS:
      return {
        oneOfficeDeliveryWeekDayListData: action.result['result'],
      };
    case officeConstants.ONE_OFFICE_DELIVERY_WEEK_DAY_LIST_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function deliveryOfficeAllListData(state = {}, action) {
  switch (action.type) {
    case officeConstants.DELIVERY_OFFICE_ALL_LIST_DATA_RESET:
      return {};
    case officeConstants.DELIVERY_OFFICE_ALL_LIST_DATA_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case officeConstants.DELIVERY_OFFICE_ALL_LIST_DATA_SUCCESS:
      return {
        deliveryOfficeAllListData: action.result['result'],
      };
    case officeConstants.DELIVERY_OFFICE_ALL_LIST_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function deliveryOfficeMarker(state = {}, action) {
  switch (action.type) {
    case officeConstants.DELIVERY_OFFICE_MARKER_RESET:
      return {};
    case officeConstants.DELIVERY_OFFICE_MARKER_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case officeConstants.DELIVERY_OFFICE_MARKER_SUCCESS:
      return {
        deliveryOfficeMarker: {
          result: action.result['result'],
          officeSno: action.result['officeSno'],
        },
        avgTotalPayAmount: action.result['avg'],
      };
    case officeConstants.DELIVERY_OFFICE_MARKER_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function deliveryMarkerDetail(state = {}, action) {
  switch (action.type) {
    case officeConstants.DELIVERY_MARKER_DETAIL_RESET:
      return {};
    case officeConstants.DELIVERY_MARKER_DETAIL_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.DELIVERY_MARKER_DETAIL_SUCCESS:
      return {
        deliveryMarkerDetail: action.result['result'],
      };
    case officeConstants.DELIVERY_MARKER_DETAIL_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function mainSaleData(state = {}, action) {
  switch (action.type) {
    case officeConstants.RESETSTATE:
      return {};
    case officeConstants.MAIN_SALE_DATA_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.MAIN_SALE_DATA_SUCCESS:
      return {
        mainSaleData: action.result['result'],
      };
    case officeConstants.MAIN_SALE_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function mainDeliveryData(state = {}, action) {
  switch (action.type) {
    case officeConstants.RESETSTATE:
      return {};
    case officeConstants.MAIN_DELIVERY_DATA_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.MAIN_DELIVERY_DATA_SUCCESS:
      return {
        mainDeliveryData: action.result['result'],
      };
    case officeConstants.MAIN_DELIVERY_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function saleTotalData(state = {}, action) {
  switch (action.type) {
    case officeConstants.SALE_TOTAL_DATA_RESET:
      return {
        saleTotalData: null,
      };
    case officeConstants.SALE_TOTAL_DATA_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.SALE_TOTAL_DATA_SUCCESS:
      return {
        saleTotalData: action.result['result'],
      };
    case officeConstants.SALE_TOTAL_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function deliveryTotalData(state = {}, action) {
  switch (action.type) {
    case officeConstants.DELIVERY_TOTAL_DATA_RESET:
      return {
        deliveryTotalData: null,
      };
    case officeConstants.DELIVERY_TOTAL_DATA_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.DELIVERY_TOTAL_DATA_SUCCESS:
      return {
        deliveryTotalData: action.result['result'],
      };
    case officeConstants.DELIVERY_TOTAL_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function posSignalList(state = {}, action) {
  switch (action.type) {
    case officeConstants.POS_SIGNAL_LIST_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.POS_SIGNAL_LIST_SUCCESS:
      return {
        posSignalList: action.result['result'],
      };
    case officeConstants.POS_SIGNAL_LIST_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function deliveryOrderAppDateListData(state = {}, action) {
  switch (action.type) {
    case officeConstants.DELIVERY_ORDER_APP_DATE_LIST_DATA_RESET:
      return {
        deliveryOrderAppDateListData: undefined,
      };
    case officeConstants.DELIVERY_ORDER_APP_DATE_LIST_DATA_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.DELIVERY_ORDER_APP_DATE_LIST_DATA_SUCCESS:
      return {
        deliveryOrderAppDateListData: {
          dataList: action.result['result']['dataList'],
          summary: action.result['result']['summary'],
        },
      };
    case officeConstants.DELIVERY_ORDER_APP_DATE_LIST_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function deliveryCustomerRating(state = {}, action) {
  switch (action.type) {
    case officeConstants.DELIVERY_CUSTOMER_RATING_RESET:
      return {
        deliveryCustomerRating: undefined,
      };
    case officeConstants.DELIVERY_CUSTOMER_RATING_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.DELIVERY_CUSTOMER_RATING_SUCCESS:
    case officeConstants.EDIT_DELIVERY_CUSTOMER_TYPE_BIT_SUCCESS:
    case officeConstants.ADD_DELIVERY_CUSTOMER_COMMENT_SUCCESS:
    case officeConstants.DELETE_DELIVERY_CUSTOMER_COMMENT_SUCCESS:
      return {
        deliveryCustomerRating: action.result['result'],
      };
    case officeConstants.DELIVERY_CUSTOMER_RATING_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
export function editDeliveryCustomerTypeBit(state = {}, action) {
  switch (action.type) {
    case officeConstants.EDIT_DELIVERY_CUSTOMER_TYPE_BIT_RESET:
      return {
        editDeliveryCustomerTypeBit: undefined,
      };
    case officeConstants.EDIT_DELIVERY_CUSTOMER_TYPE_BIT_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.EDIT_DELIVERY_CUSTOMER_TYPE_BIT_SUCCESS:
      return {
        editDeliveryCustomerTypeBit: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case officeConstants.EDIT_DELIVERY_CUSTOMER_TYPE_BIT_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function addDeliveryCustomerComment(state = {}, action) {
  switch (action.type) {
    case officeConstants.ADD_DELIVERY_CUSTOMER_COMMENT_RESET:
      return {
        addDeliveryCustomerComment: undefined,
      };
    case officeConstants.ADD_DELIVERY_CUSTOMER_COMMENT_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.ADD_DELIVERY_CUSTOMER_COMMENT_SUCCESS:
      return {
        addDeliveryCustomerComment: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case officeConstants.ADD_DELIVERY_CUSTOMER_COMMENT_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
export function deleteDeliveryCustomerComment(state = {}, action) {
  switch (action.type) {
    case officeConstants.DELETE_DELIVERY_CUSTOMER_COMMENT_RESET:
      return {
        deleteDeliveryCustomerComment: undefined,
      };
    case officeConstants.DELETE_DELIVERY_CUSTOMER_COMMENT_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.DELETE_DELIVERY_CUSTOMER_COMMENT_SUCCESS:
      return {
        deleteDeliveryCustomerComment: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case officeConstants.DELETE_DELIVERY_CUSTOMER_COMMENT_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function oneCustomerDeliveryDetail(state = {}, action) {
  switch (action.type) {
    case officeConstants.ONE_CUSTOMER_DELIVERY_DETAIL_RESET:
      return {
        oneCustomerDeliveryDetail: undefined,
      };
    case officeConstants.ONE_CUSTOMER_DELIVERY_DETAIL_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.ONE_CUSTOMER_DELIVERY_DETAIL_SUCCESS:
      return {
        oneCustomerDeliveryDetail: action.result,
      };
    case officeConstants.ONE_CUSTOMER_DELIVERY_DETAIL_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function dailySales(state = {}, action) {
  switch (action.type) {
    case officeConstants.DAILY_SALES_RESET:
      return {
        dailySales: undefined,
      };
    case officeConstants.DAILY_SALES_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.DAILY_SALES_SUCCESS:
      return {
        dailySales: action.result['result'],
        loading: false,
      };
    case officeConstants.DAILY_SALES_FAILURE:
      return {
        error: action.error,
        loading: true,
      };
    default:
      return state;
  }
}

export function timeSales(state = {}, action) {
  switch (action.type) {
    case officeConstants.TIME_SALES_RESET:
      return {
        timeSales: undefined,
      };
    case officeConstants.TIME_SALES_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.TIME_SALES_SUCCESS:
      return {
        timeSales: action.result['result'],
        loading: false,
      };
    case officeConstants.TIME_SALES_FAILURE:
      return {
        error: action.error,
        loading: true,
      };
    default:
      return state;
  }
}

export function weekdaySales(state = {}, action) {
  switch (action.type) {
    case officeConstants.WEEKDAY_SALES_RESET:
      return {
        weekdaySales: undefined,
      };
    case officeConstants.WEEKDAY_SALES_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.WEEKDAY_SALES_SUCCESS:
      return {
        weekdaySales: action.result['result'],
        loading: false,
      };
    case officeConstants.WEEKDAY_SALES_FAILURE:
      return {
        error: action.error,
        loading: true,
      };
    default:
      return state;
  }
}

export function officeSales(state = {}, action) {
  switch (action.type) {
    case officeConstants.OFFICE_SALES_RESET:
      return {
        officeSales: undefined,
      };
    case officeConstants.OFFICE_SALES_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.OFFICE_SALES_SUCCESS:
      return {
        officeSales: action.result['result'],
        loading: false,
      };
    case officeConstants.OFFICE_SALES_FAILURE:
      return {
        error: action.error,
        loading: true,
      };
    default:
      return state;
  }
}

export function officeSalesByDaily(state = {}, action) {
  switch (action.type) {
    case officeConstants.OFFICE_SALES_DAILY_RESET:
      return {
        officeSalesByDaily: undefined,
      };
    case officeConstants.OFFICE_SALES_DAILY_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.OFFICE_SALES_DAILY_SUCCESS:
      return {
        officeSalesByDaily: action.result['result'],
        loading: false,
      };
    case officeConstants.OFFICE_SALES_DAILY_FAILURE:
      return {
        error: action.error,
        loading: true,
      };
    default:
      return state;
  }
}

export function orderAppSales(state = {}, action) {
  switch (action.type) {
    case officeConstants.ORDER_APP_SALES_RESET:
      return {
        orderAppSales: undefined,
      };
    case officeConstants.ORDER_APP_SALES_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.ORDER_APP_SALES_SUCCESS:
      return {
        orderAppSales: action.result['result'],
      };
    case officeConstants.ORDER_APP_SALES_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function specificDateSales(state = {}, action) {
  switch (action.type) {
    case officeConstants.SPECIFIC_DATE_SALES_RESET:
      return {
        specificDateSales: undefined,
      };
    case officeConstants.SPECIFIC_DATE_SALES_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case officeConstants.SPECIFIC_DATE_SALES_SUCCESS:
      return {
        specificDateSales: action.result['result'],
      };
    case officeConstants.SPECIFIC_DATE_SALES_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function specificTimeSales(state = {}, action) {
  switch (action.type) {
    case officeConstants.SPECIFIC_TIME_SALES_RESET:
      return {
        specificTimeSales: undefined,
      };
    case officeConstants.SPECIFIC_TIME_SALES_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case officeConstants.SPECIFIC_TIME_SALES_SUCCESS:
      return {
        specificTimeSales: action.result['result'],
      };
    case officeConstants.SPECIFIC_TIME_SALES_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function setOrderAppAccount(state = {}, action) {
  switch (action.type) {
    case officeConstants.SET_ORDER_APP_ACCOUNT_RESET:
      return {
        setOrderAppAccount: undefined,
      };
    case officeConstants.SET_ORDER_APP_ACCOUNT_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.SET_ORDER_APP_ACCOUNT_SUCCESS:
      return {
        setOrderAppAccount: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case officeConstants.SET_ORDER_APP_ACCOUNT_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function getOrderAppOfficeList(state = {}, action) {
  switch (action.type) {
    case officeConstants.GET_ORDER_APP_OFFICE_LIST_RESET:
      return {
        getOrderAppOfficeList: undefined,
      };
    case officeConstants.GET_ORDER_APP_OFFICE_LIST_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case officeConstants.GET_ORDER_APP_OFFICE_LIST_SUCCESS:
      return {
        getOrderAppOfficeList: action.result['result'],
      };
    case officeConstants.GET_ORDER_APP_OFFICE_LIST_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function getOrderAppOfficeOwner(state = {}, action) {
  switch (action.type) {
    case officeConstants.GET_ORDER_APP_OFFICE_OWNER_RESET:
      return {
        getOrderAppOfficeOwner: undefined,
      };
    case officeConstants.GET_ORDER_APP_OFFICE_OWNER_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case officeConstants.GET_ORDER_APP_OFFICE_OWNER_SUCCESS:
      return {
        getOrderAppOfficeOwner: action.result['result'],
      };
    case officeConstants.GET_ORDER_APP_OFFICE_OWNER_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function getStoreList(state = {}, action) {
  switch (action.type) {
    case officeConstants.GET_STORE_LIST_RESET:
      return {
        getOrderAppOfficeList: undefined,
      };
    case officeConstants.GET_STORE_LIST_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case officeConstants.GET_STORE_LIST_SUCCESS:
      return {
        getStoreList: action.result['result'],
      };
    case officeConstants.GET_STORE_LIST_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function mappingStore(state = {}, action) {
  switch (action.type) {
    case officeConstants.MAPPING_STORE_RESET:
      return {
        mappingStore: undefined,
      };
    case officeConstants.MAPPING_STORE_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.MAPPING_STORE_SUCCESS:
      return {
        mappingStore: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case officeConstants.MAPPING_STORE_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function dongSalesData(state = {}, action) {
  switch (action.type) {
    case officeConstants.DONG_SALES_DATA_RESET:
      return {
        dongSalesData: undefined,
      };
    case officeConstants.DONG_SALES_DATA_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.DONG_SALES_DATA_SUCCESS:
      return {
        dongSalesData: action.result['result'],
      };
    case officeConstants.DONG_SALES_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function totalDeliveryDongSales(state = {}, action) {
  switch (action.type) {
    case officeConstants.TOTAL_DONG_SALES_DATA_RESET:
      return {
        totalDeliveryDongSales: undefined,
      };
    case officeConstants.TOTAL_DONG_SALES_DATA_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.TOTAL_DONG_SALES_DATA_SUCCESS:
      return {
        totalDeliveryDongSales: action.result['result'],
      };
    case officeConstants.TOTAL_DONG_SALES_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function allBrandSales(state = {}, action) {
  switch (action.type) {
    case officeConstants.ALL_BRAND_SALES_RESET:
      return {
        months: undefined,
        monthDataList: undefined,
        brandDataList: undefined,
      };
    case officeConstants.ALL_BRAND_SALES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case officeConstants.ALL_BRAND_SALES_SUCCESS:
      return {
        months: action.result['result']['months'],
        monthDataList: action.result['result']['monthDataList'],
        brandDataList: action.result['result']['brandDataList'],
      };
    case officeConstants.ALL_BRAND_SALES_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function monthlySales(state = {}, action) {
  switch (action.type) {
    case officeConstants.MONTHLY_SALES_RESET:
      return {
        monthlySales: undefined,
      };
    case officeConstants.MONTHLY_SALES_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.MONTHLY_SALES_SUCCESS:
      return {
        monthlySales: action.result['result'],
        loading: false,
      };
    case officeConstants.MONTHLY_SALES_FAILURE:
      return {
        error: action.error,
        loading: true,
      };
    default:
      return state;
  }
}

export function quarterlySales(state = {}, action) {
  switch (action.type) {
    case officeConstants.QUARTERLY_SALES_RESET:
      return {
        quarterlySales: undefined,
      };
    case officeConstants.QUARTERLY_SALES_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.QUARTERLY_SALES_SUCCESS:
      return {
        quarterlySales: action.result['result'],
        loading: false,
      };
    case officeConstants.QUARTERLY_SALES_FAILURE:
      return {
        error: action.error,
        loading: true,
      };
    default:
      return state;
  }
}

export function yearlySales(state = {}, action) {
  switch (action.type) {
    case officeConstants.YEARLY_SALES_RESET:
      return {
        yearSales: undefined,
      };
    case officeConstants.YEARLY_SALES_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.YEARLY_SALES_SUCCESS:
      return {
        yearlySales: action.result['result'],
        loading: false,
      };
    case officeConstants.YEARLY_SALES_FAILURE:
      return {
        error: action.error,
        loading: true,
      };
    default:
      return state;
  }
}

export function specificMonthSales(state = {}, action) {
  switch (action.type) {
    case officeConstants.SPECIFIC_MONTH_SALES_RESET:
      return {
        specificMonthSales: undefined,
      };
    case officeConstants.SPECIFIC_MONTH_SALES_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case officeConstants.SPECIFIC_MONTH_SALES_SUCCESS:
      return {
        specificMonthSales: action.result['result'],
      };
    case officeConstants.SPECIFIC_MONTH_SALES_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function specificQuarterSales(state = {}, action) {
  switch (action.type) {
    case officeConstants.SPECIFIC_QUARTER_SALES_RESET:
      return {
        specificQuarterSales: undefined,
      };
    case officeConstants.SPECIFIC_QUARTER_SALES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case officeConstants.SPECIFIC_QUARTER_SALES_SUCCESS:
      return {
        specificQuarterSales: action.result['result'],
      };
    case officeConstants.SPECIFIC_QUARTER_SALES_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function specificYearSales(state = {}, action) {
  switch (action.type) {
    case officeConstants.SPECIFIC_YEAR_SALES_RESET:
      return {
        specificYearSales: undefined,
      };
    case officeConstants.SPECIFIC_YEAR_SALES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case officeConstants.SPECIFIC_YEAR_SALES_SUCCESS:
      return {
        specificYearSales: action.result['result'],
      };
    case officeConstants.SPECIFIC_YEAR_SALES_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function setMultiOrderAppAccount(state = {}, action) {
  switch (action.type) {
    case officeConstants.SET_MULTI_ORDER_APP_ACCOUNT_RESET:
      return {
        setMultiOrderAppAccount: undefined,
      };
    case officeConstants.SET_MULTI_ORDER_APP_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case officeConstants.SET_MULTI_ORDER_APP_ACCOUNT_SUCCESS:
      return {
        setMultiOrderAppAccount: action.result['result'],
      };
    case officeConstants.SET_MULTI_ORDER_APP_ACCOUNT_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function getOfficeStatus(state = {}, action) {
  switch (action.type) {
    case officeConstants.GET_OFFICE_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case officeConstants.GET_OFFICE_STATUS_SUCCESS:
      return {
        getOfficeStatus: action.result['result'],
      };
    case officeConstants.GET_OFFICE_STATUS_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function alertList(state = {}, action) {
  switch (action.type) {
    case officeConstants.ALERT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case officeConstants.ALERT_LIST_SUCCESS:
      return {
        alertList: action.result['result'],
        loading: false,
      };
    case officeConstants.ALERT_LIST_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function orderDetailList(state = {}, action) {
  switch (action.type) {
    case officeConstants.ORDER_DETAIL_LIST_RESET:
      return {
        orderDetailList: undefined,
      };
    case officeConstants.ORDER_DETAIL_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case officeConstants.ORDER_DETAIL_LIST_SUCCESS:
      return {
        orderDetailList: action.result['result'],
        loading: false,
      };
    case officeConstants.ORDER_DETAIL_LIST_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function readAlert(state = {}, action) {
  switch (action.type) {
    case officeConstants.READ_ALERT_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.READ_ALERT_SUCCESS:
      return {
        readAlert: action.result['result'],
        loading: false,
      };
    case officeConstants.READ_ALERT_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function setSkipAccountLink(state = {}, action) {
  switch (action.type) {
    case officeConstants.SET_SKIP_ACCOUNT_LINK_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.SET_SKIP_ACCOUNT_LINK_SUCCESS:
      return {
        skipAccountLink: action.result['result'],
      };
    case officeConstants.SET_SKIP_ACCOUNT_LINK_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function getPosOwnerAccount(state = {}, action) {
  switch (action.type) {
    case officeConstants.GET_POS_OWNER_ACCOUNT_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.GET_POS_OWNER_ACCOUNT_SUCCESS:
      return {
        posOwnerAccount: action.result['result'] ? action.result['result']['accountList'] : [],
      };
    case officeConstants.GET_POS_OWNER_ACCOUNT_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function linkPosAccount(state = {}, action) {
  switch (action.type) {
    case officeConstants.LINK_POS_ACCOUNT_RESET:
      return {
      };
    case officeConstants.LINK_POS_ACCOUNT_REQUEST:
      return {
        loading: true,
      };
    case officeConstants.LINK_POS_ACCOUNT_SUCCESS:
      return {
        linkPosAccount: action.result['result'],
      };
    case officeConstants.LINK_POS_ACCOUNT_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
