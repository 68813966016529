import React, { Fragment } from 'react';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { Col, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { authActions } from '../../actions';
import { authConstants } from '../../constants';
import { rules, validation } from '../../helpers';

class SetNewPasswordModal extends React.Component {
  constructor(props) {
    super(props);

    var mobile = props.mobile;

    this.state = {
      modal: props.modal,
      mobile: props.mobile,
      password: '',
      confirmPassword: '',
      isCorrect: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    var returnState = {};

    if (validation(prevState.password, rules.password) && validation(prevState.confirmPassword, rules.password)) {
      returnState.isCorrect = true;
    }

    if (nextProps.resetPassword !== undefined && !isEqual(nextProps.resetPassword, prevState.resetPassword)) {
      var resetPassword = nextProps.resetPassword;
      returnState.resetPassword = resetPassword;

      if (resetPassword.result === 1002) {
        alert("이전과 동일한 비밀번호는 사용할 수 없습니다.");
      } else {
        alert("비밀번호 변경이 완료되었습니다.");
        nextProps.onRegistStep(0);
        returnState.modal = false;
      }
    }

    return returnState ? returnState : null;
  }

  componentWillUnmount() {
    this.props.dispatch({ type: authConstants.RESETSTATE })
    this.props.toggle({ target: { name: "password" } })
    this.props.onRegistStep(0)
  }

  toggle() {
    this.setState({ modal: !this.state.modal });

    this.props.toggle({ target: { name: "password" } })
  }

  handleChange(e) {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  }

  handleEnter(e) {
    if (e.key === 'Enter') {
      this.handleSubmit(e);
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const { mobile, password, confirmPassword } = this.state;

    if (!password || !confirmPassword) {
      alert("비밀번호를 입력해주세요.")
      return;
    }

    if (password !== confirmPassword) {
      alert("동일한 비밀번호를 입력해주세요.");
      return;
    }

    if (validation(password, rules['password']) === false) {
      alert("최소 5~20자 내의 영문, 숫자, 특수문자를 조합하여 사용하실 수 있습니다.")
      return;
    }

    this.props.dispatch(authActions.resetPassword(mobile, password));
  }

  render() {
    const { password, confirmPassword, isCorrect } = this.state;

    const btnStyle = {
      isTrue: {
        backgroundColor: "#adb5bd",
        marginBottom: "4px"
      },
      isFalse: {
        backgroundColor: "#adb5bd",
        marginBottom: "4px",
        opacity: "0.5",
        cursor: "default"
      }
    }
    const footerStyle = {
      textAlign: "right",
      display: "block",
      height: "72px"
    }

    return (
      <Fragment>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} backdrop="static">
          <ModalHeader toggle={this.toggle} onClick={this.handleClose}>신규 비밀번호 입력</ModalHeader>
          <ModalBody>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <div className="modal-usr-guide">
                    신규 설정할 비밀번호를 입력하세요.
                  </div>
                  <Input
                    type="password"
                    name="password"
                    value={password}
                    maxLength="50"
                    placeholder="비밀번호 입력 (5~20자리 이하 영문,숫자,특수문자 가능)"
                    onChange={this.handleChange}
                    onKeyPress={this.handleEnter}
                    autoComplete="false"
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Input
                    type="password"
                    name="confirmPassword"
                    value={confirmPassword}
                    maxLength="50"
                    placeholder="비밀번호 재입력 (5~20자리 이하 영문,숫자,특수문자 가능)"
                    onChange={this.handleChange}
                    onKeyPress={this.handleEnter}
                    autoComplete="false"
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter style={footerStyle}>
            <button className="ml-auto badge badge-secondary modal-edt-btn" style={isCorrect ? btnStyle.isTrue : btnStyle.isFalse} onClick={isCorrect ? this.handleSubmit : undefined}>변경하기</button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { resetPassword } = state.resetPassword;
  return {
    resetPassword,
  };
}

export default connect(mapStateToProps)(SetNewPasswordModal);