// react
import React, { useEffect, useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import {
  additionalAddressInfoState,
  additionalBusinessInfoState,
  additionalPageInfoState,
} from '../../../../recoil/user/atoms';
import { userService } from '../../../../services';

// hook
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAdditionalUserInfoMutation } from '../../../../hooks/user/useAdditionalUserInfoMutation';

// utils
import { getMyUserData } from '../../../../helpers';
import { isFreePlan, isSelfEmployed } from '../../../../helpers/CommonUtil';
import { MOBILE_AUTH_SUCCESS } from '../../../UserPages/MobileAuth/Helper';
import { isMobile } from '../../../../constants';
import { commonActions, menuActions } from '../../../../actions';
import { setThemeColor } from '../../../../helpers/StyleUtil';

// components
import MobileATAddressInput from '../../../../components/Styled/form/MobileATAddressInput';
import MobileBusinessNumInput from '../../../../components/Styled/form/MobileBusinessNumInput';
import MobileATUploader from '../../../../components/Styled/form/MobileATUploader';

// style
import styled, { css } from 'styled-components';
import { PATHS } from '../../../../constants/Routes';

const UpdateAdditionalUserInfo = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isVisibleKeyboard, setIsVisibleKeyboard] = useState(false);
  const [prevLocation, setPrevLocation] = useState(null);

  const [additionalUserInfo, setAdditionalUserInfo] = useRecoilState(additionalPageInfoState);
  const { isPageActive, additionalType, handleEvent } = additionalUserInfo;
  const [additionalAddressInfo, setAdditionalAddressInfo] = useRecoilState(additionalAddressInfoState);
  const [additionalBusinessInfo, setAdditionalBusinessInfo] = useRecoilState(additionalBusinessInfoState);

  const resetAdditionalUserInfo = useResetRecoilState(additionalPageInfoState);
  const resetAdditionalAddressInfo = useResetRecoilState(additionalAddressInfoState);
  const resetAdditionalBusinessInfo = useResetRecoilState(additionalBusinessInfoState);

  const { tabState, prevTabState } = useSelector(state => state.tabState);
  const { isSlideShow } = useSelector(state => state.setIsSlideShow);

  const { mutate: updateAddressMutate, isSuccess: isUpdateAddressSuccess } = useAdditionalUserInfoMutation();
  const { mutate: updateBusinessMutate, isSuccess: isUpdateBusinessSuccess } = useAdditionalUserInfoMutation();

  const additionalTypeInfo = {
    A: {
      header: (
        <>
          매장 위치를 입력해주세요.<p>매장 위치를 기반으로 배달 데이터를 분석합니다.</p>
        </>
      ),
      content: (
        <MobileATAddressInput
          mobileAuthState={MOBILE_AUTH_SUCCESS}
          defaultValue={{
            address: additionalAddressInfo.address,
            addressData: additionalAddressInfo.addressData,
            detailAddress: additionalAddressInfo.detailAddress,
          }}
          getValue={value => setAdditionalAddressInfo(currVal => ({ ...currVal, ...value }))}
          getDetailValue={value => setAdditionalAddressInfo(currVal => ({ ...currVal, detailAddress: value.value }))}
        />
      ),
    },
    B: {
      header: (
        <>
          사업자번호를 확인해주세요.<p>매출을 분석할 사업자를 확인해주세요.</p>
        </>
      ),
      content: (
        <>
          <MobileBusinessNumInput
            mobileAuthState={MOBILE_AUTH_SUCCESS}
            autoFocus={true}
            getValue={value => setAdditionalBusinessInfo(currVal => ({ ...currVal, businessNum: value.value }))}
            onValid={value => setAdditionalBusinessInfo(currVal => ({ ...currVal, businessAccess: value }))}
          />
          <MobileATUploader
            mobileAuthState={MOBILE_AUTH_SUCCESS}
            getValue={value => setAdditionalBusinessInfo(currVal => ({ ...currVal, businessFile: value.value }))}
          />
        </>
      ),
    },
  };

  const handleResize = event => {
    const VIEWPORT_VS_CLIENT_HEIGHT_RATIO = 0.65;

    if ((event.target.height * event.target.scale) / window.screen.height < VIEWPORT_VS_CLIENT_HEIGHT_RATIO) {
      setIsVisibleKeyboard(true);
    } else {
      setIsVisibleKeyboard(false);
    }
  };

  const handleBackClick = () => {
    if (additionalType === 'B') {
      setAdditionalUserInfo(currVal => ({ ...currVal, additionalType: 'A' }));
      return;
    }

    resetAdditionalAddressInfo();
    resetAdditionalBusinessInfo();

    if (isMobile()) {
      resetAdditionalUserInfo();

      if (history.location.pathname === '/mobile/officeOwnerAnalysis') {
        if (isSlideShow) {
          dispatch(commonActions.setIsSlideShow(false));

          if (tabState === 'menu') {
            dispatch(commonActions.setIsMobileCommonPopupShow(true));
          }
        } else {
          dispatch(menuActions.setTabState(prevTabState));
        }
      } else if (history.location.pathname === '/mobile/customerReview' && isSlideShow) {
        dispatch(commonActions.setIsSlideShow(false));
      } else {
        window.location.href =
          prevLocation && prevLocation !== '/mobile/programInstallInfo'
            ? `#${prevLocation}`
            : `#${PATHS['mobileOfficeOwnerAnalysis']['url']}`;
      }
    } else {
      window.location.href = prevLocation ? `#${prevLocation}` : `#${PATHS['analysisDate']['url']}`;
    }
  };

  const handleNextClick = () => {
    const userData = getMyUserData();

    if (additionalType === 'A') {
      setAdditionalUserInfo(currVal => ({ ...currVal, additionalType: 'B' }));
      return;
    }

    updateAddressMutate({
      userSno: userData['user_sno'],
      officeSno: userData['office_sno'],
      type: 'address',
      ...additionalAddressInfo,
    });

    updateBusinessMutate({
      userSno: userData['user_sno'],
      officeSno: userData['office_sno'],
      type: 'businessNo',
      ...additionalBusinessInfo,
    });
  };

  const nextButtonActive = () => {
    let isActive = false;

    switch (additionalType) {
      case 'A':
        return Object.values(additionalAddressInfo).filter(value => !value).length === 0;
      case 'B':
        return Object.values(additionalBusinessInfo).filter(value => !value).length === 0;
      default:
        break;
    }

    return isActive;
  };

  /**
   * 부가정보 업데이트 영역 set active 함수 (location 기준, 버튼 이벤트는 각 기능에서 set)
   * 1차 개선: 일반 자영업자만 해당
   *
   * @author 장광현
   */
  const activeAdditionalUserInfo = () => {
    const userData = getMyUserData();
    const checkInputInfo = userData && userData['check_input_info'];

    const location = history.location.pathname;
    const activeLocationHashList = {
      pc: ['/analysis/locationDong', '/analysis/location'],
      mobile: ['/mobile/officeOwnerMap', '/mobile/programInstallInfo'],
    };
    const accessLocationHashList = [
      ...activeLocationHashList.pc,
      ...activeLocationHashList.mobile,
      '/mobile/officeOwnerAnalysis',
      '/mobile/customerReview',
    ];

    if (!checkInputInfo || checkInputInfo === 'OK') {
      return;
    }

    if (!accessLocationHashList.includes(location)) {
      resetAdditionalUserInfo();
      return;
    }

    if (!isSelfEmployed() || isFreePlan()) {
      return;
    }

    if (isMobile()) {
      const guideStepIndex = userData && userData['service_open_status'];

      if (
        (guideStepIndex !== 2 &&
          (tabState === 'menu' || isSlideShow || activeLocationHashList.mobile[0] === location)) ||
        activeLocationHashList.mobile[1] === location
      ) {
        setAdditionalUserInfo(currVal => ({ ...currVal, isPageActive: true, additionalType: checkInputInfo }));
        resetAdditionalAddressInfo();
        resetAdditionalBusinessInfo();
      } else {
        resetAdditionalUserInfo();
      }
    }

    if (!isMobile()) {
      if (activeLocationHashList.pc[1] === location && !userData['pos_sno']) {
        resetAdditionalUserInfo();
        return;
      }

      setAdditionalUserInfo({
        isPageActive: true,
        additionalType: checkInputInfo,
        handleEvent: () => window.location.reload(),
      });
      resetAdditionalAddressInfo();
      resetAdditionalBusinessInfo();
    }
  };

  useEffect(() => {
    activeAdditionalUserInfo();
  }, [tabState, isSlideShow, history.location]);

  useEffect(() => {
    if (isUpdateAddressSuccess && isUpdateBusinessSuccess) {
      dispatch(
        commonActions.alertMessage(
          <AlertTitle>정보가 확인되었습니다.</AlertTitle>,
          <AlertContent>
            매장 분석 정보에 필요한 매장위치 정보와
            <br />
            사업자 등록번호가 확인되었습니다.
          </AlertContent>,
          null,
          null,
          async () => {
            const userData = getMyUserData();
            await userService.myUserData(userData['user_sno'], userData['service_type']);

            handleEvent();

            resetAdditionalUserInfo();
            resetAdditionalAddressInfo();
            resetAdditionalBusinessInfo();
          },
          0,
          isMobile() ? '92%' : '400px',
        ),
      );
    }
  }, [isUpdateAddressSuccess, isUpdateBusinessSuccess]);

  useEffect(() => {
    setThemeColor(isPageActive ? '#fff' : '#F4F6F8');

    if ('visualViewport' in window && isMobile()) {
      if (isPageActive) {
        window.visualViewport.addEventListener('resize', handleResize);
      } else {
        window.visualViewport.removeEventListener('resize', handleResize);
      }
    }
  }, [isPageActive]);

  useEffect(() => {
    if (prevLocation !== history.location.pathname && history.location.pathname !== '/analysis/locationDong') {
      setPrevLocation(history.location.pathname);
    }
  }, [history.location]);

  return (
    <>
      {isPageActive && (
        <>
          {!isMobile() && <BackGround />}
          <Container isMobile={isMobile()} isVisibleKeyboard={isVisibleKeyboard}>
            <div>
              <Header>{additionalTypeInfo[additionalType].header}</Header>
              <Content>{additionalTypeInfo[additionalType].content}</Content>
            </div>
            <Footer>
              <BackButton onClick={handleBackClick}>뒤로</BackButton>
              <NextButton onClick={handleNextClick} isActive={nextButtonActive()}>
                {additionalType === 'A' ? '다음' : '확인'}
              </NextButton>
            </Footer>
          </Container>
        </>
      )}
    </>
  );
};

export default UpdateAdditionalUserInfo;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  flex-direction: column;
  justify-content: space-between;

  position: absolute;
  padding: calc(40px + env(safe-area-inset-top)) 32px calc(32px + env(safe-area-inset-bottom)) 32px;
  z-index: 1001;

  background-color: #ffffff;

  overflow: hidden;

  ${props =>
    !props.isMobile &&
    css`
      width: 400px;
      height: 400px;

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 16px 0px;
      border-radius: 16px;
    `}

  ${props =>
    props.isVisibleKeyboard &&
    css`
      justify-content: flex-start;
      gap: 32px;
    `}
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 32px;

  color: #28282e;
  font-size: 22px;
  font-weight: 700;
  text-align: left;

  p {
    color: #939396;
    font-size: 13.5px;
    font-weight: 600;

    margin: 0 !important;
  }
`;

const Content = styled.div``;

const Footer = styled.div`
  display: flex;
  gap: 8px;
`;

const Button = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  flex: 1;
  height: 51px;
  border-radius: 18px;

  font-size: 16px;
  font-weight: 700;
`;

const BackButton = styled(Button)`
  border: 1px solid #8e909680;
  color: #8e9096;
  cursor: pointer;
`;
const NextButton = styled(Button)`
  background-color: #d9dde3;
  color: #fefefe;
  pointer-events: none;

  ${props =>
    props.isActive &&
    css`
      background-color: #686cff;
      pointer-events: visible;
      cursor: pointer;
    `}
`;

const BackGround = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;
  z-index: 1000;

  background-color: rgba(253, 253, 253, 0.6);
`;

const AlertTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: #28282e;
  margin-bottom: 12px;
`;

const AlertContent = styled.div`
  font-weight: 500;
  font-size: 13.5px;
  color: #28282e;
  opacity: 0.5;
  line-height: 19.58px;
`;
