import React from 'react';
import styled, { css } from 'styled-components';

const DiffPercent = props => {
  const { className = '', dateText = '', percent = 0, textColor = '#444054' } = props;

  const intPercent = parseInt(percent);

  return (
    <Wrapper className={className} textColor={textColor}>
      {dateText} 대비
      <Percent negative={intPercent < 0}>
        {intPercent >= 0 ? '+' : '-'}
        {Math.abs(percent)}% {intPercent >= 0 ? '상승' : '감소'}
      </Percent>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 2px;
  line-height: 1;
  font-size: 12px;
  font-weight: 400;
  color: ${props => props.textColor};
`;

const Percent = styled.span`
  padding-left: 4px;
  color: #e84545;
  ${props =>
    props.negative &&
    css`
      opacity: 0.9;
      color: #30b1ff;
    `}
`;

export default DiffPercent;
