import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { commonActions } from '../../../actions';
import PostCode from '../../Map/PostCode';
import ATInput from './ATInput';
import { MOBILE_AUTH_SUCCESS } from '../../../pages/UserPages/MobileAuth/Helper';

const MobileATAddressInput = props => {
  const {
    mobileAuthState,
    useDetailAddress = true,
    detailMaxLength = 50,
    getValue = () => {},
    getDetailValue = () => {},
    defaultValue,
  } = props;

  const dispatch = useDispatch();

  const [value, setValue] = useState({
    address: '',
    addressData: '',
  });
  const [detailValue, setDetailValue] = useState('');

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
      if (defaultValue.detailAddress) {
        setDetailValue(defaultValue.detailAddress);
      }
    }
  }, [defaultValue]);

  function onChange(e) {
    const { name, value } = e;
    if (name === 'detailAddress') {
      setDetailValue(value);
      getDetailValue(e);
    }
  }

  function handleClickAddress() {
    dispatch(commonActions.alertMessage(null, null, null, <PostCode onComplete={() => {}} onSearch={handleSearch} />));
  }

  function handleSearch(param = {}) {
    if (param['addressData'] !== undefined) {
      let res = {
        address: param['addressData']['roadAddress'],
        addressData: param['addressData'],
      };
      setValue(res);
      getValue(res);
    }

    dispatch(commonActions.alertMessage(null));
  }

  return (
    <Wrapper>
      <div onClick={mobileAuthState == MOBILE_AUTH_SUCCESS ? handleClickAddress : undefined}>
        <ATInput
          name="address"
          type="text"
          className={`inputwrap no-pointer-event ${
            mobileAuthState == MOBILE_AUTH_SUCCESS ? '' : 'is-disabled is-readonly'
          }`}
          dataText="매장 주소"
          required
          defaultValue={value.address}
        />
      </div>

      {useDetailAddress === true && (
        <ATInput
          name="detailAddress"
          type="text"
          className={`inputwrap mt8 ${mobileAuthState == MOBILE_AUTH_SUCCESS ? '' : 'is-disabled is-readonly'}`}
          dataText="상세 주소지"
          required
          defaultValue={detailValue}
          maxLength={detailMaxLength}
          getValue={v => onChange(v)}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  .mt4 {
    margin-top: 4px;
  }
  .mb4 {
    margin-bottom: 4px;
  }
  input {
    width: 100%;
    height: 38px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    padding: 6px 10px;
    font-size: 13px;
    &:focus {
      box-shadow: 0 0 0 0.2rem rgb(77 94 255 / 25%);
    }
    &::placeholder {
      color: #ced4d9;
    }
    &.click-trigger-type {
      pointer-events: none;
    }
    &:disabled {
      opacity: 0.7;
      background-color: #fafbfb;
      color: #c6c6c6;
    }
    &.auth-num-input {
      width: 88px;
      margin-top: 4px;
    }
    &.file-uploader {
      opacity: 0;
      position: absolute;
      width: 100% !important;
      height: 100%;
      cursor: pointer;
    }
  }
  .input-valid-msg {
    margin-top: 6px;
    margin-left: 12px;
    font-size: 12px;
    color: #d54b4b;
  }
  .name {
    margin-bottom: 8px;
    margin-left: 6px;
    font-size: 13px;
    font-weight: 600;
  }

  .with-button {
    display: flex;
    input {
      width: calc(100% - 88px);
    }
  }
`;

export default MobileATAddressInput;
