import {
  staticData,
  staticData_2020,
  storeList,
  storeList_2020,
  storeList_2021,
} from '../components/KmongPayment/DataTable/data';
import { paymentConstants } from '../constants/Payment';
import { paymentService } from '../services/Payment';

export const paymentActions = {
  getMasterPaymentInfo,
  getPaymentTableData,
  getPayMethodList,
  getFilterList,
  getFilterStoreOrMethod,
  getMobileOwnerPaymentInfo,
  getMobileOwnerPaybillList,
  selectBySearchText,
  updatePaymentMethod,
  updatePaymentInfo,
  updateMoOwnerPayMethod,
  updateStoreListPayMethod,
  updateStaticData,
  createPayMethod,
  createMoOwnerPayment,
  createMoOwnerPayBillData,
  deletePayMethodInfo,
  getFilterYear,
  nextModalStep,
  resetModalStep,
  resetSearchInput,
  paymethodEnrollStep,
  payStep,
  selectStoreListStep,
  selectPaymethodStep,
  handleSelectStore,
  handleSearchInput,
  handleModalPost,

  // stopService,

  getRegistInfo,
  registPaymentInfo,
  resetRegistPaymentInfo,
  getHistory,
  singlePayment,
  resetSinglePayment,
  setPaymentPlan,
  getPaymentPlan,
  checkUserOrderApp,
};

function getMasterPaymentInfo(state, year) {
  return dispatch => {
    let state;
    // switch (year) {
    //   case 2022:
    //     state = staticData;
    //     break;
    //   case 2020:
    //     state = staticData_2020;
    //     break;
    //   default:
    //     state = state;
    //     break;
    // }

    dispatch(request(state));

    /* *backend data Request 부분 */
    // paymentService.getPaymentInfo();
    function request(state) {
      return { type: paymentConstants.GET_PAY_INFO_STATE };
    }
  };
}

function getPaymentTableData(year) {
  /* ** storeList에대한 데이터를 server에 연도값과 함께 server 요청을 보내는 로직으로 향후 대체 가능
   ** 현재는 테스트를 위하여 switch case문으로 코드 작성
   */
  return dispatch => {
    let result;
    switch (year) {
      case 2022:
        result = storeList;
        break;
      case 2021:
        result = storeList_2021;
        break;
      case 2020:
        result = storeList_2020;
        break;
      default:
        result = storeList;
        break;
    }
    dispatch(request(result));
    function request(result) {
      return { type: paymentConstants.GET_PAYMENT_TABLE_DATA, result };
    }
  };
}

function getPayMethodList() {
  return dispatch => {
    dispatch(request());

    const request = () => {
      return { type: paymentConstants.GET_PAYMETHOD_LIST };
    };
  };
}

function getFilterList() {
  return dispatch => {
    dispatch(request());

    const request = () => {
      return { type: paymentConstants.GET_PAYMETHOD_LIST };
    };
  };
}

function getFilterYear(year) {
  return dispatch => {
    const result = year;
    dispatch(request(result));

    function request(result) {
      return { type: paymentConstants.GET_FILTER_YEAR, result };
    }
  };
}

function getFilterStoreOrMethod(text) {
  return dispatch => {
    const result = text;
    dispatch(request(result));

    function request(result) {
      return { type: paymentConstants.GET_FILTER_STORE_OR_PAYMENT_TEXT, result };
    }
  };
}

function getMobileOwnerPaymentInfo(state) {
  return dispatch => {
    const result = state;
    dispatch(request(state));

    function request(result) {
      return { type: paymentConstants.GET_MOBILE_OWNER_PAYMENT_INFO, result };
    }
  };
}

function getMobileOwnerPaybillList(state) {
  return dispatch => {
    const result = state;
    dispatch(request(state));

    function request(result) {
      return { type: paymentConstants.GET_MOBILE_OWNER_PAYBILL_LIST, result };
    }
  };
}

function selectBySearchText(text, year) {
  return dispatch => {
    let tableD;
    switch (year) {
      case 2022:
        tableD = storeList;
        break;
      case 2021:
        tableD = storeList_2021;
        break;
      case 2020:
        tableD = storeList_2020;
        break;
      default:
        tableD = storeList;
        break;
    }

    try {
      const result = tableD.filter(v => {
        if (v.storeName === text || v.ownerName === text) return v;
      });
      if (result.length >= 1 && text !== '') dispatch(request(result));
      else if (text !== '') dispatch(request([]));
      else dispatch(request(tableD));
    } catch (e) {}

    function request(result) {
      return { type: paymentConstants.SELECT_BY_SEARCH_TEXT, result };
    }
  };
}

/*
 *가게리스트 결제정보 등록/수정
 *선택된 가게들에 대하여 결제정보 등록시 가게리스트의 결제정보를 업데이트
 
 */
function updateStoreListPayMethod(storeList, selectList, paymentMethods) {
  return dispatch => {
    console.log(storeList, selectList, paymentMethods);

    selectList.forEach(element => {
      const data = val => val.storeId === element.storeId && val.storeName === element.storeName;
      const index = storeList.findIndex(data);
      storeList.splice(index, 1, {
        ...element,
        payMethodId: paymentMethods[paymentMethods.length - 1].paymethodId,
        cardCompany: paymentMethods[paymentMethods.length - 1].cardCompany,
        cardNum: paymentMethods[paymentMethods.length - 1].cardNum,
        pw: paymentMethods[paymentMethods.length - 1].pw,
        expdt: paymentMethods[paymentMethods.length - 1].expDuration,
        paymentMethod: '본사',
      });
    });

    dispatch(request(storeList));
    console.log(storeList);
    function request(result) {
      return { type: paymentConstants.UPDATE_STORELIST_PAYMETHOD, result };
    }
  };
}

/* 결제방식을 점주결제/본사결제로 업데이트 */
function updatePaymentMethod(state, storeId, storeName, paymentMethod) {
  return dispatch => {
    const result = state.map((v, i) => {
      if (v.storeId === storeId && v.storeName === storeName) {
        return { ...v, storeId: storeId, storeName: storeName, paymentMethod: paymentMethod };
      } else {
        return { ...v };
      }
    });
    dispatch(success(result));

    function success(result) {
      return { type: paymentConstants.UPDATE_PAYMENT_METHOD_SUCCESS, result };
    }
  };
}

/* 결제시 결제정보list update ( 결제진행후 선택된 가게들의 결제정보 업데이트 ) */
function updatePaymentInfo(state, selectStore, payMethods, payDt) {
  console.log(state, payMethods);
  return dispatch => {
    selectStore.forEach(element => {
      const data = val => val.storeId === element.storeId && val.storeName === element.storeName;
      const index = state.findIndex(data);
      console.log(index);
      /* 결제가 안되어있는 경우 처음 미납된 일자의 index를 찾는다. */

      const updateBillList = element.paybillList.map(el => {
        if (el.state === '미납' || el.state === '중지' || el.state === '대기') {
          const item = {
            price: el.price,
            cardCompany: payMethods.cardCompany,
            cardNum: payMethods.cardNum,
            payDt: payDt,
            createDt: el.createDt,
            state: '완납',
          };
          return item;
        }
        return { ...el };
      });

      state.splice(index, 1, {
        ...element,
        payMethodId: payMethods.payMethodId,
        cardCompany: payMethods.cardCompany,
        cardNum: payMethods.cardNum,
        // payMethodId: payMethods.paymethodId,
        lastpayDt: payDt,
        createDt: payDt,
        payState: true,
        stoppedDt: null,
        paybillList: updateBillList,
        unpaidMonth: undefined,
        unpaidPrice: 0,
      });
      console.log(state);
    });
    const result = state;

    dispatch(success(result));
    function success(result) {
      return { type: paymentConstants.UPDATE_PAYMENT_METHOD_INFO_SUCCESS, result };
    }
  };
}

function updateMoOwnerPayMethod(
  paymethodId,
  storeId,
  cardCompany,
  cardNum,
  expDuration,
  pw,
  personalAddre,
  createDt,
  updateDt,
  createUser,
  updateUser,
) {
  return dispatch => {
    const result = {
      storeId: storeId,
      paymethodId: paymethodId,
      cardCompany: cardCompany,
      cardNum: cardNum,
      expDuration: expDuration,
      pw: pw,
      personalAddre: personalAddre,
      flag: 0,
      createDt: createDt,
      updateDt: updateDt,
      createUser: createUser,
      updateUser: updateUser,
    };

    dispatch(success(result));

    function success(result) {
      return { type: paymentConstants.UPDATE_MO_OWNER_PAYMETHOD, result };
    }
  };
}

function updateStaticData(state, storeList) {
  return dispatch => {
    var totalPaidPrice = 0;
    var chargedPrice = 0;
    var unpaidPrice = 0;
    var unregiStoreCnt = 0;
    var regiStoreCnt = 0;
    var waitingStoreCnt = 0;
    var monthlyUnpaidCnt = 0;
    var serviceStoppedCnt = 0;
    var fullPaidStoreCnt = 0;
    var lastpayDt = [0, 0, 0];
    var regiDt = [10000, 100, 100];
    var createDt = '';
    var currentDt = [0, 0, 0];
    storeList.map(el => {
      var dt = el.lastpayDt.split(' ');
      var redt = el.registerDt.split(' ');

      dt = [Number(dt[0]), Number(dt[1]), Number(dt[2])];
      redt = [Number(redt[0]), Number(redt[1]), Number(redt[2])];
      console.log(redt);
      if (redt[0] < regiDt[0]) regiDt = redt;
      else if (redt[0] === regiDt[0]) {
        if (redt[1] < regiDt[1]) regiDt = redt;
        else if (redt[1] === regiDt[1]) {
          if (redt[2] < regiDt[2]) regiDt = redt;
        }
      }
      //   console.log(regiDt);

      if (dt[0] > lastpayDt[0]) lastpayDt = dt;
      else if (dt[0] === lastpayDt[0]) {
        if (dt[1] > lastpayDt[1]) lastpayDt = dt;
        else if (dt[1] === lastpayDt[1]) {
          if (dt[2] > lastpayDt[2]) lastpayDt = dt;
        }
      }

      if (el.payMethodId === null) unregiStoreCnt += 1;
      else regiStoreCnt += 1;
      // if (el.paymentMethod === '미정') waitingStoreCnt += 1;
      if (el.payState === false) waitingStoreCnt += 1;
      if (el.stoppedDt) serviceStoppedCnt += 1;
      if (el.payState === true) fullPaidStoreCnt += 1;

      el.paybillList.map((v, i) => {
        const currentMonth = new Date().getMonth() + 1;
        chargedPrice += v.price;
        if (v.state === '완납') totalPaidPrice += v.price;
        if (v.state === '미납' || v.state === '중지' || v.state === '대기') {
          unpaidPrice += v.price;
          // if (Number(v.createDt.split(' ')[1]) === currentMonth) monthlyUnpaidCnt + 1;
          if (Number(v.createDt.split(' ')[1]) === currentMonth) {
            monthlyUnpaidCnt += 1;
          }
        }
      });
      createDt = el.paybillList[0].createDt;
      currentDt = el.paybillList[el.paybillList.length - 1].createDt;
    });
    const result = {
      ...state,
      totalPaidPrice: totalPaidPrice,
      chargedPrice: chargedPrice,
      unpaidPrice: unpaidPrice,
      unregiStoreCnt: unregiStoreCnt,
      regiStoreCnt: regiStoreCnt,
      waitingStoreCnt: waitingStoreCnt,
      monthlyUnpaidCnt: monthlyUnpaidCnt,
      serviceStoppedCnt: serviceStoppedCnt,
      fullPaidStoreCnt: fullPaidStoreCnt,
      registerDt: `${regiDt[0]} ${regiDt[1]} ${regiDt[2]}`,
      lastpayDt: `${lastpayDt[0]} ${lastpayDt[1]} ${lastpayDt[2]}`,
      createDt: createDt,
      currentpayDt: currentDt,
    };
    // console.log(result);
    dispatch(request(result));
    function request(result) {
      return { type: paymentConstants.UPDATE_STATIC_DATA, result };
    }
  };
}

/* function stopService(state, selectstore) {
  return dispatch => {
    const result = state.map(element => {
      if (selectstore.storeId === element.storeId && selectstore.storeName === element.storeName) {
        return {
          ...element,
          payState: false,
          stoppedAt: `${new Date().getFullYear()} ${new Date().getMonth()} ${new Date().getDay()}`,
        };
      }
      return { ...element };
    });
    dispatch(success(result));
    function success(result) {
      return { type: paymentConstants.STOP_SERVICE, result };
    }
  };
} */

function createPayMethod(
  state,
  paymethodId,
  storeId,
  cardCompany,
  cardNum,
  expDuration,
  pw,
  personalAddre,
  createDt,
  updateDt,
  createUser,
  updateUser,
) {
  return dispatch => {
    state.push({
      storeId: storeId,
      paymethodId: paymethodId,
      cardCompany: cardCompany,
      cardNum: cardNum,
      expDuration: expDuration,
      pw: pw,
      personalAddre: personalAddre,
      flag: 0,
      createDt: createDt,
      updateDt: updateDt,
      createUser: createUser,
      updateUser: updateUser,
    });
    const result = state;

    dispatch(success(result));

    function success(result) {
      return { type: paymentConstants.CREATE_PAYNETGID_ITEM, result };
    }
  };
}

function createMoOwnerPayment(
  paymethodId,
  storeId,
  cardCompany,
  cardNum,
  expDuration,
  pw,
  personalAddre,
  createDt,
  updateDt,
  createUser,
  updateUser,
) {
  return dispatch => {
    const result = {
      storeId: storeId,
      paymethodId: paymethodId,
      cardCompany: cardCompany,
      cardNum: cardNum,
      expDuration: expDuration,
      pw: pw,
      personalAddre: personalAddre,
      flag: 0,
      createDt: createDt,
      updateDt: updateDt,
      createUser: createUser,
      updateUser: updateUser,
    };
    dispatch(moSuccess(result));

    function moSuccess(result) {
      return { type: paymentConstants.CREATE_MO_OWNER_PAYNET, result };
    }
  };
}

function createMoOwnerPayBillData(state, paybilldata) {
  return dispatch => {
    console.log('state', state);
    state.push({
      ...paybilldata,
    });
    const result = state;
    console.log('result', result);

    dispatch(success(result));

    function success(result) {
      return { type: paymentConstants.CREATE_MO_OWNER_PAYBILL_DATA, result };
    }
  };
}

function deletePayMethodInfo(state, flag, paymethodId, storeId, cardCompany, cardNum) {
  return dispatch => {
    const result = state.map((v, i) => {
      if (
        v.storeId === storeId &&
        v.paymethodId === paymethodId &&
        v.cardCompany === cardCompany &&
        v.cardNum === cardNum
      ) {
        return { ...v, flag: flag };
      } else {
        return { ...v };
      }
    });
    dispatch(success(result));

    function success(result) {
      return { type: paymentConstants.DELETE_PAYMETHOD_INFO, result };
    }
  };
}

function nextModalStep(state) {
  return dispatch => {
    dispatch(change(state + 1));

    function change(state) {
      return { type: paymentConstants.NEXT_PAY_STEP, state };
    }
  };
}

function resetModalStep() {
  return dispatch => {
    dispatch(reset(0));

    function reset(state) {
      return { type: paymentConstants.RESET_STEP, state };
    }
  };
}

function selectStoreListStep() {
  return dispatch => {
    dispatch(success(0));

    function success(state) {
      return { type: paymentConstants.SELECT_STORE_STEP, state };
    }
  };
}
function selectPaymethodStep() {
  return dispatch => {
    dispatch(success(1));

    function success(state) {
      return { type: paymentConstants.SELECT_PAYMETHOD_STEP, state };
    }
  };
}
function paymethodEnrollStep() {
  return dispatch => {
    dispatch(success(2));

    function success(state) {
      return { type: paymentConstants.CREATE_PAYMETHOD_STEP, state };
    }
  };
}

function payStep() {
  return dispatch => {
    dispatch(success(3));

    function success(state) {
      return { type: paymentConstants.SELECT_PAY_STEP, state };
    }
  };
}

function handleSelectStore(chkList) {
  return dispatch => {
    dispatch(success(chkList));
    // console.log(chkList);

    function success(state) {
      return { type: paymentConstants.HANDLE_SELECT_STORE, state };
    }
  };
}

function resetSearchInput() {
  return dispatch => {
    dispatch(reset(''));

    function reset(state) {
      return { type: paymentConstants.RESET_SEARCH_INPUT_VALUE, state };
    }
  };
}

function handleSearchInput(text) {
  return dispatch => {
    dispatch(onChange(text));
    function onChange(text) {
      return { type: paymentConstants.ON_SEARCH_INPUT_CHANGE, text };
    }
  };
}

function handleModalPost(state) {
  return dispatch => {
    dispatch(request(state));
    function request(state) {
      return { type: paymentConstants.POST_SUCCESS, state };
    }
  };
}

// paymethodId,
// storeId,
// cardCompany,
// cardNum,
// expDuration,
// pw,
// personalAddre,
// createDt,
// updateDt,
// createUser,
// updateUser,
function registPaymentInfo(requestData) {
  return dispatch => {
    dispatch(request());

    paymentService.registPaymentInfo(requestData).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: paymentConstants.REGIST_PAYMENT_REQUEST };
  }
  function success(result) {
    return { type: paymentConstants.REGIST_PAYMENT_SUCCESS, result };
  }
  function failure(error) {
    return { type: paymentConstants.REGIST_PAYMENT_FAILURE, error };
  }
}

// 결제정보 조회 (마이페이지, 결제정보 페이지)
function getRegistInfo(userSno, officeSno, brandCode) {
  return dispatch => {
    dispatch(request());

    paymentService.getRegistInfo(userSno, officeSno, brandCode).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: paymentConstants.GET_MY_PAYMENT_REQUEST };
  }
  function success(result) {
    return { type: paymentConstants.GET_MY_PAYMENT_SUCCESS, result };
  }
  function failure(error) {
    return { type: paymentConstants.GET_MY_PAYMENT_FAILURE, error };
  }
}

// 결제 히스토리 (결제정보 페이지)
function getHistory(userSno, officeSno, brandCode) {
  return dispatch => {
    dispatch(request());

    paymentService.getHistory(userSno, officeSno, brandCode).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: paymentConstants.GET_MY_PAYMENT_HISTORY_REQUEST };
  }
  function success(result) {
    return { type: paymentConstants.GET_MY_PAYMENT_HISTORY_SUCCESS, result };
  }
  function failure(error) {
    return { type: paymentConstants.GET_MY_PAYMENT_HISTORY_FAILURE, error };
  }
}

function singlePayment(targetDate, userSno, officeSno) {
  return dispatch => {
    dispatch(request());

    paymentService.singlePayment(targetDate, userSno, officeSno).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: paymentConstants.SINGLE_PAYMENT_REQUEST };
  }
  function success(result) {
    return { type: paymentConstants.SINGLE_PAYMENT_SUCCESS, result };
  }
  function failure(error) {
    return { type: paymentConstants.SINGLE_PAYMENT_FAILURE, error };
  }
}

function resetSinglePayment() {
  return dispatch => {
    dispatch(resetItem());

    function resetItem() {
      return { type: paymentConstants.SINGLE_PAYMENT_RESET };
    }
  };
}

function resetRegistPaymentInfo() {
  return dispatch => {
    dispatch(resetItem());

    function resetItem() {
      return { type: paymentConstants.REGIST_PAYMENT_RESET };
    }
  };
}

function setPaymentPlan(userSno, productCode) {
  return dispatch => {
    dispatch(request());

    paymentService.setPaymentPlan(userSno, productCode).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: paymentConstants.SET_PAYMENT_PLAN_REQUEST };
  }
  function success(result) {
    return { type: paymentConstants.SET_PAYMENT_PLAN_SUCCESS, result };
  }
  function failure(error) {
    return { type: paymentConstants.SET_PAYMENT_PLAN_FAILURE, error };
  }
}

function getPaymentPlan(userSno) {
  return dispatch => {
    dispatch(request());

    paymentService.getPaymentPlan(userSno).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: paymentConstants.GET_PAYMENT_PLAN_REQUEST };
  }
  function success(result) {
    return { type: paymentConstants.GET_PAYMENT_PLAN_SUCCESS, result };
  }
  function failure(error) {
    return { type: paymentConstants.GET_PAYMENT_PLAN_FAILURE, error };
  }
}

function checkUserOrderApp(isCheckUserOrderApp) {
  return { type: paymentConstants.CHECK_USER_ORDER_APP, isCheckUserOrderApp };
}
