import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import icBack from '../../../../assets/outsourcing/img/ic_back.svg';
import HelpButton from '../components/HelpButton';
import { isMarketbomLink } from '../../../../helpers';

const MypageHeader = props => {
  const history = useHistory();
  const {
    handleBack = () => history.goBack(),
    className = '',
    title = '',
    optionElement = <></>,
    isVisibleBack = true,
  } = props;

  return (
    <Wrapper className={className}>
      <div className="inner wrap">
        {isVisibleBack && (
          <div onClick={handleBack}>
            <img className="back-icon" alt="" src={icBack} />
          </div>
        )}
        <div className="header-txt">{title}</div>

        {!isMarketbomLink() && <HelpButton />}

        {optionElement}
      </div>
    </Wrapper>
  );
};

export default MypageHeader;

const Wrapper = styled.header`
  padding-top: env(safe-area-inset-top);

  .wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .back-icon {
    position: absolute;
    left: 10px;
    top: 16px !important;
  }
  .header-txt {
    display: inline-flex;
    align-items: center;
    color: #28282e;
    justify-content: center;
    height: 32px;
    font-weight: 800;
    font-size: 17px;
  }
`;
