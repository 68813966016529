import classnames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { isMobile } from '../../constants';
import { PATHS } from '../../constants/Routes';
import { getMyUserData, isMarketbomLink } from '../../helpers';
import { isFreePlan, isWmpoSm } from '../../helpers/CommonUtil';
import { paymentPlanProcess } from '../../helpers/PaymentPlanProcess';
import PosTooltip from '../../pages/Component/PosTooltip';
import FunnelCheck from '../Modal/FunnelCheck';
import { MobileAppInstallPopup } from '../Modal/MobileAppInstallPopup';
import { ModalADPilot } from '../Modal/ModalADPilot';
import { ModalAppLaunching } from '../Modal/ModalAppLaunching';
import ToastAccountLinkError from './ToastAccountLinkError';
import ToastInstallPos from './ToastInstallPos';
import ToastNonPayment from './ToastNonPayment';
import ToastNotice from './ToastNotice';
import ToastPeriod from './ToastPeriod';
import './toast.scss';
import UpdateAdditionalUserInfo from '../../pages/Mobile/MyPage/Container/UpdateAdditionalUserInfo';

/**
 * @function
 * @description viewType 지정으로 선택적 토스트 작동 (default : 모든 토스트 작동)
 * @param {*}
 * @return {*}
 */
const Toast = ({ viewType = 'default' }) => {
  const userData = getMyUserData(false);

  const { isSlideShow } = useSelector(state => state.setIsSlideShow);
  const { tabState } = useSelector(state => state.tabState);
  const { getOrderAppOfficeOwner: orderAppData } = useSelector(state => state.getOrderAppOfficeOwner);

  const isAccessUser = () => {
    const tmpUserData = getMyUserData(false);

    if (!tmpUserData || !orderAppData) {
      return false;
    }

    if (tmpUserData['auth_type'] !== 'D1') {
      return true;
    }

    if (tmpUserData['company_auth_type'] === 2) {
      if (
        tmpUserData['skip_account_link'] === 0 &&
        (!orderAppData['orderAppObj'] || Object.values(orderAppData['orderAppObj']).length <= 0) &&
        tmpUserData['payment_card_state'] === 0
      ) {
        return false;
      }

      // 요금제
      if (tmpUserData['product_code'] === 'P000F1') {
        return true;
      }

      if (!tmpUserData['product_code']) {
        return false;
      }
      // 결제정보 미입력 상태(0 : 미입력, 1 : 입력)
      if (tmpUserData['payment_card_state'] === 0) {
        return false;
      }

      return true;
    }

    if (tmpUserData['skip_account_link']) {
      return true;
    }

    if (!orderAppData['orderAppObj'] || Object.values(orderAppData['orderAppObj']).length <= 0) {
      return false;
    }
  };

  const isVisible = () => {
    // 임시로 메뉴 탭에서 토스트 미노출 되도록 수정 -> 추후 개선할 때 변경할 예정
    return !isSlideShow && !(paymentPlanProcess() && tabState === 'menu');
  };

  let isActiveSlideArea = document.getElementById('slideArea');

  return (
    <>
      <ToastContainer className={classnames('Toast-ui', viewType)} isActiveSlideArea={isActiveSlideArea}>
        {(viewType === 'default' || viewType === 'is-date') && <ToastPeriod />}
        {(viewType === 'default' || viewType === 'is-non-payment') && <ToastNonPayment />}
        {/* {(viewType === 'default' || viewType === 'is-loading-text') && <ToastSyncData />} */}

        {!isMobile() && (viewType === 'default' || viewType === 'is-notice') && <ToastNotice />}
        {!isMobile() &&
          window.location.hash.indexOf('#/mobile/') === -1 &&
          window.location.hash.indexOf(PATHS.manageOrderApp.url) === -1 && <ToastAccountLinkError viewType="pc" />}

        {!isMobile() && isWmpoSm() && <ModalAppLaunching />}

        {!isMobile() && <ModalADPilot />}

        {(window.location.hash.indexOf('#/mobile/main') !== -1 ||
          window.location.hash.indexOf('#/mobile/officeOwnerAnalysis') !== -1) &&
          isVisible() &&
          isAccessUser() && (
            <>
              <ToastNotice />
              <MobileAppInstallPopup />

              {userData && userData['auth_type'] === 'D1' && !isFreePlan() && (
                <>
                  <PosTooltip />
                  {!userData['pos_sno'] && <ToastInstallPos />}
                </>
              )}
              <FunnelCheck />
            </>
          )}
      </ToastContainer>

      {/* 일반 자영업자 부가정보 입력 */}
      {isMarketbomLink() === false &&
        (window.location.hash.includes('#/analysis') || window.location.hash.includes('#/mobile')) && (
          <UpdateAdditionalUserInfo />
        )}
    </>
  );
};

export default Toast;

const ToastContainer = styled.div`
  @media (min-width: 560px) {
    gap: 8px;
  }

  ${props =>
    props.isActiveSlideArea &&
    css`
      margin: 0 0 8px 0 !important;
      bottom: calc(164px + env(safe-area-inset-bottom)) !important;
    `}
`;
