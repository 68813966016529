import { isMobile } from '../constants';
import { setAppHeaderColor } from './AppWebView';

export const chartAvgStyle = props => {
  const barChartMaxValue = props.yMax;
  const barChartHeight = props.barChartHeight;
  const averageValue = props.average;

  const averAgeScrollHeight = (averageValue * barChartHeight) / barChartMaxValue;
  let lineHeight = isMobile() ? averAgeScrollHeight + 31 : averAgeScrollHeight + 23;
 
  if(!lineHeight){
    return `display: none`;
  }
  
  return {
    bottom: `${lineHeight}px`,
  };
};

export const chartAvgLineStyle = props => {
  // 차트 높이, 최댓값에 비례하여 평균 값의 위치 구하는 함수.
  const barChartMaxValue = props.yMax;
  const barChartHeight = props.barChartHeight;
  const averageValue = props.average;

  const averAgeScrollHeight = (averageValue * barChartHeight) / barChartMaxValue;
  let lineHeight = isMobile() ? averAgeScrollHeight + 38 : averAgeScrollHeight + 31;


  if(!lineHeight){
    return `display: none`;
  }

  return {
    bottom: `${lineHeight}px`,
  };
};

export const setThemeColor = themeColor => {
  setAppHeaderColor(themeColor);

  document.getElementsByName('theme-color')[0].content = themeColor;
  document.getElementsByName('msapplication-navbutton-color')[0].content = themeColor;
  document.getElementsByName('apple-mobile-web-app-status-bar-style')[0].content = themeColor;
};
