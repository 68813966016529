import { checkReactNativeWebView } from '../helpers/AppWebView';
import { useTranslation } from '../locales';

// 일반 상수
var GLOBAL_COUNT = 1;
var GLOBAL_CHART_COUNT = 1;
export const TABLE_LISTING_COUNT = 20;
export const MOBILE_TABLE_LISTING_COUNT = 10;
export const EXCEL_TABLE_LISTING_COUNT = 30;
export const PAYMENT_TABLE_LISTING_COUNT = 10;

export const ASC_KEY = ' ASC';
export const DESC_KEY = ' DESC';

export const INPUT = '/d{4}/';

// 용도?
export const chartCount = () => {
  GLOBAL_CHART_COUNT > 2 ? (GLOBAL_CHART_COUNT = 1) : GLOBAL_CHART_COUNT++;

  return GLOBAL_CHART_COUNT;
};

// 사용처 ? 로그인 checkDispatch 횟수?
export const globalCount = () => {
  GLOBAL_COUNT++;

  return GLOBAL_COUNT;
};

// 리덕스에서 api fetch가 아닌 state 업데이트로만 사용되는 actions
export const commonConstants = {
  SET_SEARCH_PARAMS: 'SET_SEARCH_PARAMS',
  SET_OFFICE_RANGE: 'SET_OFFICE_RANGE',
  SET_IS_ACTIVE_AD_PIN: 'SET_IS_ACTIVE_AD_PIN',
  SET_DETAIL_ORDER_VIEW_DATE: 'SET_DETAIL_ORDER_VIEW_DATE',
  SET_RANGE_PERCENTAGE_BY_VIEWTYPE: 'SET_RANGE_PERCENTAGE_BY_VIEWTYPE',
  SET_OFFICE_RANGE_RESET: 'SET_OFFICE_RANGE_RESET',
  SET_MAX_DISTANCE: 'SET_MAX_DISTANCE',
  SET_MAX_DISTANCE_RESET: 'SET_MAX_DISTANCE_RESET',
  SET_DELIVERY_OFFICE_SNO: 'SET_DELIVERY_OFFICE_SNO',
  SET_DELIVERY_RANGE_COUNT: 'SET_DELIVERY_RANGE_COUNT',
  SET_DELIVERY_ORDER_VIEW_TYPE: 'SET_DELIVERY_ORDER_VIEW_TYPE',
  SET_ADVERTISEMENT_FILTER_TYPE: 'SET_ADVERTISEMENT_FILTER_TYPE',
  SET_MENU_OPTION_TYPE: 'SET_MENU_OPTION_TYPE',
  SET_ALERT_MESSAGE: 'SET_ALERT_MESSAGE',
  NAV_RERENDER: 'NAV_RERENDER',
  OFFICE_MANAGEMENT_EXCEL_GUIDE_STEP: 'OFFICE_MANAGEMENT_EXCEL_GUIDE_STEP',
  USER_MANAGEMENT_EXCEL_GUIDE_STEP: 'USER_MANAGEMENT_EXCEL_GUIDE_STEP',
  SET_MANAGEMENT_EDITMODE: 'SET_MANAGEMENT_EDITMODE',

  REVERSE_GEOCODE_RESET: 'GET_REVERSE_GEOCODE_RESET',
  REVERSE_GEOCODE_REQUEST: 'GET_REVERSE_GEOCODE_REQUEST',
  REVERSE_GEOCODE_SUCCESS: 'GET_REVERSE_GEOCODE_SUCCESS',
  REVERSE_GEOCODE_FAILURE: 'GET_REVERSE_GEOCODE_FAILURE',

  ACTIVE_TOOLTIP_RESET: 'ACTIVE_TOOLTIP_RESET',
  ACTIVE_TOOLTIP: 'ACTIVE_TOOLTIP',

  SET_MAIN_OFFICE_FILTER: 'SET_MAIN_OFFICE_FILTER',
  SET_DELIVERY_VIEW_TYPE: 'SET_DELIVERY_VIEW_TYPE',
  SET_MENU_SALE_VIEW_TYPE: 'SET_MENU_SALE_VIEW_TYPE',
  SET_MAP_FULL_SIZE: 'SET_MAP_FULL_SIZE',

  SET_CHART_TOOLTIP_ZINDEX: 'SET_CHART_TOOLTIP_ZINDEX',
  SET_CUSTOMER_REVIEW_VIEW_TYPE: 'SET_CUSTOMER_REVIEW_VIEW_TYPE',

  ACTIVE_NAV_ITEM_ONLY: 'ACTIVE_NAV_ITEM_ONLY',

  MASTER_MANAGER_STATE: 'MASTER_MANAGER_STATE',

  SET_DONG_SALES_PARAMS: 'SET_DONG_SALES_PARAMS',

  SET_DONG_SALES_DATA_LIST: 'SET_DONG_SALES_DATA_LIST',

  SET_MOBILE_OFFICE_TAB_DETECTING_SCROLL: 'SET_MOBILE_OFFICE_TAB_DETECTING_SCROLL',

  SET_IS_REAL_TIME: 'SET_IS_REAL_TIME',

  SET_COMBINE_ALARM_LIST: 'SET_COMBINE_ALARM_LIST',
  SET_CLOSE_MENU_ITEM_SLIDE: 'SET_CLOSE_MENU_ITEM_SLIDE',

  SET_IS_SLIDE_SHOW: 'SET_IS_SLIDE_SHOW',

  SET_IS_MOBILE_POPUP_SHOW: 'SET_IS_MOBILE_POPUP_SHOW',

  SET_IS_MOBILE_COMMON_POPUP_SHOW: 'SET_IS_MOBILE_COMMON_POPUP_SHOW',
};

export const ORDER_CHANNEL_INFO = {
  0: {
    value: 0,
    name: '기타(배달)',
    priority: 99,
    symbolColor: '#9BA2BA',
    symbolShape: '',
    iconName: 'iconShop',
    useReview: false,
  },
  1: {
    value: 1,
    name: '배달의민족',
    priority: 1,
    symbolColor: '#39D7CD',
    symbolShape: '',
    iconName: 'iconBaemin',
    useReview: true,
  },
  2: {
    value: 2,
    name: '배민1',
    priority: 2,
    symbolColor: '#F5D663',
    symbolShape: '',
    iconName: 'iconBaeminOne',
    useReview: true,
  },
  3: {
    value: 3,
    name: '요기요',
    priority: 4,
    symbolColor: '#E85074',
    symbolShape: '',
    iconName: 'iconYogiyo',
    useReview: true,
  },
  4: {
    value: 4,
    name: '전화주문',
    priority: 10,
    symbolColor: '#5666FF',
    symbolShape: '',
    iconName: 'iconTelepone',
    useReview: false,
  },
  5: {
    value: 5,
    name: '쿠팡이츠',
    priority: 3,
    symbolColor: '#58B4FC',
    symbolShape: '',
    iconName: 'iconCoupang',
    useReview: true,
  },
  6: {
    value: 6,
    name: '위메프오',
    priority: 5,
    symbolColor: '#F05641',
    symbolShape: '',
    iconName: 'iconWmpo',
    useReview: false,
  },
  7: {
    value: 7,
    name: '배달특급',
    priority: 7,
    symbolColor: '#496EF2',
    symbolShape: '',
    iconName: 'iconSpecialDelivery',
    useReview: false,
  },
  8: {
    value: 8,
    name: '스마트플레이스',
    priority: 8,
    symbolColor: '#54D28E',
    symbolShape: '',
    iconName: 'iconSmartPlace',
    useReview: false,
  },
  9: { value: 9, name: '땡겨요', priority: 9, symbolColor: '#F47E4B', iconName: 'iconddangyo', useReview: false },
  10: {
    value: 10,
    name: '자사앱',
    priority: 6,
    symbolColor: '#5C5C5C',
    symbolShape: 'donut',
    iconName: 'iconSelfApp',
    useReview: false,
  },
};

export const PAY_TYPE_INFO = {
  100: {
    value: 100,
    siteType: 1,
    name: '신용카드',
    subname: '',
    priority: 100,
    symbolColor: '#6F72FF',
    symbolShape: '',
    iconName: 'iconCard',
  },
  101: {
    value: 101,
    siteType: 2,
    name: '현금',
    subname: '',
    priority: 101,
    symbolColor: '#D16FFF',
    symbolShape: '',
    iconName: 'iconCash',
  },
  102: {
    value: 102,
    siteType: 3,
    name: '기타',
    subname: '',
    priority: 102,
    symbolColor: '#9BA2BA',
    symbolShape: '',
    iconName: 'iconShop',
  },

  10: {
    value: 10,
    siteType: 3,
    name: '외상',
    subname: '',
    priority: 110,
    symbolColor: '#9BA2BA',
    symbolShape: '',
    iconName: 'iconShop',
  },
  11: {
    value: 11,
    siteType: 3,
    name: '상품권',
    subname: '',
    priority: 111,
    symbolColor: '#9BA2BA',
    symbolShape: '',
    iconName: 'iconShop',
  },
  12: {
    value: 12,
    siteType: 3,
    name: '식권',
    subname: '',
    priority: 112,
    symbolColor: '#9BA2BA',
    symbolShape: '',
    iconName: 'iconShop',
  },
  13: {
    value: 13,
    siteType: 3,
    name: '포인트',
    subname: '',
    priority: 113,
    symbolColor: '#9BA2BA',
    symbolShape: '',
    iconName: 'iconShop',
  },
  14: {
    value: 14,
    siteType: 3,
    name: '제휴포인트',
    subname: '',
    priority: 114,
    symbolColor: '#9BA2BA',
    symbolShape: '',
    iconName: 'iconShop',
  },
  15: {
    value: 15,
    siteType: 3,
    name: '제휴카드',
    subname: '',
    priority: 115,
    symbolColor: '#9BA2BA',
    symbolShape: '',
    iconName: 'iconShop',
  },
  16: {
    value: 16,
    siteType: 3,
    name: '사원카드',
    subname: '',
    priority: 116,
    symbolColor: '#9BA2BA',
    symbolShape: '',
    iconName: 'iconShop',
  },
  17: {
    value: 17,
    siteType: 3,
    name: '모바일쿠폰',
    subname: '',
    priority: 117,
    symbolColor: '#9BA2BA',
    symbolShape: '',
    iconName: 'iconShop',
  },
  18: {
    value: 18,
    siteType: 3,
    name: '캐시비',
    subname: '',
    priority: 118,
    symbolColor: '#9BA2BA',
    symbolShape: '',
    iconName: 'iconShop',
  },
  19: {
    value: 19,
    siteType: 3,
    name: '선불카드',
    subname: '',
    priority: 119,
    symbolColor: '#9BA2BA',
    symbolShape: '',
    iconName: 'iconShop',
  },
  20: {
    value: 20,
    siteType: 3,
    name: '계좌이체',
    subname: '',
    priority: 120,
    symbolColor: '#9BA2BA',
    symbolShape: '',
    iconName: 'iconShop',
  },
  21: {
    value: 21,
    siteType: 3,
    name: '카드(임의)',
    subname: '',
    priority: 121,
    symbolColor: '#9BA2BA',
    symbolShape: '',
    iconName: 'iconShop',
  },
  22: {
    value: 22,
    siteType: 3,
    name: '선결제',
    subname: '',
    priority: 122,
    symbolColor: '#9BA2BA',
    symbolShape: '',
    iconName: 'iconShop',
  },
  23: {
    value: 23,
    siteType: 3,
    name: '전자상품권',
    subname: '',
    priority: 123,
    symbolColor: '#9BA2BA',
    symbolShape: '',
    iconName: 'iconShop',
  },
  24: {
    value: 24,
    siteType: 3,
    name: '간편결제',
    subname: '',
    priority: 124,
    symbolColor: '#9BA2BA',
    symbolShape: '',
    iconName: 'iconShop',
  },
  25: {
    value: 25,
    siteType: 3,
    name: '교환권',
    subname: '',
    priority: 125,
    symbolColor: '#9BA2BA',
    symbolShape: '',
    iconName: 'iconShop',
  },
  26: {
    value: 26,
    siteType: 3,
    name: '전자화폐',
    subname: '',
    priority: 126,
    symbolColor: '#9BA2BA',
    symbolShape: '',
    iconName: 'iconShop',
  },
  27: {
    value: 27,
    siteType: 3,
    name: '전자결제',
    subname: '',
    priority: 127,
    symbolColor: '#9BA2BA',
    symbolShape: '',
    iconName: 'iconShop',
  },
  28: {
    value: 28,
    siteType: 3,
    name: '쿠폰',
    subname: '',
    priority: 128,
    symbolColor: '#9BA2BA',
    symbolShape: '',
    iconName: 'iconShop',
  },
  29: {
    value: 29,
    siteType: 3,
    name: '외부결제',
    subname: '',
    priority: 129,
    symbolColor: '#9BA2BA',
    symbolShape: '',
    iconName: 'iconShop',
  },
  30: {
    value: 30,
    siteType: 3,
    name: 'E교환권',
    subname: '',
    priority: 130,
    symbolColor: '#9BA2BA',
    symbolShape: '',
    iconName: 'iconShop',
  },
  31: {
    value: 31,
    siteType: 3,
    name: '페이코',
    subname: '',
    priority: 131,
    symbolColor: '#9BA2BA',
    symbolShape: '',
    iconName: 'iconShop',
  },
  32: {
    value: 32,
    siteType: 3,
    name: '스마트플레이스',
    subname: '',
    priority: 132,
    symbolColor: '#54D28E',
    symbolShape: '',
    iconName: 'iconSmartPlace',
  },
};

export const ORDER_APP_COLORS = Object.fromEntries(
  Object.entries(ORDER_CHANNEL_INFO).map(([key, value]) => [key, value.symbolColor]),
);

export const ORDER_WEIGHT_KEY = Object.fromEntries(
  Object.entries(ORDER_CHANNEL_INFO).map(([key, value]) => [key, value.priority]),
);

export const ORDER_APPS_INDEX = Object.fromEntries(
  Object.entries(ORDER_CHANNEL_INFO).map(([key, value]) => [key, value.name]),
);

export const SITE_TYPES_INDEX = Object.fromEntries(
  Object.entries(PAY_TYPE_INFO).map(([key, value]) => [key, value.name]),
);

export const ORDER_APPS_CODE = Object.fromEntries(
  // 이미지는 있지만 사용하지 않는 아이콘 "icon3dot","iconOptionMenu","iconSingleMenu" ,"iconDeliveryMenu", iconNewDeliveryMenu",iconMissingAddr"
  Object.entries({ ...ORDER_CHANNEL_INFO, ...PAY_TYPE_INFO }).map(([key, value]) => [key, value.iconName]),
);

// 매장관리 엑셀 다운로드 튜토리얼 관련 플래그
export const BUBBLE_FLAG = {
  step1: 1,
  step2: 2,
  onceUploaded: 3,
  close: 4, // 사용..
  open: 1, // 사용빼기
  allInvite: 5,
  logout: 6,
};

export const ORDER_TYPES = ['전체주문', '신규', '재주문', '주소없음'];

// 알림 타입
export const NOTI_TYPES_INDEX = {
  status: 1,
  service: 3,
  realTime: 4,
  notice: 6,
};

// 리팩토링 지도 배달앱버튼에 사용 (신규추가)
export const getOrderAppFilter2 = orderApps => {
  let orderAppFilter = Object.values(ORDER_CHANNEL_INFO).map(item => {
    return {
      name: item['name'],
      value: item['value'],
      color: item['symbolColor'],
      shape: item['symbolShape'],
    };
  });

  if (orderApps) {
    return orderAppFilter.filter(f => orderApps.some(v => v === f.value));
  } else {
    return orderAppFilter;
  }
};

export const getSiteTypes = () => {
  return Object.values(PAY_TYPE_INFO).map(item => {
    return {
      name: item['name'],
      value: item['siteType'],
      color: item['symbolColor'],
      priority: item['priority'],
      orderApp: item['value'],
      shape: item['symbolShape'],
    };
  });
};

export const DELIVERY_ACCOUNT_LINK_INFO = {
  1: {
    key: 1,
    labelName: '배달의민족/배민1',
    subLabelName: '배민',
    subLabelName2: '배달의민족',
    orderApp: 1,
    avgCheckSec: 20,
  },
  2: {
    key: 2,
    labelName: '쿠팡이츠',
    subLabelName: '쿠팡이츠',
    subLabelName2: '쿠팡이츠',
    orderApp: 5,
    avgCheckSec: 10,
  },
  3: { key: 3, labelName: '요기요', subLabelName: '요기요', subLabelName2: '요기요', orderApp: 3, avgCheckSec: 10 },
  4: {
    key: 4,
    labelName: '배달특급',
    subLabelName: '배달특급',
    subLabelName2: '배달특급',
    orderApp: 7,
    avgCheckSec: 10,
  },
};

export const ADVERTISEMENT_ORDER_TYPES = {
  1: { name: '배달의민족/배민1', value: 1 },
  2: { name: '쿠팡이츠', value: 2 },
  3: { name: '요기요', value: 3 },
  6: { name: '위메프오', value: 6 },
};

export const ADVERTISEMENT_FILTER_TYPES = {
  0: { name: '광고 효율순', value: 'Ratio' },
  1: { name: '광고 매출액 높은순', value: 'Amount' },
  2: { name: '광고핀 많은순', value: 'PinCnt' },
  3: { name: '노출수 많은순', value: 'DisplayCnt' },
  4: { name: '클릭수 많은순', value: 'ClickCnt' },
  5: { name: '클릭률 높은순', value: 'ClickPer' },
  6: { name: '주문건 많은순', value: 'OrderCnt' },
};

export const SITE_ACCOUNT_LINK_INFO = {
  0: {
    key: 0,
    labelName: '매장 홀 POS',
    subLabelName: '매장 홀 POS',
    subLabelName2: '매장 홀 POS',
  },
};

// 리팩토링 지도 주소없음리스트에 사용 (신규추가)
export const ORDER_FREQ = {
  NO_ADDR: { name: '주소없음', value: 0, color: 'rgb(108, 117, 125)' },
  RE: { name: '재주문', value: 1, color: '#3ac47d' },
  NEW: { name: '신규', value: 2, color: '#4d5eff' },
};

// NOTE: 고객리뷰 현황 배달앱 구분에 사용
export const ORDER_APP_FILTER3 = Object.values(ORDER_CHANNEL_INFO)
  .filter(v => v['useReview'])
  .map(v => {
    return {
      name: v['name'],
      value: v['value'],
      color: v['symbolColor'],
    };
  });

// 위치별 - getTopMenu에서 배달주문이 맞는 지 확인하기 위한 식별단어 - ?
export const DELIVERY_FILTER_WORDS = ['배달비', '배달료', '배달팁'];

/*
 * report START
 */

// LAST_DOWNLOAD_REQUEST_VALUE 사용처 - ?
var LAST_DOWNLOAD_REQUEST_VALUE = [null, null];
export const getDownloadRequestValue = () => {
  return LAST_DOWNLOAD_REQUEST_VALUE;
};

// 리포트 다운로드(?)에 대한 값 전역으로 가지고 있기 위한 함수
export const setDownloadRequestValue = (apiPath, requestOptions) => {
  LAST_DOWNLOAD_REQUEST_VALUE = [apiPath, requestOptions];
};

// 리포트 생성 페이지 드랍다운 options 등에서 사용되는 상수
export const DOWNLOAD_SALE_TYPE_NAME = '매출지표(홀)';
export const DOWNLOAD_SALE_VIEW_TYPE_NAME_1 = '일별';
export const DOWNLOAD_SALE_VIEW_TYPE_KEY_1 = 11;
export const DOWNLOAD_SALE_VIEW_TYPE_NAME_2 = '월별';
export const DOWNLOAD_SALE_VIEW_TYPE_KEY_2 = 12;
export const DOWNLOAD_SALE_VIEW_TYPE_NAME_3 = '시간별';
export const DOWNLOAD_SALE_VIEW_TYPE_KEY_3 = 13;
export const DOWNLOAD_SALE_VIEW_TYPE_NAME_4 = '일별상세';
export const DOWNLOAD_SALE_VIEW_TYPE_KEY_4 = 14;

export const DOWNLOAD_MENU_TYPE_NAME = '메뉴지표(홀)';
export const DOWNLOAD_MENU_VIEW_TYPE_NAME_1 = '일별';
export const DOWNLOAD_MENU_VIEW_TYPE_KEY_1 = 21;
export const DOWNLOAD_MENU_VIEW_TYPE_NAME_2 = '월별';
export const DOWNLOAD_MENU_VIEW_TYPE_KEY_2 = 22;
export const DOWNLOAD_MENU_VIEW_TYPE_NAME_3 = '시간별';
export const DOWNLOAD_MENU_VIEW_TYPE_KEY_3 = 23;

export const DOWNLOAD_DELIVERY_TYPE_NAME = '배달지표';
export const DOWNLOAD_DELIVERY_VIEW_TYPE_NAME_1 = '주문건 일별';
export const DOWNLOAD_DELIVERY_VIEW_TYPE_KEY_1 = 31;
export const DOWNLOAD_DELIVERY_VIEW_TYPE_NAME_2 = '주문건 월별';
export const DOWNLOAD_DELIVERY_VIEW_TYPE_KEY_2 = 32;
export const DOWNLOAD_DELIVERY_VIEW_TYPE_NAME_3 = '배달지별';
export const DOWNLOAD_DELIVERY_VIEW_TYPE_KEY_3 = 33;

export const DOWNLOAD_MATERIAL_TYPE_NAME = '원부자재지표';
export const DOWNLOAD_MATERIAL_VIEW_TYPE_NAME_1 = '일별';
export const DOWNLOAD_MATERIAL_VIEW_TYPE_KEY_1 = 41;
export const DOWNLOAD_MATERIAL_VIEW_TYPE_NAME_2 = '월별';
export const DOWNLOAD_MATERIAL_VIEW_TYPE_KEY_2 = 42;

// 어떤 ui에서 최근 날짜순, 과거날짜순 등이 필요한 지
export const downloadOptionTypes = {
  [DOWNLOAD_SALE_TYPE_NAME]: {
    [DOWNLOAD_SALE_VIEW_TYPE_NAME_1]: {
      key: DOWNLOAD_SALE_VIEW_TYPE_KEY_1,
      parents: DOWNLOAD_SALE_TYPE_NAME,
      name: DOWNLOAD_SALE_VIEW_TYPE_NAME_1,
      sortName: ['최근 날짜순', '과거 날짜순'],
      monthPicker: false,
    },
    [DOWNLOAD_SALE_VIEW_TYPE_NAME_2]: {
      key: DOWNLOAD_SALE_VIEW_TYPE_KEY_2,
      parents: DOWNLOAD_SALE_TYPE_NAME,
      name: DOWNLOAD_SALE_VIEW_TYPE_NAME_2,
      sortName: ['최근 날짜순', '과거 날짜순'],
      monthPicker: true,
    },
    [DOWNLOAD_SALE_VIEW_TYPE_NAME_3]: {
      key: DOWNLOAD_SALE_VIEW_TYPE_KEY_3,
      parents: DOWNLOAD_SALE_TYPE_NAME,
      name: DOWNLOAD_SALE_VIEW_TYPE_NAME_3,
      sortName: ['최근 날짜순', '과거 날짜순'],
      monthPicker: false,
    },
    [DOWNLOAD_SALE_VIEW_TYPE_NAME_4]: {
      key: DOWNLOAD_SALE_VIEW_TYPE_KEY_4,
      parents: DOWNLOAD_SALE_TYPE_NAME,
      name: DOWNLOAD_SALE_VIEW_TYPE_NAME_4,
      sortName: ['최근 날짜순', '과거 날짜순'],
      monthPicker: false,
    },
  },
  [DOWNLOAD_MENU_TYPE_NAME]: {
    [DOWNLOAD_MENU_VIEW_TYPE_NAME_1]: {
      key: DOWNLOAD_MENU_VIEW_TYPE_KEY_1,
      parents: DOWNLOAD_MENU_TYPE_NAME,
      name: DOWNLOAD_MENU_VIEW_TYPE_NAME_1,
      sortName: ['최근 날짜순', '과거 날짜순'],
      monthPicker: false,
    },
    [DOWNLOAD_MENU_VIEW_TYPE_NAME_2]: {
      key: DOWNLOAD_MENU_VIEW_TYPE_KEY_2,
      parents: DOWNLOAD_MENU_TYPE_NAME,
      name: DOWNLOAD_MENU_VIEW_TYPE_NAME_2,
      sortName: ['최근 날짜순', '과거 날짜순'],
      monthPicker: true,
    },
    [DOWNLOAD_MENU_VIEW_TYPE_NAME_3]: {
      key: DOWNLOAD_MENU_VIEW_TYPE_KEY_3,
      parents: DOWNLOAD_MENU_TYPE_NAME,
      name: DOWNLOAD_MENU_VIEW_TYPE_NAME_3,
      sortName: ['최근 날짜순', '과거 날짜순'],
      monthPicker: false,
    },
  },
  [DOWNLOAD_DELIVERY_TYPE_NAME]: {
    [DOWNLOAD_DELIVERY_VIEW_TYPE_NAME_1]: {
      key: DOWNLOAD_DELIVERY_VIEW_TYPE_KEY_1,
      parents: DOWNLOAD_DELIVERY_TYPE_NAME,
      name: DOWNLOAD_DELIVERY_VIEW_TYPE_NAME_1,
      sortName: ['최근 날짜순', '과거 날짜순'],
      monthPicker: false,
    },
    [DOWNLOAD_DELIVERY_VIEW_TYPE_NAME_2]: {
      key: DOWNLOAD_DELIVERY_VIEW_TYPE_KEY_2,
      parents: DOWNLOAD_DELIVERY_TYPE_NAME,
      name: DOWNLOAD_DELIVERY_VIEW_TYPE_NAME_2,
      sortName: ['최근 날짜순', '과거 날짜순'],
      monthPicker: true,
    },
    [DOWNLOAD_DELIVERY_VIEW_TYPE_NAME_3]: {
      key: DOWNLOAD_DELIVERY_VIEW_TYPE_KEY_3,
      parents: DOWNLOAD_DELIVERY_TYPE_NAME,
      name: DOWNLOAD_DELIVERY_VIEW_TYPE_NAME_3,
      sortName: ['주문건 많은순', '주문건 적은순'],
      monthPicker: false,
    },
  },
  [DOWNLOAD_MATERIAL_TYPE_NAME]: {
    [DOWNLOAD_MATERIAL_VIEW_TYPE_NAME_1]: {
      key: DOWNLOAD_MATERIAL_VIEW_TYPE_KEY_1,
      parents: DOWNLOAD_MATERIAL_TYPE_NAME,
      name: DOWNLOAD_MATERIAL_VIEW_TYPE_NAME_1,
      sortName: ['최근 날짜순', '과거 날짜순'],
      monthPicker: false,
    },
    [DOWNLOAD_MATERIAL_VIEW_TYPE_NAME_2]: {
      key: DOWNLOAD_MATERIAL_VIEW_TYPE_KEY_2,
      parents: DOWNLOAD_MATERIAL_TYPE_NAME,
      name: DOWNLOAD_MATERIAL_VIEW_TYPE_NAME_2,
      sortName: ['최근 날짜순', '과거 날짜순'],
      monthPicker: true,
    },
  },
};
/*
 * report END
 */

// 인터넷 익스플로러인 지 판별하는 함수
export const isIEBrowser = () => {
  return /MSIE (\d+\.\d+);/.test(navigator.userAgent) || navigator.userAgent.indexOf('Trident/') > -1;
};

// 모바일뷰라면 어떤 모바일뷰인 지 판별하는 함수
export const isMobile = (checkWidth = true) => {
  const isWebView = checkReactNativeWebView();
  if (isWebView) {
    return true;
  }

  if (checkWidth && document.body.clientWidth >= 1024) {
    return false;
  }

  if (window.location.hash.includes('promotion-google')) {
    return /Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  } else {
    if (isTablet()) {
      return false;
    }

    return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
};

export const isTablet = () => {
  const userAgent = navigator.userAgent;

  if (userAgent.match(/SM-T[0-9]/)) {
    return true;
  }

  return /ipad|Tablet/i.test(userAgent);
};

export const isIOS = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const mobilePlatform = () => {
  const agent = navigator.userAgent.toLowerCase();

  if (agent.indexOf('android') > -1) {
    return 'android';
  } else if (agent.indexOf('iphone') > -1 || agent.indexOf('ipad') > -1 || agent.indexOf('ipod') > -1) {
    return 'ios';
  }
};

// slick 슬라이더 dot에 대한 상수
export const defaultDotItems = () => {
  return getDotItems(10);
};

// 현재 슬라이드 index를 인덱스를 리턴??하는 함수
export const getDotItems = count => {
  return [...Array(count).keys()].map(i => ({ id: i + 1, name: 'Item ' + (i + 1) }));
};

// 첨부 가능한 이미지 확장자에 대한 상수
export const ACCEPT_IMG_FILE = '.jpg,.jpeg,.png,.gif';

export const TUTORIAL_STATUS_INDEX = {
  MENU_MANAGEMENT: 0,
  FRANCHISE_SERVICE_GUIDE: 1,
  DELIVERY_PUSH_GUIDE_1: 2,
  DELIVERY_PUSH_GUIDE_2: 3,
  DELIVERY_TIP_INFO: 4,
};

// contents_menu_able_bit 컨텐츠 노출여부 index
// (메뉴관리, 매장관리, 사용자관리, 배달핀, 홀매출지표, 홀메뉴지표, 배달지표, 원부자재지표, 리뷰지표, 영수증원문보기)
export const CONTENTS_ABLE_INDEX = {
  MENU_MANAGEMENT: 0, // 메뉴관리
  OFFICE_MANAGEMENT: 1, // 매장관리
  USER_MANAGEMENT: 2, // 사용자관리 (미사용)
  DELEVERY_DRAWER: 3, // 배달핀
  SALE_INDICATORS: 4, // 홀매출지표
  MENU_INDICATORS: 5, // 홀메뉴지표
  DELIVERY_INDICATORS: 6, // 배달지표
  MATERIAL_INDICATORS: 7, // 원부자재지표
  REVIEW_INDICATORS: 8, // 리뷰지표
  VIEW_ORIGINAL_RECEIPT: 9, // 브랜드톡톡
};

export const SERVICE_CONTENTS = {
  ANALYSIS_DATE: 'ANALYSIS_DATE', // 기간별 매출
  ANALYSIS_OFFICE: 'ANALYSIS_OFFICE', // 매장별 매출
  ANALYSIS_CHANNEL: 'ANALYSIS_CHANNEL', // 채널별 매출
  ANALYSIS_REALTIME: 'ANALYSIS_REALTIME', // 실시간 주문 현황
  ANALYSIS_MENU: 'ANALYSIS_MENU', // 메뉴판매현황
  ANALYSIS_LOCATION: 'ANALYSIS_LOCATION', // 배달지/고객분석
  ANALYSIS_LOCATION_DONG: 'ANALYSIS_LOCATION_DONG', // 동단위 주문/배달비
  DELIVERY_REVIEW: 'DELIVERY_REVIEW', // 고객리뷰 현황
  ANALYSIS_MATERIAL: 'ANALYSIS_MATERIAL', // 원부자재 분석
  ANALYSIS_LOCATION_AD: 'ANALYSIS_LOCATION_AD', // 광고 분석
  ANALYSIS_REPORT: 'ANALYSIS_REPORT', // 통계/정산
  MANAGEMENT_OFFICE: 'MANAGEMENT_OFFICE', // 매장관리
  MANAGEMENT_ACCOUNT_LINK: 'MANAGEMENT_ACCOUNT_LINK', // 계정연동관리
  MANAGEMENT_USER: 'MANAGEMENT_USER', // 관리자 관리
  MANAGEMENT_MENU: 'MANAGEMENT_MENU', // 메뉴관리
  BRAND_NOTICE: 'BRAND_NOTICE'// 앳트래커 고객센터 - 프렌차이즈 공지사항
};

// 아이콘 상수
export const OFFICE_ICON = 'pe-7s-home';
export const MENU_ICON = 'pe-7s-coffee';
export const MATERIAL_ICON = 'pe-7s-box1';

// 주소없음에 의해 발생된 주문고객의 유의사항-물음표 마크에 사용되는 문구
export const NO_ADDR_COSTUMER_ALT_MSG =
  '- 주소지미상 고객수는 합산되지 않음\n- 기간내 중복 고객은 한명으로 정의되어 추가 합산되지 않음';

// 마커클러스터 뷰잉 줌 단계
export const MARKER_CLUSTER_OFFICE_SHOW_ZOOM = 11;
export const MARKER_CLUSTER_DELIVERY_SHOW_ZOOM = 16;

// 지도분석 배달지 마커
export const MARKER_COLOR = {
  GREEN: 'map_green_icon',
  RED: 'map_red_icon',
  GRAY: 'map_gray_icon',
  BLUE: 'map_blue_icon',
};

// 포스 설치단계 (0:설치대기, 1:설치예약(미구현) 2:정합성검증, 3: 완료)
export const POS_INSTALL_STATE = {
  0: { value: 2, msg: '설치 대기중', installable: true },
  // 1: { value: 3 , msg: '' },
  2: { value: 4, msg: '데이터 검증중' },
  // 3: { value: 5, msg: '' }
};

// 모바일 점주 분석현황 단계
export const OFFICE_STATUS_STATE = {
  11: { value: 1, msg: '계정연동 대기중', dotColor: '#c1c7d7' },
  12: { value: 2, msg: '계정연동 오류', redDot: true },
  13: { value: 3, msg: '계정연동 라이브', dotColor: '#515EF6' },
  5: { value: 3, msg: '설치 대기중', dotColor: '#c1c7d7' },
  6: { value: 4, msg: '데이터수집 & 검증중', dotColor: '#c1c7d7' },
  8: { value: 5, msg: 'DATA 수신오류', redDot: true },
  14: { value: 6, msg: 'D/C설치 라이브', dotColor: '#515EF6' },
  10: { value: 7, msg: '미납정지', redDot: true },
  7: { value: 8, msg: '정상분석 라이브', dotColor: '#3AC47D' },
};

export const OFFICE_STATUS_STATE_SIMPLE = {
  1: { value: 1, msg: '계정연동 대기중', dotColor: '#c1c7d7'},
  2: { value: 2, msg: '계정연동 오류', redDot: true },
  3: { value: 3, msg: '미납정지', redDot: true },
  4: { value: 4, msg: '정상분석 라이브', dotColor: '#3AC47D' },
};

export const AOS_MARKET = 'https://play.google.com/store/apps/details?id=shop.attracker.service';
export const IOS_STORE = 'https://apps.apple.com/kr/app/%EC%95%B3%ED%8A%B8%EB%9E%98%EC%BB%A4/id1638899690';

export const REPORT_REASON_STATE = [
  { value: 6, msg: '부정확한 정보 공유' },
  { value: 5, msg: '주제와 맞지 않은 대화' },
  { value: 4, msg: '욕설/비방' },
  { value: 3, msg: '같은 내용 반복 게시' },
  { value: 2, msg: '음란성/선정성' },
  { value: 1, msg: '기타' },
];

// 프렌차이즈 요금제
export const BRAND_PRODUCT_INFO = {
  default: {
    key: null,
    name: '없음',
    useable: true,
  },
  PF0000: {
    key: 'PF0000',
    name: '(구)유료',
    useable: true,
  },
  PF00F1: {
    key: 'PF00F1',
    name: '무료',
    useable: true,
  },
  PF00F0: {
    key: 'PF00F0',
    name: '무료(제휴사)',
    useable: true,
  },
  PF00L1: {
    key: 'PF00L1',
    name: '라이트',
    useable: false,
  },
  PF00B1: {
    key: 'PF00B1',
    name: '베이직',
    useable: true,
  },
  PF00P1: {
    key: 'PF00P1',
    name: '프리미엄',
    useable: false,
  },
  CLOSE: {
    key: 'CLOSE',
    name: '종료',
    useable: true,
  },
};

// 공지사항 파일 업로드 확장자.
export const ALLOWED_FILE_TYPES = [
  'jpg',
  'jpeg',
  'png',
  'pdf',
  'doc',
  'docx',
  'txt',
  'hwp',
  'xls',
  'xlsx',
  'csv',
  'zip',
  'rar',
];

export const PRODUCT_CODE = {
  P000F1: '무료',
  P000B1: '베이직',
  P000B2: '베이직(6개월)',
  P000B3: '베이직(1년)',
  PF0000: '(구)유료',
  PF00F1: '무료',
  PF00F0: '무료(제휴사)',
  PF00L1: '라이트',
  PF00B1: '베이직',
  PF00P1: '프리미엄',
  PM00B1: '마켓봄-베이직',
  CLOSE: '종료',
};
