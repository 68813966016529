import { authConstants, globalCount } from '../constants';

export function login(state = {}, action) {
  switch (action.type) {
    case authConstants.RESETSTATE:
      return {};
    case authConstants.LOGIN_REQUEST:
      return {
        loading: true,
      };
    case authConstants.LOGIN_SUCCESS:
      return {
        login: {
          login: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case authConstants.LOGIN_FAILURE:
      return {
        error: {
          error: action.error,
          globalCount: globalCount(),
        },
      };
    default:
      return state;
  }
}

export function brandLogin(state = {}, action) {
  switch (action.type) {
    case authConstants.RESETSTATE:
      return {};
    case authConstants.BRAND_LOGIN_REQUEST:
      return {
        loading: true,
      };
    case authConstants.BRAND_LOGIN_SUCCESS:
      return {
        brandLogin: {
          login: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case authConstants.BRAND_LOGIN_FAILURE:
      return {
        error: {
          error: action.error,
          globalCount: globalCount(),
        },
      };
    default:
      return state;
  }
}

export function validPhoneNum(state = {}, action) {
  switch (action.type) {
    case authConstants.RESETSTATE:
      return {};
    case authConstants.VALID_PHONENUM_REQUEST:
      return {
        loading: true,
      };
    case authConstants.VALID_PHONENUM_SUCCESS:
      return {
        validPhoneNum: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case authConstants.VALID_PHONENUM_FAILURE:
      return {
        error: {
          error: action.error,
          globalCount: globalCount(),
        },
      };
    default:
      return state;
  }
}

export function validMobileAuth(state = {}, action) {
  switch (action.type) {
    case authConstants.RESETSTATE:
      return {};
    case authConstants.VALID_MOBILE_AUTH_REQUEST:
      return {
        loading: true,
      };
    case authConstants.VALID_MOBILE_AUTH_SUCCESS:
      return {
        validMobileAuth: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case authConstants.VALID_MOBILE_AUTH_FAILURE:
      return {};
    default:
      return state;
  }
}

export function sendPhoneAuthNumber(state = {}, action) {
  switch (action.type) {
    case authConstants.RESETSTATE:
      return {};
    case authConstants.PHONE_AUTH_NUMBER_REQUEST:
      return {
        loading: true,
      };
    case authConstants.PHONE_AUTH_NUMBER_SUCCESS:
      return {
        sendPhoneAuthNumber: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case authConstants.PHONE_AUTH_NUMBER_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function checkAuthNumber(state = {}, action) {
  switch (action.type) {
    case authConstants.RESETSTATE:
      return {};
    case authConstants.CHECK_AUTH_NUMBER_REQUEST:
      return {
        loading: true,
      };
    case authConstants.CHECK_AUTH_NUMBER_SUCCESS:
      return {
        checkAuthNumber: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case authConstants.CHECK_AUTH_NUMBER_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function resetPassword(state = {}, action) {
  switch (action.type) {
    case authConstants.RESETSTATE:
      return {};
    case authConstants.RESETPASSWORD_REQUEST:
      return {
        loading: true,
      };
    case authConstants.RESETPASSWORD_SUCCESS:
      return {
        resetPassword: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case authConstants.RESETPASSWORD_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function checkLoginState(state = {}, action) {
  switch (action.type) {
    case authConstants.CHECK_LOGIN_STATE_REQUEST:
      return {
        loading: true,
      };
    case authConstants.CHECK_LOGIN_STATE_SUCCESS:
      return {
        checkLoginState: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case authConstants.CHECK_LOGIN_STATE_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function systemErrorLog(state = {}, action) {
  switch (action.type) {
    case authConstants.SYSTEM_ERROR_LOG_RESET:
      return {
        systemErrorLog: null,
      };
    case authConstants.SYSTEM_ERROR_LOG_REQUEST:
      return {
        loading: true,
      };
    case authConstants.SYSTEM_ERROR_LOG_SUCCESS:
      return {
        systemErrorLog: {
          result: action.result,
          globalCount: globalCount(),
        },
      };
    case authConstants.SYSTEM_ERROR_LOG_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export const changeEmail = (state = {}, action) => {
  switch (action.type) {
    case authConstants.CHANGE_EMAIL_RESET:
      return {
        changeEmail: undefined,
      };
    case authConstants.CHANGE_EMAIL_REQUEST:
      return {
        loading: true,
      };
    case authConstants.CHANGE_EMAIL_SUCCESS:
      return {
        changeEmail: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case authConstants.CHANGE_EMAIL_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
};

export const changeMobile = (state = {}, action) => {
  switch (action.type) {
    case authConstants.RESETSTATE:
      return {
        changeMobile: undefined,
      };
    case authConstants.CHANGE_MOBILE_REQUEST:
      return {
        loading: true,
      };
    case authConstants.CHANGE_MOBILE_SUCCESS:
      return {
        changeMobile: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case authConstants.CHANGE_MOBILE_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
};

export const ghostInit = (state = {}, action) => {
  switch (action.type) {
    case authConstants.RESETSTATE:
      return {
        ghostInit: undefined,
      };
    case authConstants.GHOST_INIT_REQUEST:
      return {
        loading: true,
      };
    case authConstants.GHOST_INIT_SUCCESS:
      return {
        ghostInit: {
          result: action.result,
          globalCount: globalCount(),
        },
      };
    case authConstants.GHOST_INIT_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
};

export const checkAdminAccount = (state = {}, action) => {
  switch (action.type) {
    case authConstants.RESETSTATE:
      return {
        checkAdminAccount: undefined,
      };
    case authConstants.CHECK_ADMIN_ACCOUNT_REQUEST:
      return {
        loading: true,
      };
    case authConstants.CHECK_ADMIN_ACCOUNT_SUCCESS:
      return {
        checkAdminAccount: {
          result: action.result,
          globalCount: globalCount(),
        },
      };
    case authConstants.CHECK_ADMIN_ACCOUNT_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
};

export const checkJandiBrandAccessAuth = (state = {}, action) => {
  switch (action.type) {
    case authConstants.RESETSTATE:
      return {
        checkJandiBrandAccessAuth: undefined,
      };
    case authConstants.CHECK_JANDI_BRAND_ACCESS_AUTH_REQUEST:
      return {
        loading: true,
      };
    case authConstants.CHECK_JANDI_BRAND_ACCESS_AUTH_SUCCESS:
      return {
        checkJandiBrandAccessAuth: {
          result: action.result,
          globalCount: globalCount(),
        },
      };
    case authConstants.CHECK_JANDI_BRAND_ACCESS_AUTH_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
};

export const appInfo = (state = {}, action) => {
  switch (action.type) {
    case authConstants.SET_APP_INFO:
      return {
        appInfo: action.appInfo,
      };
    default:
      return state;
  }
};

export const alarmPermissionStatus = (state = {}, action) => {
  switch (action.type) {
    case authConstants.SET_IS_ALARM_PERMISSION_DENIED:
      return {
        isAlarmPermissionDenied: action.isAlarmPermissionDenied,
      };
    default:
      return state;
  }
};

export const setAppPushOption = (state = {}, action) => {
  switch (action.type) {
    case authConstants.SET_APP_PUSH_OPTION_REQUEST:
      return {
        loading: true,
      };
    case authConstants.SET_APP_PUSH_OPTION_SUCCESS:
      return {
        changeOrderAlarm: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case authConstants.SET_APP_PUSH_OPTION_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
};

export const setAuthInfo = (state = {}, action) => {
  switch (action.type) {
    case authConstants.SET_AUTH_INFO:
      return {
        setAuthInfo: action.authInfo,
      };
    default:
      return state;
  }
};