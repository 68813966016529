import { advertisementActiveMarkerInfo, advertisementConstants } from '../constants';

export const officeInfo = (state = {}, action) => {
  switch (action.type) {
    case advertisementConstants.SET_OFFICE_INFO:
      return {
        officeInfo: action.officeInfo,
      };
    default:
      return state;
  }
};

export const distanceToggle = (state = { distance: 3000 }, action) => {
  switch (action.type) {
    case advertisementConstants.SET_DISTANCE_TOGGLE:
      return {
        distance: action.distance,
      };
    default:
      return state;
  }
};

export const activeMarkerInfo = (state = advertisementActiveMarkerInfo, action) => {
  switch (action.type) {
    case advertisementConstants.RESET_ACTIVE_MARKER_INFO:
      return advertisementActiveMarkerInfo;
    case advertisementConstants.SET_ACTIVE_MARKER_INFO:
      return action.activeMarkerInfo;
    default:
      return state;
  }
};

export const setDimmedOfficeFilter = (state = { setDimmedOfficeFilter: false }, action) => {
  switch (action.type) {
    case advertisementConstants.SET_DIMMED_OFFICE_FILTER:
      return {
        setDimmedOfficeFilter: action.isDimmed,
      };
    default:
      return state;
  }
};

export const setOfficeView = (state = {}, action) => {
  switch (action.type) {
    case advertisementConstants.SET_OFFICE_VIEW:
      return {
        setOfficeView: action.isShow,
      };
    default:
      return state;
  }
};

export function getAreaLocationPinListData(state = {}, action) {
  switch (action.type) {
    case advertisementConstants.ADVERTISEMENT_AREA_LIST_REQUEST:
      return {
        loading: true,
      };
    case advertisementConstants.ADVERTISEMENT_AREA_LIST_SUCCESS:
      return {
        getAreaLocationPinListData: {
          result: action.result['result'],
        },
        loading: false,
      };
    case advertisementConstants.ADVERTISEMENT_AREA_LIST_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function getOfficeLocationPinListData(state = {}, action) {
  switch (action.type) {
    case advertisementConstants.ADVERTISEMENT_OFFICE_LIST_REQUEST:
      return {
        loading: true,
      };
    case advertisementConstants.ADVERTISEMENT_OFFICE_LIST_SUCCESS:
      return {
        getOfficeLocationPinListData: {
          result: action.result['result'],
        },
        loading: false,
      };
    case advertisementConstants.ADVERTISEMENT_OFFICE_LIST_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function advertisementMarkerData(state = {}, action) {
  switch (action.type) {
    case advertisementConstants.ADVERTISEMENT_MARKER_LIST_REQUEST:
      return {
        loading: true,
      };
    case advertisementConstants.ADVERTISEMENT_MARKER_LIST_SUCCESS:
      return {
        advertisementMarkerData: {
          result: action.result['result'],
        },
        loading: false,
      };
    case advertisementConstants.ADVERTISEMENT_MARKER_LIST_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export const pinInfo = (state = {}, action) => {
  switch (action.type) {
    case advertisementConstants.SET_PIN_INFO:
      return {
        pinInfo: action.pinInfo,
      };
    default:
      return state;
  }
};

export const activeChannels = (state = { activeChannels: [] }, action) => {
  switch (action.type) {
    case advertisementConstants.SET_ACTIVE_CHANNELS:
      return {
        activeChannels: action.channels,
      };
    default:
      return state;
  }
};

export const maxDeliveryBounds = (state = { maxDeliveryBounds: null }, action) => {
  switch (action.type) {
    case advertisementConstants.SET_MAX_DELIVERY_BOUNDS:
      return {
        maxDeliveryBounds: action.bounds,
      };
    default:
      return state;
  }
};

export const advertisementFilter = (state = { filterType: 1, visibleAdvertisements: [] }, action) => {
  switch (action.type) {
    case advertisementConstants.SET_ADVERTISEMENT_FILTER:
      return action.result;
    default:
      return state;
  }
};
