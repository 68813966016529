import { commonActions, userActions } from '../../../actions';
import { getTargetDomain, pushGAEventByItem } from '../../../helpers';

import {
  removeForFileName,
  replaceHypen,
  rules,
  testPhoneRegex,
  validation,
  maskingPhoneNum,
} from '../../../helpers';

import { MOBILE_AUTH_SUCCESS } from '../../UserPages/MobileAuth/Helper';

// 가입 유형
export const REGIST_TYPE = {
  selfBiz: 1, // 자영업자
  master: 2, // 마스터
  manager: 3, // 본점관리자
  officeOwner: 4, // 점주
};

// 휴대폰번호 인증 관련
export const PHONE_AUTH_NORMAL = 0; // 기본상태
export const PHONE_AUTH_PROGRESS = 1; // 진행중
export const PHONE_AUTH_SUCCESS = 2; // 인증성공
export const PHONE_AUTH_TIMEOUT = 3; // 인증시간만료
export const PHONE_AUTH_INVALID = 4; // 인증실패
export const PHONE_AUTH_ALREADY_REGIST = 5; // 이미 등록된 번호
export const AUTH_COOLTIME = {
  ms: 1800000, // 3분
  sec: 180,
};

// 파일 업로드 관련
export const uploadAttachmentFile = (fileName, file) => {
  const uploadUrl = () => {
    return getTargetDomain() + 'upload/businessFileUpload';
  };

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', uploadUrl());
    const data = new FormData();

    data.append('file', file, fileName + '.' + file.name.split('.').pop(file.name));

    xhr.send(data);
    xhr.addEventListener('load', () => {
      const response = JSON.parse(xhr.responseText);
      resolve(response);
    });
    xhr.addEventListener('error', () => {
      const error = JSON.parse(xhr.responseText);
      reject(error);
    });
  });
};

// submit ---------------------------
export function handleRegist(
  {
    dispatch,
    registType,
    brandKey,
    inputValues,
    phoneAuthState,
    mobileAuthState,
    checkList,
    checkUsePosFeed,
    requireInputFlag,
    validCheckPolicy,
  },
  userKey,
  setSubmitInProgress = () => {},
) {
  const {
    userName,
    phoneNumber,
    email,
    password,
    repeatPassword,
    brandName, // 브랜드명
    companyName, // 회사명
    officeName, // 특정 매장명
    ceoName,
    businessNum,
    businessFile,
    addressData,
    address,
    detailAddress,
    officeCount,
    promotionCode,
    promotionType,
  } = inputValues;

  const alert = message => {
    dispatch(commonActions.alertMessage(message));
  };

  // requireInputFlag : 페이지별로 사용하는 input들이 달라서
  if (requireInputFlag.mobileAuthState === true && mobileAuthState !== MOBILE_AUTH_SUCCESS) {
    alert('본인인증이 확인되지 않았습니다.');
    return;
  }
  if (requireInputFlag.userName === true && !userName) {
    alert('본인인증이 확인되지 않았습니다.');
    return;
  }
  if (requireInputFlag.phoneNumber === true && !phoneNumber) {
    alert('본인인증이 확인되지 않았습니다.');
    return;
  }
  if (
    requireInputFlag.phoneNumber === true &&
    (phoneNumber[0] !== '0' || testPhoneRegex(phoneNumber, true) === false)
  ) {
    alert('휴대전화 형식에 맞지 않습니다.');
    return;
  }
  if (requireInputFlag.phoneAuthState === true && phoneAuthState !== PHONE_AUTH_SUCCESS) {
    alert('휴대전화가 확인되지 않았습니다.');
    return;
  }
  if (requireInputFlag.email === true && !email) {
    alert('이메일이 확인되지 않았습니다.');
    return;
  }
  if (requireInputFlag.email === true && validation(email, rules['email']) === false) {
    alert('이메일 형식에 맞지 않습니다.');
    return;
  }
  if (requireInputFlag.password === true && !password) {
    alert('비밀번호가 확인되지 않았습니다.');
    return;
  }
  if (requireInputFlag.repeatPassword === true && password !== repeatPassword) {
    alert('비밀번호를 동일하게 입력해 주세요.');
    return;
  }
  if (requireInputFlag.brandName === true && !brandName) {
    alert('브랜드명이 확인되지 않았습니다.');
    return;
  }
  if (requireInputFlag.companyName === true && !companyName) {
    alert('회사명이 확인되지 않았습니다.');
    return;
  }
  if (requireInputFlag.officeName === true && !officeName) {
    alert('매장명이 확인되지 않았습니다.');
    return;
  }
  if (requireInputFlag.ceoName === true && !ceoName) {
    alert('대표자이름이 확인되지 않았습니다.');
    return;
  }
  if (requireInputFlag.businessNum === true && !businessNum) {
    alert('사업자번호가 확인되지 않았습니다.');
    return;
  }
  if (requireInputFlag.businessFile === true && !businessFile) {
    alert('사업자등록증이 확인되지 않았습니다.');
    return;
  }
  if (requireInputFlag.address === true && !addressData && !address) {
    alert('주소가 확인되지 않았습니다.');
    return;
  }
  if (requireInputFlag.detailAddress === true && !detailAddress) {
    alert('상세주소가 확인되지 않았습니다.');
    return;
  }
  if (requireInputFlag.email === true && !validCheckPolicy) {
    alert('약관이 확인되지 않았습니다.');
    return;
  }
  const phoneNum = phoneNumber ? replaceHypen(phoneNumber) : null;
  const fileName = businessFile ? removeForFileName(officeName + '_' + phoneNum + '_' + userName) : null;

  const memo = checkUsePosFeed && checkUsePosFeed[0] === true ? '포스피드 사용' : '';

  if (requireInputFlag.businessFile === true) {
    uploadAttachmentFile(fileName, businessFile).then(result => {
      if (!result || !result.path) {
        alert('사업자등록증 업로드에 실패하였습니다.');
        return;
      }

      setSubmitInProgress(true);

      dispatch(
        userActions.registUser(
          registType,
          phoneNum,
          userName,
          email,
          password,
          companyName,
          brandName,
          officeCount,
          officeName,
          ceoName,
          replaceHypen(businessNum),
          addressData,
          detailAddress,
          result.path, // buinessFilePath
          brandKey,
          memo,
          userKey,
          promotionCode,
          promotionType,
          checkList,
        ),
      );
    });
  } else {
    setSubmitInProgress(true);

    dispatch(
      userActions.registUser(
        registType,
        phoneNum,
        userName,
        email,
        password,
        companyName,
        brandName,
        officeCount,
        officeName,
        ceoName,
        replaceHypen(businessNum),
        addressData,
        detailAddress,
        null, // buinessFilePath
        brandKey,
        memo,
        userKey,
        promotionCode,
        promotionType,
      ),
    );
  }
}

export const registTypeKor = registType => {
  switch (registType) {
    case 1:
      return '일반 자영업자';
    case 2:
      return '마스터관리자';
    case 3:
      return '본점관리자';
    case 4:
      return '프랜차이즈 가맹점주';
    default:
      return '';
  }
};

export const pushSignupGAEventItem = item => {
  // GA Event Push가 실패해도 실행에 영향이 없어야함
  try {
    const GAItem = {
      category: '회원가입',
      action: item.action,
      label: makeSignupGALabel(item),
    };

    // push GA Event
    pushGAEventByItem(GAItem);
  } catch (err) {
    console.log('err', err);
  }
};

export const makeSignupGALabel = item => {
  // item = {registType, brandKey = '', userInfo = {}}
  let userInfo = {};

  let resultLabel = {
    registType: item.registType ? registTypeKor(item.registType) : '',
  };

  if (item.brandKey) {
    resultLabel['brandKey'] = item.brandKey;
  }

  if (item.userInfo) {
    userInfo['userName'] = item.userInfo.userName;
    userInfo['phoneNumber'] = maskingPhoneNum(item.userInfo.phoneNumber);
    resultLabel = { ...resultLabel, ...userInfo };
  }

  return JSON.stringify(resultLabel);
};
