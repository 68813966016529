import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import './toast.scss';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkDispatch } from '../../helpers';
import styled from 'styled-components';

const ToastNotice = () => {
  const dispatch = useDispatch();

  const { noticeToastList: list } = useSelector(state => state.serviceNoticeList);

  const [value, setValue] = useState('');
  const [noticeIdx, setNoticeIdx] = useState(null);

  useEffect(() => {
    checkDispatch({ dispatch }, ['serviceNoticeList'], false);
    return () => {};
  }, []);

  useEffect(() => {
    if (list && list.length > 0 && list[0]['on_off'] === 1) {
      setNoticeIdx(list[0]['notice_idx']);
    }
  }, [list]);

  const onClickDisable = value => {
    setValue(value);
    localStorage.setItem('disableToastNoticeIdx', noticeIdx);
  };

  const onClickView = () => {
    localStorage.setItem('viewedNotice', true);
    localStorage.setItem('disableToastNoticeIdx', noticeIdx);
  };

  let disableIdx = localStorage.getItem('disableToastNoticeIdx');

  return (
    <>
      {(!disableIdx || parseInt(disableIdx) !== noticeIdx) && noticeIdx > 0 && list && list.length > 0 && (
        <NoticeContainer className={classnames('toast-ui notice', value)}>
          <div className="desc">{list[0]['toast_comment']}</div>
          <div className="btn-wrap">
            {/* 상위인 Toast-ui 에 클래스 주고 z-index를 마이너스로 처리하기 */}
            <button className="later-btn" onClick={() => onClickDisable('is-ani-stop')}>
              다음에 볼게요
            </button>
            <Link className="inquiry-btn" to={`/service/notice?n=${noticeIdx}`} onClick={() => onClickView()}>
              상세보기
            </Link>
          </div>
        </NoticeContainer>
      )}
    </>
  );
};

export default ToastNotice;

const NoticeContainer = styled.div`
  @media (min-width: 560px) {
    max-width: 540px !important;
    margin: 0 auto !important;
    left: 0 !important;
    right: 0 !important;
  }
`;
