import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { commonActions } from '../actions';
import ATMobileAuthValidInput from '../components/Styled/form/ATMobileAuthValidInput';
import { getMyUserData } from '../helpers';
import { authService, officeService } from '../services';

const CheckPhoneVerfication = ({ isResizeWidth = false }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (isResizeWidth === true) {
      let commonModalWidth = document.getElementsByClassName('modal-dialog');
      if (commonModalWidth) {
        commonModalWidth[0].style.width = 'unset';
      }
    }

    return () => {
      let commonModalWidth = document.getElementsByClassName('modal-dialog');
      if (commonModalWidth) {
        commonModalWidth[0].style.width = 'inherit';
      }
    };
  }, []);

  const [isComplete, setIsComplete] = useState(false);

  const checkPhoneVerfication = async data => {
    if (!data.phoneNumber) {
      dispatch(commonActions.alertMessage(null));
      return;
    }

    let userData = getMyUserData(false);
    let response = await authService.completePhoneVerfication(userData['user_sno'], data.phoneNumber);
    if (response && response['result'] === 1) {
      setIsComplete(true);
    }
  };

  const handleComplete = async () => {
    if (isComplete === false) {
      return;
    }

    // 본인인증 후 서비스중인 매장이 없을 경우 리다이렉트 (마스터 관리자만)
    let userData = getMyUserData(false);
    if (userData['auth_type'] === 'A1') {
      let response = await officeService.managedOfficeList(userData['user_sno'], userData['brand_code'], 2);
      if (response && response['result'].length === 0) {
        window.location.href = '#/members/officeAccount';
      }
    }

    dispatch(commonActions.alertMessage(null));
  };

  return (
    <Wrapper isComplete={isComplete}>
      <div className="title">본인인증</div>
      <div className="sub-title">원활한 서비스를 위해 사용자의 본인 인증이 필요합니다.</div>

      <ATMobileAuthValidInput
        getValue={checkPhoneVerfication}
        useResultAlert={false}
        isComplete={!isComplete}
        element={<button className="btn-style-reset auth-btn">{isComplete ? '본인 인증 완료' : '본인인증'}</button>}
      />
      {isComplete === true && (
        <button className="btn-style-reset next-btn" onClick={handleComplete}>
          다음
          <img className="next-icon" src={require('../assets/utils/images/authNextIcon.svg')} alt="" />
        </button>
      )}

      {isComplete === false && (
        <button className="btn-style-reset next-btn2" onClick={handleComplete}>
          다음
          <img className="next-icon" src={require('../assets/utils/images/authNextIcon.svg')} alt="" />
        </button>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: #ffffff;
  width: 375px;
  height: 285px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 34px 32px 40px 32px;
  .title {
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    color: #28282e;
    margin-bottom: 8px;
  }
  .sub-title {
    color: #28282e;
    font-weight: 600;
    font-size: 13.5px;
    opacity: 0.5;
    margin-bottom: 34px;
  }
  .auth-btn {
    width: 288px;
    padding: 11px 11px 12px 12px;
    background: ${props => (props.isComplete ? ' #D0D5D9B2' : 'rgba(104, 108, 255, 0.7)')};
    pointer-events: ${props => (props.isComplete ? 'none' : '')};
    border-radius: 12px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #fefefe;
  }
  .next-btn {
    margin: 40px auto 0px;
    background: #9598ff;
    pointer-events: '';
    border-radius: 24px;
    width: 107px;
    color: #fefefe;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 14px 32px 14px 32px;
    height: 19px;
    align-items: center;
  }
  .next-btn2 {
    margin: 40px auto 0px;
    background: #d0d5d9b2;
    pointer-events: none;
    border-radius: 24px;
    width: 107px;
    color: #fefefe;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 14px 32px 14px 32px;
    height: 19px;
    align-items: center;
  }
  .next-icon {
    margin-left: 10px;
  }
`;

export default CheckPhoneVerfication;
