import { paymentConstants } from '../constants/Payment';
import {
  mobileOwnerPaybillList,
  MobileOwnerStaticData,
  moOwnerPaymethod,
  paymethodsList,
  staticData,
  storeList,
} from '../components/KmongPayment/DataTable/data';
import { paymentActions } from '../actions/Payment';
import { commonConstants, globalCount, userConstants } from '../constants';

// outsourcing
export function getMasterPaymentInfo(state = staticData, action) {
  switch (action.type) {
    case paymentConstants.GET_PAY_INFO_STATE:
      return state;
    case paymentConstants.GET_MODILE_OWNER_INFO:
      return state;
    case paymentConstants.UPDATE_STATIC_DATA:
      return action.result;
    default:
      return state;
  }
}
// outsourcing
export function getMobileOwnerPaymentInfo(state = moOwnerPaymethod, action) {
  switch (action.type) {
    case paymentConstants.GET_MOBILE_OWNER_PAYMENT_INFO:
      return action.result;
    case paymentConstants.CREATE_MO_OWNER_PAYNET:
      return action.result;
    case paymentConstants.UPDATE_MO_OWNER_PAYMETHOD:
      return action.result;
    default:
      return state;
  }
}

export function registPaymentInfo(state = {}, action) {
  switch (action.type) {
    case paymentConstants.REGIST_PAYMENT_RESET:
      return {};
    case paymentConstants.REGIST_PAYMENT_REQUEST:
      return {
        loading: true,
      };
    case paymentConstants.REGIST_PAYMENT_SUCCESS:
      return {
        registPaymentInfo: {
          result: action.result['result'],
          globalCount: globalCount(),
        },
      };
    case paymentConstants.REGIST_PAYMENT_FAILURE:
      return {
        registPaymentInfo: {
          error: action.error,
        },
      };
    default:
      return state;
  }
}

export function getRegistInfo(state = {}, action) {
  switch (action.type) {
    case paymentConstants.GET_MY_PAYMENT_REQUEST:
      return {
        loading: true,
      };
    case paymentConstants.GET_MY_PAYMENT_SUCCESS:
      return {
        getRegistInfo: {
          result: action.result['result'],
          globalCount: globalCount(),
          loading: false,
        },
      };
    case paymentConstants.GET_MY_PAYMENT_FAILURE:
      return {
        getRegistInfo: {
          error: action.error,
        },
      };
    default:
      return state;
  }
}

export function getHistory(state = {}, action) {
  switch (action.type) {
    case paymentConstants.GET_MY_PAYMENT_HISTORY_REQUEST:
      return {
        loading: true,
      };
    case paymentConstants.GET_MY_PAYMENT_HISTORY_SUCCESS:
      return {
        getHistory: {
          result: action.result['result'],
          globalCount: globalCount(),
          loading: false,
        },
      };
    case paymentConstants.GET_MY_PAYMENT_HISTORY_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function singlePayment(state = {}, action) {
  switch (action.type) {
    case paymentConstants.SINGLE_PAYMENT_RESET:
      return {};
    case paymentConstants.SINGLE_PAYMENT_REQUEST:
      return {
        loading: true,
      };
    case paymentConstants.SINGLE_PAYMENT_SUCCESS:
      return {
        singlePayment: {
          result: action.result['result'],
          globalCount: globalCount()
        },
        loading: false,
      };
    case paymentConstants.SINGLE_PAYMENT_FAILURE:
      return {
        singlePayment: {
          error: action.error,
        },
      };
    default:
      return state;
  }
}

export function getPaymentTableData(state = [], action) {
  switch (action.type) {
    case paymentConstants.GET_PAYMENT_TABLE_DATA:
      return action.result;
    case paymentConstants.UPDATE_PAYMENT_METHOD_SUCCESS:
      return action.result;
    case paymentConstants.UPDATE_PAYMENT_ID_SUCCESS:
      return action.result;
    case paymentConstants.UPDATE_PAYMENT_METHOD:
      return action.result;
    case paymentConstants.UPDATE_PAYMENT_METHOD_INFO_SUCCESS:
      return action.result;
    case paymentConstants.UPDATE_STORELIST_PAYMETHOD:
      return action.result;
    case paymentConstants.STOP_SERVICE:
      return action.result;
    case paymentConstants.SELECT_BY_SEARCH_TEXT:
      return action.result;

    default:
      return state;
  }
}

export function getPayMethodList(state = paymethodsList, action) {
  switch (action.type) {
    case paymentConstants.GET_PAYMETHOD_LIST:
      return state;
    case paymentConstants.CREATE_PAYNETGID_ITEM:
      return action.result;
    case paymentConstants.DELETE_PAYMETHOD_INFO:
      return action.result;
    default:
      return state;
  }
}

export function moOwnerPaybill(state = mobileOwnerPaybillList, action) {
  switch (action.type) {
    case paymentConstants.GET_MOBILE_OWNER_PAYBILL_LIST:
      return action.result;
    case paymentConstants.CREATE_MO_OWNER_PAYBILL_DATA:
      return action.result;
    default:
      return state;
  }
}

export function updatePaymentMethod(state = storeList, action) {
  switch (action.type) {
    default:
      return { state };
  }
}

export function getFilterList(state = [2022, 2021, 2020], action) {
  switch (action.type) {
    default:
      return state;
  }
}

export function getFilterYear(state = 2022, action) {
  switch (action.type) {
    case paymentConstants.GET_FILTER_YEAR:
      return action.result;
    default:
      return state;
  }
}

export function getFilterStoreOrMethod(state = '매장 기준', action) {
  switch (action.type) {
    case paymentConstants.GET_FILTER_STORE_OR_PAYMENT_TEXT:
      return action.result;
    default:
      return state;
  }
}

export function handleModalStep(state = 0, action) {
  switch (action.type) {
    case paymentConstants.SELECT_STORE_STEP:
      return action.state;
    case paymentConstants.SELECT_PAYMETHOD_STEP:
      return action.state;
    case paymentConstants.CREATE_PAYMETHOD_STEP:
      return action.state;
    case paymentConstants.SELECT_PAY_STEP:
      return action.state;
    default:
      return state;
  }
}

export function handleSelectStore(state = [], action) {
  switch (action.type) {
    case paymentConstants.HANDLE_SELECT_STORE:
      return action.state;
    default:
      return state;
  }
}

export function setSearchtextInput(state = '', action) {
  switch (action.type) {
    case paymentConstants.RESET_SEARCH_INPUT_VALUE:
      return action.state;
    case paymentConstants.ON_SEARCH_INPUT_CHANGE:
      return action.text;
    default:
      return state;
  }
}

export function handlePostState(state = false, action) {
  switch (action.type) {
    case paymentConstants.POST_SUCCESS:
      return action.state;
    case paymentConstants.POST_RESET:
      return action.state;
    default:
      return state;
  }
}

export function setPaymentPlan(state = {}, action) {
  switch (action.type) {
    case paymentConstants.SET_PAYMENT_PLAN_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case paymentConstants.SET_PAYMENT_PLAN_SUCCESS:
      return {
        setPaymentPlan: action.result['result'],
        globalCount: globalCount(),
      };
    case paymentConstants.SET_PAYMENT_PLAN_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function getPaymentPlan(state = {}, action) {
  switch (action.type) {
    case paymentConstants.GET_PAYMENT_PLAN_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case paymentConstants.GET_PAYMENT_PLAN_SUCCESS:
      return {
        getPaymentPlan: action.result['result'],
      };
    case paymentConstants.GET_PAYMENT_PLAN_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function checkUserOrderApp(state = { isCheckUserOrderApp: false }, action) {
  switch (action.type) {
    case paymentConstants.CHECK_USER_ORDER_APP:
      return {
        isCheckUserOrderApp: action.isCheckUserOrderApp,
      };
    default:
      return state;
  }
}
