import { materialActions, menuActions, officeActions, reportActions, reviewActions, userActions } from '../actions';
import { SERVICE_CONTENTS } from '../constants';
import { arrayToObject } from './DataMapping';
import { subDate } from './DateConverter';

/**
 * @module LoadData
 */

/**
 * @function
 * @description 브라우저 로컬스토리지에 저장된 값 불러옴
 * @param {*}
 * @return {*}
 */
export const localDataLoad = key => {
  var data = localStorage.getItem(key);
  if (!data || data === 'undefined') {
    return null;
  }

  return JSON.parse(data);
};

/**
 * @function
 * @description 브라우저 로컬스토리지에 해당 키로 값을 저장함
 * @param {*}
 * @return {*}
 */
export const setLocalData = (key, item) => {
  localStorage.setItem(key, JSON.stringify(item));
};

/**
 * @function
 * @description 브라우저 로컬스토리지에 해당 항목을 삭제함
 * @param {*}
 * @return {*}
 */
export const removeLocalData = key => {
  localStorage.removeItem(key);
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const getUserIDAndLoginExpire = () => {
  var result = localDataLoad('user');
  if (!result) {
    return undefined;
  }

  return {
    expireDate: result['_login_expire'],
    userID: result['phoneNumber'],
  };
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const getMyUserData = (redirect = true) => {
  var result = localDataLoad('user');
  if (redirect === true && (!result || !result['userData'] || !result['userData']['brand_code'])) {
    window.location.href = '#/auth/login';
    return;
  }

  return result && result['userData'];
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const getUserToken = () => {
  var result = localDataLoad('user');

  return result && result['token'];
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const setUserToken = (token) => {
  var result = localDataLoad('user') ? localDataLoad('user') : {};
  result['token'] = token;

  setLocalData('user', result);
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const setMyUserData = userData => {
  var result = localDataLoad('user') ? localDataLoad('user') : {};

  result['_brand_code'] = userData['brand_code'];
  result['userData'] = userData;

  setLocalData('user', result);
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const joinMyUserData = obj => {
  let result = localDataLoad('user') ? localDataLoad('user') : {};

  result['userData'] = {
    ...result['userData'],
    ...obj,
  };

  setLocalData('user', result);
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const getServiceType = () => {
  var result = getMyUserData(false);
  if (!result) {
    return undefined;
  }

  return parseInt(result.service_type);
};

/**
 * @function
 * @description 프랜차이즈에 속해있는 점주인지 확인
 * @param {*}
 * @return {*}
 */
export const isRetail = () => {
  var result = getMyUserData(false);
  if (!result) {
    return false;
  }

  return result.is_retail === 1;
};

/**
 * @function
 * @description 프랜차이즈에 속해있는 점주인지 확인
 * @param {*}
 * @return {*}
 */
export const isBrandRetail = () => {
  var userData = getMyUserData(false);
  if (!userData) {
    return false;
  }

  return userData['is_retail'] === 1 && parseInt(userData['brand_type']) === 0;
};

/**
 * @function
 * @description 외부 서비스 연동 계정 여부 확인
 * @param {*}
 * @return {*}
 */
export const isSystemLink = () => {
  var userData = getMyUserData(false);
  if (!userData) {
    return false;
  }

  return userData['system_link'] ? true : false;
};

/**
 * @function
 * @description 외부 서비스 연동 계정 여부 확인
 * @param {*}
 * @return {*}
 */
export const isWmpoLink = () => {
  var userData = getMyUserData(false);
  if (!userData) {
    return false;
  }

  return userData['system_link'] === 1 ? true : false;
};

/**
 * @function
 * @description 마켓봄 서비스 연동 계정 여부 확인
 * @param {*}
 * @return {*}
 */
export const isMarketbomLink = () => {
  var userData = getMyUserData(false);
  if (!userData) {
    return false;
  }

  return userData['marketbom_user_link'] === 1 ? true : false;
};

/**
 * @function
 * @description 
 * @param {*}
 * @return {*}
 */
export const isOfficeManageV2 = () => {
  let userData = getMyUserData(false);
  if (!userData) {
    return false;
  }

  const contentsObj = userData['service_contents_json'] ? arrayToObject(userData['service_contents_json'], 'key') : {};
  if (!contentsObj[SERVICE_CONTENTS['MANAGEMENT_OFFICE']]) {
    return false;
  }

  return contentsObj[SERVICE_CONTENTS['MANAGEMENT_OFFICE']]['version'] === 2;
};

export const isOfficeManageFree = () => {
  let userData = getMyUserData(false);
  if (!userData) {
    return false;
  }

  const contentsObj = userData['service_contents_json'] ? arrayToObject(userData['service_contents_json'], 'key') : {};
  if (!contentsObj[SERVICE_CONTENTS['ANALYSIS_OFFICE']]) {
    return false;
  }

  return contentsObj[SERVICE_CONTENTS['ANALYSIS_OFFICE']]['option'][0] === 'free';
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const getLocalToken = (userID, serviceType) => {
  var result = localDataLoad('token_' + userID + '_' + serviceType);

  if (!result) {
    return undefined;
  }

  return result;
};

/**
 * @function
 * @description 페이지 이동시, 어떤 탭을 열람하고 있는 지에 대한 값을 로컬스토리지에 저장함
 * @param {*}
 * @return {*}
 */
export const setTabKeyByQuery = (query = {}) => {
  var tabKey = query['tab'];
  if (!tabKey) {
    return;
  }

  // 매장별=shop, 날짜별=date, 메뉴별=menu
  // 일별=day, 요일별=week, 시간별=hour
  // 위치별=loc, 배달앱별=ch
  // 서비스별=source, 매체별=media, 자재별=material

  setLocalData('tab_key', tabKey);
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const setTabKey = tabKey => {
  setLocalData('tab_key', tabKey);
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const getTabKey = () => {
  var tabKey = localDataLoad('tab_key');

  setLocalData('tab_key', undefined);

  return tabKey;
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const setSubTabKey = tabKey => {
  setLocalData('sub_tab_key', tabKey);
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const getSubTabKey = () => {
  var tabKey = localDataLoad('sub_tab_key');

  setLocalData('sub_tab_key', undefined);

  return tabKey;
};

/**
 * @function
 * @description 설명추가
 * @param {*}
 * @return {*}
 */
export const checkDispatch = (props, list, isCheck = true) => {
  var userData = getMyUserData(false);
  var serviceType = getServiceType();

  if (!userData) {
    return;
  }

  list.map(item => {
    if (props[item] !== undefined && isCheck) {
      return null;
    }

    switch (item) {
      case 'managedOfficeList':
        props.dispatch(officeActions.managedOfficeList(userData.user_sno, userData.brand_code, serviceType));
        break;
      case 'areaList':
        props.dispatch(officeActions.areaList(userData.brand_code, userData.user_sno));
        break;
      case 'brandMenuList':
        props.dispatch(menuActions.brandMenuList(userData.brand_code));
        break;
      case 'categoryList':
        props.dispatch(menuActions.categoryList(userData.brand_code));
        break;
      case 'companyUserList':
        props.dispatch(userActions.companyUserList(userData.company_code));
        break;
      case 'allOfficeList':
        props.dispatch(officeActions.allOfficeList(userData.company_code));
        break;
      case 'allMenuEngineering':
        props.dispatch(
          menuActions.allMenuEngineering(
            userData['user_sno'],
            userData['brand_code'],
            userData['service_type'],
            subDate('', 7),
            subDate('', 1),
          ),
        );
        break;
      case 'brandRssKeywordList':
        props.dispatch(reviewActions.brandRssKeywordList(userData.user_sno, userData.brand_code));
        break;
      case 'franchiseBrandList':
        props.dispatch(userActions.franchiseBrandList(userData.user_sno, serviceType));
        break;
      case 'getMyUserData':
        props.dispatch(userActions.myUserData(userData.user_sno, serviceType));
        break;
      case 'reportList':
        props.dispatch(reportActions.reportList(userData.user_sno, userData.brand_code));
        break;
      case 'mainReviewData':
        props.dispatch(reviewActions.mainReviewData(userData.user_sno, userData.brand_code, serviceType));
        break;
      case 'materialList':
        props.dispatch(materialActions.materialList(userData.brand_code));
        break;
      case 'serviceNoticeList':
        props.dispatch(userActions.serviceNoticeList(userData.company_code, userData.brand_code, userData.dept_id));
        break;
      case 'getFaqList':
        props.dispatch(userActions.getFaqList());
        break;
      case 'recommendUnlinkMenuData':
        props.dispatch(menuActions.recommendUnlinkMenuData(userData.brand_code));
        break;
      case 'posSignalList':
        props.dispatch(officeActions.posSignalList(userData.user_sno, userData.brand_code, serviceType));
        break;
      case 'getOrderAppOfficeOwner':
        if (userData.auth_type === 'D1') {
          props.dispatch(officeActions.getOrderAppOfficeOwner(userData.brand_code, userData.user_sno));
        }
        break;
      default:
        break;
    }

    return null;
  });
};

export const getParam = (splitCharacter = '&') => {
  var hash = window.location.hash.substr(1);

  var result = hash.split(splitCharacter).reduce(function(res, item) {
    var parts = item.split('=');
    res[parts[0]] = parts[1];
    return res;
  }, {});

  return result;
};

export const getParamAction = param => {
  if (param.indexOf('action') > -1) {
    let actionValue = param.split('action=')[1];
    return actionValue;
  }
};

export const getTargetDomain = () => {
  if (window.location.host.indexOf('attracker.shop') !== -1) {
    return `https://${window.location.host}/`;
  }

  // NOTE : for test
  return `http://localhost:3022/`;
  // return 'https://service-dev.attracker.shop/';

  return 'https://service.attracker.shop/';
}