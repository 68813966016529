import React from 'react';
import { BasicBtn } from '../Buttons';
import AniProgressBar from '../ProgressBar/AniProgressBar';
import './accountcertifymodal.scss';

const AccountcertifyModal = ({
  timer = 0,
  onClose = () => {},
  state = 0,
  popUpClose = () => {},
  setFormType = () => {},
  formType,
  useBtn = true,
}) => {
  const handleClose = () => {
    if (state === 2) {
      onClose(true);
      if (formType === 4) {
        setFormType(9);
      } else {
        setFormType(1);
      }
      return;
    }
    popUpClose();
    onClose(true);
  };

  // state : 0: none, 1: 확인중, 2: 인증성공, 3: 인증실패
  const getTitleMsg = () => {
    if (state === 3) {
      if (formType === 4) {
        setFormType(10);
      } else {
        setFormType(7);
      }
    }
    switch (state) {
      case 1:
      default:
        return '계정정보 인증중..';
      case 2:
        return '계정정보 인증완료';
      case 3:
        return '계정정보 인증실패';
    }
  };

  let isComplete = state === 2 || state === 3;

  return (
    <>
      <div className="popup flex">
        <div className="account-certify-modal">
          <div className="title">{getTitleMsg()}</div>

          <div className="progressbar-account progressbar-wrap">
            <AniProgressBar timer={isComplete ? 0 : timer} />
          </div>

          <div className="desc" style={{ textAlign: 'center' }}>
            정확한 매출정보는
            <br />
            익일오전 7시 이후(12시간 이내)부터 확인가능하며,
            <br />
            이후 별도의 인증절차는 필요없습니다.
          </div>

          {useBtn && (
            <div className="progress-btn btn-wrap">
              <BasicBtn size="regular" onClick={handleClose} disabled={!isComplete}>
                완료
              </BasicBtn>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AccountcertifyModal;
