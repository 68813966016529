import { setDownloadRequestValue } from '../constants';
import { authHeader, handleResponse, isRetail } from '../helpers';
export const officeService = {
  managedOfficeList,
  allOfficeList,
  areaList,
  saleOfficeHeaderData,
  saleDateListData,
  saleOfficeListData,
  saleWeekDayListData,
  oneOfficeSaleWeekDayListData,
  deliveryHeaderData,
  deliveryOfficeListData,
  deliveryDateListData,
  deliveryOfficeAllListData,
  deliveryOfficeMarker,
  deliveryMarkerDetail,
  deliveryWeekDayListData,
  oneOfficeDeliveryWeekDayListData,
  mainSaleData,
  mainDeliveryData,
  saleTotalData,
  deliveryTotalData,
  posSignalList,
  deliveryOrderAppDateListData,
  inRangeDeliveryDateListData,
  deliveryCustomerRating,
  editDeliveryCustomerTypeBit,
  addDeliveryCustomerComment,
  deleteDeliveryCustomerComment,
  oneCustomerDeliveryDetail,
  alertList,
  orderDetailList,
  readAlert,
  setSkipAccountLink,

  // v2
  dailySales,
  timeSales,
  weekdaySales,
  officeSales,
  officeSalesByDaily,
  orderAppSales,
  specificDateSales,
  specificTimeSales,
  setOrderAppAccount,
  setMultiOrderAppAccount,
  getOrderAppOfficeList,
  getOrderAppOfficeOwner,
  getStoreList,
  mappingStore,
  dongSalesData,
  totalDeliveryDongSales,
  allBrandSales,
  monthlySales,
  quarterlySales,
  yearlySales,
  specificMonthSales,
  specificQuarterSales,
  specificYearSales,
  getOfficeStatus,

  // pos scraping
  getPosOwnerAccount,
  getPlatformSelectBox,
  linkPosAccount,
};

let abertControllers = {};

function managedOfficeList(userSno, brandCode, serviceType) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      serviceType: serviceType,
    }),
  };

  return fetch('/office/managedOfficeList', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function allOfficeList(companyCode) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      companyCode: companyCode,
    }),
  };

  return fetch('/office/allOfficeList', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function areaList(brandCode, userSno) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandCode: brandCode,
      userSno: userSno,
    }),
  };

  return fetch('/office/areaList', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function saleOfficeHeaderData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoQuery,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      serviceType: serviceType,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      officeSnoQuery: officeSnoQuery,
    }),
  };

  return fetch('/office/saleOfficeHeaderData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function saleDateListData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoQuery,
  officeName,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      serviceType: serviceType,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      officeSnoQuery: officeSnoQuery,
      officeName: officeName,
    }),
  };

  setDownloadRequestValue('/report/pageExportSaleDateView', requestOptions);

  return fetch('/office/saleDateListData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function saleWeekDayListData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoQuery,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      serviceType: serviceType,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      officeSnoQuery: officeSnoQuery,
    }),
  };

  // fetch 1 - 상단 다운로드 버튼
  setDownloadRequestValue('/report/pageExportSaleWeekDayView', requestOptions);

  // fetch 2 - 데이터 fetch
  return fetch('/office/saleWeekDayListData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function oneOfficeSaleWeekDayListData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoQuery,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      serviceType: serviceType,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      officeSnoQuery: officeSnoQuery,
    }),
  };

  // fetch 1 - 상단 다운로드 버튼
  setDownloadRequestValue('/report/pageExportSaleWeekDayView', requestOptions);

  // fetch 2 - 데이터 fetch
  return fetch('/office/saleWeekDayListData', requestOptions) // 전체 - 홀: 요일별과 동일한 API 사용
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function deliveryWeekDayListData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoQuery,
  menuSnoQuery,
  dlType,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      serviceType: serviceType,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      officeSnoQuery: officeSnoQuery,
      menuSnoQuery: menuSnoQuery,
      dlType: dlType,
    }),
  };

  // fetch 1 - 상단 다운로드 버튼
  setDownloadRequestValue('/report/pageExportDeliveryWeekDayView', requestOptions);

  // fetch 2 - 데이터 fetch
  return fetch('/office/deliveryWeekdayListData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function oneOfficeDeliveryWeekDayListData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoQuery,
  menuSnoQuery,
  dlType,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      serviceType: serviceType,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      officeSnoQuery: officeSnoQuery,
      menuSnoQuery: menuSnoQuery,
      dlType: dlType,
    }),
  };

  // fetch 1 - 상단 다운로드 버튼
  setDownloadRequestValue('/report/pageExportDeliveryWeekDayView', requestOptions);

  // fetch 2 - 데이터 fetch
  return fetch('/office/deliveryWeekdayListData', requestOptions) // 전체 배달지표 요일별과 공용 API
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function saleOfficeListData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  limitStart,
  limitCount,
  sortKey,
  officeSnoQuery,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      serviceType: serviceType,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      limitStart: limitStart,
      limitCount: limitCount,
      sortKey: sortKey,
      officeSnoQuery: officeSnoQuery,
    }),
  };

  setDownloadRequestValue('/report/pageExportSaleOfficeView', requestOptions);

  return fetch('/office/saleOfficeListData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function deliveryHeaderData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoQuery,
  menuSnoQuery,
  dlType,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      serviceType: serviceType,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      officeSnoQuery: officeSnoQuery,
      menuSnoQuery: menuSnoQuery,
      orderApp: dlType,
    }),
  };

  return fetch('/office/deliveryHeaderData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function deliveryOfficeListData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  limitStart,
  limitCount,
  sortKey,
  officeSnoQuery,
  menuSnoQuery,
  dlType,
  menuName,
  noReCustomer,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      serviceType: serviceType,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      limitStart: limitStart,
      limitCount: limitCount,
      sortKey: sortKey,
      officeSnoQuery: officeSnoQuery,
      menuSnoQuery: menuSnoQuery,
      dlType: dlType,
      menuName: menuName,
      noReCustomer: noReCustomer,
    }),
  };

  setDownloadRequestValue('/report/pageExportDeliveryOfficeView', requestOptions);

  return fetch('/office/deliveryOfficeListData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function deliveryDateListData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoQuery,
  dlType,
  officeName,
  menuSnoQuery,
  menuName,
  noReCustomer,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      serviceType: serviceType,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      officeSnoQuery: officeSnoQuery,
      dlType: dlType,
      officeName: officeName,
      menuSnoQuery: menuSnoQuery,
      menuName: menuName,
      noReCustomer: noReCustomer,
    }),
  };

  setDownloadRequestValue('/report/pageExportDeliveryDateView', requestOptions);

  return fetch('/office/deliveryDateListData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function deliveryOfficeAllListData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  menuSnoQuery,
  officeSnoQuery,
  dlType,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      serviceType: serviceType,
      startDate: startDate,
      endDate: endDate,
      menuSnoQuery: menuSnoQuery,
      officeSnoQuery: officeSnoQuery,
      dlType: dlType,
    }),
  };

  return fetch('/office/deliveryOfficeAllListData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function deliveryOfficeMarker(brandCode, userSno, officeSno, startDate, endDate, menuSnoQuery, dlType) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandCode: brandCode,
      userSno: userSno,
      officeSno: officeSno,
      startDate: startDate,
      endDate: endDate,
      menuSnoQuery: menuSnoQuery,
      dlType: dlType,
    }),
  };

  return fetch('/office/deliveryOfficeMarker', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function deliveryMarkerDetail(deliverySno, officeSno, startDate, endDate, menuSnoQuery, dlType) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      deliverySno: deliverySno,
      officeSno: officeSno,
      startDate: startDate,
      endDate: endDate,
      menuSnoQuery: menuSnoQuery,
      dlType: dlType,
    }),
  };

  return fetch('/office/deliveryMarkerDetail', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function mainSaleData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  contentsBoolList,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      serviceType: serviceType,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      contentsBoolList: contentsBoolList,
    }),
  };

  var downloadApi = isRetail() ? '/report/pageExportMainRetailView' : '/report/pageExportMainView';
  setDownloadRequestValue(downloadApi, requestOptions);

  return fetch('/office/mainSaleData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function mainDeliveryData(userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate, dlType) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      serviceType: serviceType,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      dlType: dlType,
    }),
  };

  return fetch('/office/mainDeliveryData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function saleTotalData(userSno, brandCode, serviceType, startDate, endDate, diffStartDate, diffEndDate) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      serviceType: serviceType,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
    }),
  };

  return fetch('/office/saleTotalData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function deliveryTotalData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  dlType,
  menuSno,
  menuName,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      serviceType: serviceType,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      dlType: dlType,
      menuSno: menuSno,
      menuName: menuName,
    }),
  };

  return fetch('/office/deliveryTotalData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function posSignalList(userSno, brandCode, serviceType) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      serviceType: serviceType,
    }),
  };

  return fetch('/office/posSignalList', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function deliveryOrderAppDateListData(
  userSno,
  brandCode,
  serviceType,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoQuery,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      serviceType: serviceType,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      officeSnoQuery: officeSnoQuery,
    }),
  };

  setDownloadRequestValue('/report/pageExportOrderAppDateListData', requestOptions);

  return fetch('/office/deliveryOrderAppDateListData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function inRangeDeliveryDateListData(
  userSno,
  brandCode,
  startDate,
  endDate,
  selectedOfficeSno,
  dlType,
  range,
  isFullRange,
  orderViewType,
  rangeCount,
  menuSnoQuery,
  trigger, // 지울것
  needTotalChannels,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      startDate: startDate,
      endDate: endDate,
      selectedOfficeSno: selectedOfficeSno,
      dlType: dlType,
      range: range,
      isFullRange: isFullRange,
      orderViewType: orderViewType,
      rangeCount: rangeCount,
      menuSnoQuery: menuSnoQuery,
      trigger: trigger,
      needTotalChannels: needTotalChannels,
    }),
  };

  return fetch('/office/inRangeDeliveryDateListData', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function deliveryCustomerRating(userSno, customerSno) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      customerSno: customerSno,
    }),
  };

  return fetch('/office/deliveryCustomerRating', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function editDeliveryCustomerTypeBit(userSno, customerSno, typeBit) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      customerSno: customerSno,
      typeBit: typeBit,
    }),
  };

  return fetch('/office/editDeliveryCustomerTypeBit', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}
function addDeliveryCustomerComment(userSno, customerSno, comment) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      customerSno: customerSno,
      comment: comment,
    }),
  };

  return fetch('/office/addDeliveryCustomerComment', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}
function deleteDeliveryCustomerComment(userSno, customerSno, commentSno) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      customerSno: customerSno,
      commentSno: commentSno,
    }),
  };

  return fetch('/office/deleteDeliveryCustomerComment', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function oneCustomerDeliveryDetail(officeSno, customerSno, startDate, endDate, orderApp) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      officeSno: officeSno,
      customerSno: customerSno,
      startDate: startDate,
      endDate: endDate,
      orderApp: orderApp,
    }),
  };

  return fetch('/office/getOneCustomerDeliveryDetail', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function dailySales(
  getType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoList,
  siteType,
  deliveryTipType,
  payTypeList,
  orderChannelList,
  standardSaleTime,
) {
  if (abertControllers['dailySales']) {
    abertControllers['dailySales'].abort();
  }
  abertControllers['dailySales'] = new AbortController();
  let signal = abertControllers['dailySales'].signal;

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      getType: getType,
      userSno: userSno,
      brandCode: brandCode,
      orderApp: orderApp,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      officeSnoList: officeSnoList,
      siteType: siteType,
      deliveryTipType: deliveryTipType,
      payTypeList: payTypeList,
      orderChannelList: orderChannelList,
      standardSaleTime: standardSaleTime,
    }),
    signal,
  };

  setDownloadRequestValue('/report/pageExportDailySales', requestOptions);

  return fetch('/v2/sale/dailySales', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function timeSales(
  getType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoList,
  siteType,
  deliveryTipType,
  payTypeList,
  orderChannelList,
) {
  if (abertControllers['timeSales']) {
    abertControllers['timeSales'].abort();
  }
  abertControllers['timeSales'] = new AbortController();
  let signal = abertControllers['timeSales'].signal;

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      getType: getType,
      userSno: userSno,
      brandCode: brandCode,
      orderApp: orderApp,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      officeSnoList: officeSnoList,
      siteType: siteType,
      deliveryTipType: deliveryTipType,
      payTypeList: payTypeList,
      orderChannelList: orderChannelList,
    }),
    signal,
  };

  setDownloadRequestValue('/report/pageExportTimeSales', requestOptions);

  return fetch('/v2/sale/timeSales', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function weekdaySales(
  getType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoList,
  siteType,
  deliveryTipType,
  payTypeList,
  orderChannelList,
  standardSaleTime,
) {
  if (abertControllers['weekdaySales']) {
    abertControllers['weekdaySales'].abort();
  }
  abertControllers['weekdaySales'] = new AbortController();
  let signal = abertControllers['weekdaySales'].signal;

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      getType: getType,
      userSno: userSno,
      brandCode: brandCode,
      orderApp: orderApp,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      officeSnoList: officeSnoList,
      siteType: siteType,
      deliveryTipType: deliveryTipType,
      payTypeList: payTypeList,
      orderChannelList: orderChannelList,
      standardSaleTime: standardSaleTime,
    }),
    signal,
  };

  setDownloadRequestValue('/report/pageExportWeekdaySales', requestOptions);

  return fetch('/v2/sale/weekdaySales', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function officeSales(
  getType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoList,
  siteType,
  deliveryTipType,
  payTypeList,
  orderChannelList,
  standardSaleTime,
) {
  if (abertControllers['officeSales']) {
    abertControllers['officeSales'].abort();
  }
  abertControllers['officeSales'] = new AbortController();
  let signal = abertControllers['officeSales'].signal;

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      getType: getType,
      userSno: userSno,
      brandCode: brandCode,
      orderApp: orderApp,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      officeSnoList: officeSnoList,
      siteType: siteType,
      deliveryTipType: deliveryTipType,
      payTypeList: payTypeList,
      orderChannelList: orderChannelList,
      standardSaleTime: standardSaleTime,
    }),
    signal,
  };

  setDownloadRequestValue('/report/pageExportOfficeSales', requestOptions);

  return fetch('/v2/sale/officeSales', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function officeSalesByDaily(
  userSno,
  brandCode,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoList,
  deliveryTipType,
  payTypeList,
  orderChannelList,
  standardSaleTime,
) {
  if (abertControllers['officeSalesByDaily']) {
    abertControllers['officeSalesByDaily'].abort();
  }
  abertControllers['officeSalesByDaily'] = new AbortController();
  let signal = abertControllers['officeSalesByDaily'].signal;

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      officeSnoList: officeSnoList,
      deliveryTipType: deliveryTipType,
      payTypeList: payTypeList,
      orderChannelList: orderChannelList,
      standardSaleTime: standardSaleTime,
    }),
    signal,
  };

  setDownloadRequestValue('/report/pageExportOfficeSalesByDaily', requestOptions);

  return fetch('/v2/sale/officeSalesByDaily', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function orderAppSales(
  userSno,
  brandCode,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoList,
  deliveryTipType,
  getType,
  standardSaleTime,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      officeSnoList: officeSnoList,
      deliveryTipType: deliveryTipType,
      getType: getType,
      standardSaleTime: standardSaleTime,
    }),
  };

  setDownloadRequestValue('/report/pageExportOrderAppSales', requestOptions);

  return fetch('/v2/sale/orderAppSales', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function specificDateSales(userSno, brandCode, date, officeSnoList, deliveryTipType, standardSaleTime) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      date: date,
      officeSnoList: officeSnoList,
      deliveryTipType: deliveryTipType,
      standardSaleTime: standardSaleTime,
    }),
  };

  return fetch('/v2/sale/specificDateSales', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function specificTimeSales(
  userSno,
  brandCode,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  time,
  officeSnoList,
  deliveryTipType,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      time: time,
      officeSnoList: officeSnoList,
      deliveryTipType: deliveryTipType,
    }),
  };

  return fetch('/v2/sale/specificTimeSales', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function setOrderAppAccount(officeStatusIdx, id, pwd, orderApp, isOneId, isUsed, orderAppAccountIdx, isInit) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      officeStatusIdx: officeStatusIdx,
      id: id,
      pwd: pwd,
      orderApp: orderApp,
      isOneId: isOneId,
      isUsed: isUsed,
      orderAppAccountIdx: orderAppAccountIdx,
      isInit: isInit,
    }),
  };

  return fetch('/v2/manage/setOrderAppAccount', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function setMultiOrderAppAccount(userList) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userList: userList,
    }),
  };

  return fetch('/v2/manage/setMultiOrderAppAccount', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function getOrderAppOfficeList(brandCode, checkIdxList) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandCode: brandCode,
      checkIdxList: checkIdxList,
    }),
  };

  return fetch('/v2/manage/getOrderAppOfficeList', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function getOrderAppOfficeOwner(brandCode, userSno) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandCode: brandCode,
      userSno: userSno,
    }),
  };

  return fetch('/v2/manage/getOrderAppOfficeOwner', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function getStoreList(idx) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      tbUserIdx: idx,
    }),
  };

  return fetch('/v2/manage/getStoreList', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function mappingStore(idx, storeIdxList) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      tbUserIdx: idx,
      storeIdxList: storeIdxList,
    }),
  };

  return fetch('/v2/manage/mappingStore', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function dongSalesData(userSno, brandCode, officeSno, startDate, endDate, diffStartDate, diffEndDate) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      officeSno: officeSno,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
    }),
  };

  return fetch('/v2/sale/oneOfficeDeliveryDongSales', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function totalDeliveryDongSales(
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoList,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      orderApp: orderApp,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      officeSnoList: officeSnoList,
    }),
  };

  return fetch('/v2/sale/totalDeliveryDongSales', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function allBrandSales(userSno, startDate, endDate) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      startDate: startDate,
      endDate: endDate,
    }),
  };

  return fetch('/v2/sale/allBrandSales', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function monthlySales(
  getType,
  siteType,
  deliveryTipType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoList,
  payTypeList,
  orderChannelList,
  standardSaleTime,
) {
  if (abertControllers['monthlySales']) {
    abertControllers['monthlySales'].abort();
  }
  abertControllers['monthlySales'] = new AbortController();
  let signal = abertControllers['monthlySales'].signal;

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      getType: getType,
      siteType: siteType,
      deliveryTipType: deliveryTipType,
      userSno: userSno,
      brandCode: brandCode,
      orderApp: orderApp,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      officeSnoList: officeSnoList,
      payTypeList: payTypeList,
      orderChannelList: orderChannelList,
      standardSaleTime: standardSaleTime,
    }),
    signal,
  };

  setDownloadRequestValue('/report/pageExportMonthlySales', requestOptions);

  return fetch('/v2/sale/monthlySales', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function quarterlySales(
  getType,
  siteType,
  deliveryTipType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoList,
  payTypeList,
  orderChannelList,
  standardSaleTime,
) {
  if (abertControllers['quarterlySales']) {
    abertControllers['quarterlySales'].abort();
  }
  abertControllers['quarterlySales'] = new AbortController();
  let signal = abertControllers['quarterlySales'].signal;

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      getType: getType,
      siteType: siteType,
      deliveryTipType: deliveryTipType,
      userSno: userSno,
      brandCode: brandCode,
      orderApp: orderApp,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      officeSnoList: officeSnoList,
      payTypeList: payTypeList,
      orderChannelList: orderChannelList,
      standardSaleTime: standardSaleTime,
    }),
    signal,
  };

  setDownloadRequestValue('/report/pageExportQuarterlySales', requestOptions);

  return fetch('/v2/sale/quarterlySales', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function yearlySales(
  getType,
  siteType,
  deliveryTipType,
  userSno,
  brandCode,
  orderApp,
  startDate,
  endDate,
  diffStartDate,
  diffEndDate,
  officeSnoList,
  payTypeList,
  orderChannelList,
  standardSaleTime,
) {
  if (abertControllers['yearlySales']) {
    abertControllers['yearlySales'].abort();
  }
  abertControllers['yearlySales'] = new AbortController();
  let signal = abertControllers['yearlySales'].signal;

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      getType: getType,
      siteType: siteType,
      deliveryTipType: deliveryTipType,
      userSno: userSno,
      brandCode: brandCode,
      orderApp: orderApp,
      startDate: startDate,
      endDate: endDate,
      diffStartDate: diffStartDate,
      diffEndDate: diffEndDate,
      officeSnoList: officeSnoList,
      payTypeList: payTypeList,
      orderChannelList: orderChannelList,
      standardSaleTime: standardSaleTime,
    }),
    signal,
  };

  setDownloadRequestValue('/report/pageExportYearlySales', requestOptions);
  return fetch('/v2/sale/yearlySales', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function specificMonthSales(brandCode, deliveryTipType, officeSnoList, userSno, dateYm, standardSaleTime) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandCode: brandCode,
      deliveryTipType: deliveryTipType,
      officeSnoList: officeSnoList,
      userSno: userSno,
      dateYm: dateYm,
      standardSaleTime: standardSaleTime,
    }),
  };

  return fetch('/v2/sale/specificMonthSales', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function specificQuarterSales(brandCode, deliveryTipType, officeSnoList, userSno, dateYq, standardSaleTime) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandCode: brandCode,
      deliveryTipType: deliveryTipType,
      officeSnoList: officeSnoList,
      userSno: userSno,
      dateYq: dateYq,
      standardSaleTime: standardSaleTime,
    }),
  };

  return fetch('/v2/sale/specificQuarterSales', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function specificYearSales(brandCode, deliveryTipType, officeSnoList, userSno, year, standardSaleTime) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandCode: brandCode,
      deliveryTipType: deliveryTipType,
      officeSnoList: officeSnoList,
      userSno: userSno,
      year: year,
      standardSaleTime: standardSaleTime,
    }),
  };

  return fetch('/v2/sale/specificYearSales', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function getOfficeStatus(brandCode, userSno, officeSno) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandCode: brandCode,
      userSno: userSno,
      officeSno: officeSno,
    }),
  };

  return fetch('/office/getOfficeStatus', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function alertList(userSno, brandCode, alertIdx) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      alertIdx: alertIdx,
      brandCode: brandCode,
    }),
  };

  return fetch('/v2/sale/alertList', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function orderDetailList(userSno, brandCode, orderIdx) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      orderIdx: orderIdx,
    }),
  };

  return fetch('/v2/sale/orderDetailList', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function readAlert(userSno, brandCode, notiType, lastReadIdx) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      notiType: notiType,
      lastReadIdx: lastReadIdx,
    }),
  };

  return fetch('/v2/sale/readAlert', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function setSkipAccountLink(userSno, flag, isUsedSite) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      flag: flag,
      isUsedSite: isUsedSite,
    }),
  };

  return fetch('/office/setSkipAccountLink', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function getPosOwnerAccount(brandCode, userSno) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandCode: brandCode,
      userSno: userSno,
    }),
  };

  return fetch('/pos/getOwnerAccount', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function getPlatformSelectBox() {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({}),
  };

  return fetch('/pos/getPlatformSelectBox', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function linkPosAccount({
  brandCode,
  userSno,
  credentialIdx,
  platform,
  etcPlatform,
  userId,
  userPw,
  officeStatusIdx,
  disabled,
}) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandCode,
      userSno,
      credentialIdx,
      platform,
      etcPlatform,
      userId,
      userPw,
      officeStatusIdx,
      disabled,
    }),
  };

  return fetch(credentialIdx ? '/pos/editAccount' : '/pos/addAccount', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}
