import React from 'react';
import { useHistory } from 'react-router';
import IMAGES from '../../assets/utils/images';
import './NotFoundPage.scss';

const NotFoundPage = () => {
  const history = useHistory();

  return (
      <div className="not-found-main" >
        <div>
          <img src={IMAGES.pageNotFound} alt="" className="not-found-img" />
        </div>
        <div className="text-container">
          <div className="text-title">이 페이지를 표시할 수 없습니다</div>
          <div className="text-content-box">
            <span>페이지 주소를 잘못 입력되었거나,</span> <span>주소가 변경 혹은 삭제되었을 수 있습니다.</span>
            <p>입력된 주소를 다시 확인해 주세요!</p>
          </div>
          <div className="btn-container">
            <button className="back-btn btn-style-reset" onClick={() => window.history.back()}>
            <span><img src={IMAGES.pageNotFoundLeftIcon} alt="pre" />
              이전으로</span>
            </button>
            <button className="home-btn btn-style-reset" onClick={() => history.push('/')}>
              <span>서비스 홈으로
              <img src={IMAGES.pageNotFoundRightIcon} alt="next" /></span>
            </button>
          </div>
        </div>
      </div>
  );
}

export default NotFoundPage;
