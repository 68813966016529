import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import './SystemTooltip.scss';

/**
 *
 * @param 'type : type2', position : 'top'
 * @returns
 */
const SystemTooltip = ({ text, type = 'type2', classname, style }) => {
  // console.log('cc', style)
  return (
    <Style className={classNames('system-tooltip', type, classname)} style={style}>
      {text}
    </Style>
  );
};

const Style = styled.span`
  
`

export default SystemTooltip;
