import React from 'react';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { authActions } from '../actions';
import { authConstants } from '../constants';
import { isLocalhost } from '../helpers/CommonUtil';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    if (props.error) {
      props.dispatch(
        authActions.systemErrorLog(
          window.location.hostname,
          window.location.pathname,
          props.error.message + '\n' + props.error.stack,
        ),
      );
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    var returnState = {};

    if (
      nextProps.error !== undefined &&
      !isEqual(nextProps.error, prevState ? prevState.error : null) &&
      !isLocalhost()
    ) {
      returnState.error = nextProps.error;

      alert('시스템에 문제가 발생하여 로그인 페이지로 이동합니다.');
      window.location.href = '#/auth/login';

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }

    return returnState ? returnState : null;
  }

  componentWillUnmount() {
    this.props.dispatch({ type: authConstants.SYSTEM_ERROR_LOG_RESET });
  }

  render() {
    return null;
  }
}

function mapStateToProps(state) {
  const { systemErrorLog } = state.systemErrorLog;

  return {
    systemErrorLog,
  };
}

export default connect(mapStateToProps)(ErrorBoundary);
