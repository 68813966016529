import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody } from 'reactstrap';
import styled from 'styled-components';
import { commonActions } from '../../actions';
import '../Modal/MobileModal.scss';
import ChannelChat from '../../helpers/ChannelChat';

const CommonAlert = () => {
  const dispatch = useDispatch();

  // closeType = 0: 기본 : 1: 없음 : 2: footerButton
  const { alertMessage } = useSelector(state => state.alertMessage);
  if (!alertMessage) {
    return null;
  }

  const {
    title = '',
    message = '',
    contents = null,
    action = '',
    type = null,
    closeType = 0,
    modalWidth = '92%',
    isBackDrop = false,
  } = alertMessage;

  const handleClose = () => {
    dispatch(commonActions.alertMessage(null));

    if (action && typeof action === 'function') {
      action();
    } else if (action && action === 'back') {
      window.history.back();
    } else if (action && action !== 'back' && action.indexOf('/#/') !== -1) {
      window.location.href = action;
    } else if (action && action === 'goMain') {
      window.location.href = '/#/';
    } else if (action && action === 'closeWindow') {
      window.open('about:blank', '_self');
      window.close();
    } else if (action && action === 'openChannelTalk') {
      ChannelChat.setSupportBot(79184);
    }
  };

  if (!contents && !title) {
    return null;
  }

  const renderContents = () => {
    return (
      <ModalBody className="contents-body">
        {closeType === 0 && (
          <Button className="contents-close-btn" onClick={handleClose}>
            <span className="contents-close-text close">×</span>
          </Button>
        )}
        <div className="custom-scrollbar vertical contents-body-modal">{contents}</div>
        {closeType === 1 && <></>}
        {closeType === 2 && (
          <FooterButton type={type} onClick={handleClose}>
            확인
          </FooterButton>
        )}
      </ModalBody>
    );
  };

  const renderTitleAndMessage = () => {
    return (
      <ModalBody className="basic-body">
        <div className="text-center mt-2 basic-body-modal">
          <div className="basic-title-tm">{alertMessage['title']}</div>
          <div className="basic-text">{message}</div>
          <CommomComfirmButton onClick={handleClose}>확인</CommomComfirmButton>
        </div>
      </ModalBody>
    );
  };

  const renderTitle = () => {
    return (
      <ModalBody className="basic-body2">
        <div className="basic-title">
          {type === 'html' ? <div dangerouslySetInnerHTML={{ __html: title }} /> : <div>{title}</div>}
        </div>
        {closeType !== 1 && <CommomComfirmButton onClick={handleClose}>확인</CommomComfirmButton>}
      </ModalBody>
    );
  };

  return (
    <Modal
      color="primary"
      isOpen={true}
      toggle={() => isBackDrop && dispatch(commonActions.alertMessage(null))}
      style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        width: modalWidth ? modalWidth : '92%',
        maxWidth: '570px',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        fontSize: '1.15em',
        lineHeight: '1.8em',
        borderRadius: '16px',
        margin: '0',
      }}
      contentClassName={type === 'fullSizeModal' ? '' : 'br-16'}
    >
      <Wrapper type={type}>
        {/* 컴포넌트를 전달하는 경우 */}
        {contents && renderContents()}

        {/* title, message 둘다 전달하는 경우 */}
        {!contents && title && message && renderTitleAndMessage()}

        {/* title만 전달하는 경우 */}
        {!contents && title && !message && renderTitle()}
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  .basic-body {
    justify-content: space-between;
    flex-direction: column;
    padding: 48px 32px 40px 32px;
  }

  .contents-body-modal {
    max-height: ${props => (props.type === 'fullSizeModal' ? '94vh' : '84vh')};
    overflow: auto;
    text-align: initial;

    @media (max-width: 768px) {
      max-height: ${props => props.type === 'fullSizeModal' && 'calc(var(--vh, 1vh) * 100 - 45px)'};
      min-height: ${props => props.type === 'fullSizeModal' && 'calc(var(--vh, 1vh) * 100 - 45px)'};
    }
  }

  .basic-body-modal {
    max-height: 84vh;
    overflow: auto;
    text-align: initial;
  }

  .basic-body2 {
    justify-content: space-between;
    flex-direction: column;
    padding: 43px 18px 38px 18px;
  }

  .basic-text {
    white-space: break-spaces;
    margin-top: 12px;
    font-weight: 500;
    font-size: 13.5px;
    line-height: 150%;
    text-align: center;
    color: #26262680;
  }

  .basic-title-tm {
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    color: #262626;
  }

  .basic-title {
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    text-align: center;
    color: #262626;
  }

  .contents-body {
    justify-content: space-between;
    flex-direction: column;
    padding: 0px;
  }

  .contents-close-btn {
    position: absolute;
    top: -30px;
    right: -30px;
    background-color: initial;
    border: none;
    color: black;
    font-size: 1.5em;
    font-weight: 100;
    padding: 0.2em;
    opacity: 0.5;
    z-index: 1;
  }

  .contents-close-text {
    font-size: 0.95em;
    color: #ffffff;
    opacity: 0.7;
    text-shadow: none;
  }
`;

const FooterButton = styled.button`
  width: 100%;
  word-break: keep-all;
  background: #686cff;
  border-bottom-right-radius: ${props => (props.type === 'fullSizeModal' ? '0px' : '12px')};
  border-bottom-left-radius: ${props => (props.type === 'fullSizeModal' ? '0px' : '12px')};
  color: white;
  height: 48px;
  border: none;
  margin-bottom: -2px;

  &.is-disabled {
    background: #dee2e4;
    pointer-events: none;
  }
`;

const CommomComfirmButton = styled.button`
  padding: 14px 30px;
  width: 91px;
  height: 47px;
  background: #686cff;
  border-radius: 24px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #fefefe;
  margin-top: 20px;
  border: 0px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
`;

export default CommonAlert;
