import React from 'react';
import ChannelChat from '../../helpers/ChannelChat';
import useLocalStorage from '../../helpers/UseLocalStorage';
import { useTranslation } from '../../locales';
import './chatbotCustom.scss';
import { trackEvent } from '../../helpers';
import { GA_EVENT_CONST } from '../../constants';

const ChatbotCustom = props => {
  const { onClick } = props;
  const t = useTranslation();
  const channelChatBadge = useLocalStorage('channelChatBadge');

  return (
    <div
      className="chatbot-custom"
      onClick={() => {
        trackEvent(GA_EVENT_CONST['C1204']);
        onClick && onClick();
        ChannelChat.showMessenger();
      }}
    >
      <span className="text">{t['사이드-실시간상담']}</span>
      {channelChatBadge && parseInt(channelChatBadge['storedValue']) > 0}
      <span data-count={channelChatBadge['storedValue']}>{channelChatBadge['storedValue']}</span>
    </div>
  );
};

export default ChatbotCustom;
