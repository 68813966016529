import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import GraphDataTable from '../../components/DataTable/GraphDataTable';
import Filter from '../../components/Filter/Filter';
import classnames from 'classnames';
import './componentPage.scss';
import AniProgressBar from '../../components/ProgressBar/AniProgressBar';
import PopupPage from './PopupPage';
import Toast from '../../components/Toast/Toast';
import classNames from 'classnames';
import LoadingItem from '../../components/Loading/LoadingItem';
import { ActionBtn } from '../../components/Buttons';
import ATRadioGroup from '../../components/Styled/form/ATRadioGroup';
import ATCheckBox from '../../components/Styled/form/ATCheckBox';
import ATItemBox from '../../components/Styled/form/ATItemBox';
import SystemTooltip from '../../components/Tooltip/SystemTooltip';
import DataTableBadge from '../../components/DataTable/items/DataTableBadge';

const ComponentPage = () => {
  const [filterType, setFilterType] = useState('0');
  const [sendFilter, setSendFilter] = useState(-1);

  const goBack = () => {
    window.history.back();
  };

  // 필터
  const FilterEvent = value => {
    setFilterType(value);
  };

  const cellRef = useRef(null);

  // 1. flex로 잡아서 ref 로 가져오기
  // 2. 타이틀을 포함해서 테이블 2개

  return (
    <div className="component-page">
      <h4>컴포넌트 모음 페이지 </h4>
      <Link to="/" onClick={goBack}>
        뒤로 가기
      </Link>
      <h5>차트</h5>
      <>
        <h6>쌓여진 바 차트 - barStackedChart</h6>
        <div>차트영역</div>
      </>

      <h5>데이터테이블</h5>
      <>
        <h6>menu-data-table</h6>
        <div>차트영역</div>
        <h6>graph-data-table</h6>
        <div>
          <div className="menu-data-table2 none">
            <div className="table-row flex flex-between">
              <div className="left">
                <table className="inline-table table-date">
                  <thead></thead>
                  <tbody>
                    {/* 여기 tr반복 */}
                    <tr>
                      <td>
                        <div className="cell">
                          <span className="numbering">{0 + 1}</span>
                          <p className="item">1월 20일(목)</p>
                          <span className="people">
                            11
                            <div className="tooltip">
                              <div>- 주소지미상 고객수는 합산되지 않음</div>
                              <div>- 기간내 중복 고객은 한명으로 정의되어 추가 합산되지 않음</div>
                            </div>
                          </span>
                          <DataTableBadge />
                          <span className="analyzing">분석중</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="right">
                <table className="inline-table table-data">
                  <thead>
                    <tr className="title">
                      <td className="">전체 매출</td>
                      <td className="">홀 매출</td>
                      <td className="">매장 매출</td>
                    </tr>
                  </thead>
                  <tbody>
                    {/* 여기 tr반복 */}
                    <tr>
                      <td>
                        <div className="cell">
                          <div className="price">
                            111,111원<p>123건</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="cell">
                          <div className="price">
                            111,111원<p>123건</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="cell">
                          <div className="price">
                            111,111원<p>123건</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="inline-table table-bar-chart">
                  <thead></thead>
                  <tbody>
                    {/* 여기 tr반복 */}
                    <tr>
                      <td>
                        <div className="cell">18.5% 프로그레스 81.5%</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="v-layout"></div>
          </div>

          <div className="menu-data-table2 second">
            <table>
              <colgroup>
                <col width="auto" />
                <col width="53px" />
                <col width="53px" />
                <col width="53px" />
                <col width="100px" />
                <col width="100px" />
              </colgroup>
              <thead>
                <tr>
                  <th className="text-left"></th>
                  <th className="text-center">전체 매출</th>
                  <th className="text-center price-gap">홀 매출</th>
                  <th className="text-center price-gap">배달 매출</th>
                  <th> 홀 점유율 </th>
                  <th>배달 점유율</th>
                </tr>
              </thead>
              <tbody>
                {/* 이 부분이 반복 */}
                <tr>
                  <td className="td-date text-left">
                    <div class="table-title">
                      <span class="numbering">1</span>
                      <p class="item">1월 31일 (월)</p>
                      <span class="people">
                        0
                        <div class="tooltip">
                          <div>- 주소지미상 고객수는 합산되지 않음</div>
                          <div>- 기간내 중복 고객은 한명으로 정의되어 추가 합산되지 않음</div>
                        </div>
                      </span>
                      <DataTableBadge />
                      <span class="analyzing">분석중</span>
                    </div>
                  </td>
                  <td className="td-price">
                    <span>,789원</span> <br />
                    111건
                  </td>
                  <td className="td-price price-gap">
                    <span>,789원</span> <br />
                    111건
                  </td>
                  <td className="td-price price-gap">
                    <span>,789원</span> <br />
                    111건
                  </td>
                  <td colSpan={2} className="td-graph graph-gap">
                    <span className="color-green">9.0%</span>
                    그래프
                    <span className="color-blue">91.0%</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>

      <h5>필터</h5>
      <>
        <h6>필터타입1</h6>
        <div className="filter-area">
          <Filter
            classType="default"
            inputType="USER_MANAGEMENT_DATA"
            eventHandle={FilterEvent}
            sendText={sendFilter}
            filterType={filterType}
          />
        </div>
        <div className="filter-area">
          <Filter
            classType="default-type2"
            inputType="USER_MANAGEMENT_DATA"
            eventHandle={FilterEvent}
            sendText={sendFilter}
            filterType={filterType}
          />
        </div>
        <div className="filter-area">
          <Filter
            classType="select"
            inputType="USER_MANAGEMENT_DATA"
            eventHandle={FilterEvent}
            sendText={sendFilter}
            filterType={filterType}
          />
        </div>
        <div className="filter-area">
          <Filter
            classType="search"
            inputType="USER_MANAGEMENT_DATA"
            eventHandle={FilterEvent}
            sendText={sendFilter}
            filterType={filterType}
          />
        </div>
        <div className="filter-area">
          <Filter
            classType="full-menu"
            inputType="USER_MANAGEMENT_DATA"
            eventHandle={FilterEvent}
            sendText={sendFilter}
            filterType={filterType}
          />
        </div>
        <div className="filter-area">
          <Filter
            classType="opacity-img"
            inputType="USER_MANAGEMENT_DATA"
            eventHandle={FilterEvent}
            sendText={sendFilter}
            filterType={filterType}
          />
        </div>
      </>

      <h5>버튼</h5>
      <>
        <button className="basic-btn is-loding">하이</button>
      </>

      <h5>애니메이션 프로그레스바</h5>
      <>
        <AniProgressBar timer="3" classname="" itemLength={13} />
        <AniProgressBar timer="3" classname="" itemLength={83} />
      </>

      <div>
        <h5>이미지</h5>
      </div>

      <div>
        <ActionBtn>dortus</ActionBtn>
      </div>

      <div>
        <h5>토스트</h5>
        <h6>공지사항</h6>
        <div className={classnames('toast-ui notice', 'value')}>
          <div className="desc">aa</div>
          <div className="btn-wrap">
            <button className="later-btn">다음에 볼게요</button>
            <a className="inquiry-btn">상세보기</a>
          </div>
        </div>

        <h6>기간</h6>

        <h6>기간2?</h6>

        <h6>form</h6>
        <ATRadioGroup />
        <ATCheckBox
          contents={
            <>
              <span>(필수)</span>서비스 이용약관에 동의합니다.
            </>
          }
          // getValue={checked => setCheckList([checked, checkList[1]])}
        />
        <div className="wrap" style={{ width: '50px'}}>
          <ATItemBox />
          <ATItemBox />
        </div>

        <h6>매출데이터</h6>
        <div id="ToastSyncData" className={classNames(`toast-ui is-loading-text`)}>
          <LoadingItem />
          <div className="desc">매출 데이터를 동기화 중입니다</div>
        </div>

        <h6>인스톨포스</h6>
        <div className={classNames('toast-ui ment auto-hide-none')}>
          <div className="close" />
          <div className="desc">
            아직 고객님의 매장POS에 프로그램 설치가 <br />
            완료되지 않았습니다. 설치를 진행하시겠습니까?
          </div>
        </div>
      </div>

      <div style={{ position:'relative'}}>
        111
        <SystemTooltip text="미확인배달" style={{ display: 'block', right: '20px' }}/>
      </div>

      <div>
        <div className="content">
          <div className="wrap">
            <div className="modal-body text-center">
              <strong className="">{'타이틀'}</strong>
              <p>{'설명'}</p>
              {/* 현재 상태 : is-doing, 진행 완료 : is-done */}
              <ol className="res-progress" data-state={2}>
                <li className={classnames('res-progress__item is-done')}>
                  <span className="number">1</span>
                  <span className="text"></span>
                </li>
                <li className={classnames('res-progress__item')}>
                  <span className="number">2</span>
                  <span className="text">설치 예약대기중</span>
                </li>
                <li className={classnames('res-progress__item')}>
                  <span className="number">3</span>
                  <span className="text">설치 대기중</span>
                </li>
                <li className={classnames('res-progress__item')}>
                  <span className="number">4</span>
                  <span className="text">데이터 검증</span>
                </li>
                <li className="res-progress__item" value="4">
                  <spa className="number">5</spa>
                  <span className="text"></span>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComponentPage;
