import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import './buttons.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusSquare, faPlusSquare } from '@fortawesome/free-regular-svg-icons';

/**
 * @function
 * @description 버튼 컴포넌트 (스토리북용)
 * @param {type} size: small, medium, large
 * @return button 컴포넌트
 */
export const BasicBtn = props => {
  const {
    children,
    style,
    size = 'small',
    classname,
    contents,
    onClick = () => {},
    disabled,
    disabledCheckBtn,
  } = props;
  return (
    <Btn
      // onMouseDown={onClick}
      className={classNames(
        'basic-btn',
        disabled && 'is-disabled',
        size,
        classname,
        disabledCheckBtn && 'click-disabled-btn',
      )}
      onClick={onClick}
      style={{ ...style }}
    >
      {children}
      {contents}
    </Btn>
  );
};

export const ActionBtn = props => {
  const { children = '액션버튼', viewType } = props;

  return (
    <div className="">
      {viewType === 'add' ? (
        <FontAwesomeIcon icon={faPlusSquare} className="icon-edit" />
      ) : (
        <FontAwesomeIcon icon={faMinusSquare} className="icon-edit" />
      )}
      <ActionBtnStyle viewType={viewType} className="text">
        {children}
      </ActionBtnStyle>
    </div>
  );
};

const Btn = styled.button``;

const ActionBtnStyle = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #232323;
  margin-left: 26px;
  cursor: pointer;
  .icon-edit {
    margin-top: -2px;
    margin-right: 5px;
    width: 20px;
    height: 20px;
  }
  .text {
    margin-left: 1px;
    opacity: 0.7;
    letter-spacing: -0.45px;
  }
`;
