import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import IMG from '../../assets/utils/images/banner/ad_poilt.svg';
import IMG2 from '../../assets/utils/images/banner/ad_poilt_btn.svg';
import checkBoxChecked from '../../assets/utils/images/check_bg_blue.svg';
import checkBox from '../../assets/utils/images/check_bg_default.svg';
import BtnClose from '../../assets/utils/images/closeGray.svg';
import { getMyUserData } from '../../helpers';

export const ModalADPilot = () => {
  const [isModal, setIsModal] = useState(true);

  useEffect(() => {
    checkModalClose() ? setIsModal(false) : setIsModal(true);
  }, []);

  const closeModal = (expireDays = 0) => {
    if (expireDays > 0) {
      let expire = new Date();
      expire.setTime(expire.getTime() + expireDays * 24 * 60 * 60 * 1000);
      localStorage.setItem('adModalNoShow', expire.getTime());
    }

    setIsModal(false);
  };

  const checkModalClose = () => {
    const expireDay = localStorage.getItem('adModalNoShow');
    let today = new Date();
    if (today.getTime() > expireDay) {
      return false;
    } else {
      return true;
    }
  };

  const handleClick = () => {
    window.open('https://forms.gle/uHGmES7ER2CWVh1C6', '_blank');
    closeModal(0);
  };

  const userData = getMyUserData(false);
  if (
    !userData ||
    !userData['display_message'] ||
    !userData['display_message'].find(message => message.key === 'AD_PILOT_MODAL')
  ) {
    return null;
  }

  return (
    <>
      {isModal && (
        <ADPilotModal id="adModal">
          <div className="preview-container page-content-container mt0">
            <div className="preview-center page-content-area">
              <div className="preview-guide-container">
                <div className="btn-close" onClick={() => closeModal(0)}></div>
                <img src={IMG} alt="" />
                <img src={IMG2} alt="" className="submit-button" onClick={handleClick} />
                <div className="modal-footer-wrap" onClick={() => closeModal(7)}>
                  <input id="modalcheckBox" type="checkbox" value="check" />
                  <label htmlFor="modalcheckBox"></label>
                </div>
              </div>
            </div>
          </div>
        </ADPilotModal>
      )}
    </>
  );
};
const ADPilotModal = styled.div`
  @media (max-width: 767px) {
    width: 100%;
    max-width: 374px;
    min-width: 356px;
    left: 0;
    bottom: -100%;
  }

  position: fixed;
  top: 0;
  right: 0;
  z-index: 107;
  width: 100%;
  height: 100%;
  background-color: rgba(168, 171, 192, 0.6);
  .modal-footer-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 18px;
    background-color: rgba(255, 255, 255, 0.98);
    border-radius: 0 0 12px 12px;
    input {
      cursor: pointer;
    }
    input[type='checkbox'] {
      display: none;
    }
    input[type='checkbox'] + label {
      width: 20px;
      height: 20px;
      position: relative;
      cursor: pointer;
      left: 0;
      content: '';
      background-image: url(${checkBox});
      background-size: 20px 20px;
      background-repeat: no-repeat;
      background-position: center;
      &:after {
        content: '7일간 열지 않기';
        white-space: nowrap;
        position: relative;
        left: 15px;
        bottom: 1px;
      }
    }
    input[type='checkbox']:checked + label {
      background-image: url(${checkBoxChecked});
      background-size: 20px 20px;
      background-repeat: no-repeat;
      background-position: center;
    }
    input[type='checkbox']:checked {
      fill: #fff;
      opacity: 1;
    }
    label {
      margin-bottom: 0;
      padding-left: 12px;
      cursor: pointer;
    }
  }

  .preview-container {
    max-height: unset;
    height: calc(100vh - 56px);
    min-height: calc(100vh - 136px);
    padding: 0px;
    .preview-center {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px;
    }
  }
  .page-content-container {
    background: unset;
  }
  .page-content-area {
    background: unset;
  }
  .preview-guide-container {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    background: #edeffa;
    box-shadow: 0px 6.82667px 13.65333px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    border-radius: 32px;
    .btn-close {
      background-image: url(${BtnClose});
      position: absolute;
      top: 20px;
      right: 20px;
      width: 14px;
      height: 14px;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
    }
  }

  .submit-button {
    position: absolute;
    bottom: 98px;
    cursor: pointer;
  }
`;
