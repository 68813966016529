import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { commonActions, userActions } from '../../../../actions';
import { getMyUserData } from '../../../../helpers';
import { useState } from 'react';
import { isMobile } from '../../../../constants';
import ChannelChat from '../../../../helpers/ChannelChat';

const WithdrawCancelModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  let userData = getMyUserData(false);
  const [isCanelComplete, setIsCanelComplete] = useState(false);
  const { withdrawalCancel: withdrawalCancelResult = null } = useSelector(state => state.withdrawalCancel);

  if (!userData) {
    return null;
  }

  useEffect(() => {
    let closeIcon = document.getElementsByClassName('contents-close-btn')
    if(closeIcon[0]) {
      closeIcon[0].style.display = "none"
    }
  },[])

  useEffect(() => {
    if (withdrawalCancelResult && withdrawalCancelResult === 1) {
      setIsCanelComplete(true);
    }
    dispatch(userActions.myUserData(userData['user_sno'], userData['service_type']));
  }, [withdrawalCancelResult]);

  const withdrawalCancelSubmit = () => {
    if (userData && userData['auth_type'] === 'D1' && userData['company_type'] === '3') {
      dispatch(userActions.withdrawalCancel(userData['user_sno']));
      dispatch(
        userActions.withdrawalRequest(
          '',
          '',
          '',
          '',
        ),
      );
    } else {
      dispatch(commonActions.alertMessage(null));
      ChannelChat.showMessenger();
    }
  };

  const onClickCloseBtn = () => {
    if (isMobile() === false) {
      dispatch(commonActions.alertMessage(null));
    } else {
      closeModal();
    }
  };

  return (
    <Wrapper>
      <div className="btn-modal">
        {isCanelComplete === false ? (
          <div className="message">
            <p>
              서비스 해지/탈퇴 신청을
              <br />
              취소하시겠습니까?
            </p>
            <div className="btn-wrap">
              <button type="button" onClick={onClickCloseBtn}>
                아니요
              </button>
              <button type="button" onClick={withdrawalCancelSubmit}>
                네, 취소할게요
              </button>
            </div>
          </div>
        ) : (
          <div className="message">
            <p>서비스 해지/탈퇴 신청이 취소되었습니다.</p>
            <div className="btn-wrap">
              <button type="submit" onClick={onClickCloseBtn}>
                확인
              </button>
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #f4f6f8;
  min-width: 280px;
  max-width: 395px;
  width: 100%;
  color: #3c3f43;

  .message {
    width: 330px;
    max-height: 180px;
    height: auto;
    padding: 40px 24px 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border: 1px solid #e1e3e8;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;

    p {
      line-height: 150%;
      text-align: center;
      color: #262626;
      font-weight: 600;
      font-size: 15px;
    }

    button {
      background: #686cff;
      color: #fefefe;
      padding: 10px 23px;
      line-height: 18px;
      border-radius: 24px;
      border: 1px solid #686cff;
    }
  }

  @media (max-width: 1024px) {
    .btn-modal {
      width: 100%;
      height: 100vh;
      text-align: center;
      overflow: hidden;
      background: rgba(244, 246, 248, 0.7);
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 20;
      font-size: 15px;
      font-weight: 600;
      overflow: hidden;

      .message {
        width: calc(100% - 30px);
        max-height: 180px;
        height: auto;
        padding: 40px 24px 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        border: 1px solid #e1e3e8;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        align-items: center;

        p {
          line-height: 150%;
        }

        button {
          background: #686cff;
          color: #fefefe;
          padding: 10px 23px;
          line-height: 18px;
          border-radius: 24px;
        }
      }
    }
  }
`;

export default WithdrawCancelModal;
