import cx from 'classnames';
import $ from 'jquery';
import QueryString from 'qs';
import React, { lazy, useEffect, useLayoutEffect, useRef, useState } from 'react';
import Loader from 'react-loaders';
import { connect, useDispatch, useSelector } from 'react-redux';
import ResizeDetector from 'react-resize-detector';
import { withRouter } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { authActions, commonActions, officeActions } from '../../actions';
import CheckAuthTypeAction from '../../components/CheckAuthTypeAction';
import CheckLogin from '../../components/CheckLogin';
import ErrorBountary from '../../components/ErrorBoundary';
import Modal from '../../components/Modal';
import CommonAlert from '../../components/Modal/CommonAlert';
import Toast from '../../components/Toast/Toast';
import { isMobile, NOTI_TYPES_INDEX } from '../../constants';
import { PATHS } from '../../constants/Routes';
import {
  dateToStr,
  getMyUserData,
  getPageTitle,
  getUnixTime,
  localDataLoad,
  setLocalData,
  startOfMonthDay,
  toSha256,
} from '../../helpers';
import { setAppHeaderColor, setNotifyBadgeCount } from '../../helpers/AppWebView';
import { setMobileViewPort } from '../../helpers/CommonUtil';
import { PrivateRoute, PublicRoute } from '../../helpers/PrivateRoute';
import WebSocket from '../../helpers/WebSocket';
import ComponentPage from '../../pages/Component/ComponentPage';
import PopupPage from '../../pages/Component/PopupPage';
import UpdateAdditionalUserInfo from '../../pages/Mobile/MyPage/Container/UpdateAdditionalUserInfo';
import NotFoundPage from '../../pages/NotFound/NotFoundPage';
import { visibilityFlagState } from '../../recoil/common/atoms';
import { officeService } from '../../services';

const Analysis = lazy(() => import('../../pages/Analysis'));
const Mobile = lazy(() => import('../../pages/Mobile'));
const UserPages = lazy(() => import('../../pages/UserPages'));
const Dashborads = lazy(() => import('../../pages/Dashborad'));
const Deliverys = lazy(() => import('../../pages/Delivery'));
const Materials = lazy(() => import('../../pages/Material'));
const OfficeDetails = lazy(() => import('../../pages/OfficeDetail'));
const MenuDetails = lazy(() => import('../../pages/MenuDetail'));
const MaterialDetails = lazy(() => import('../../pages/MaterialDetail'));
const Indicators = lazy(() => import('../../pages/Indicators'));
const Reports = lazy(() => import('../../pages/Report'));
const BackViews = lazy(() => import('../../pages/BackView'));
const Mains = lazy(() => import('../../pages/Main'));
const ServicePages = lazy(() => import('../../pages/ServicePages'));
const BrandManage = lazy(() => import('../../pages/BrandManage'));
const Members = lazy(() => import('../../pages/Members'));
const DevUITest = lazy(() => import('../../pages/DevUITest'));
const RedirectPages = lazy(() => import('../../pages/RedirectPages'));
const PolicyPages = lazy(() => import('../../pages/PolicyPages'));
const PromotionPage = lazy(() => import('../../pages/PromotionPages/PromotionPage'));
const PreviewPages = lazy(() => import('../../pages/Preview'));
const MarketboroPage = lazy(() => import('../../pages/Marketboro'));
const MarketbomPage = lazy(() => import('../../pages/Marketbom'));
const RedirectWithDrawCancel = lazy(() => import('../../pages/RedirectPages/RedirectWithDrawCancel'));
const PosPages = lazy(() => import('../../pages/Pos'));

const Main = () => {
  const dispatch = useDispatch();

  const { searchParams } = useSelector(state => state.searchParams);
  const { orderDetailList } = useSelector(state => state.orderDetailList);
  const { alertList } = useSelector(state => state.alertList);
  const { isSlideShow } = useSelector(state => state.setIsSlideShow);

  const setVisibilityFlag = useSetRecoilState(visibilityFlagState);

  const userData = getMyUserData(false);

  let orderDetailListRef = useRef(orderDetailList);
  let alertListRef = useRef(alertList);
  let isSlideShowRef = useRef(isSlideShow);

  const [isForegroundTransform, setIsForegroundTransform] = useState(false);

  const setCommonNotifyBadgeCount = () => {
    if (!userData) {
      return;
    }

    if (userData['auth_type'] === 'D1') {
      if (isSlideShowRef.current) {
        setNotifyBadgeCount(0);
      } else if (orderDetailListRef.current) {
        setNotifyBadgeCount(orderDetailListRef.current.unReadCnt);
      }
    } else {
      if (alertListRef.current) {
        const StatusAlarmList = alertListRef.current.find(value => value.tabType === 1);
        setNotifyBadgeCount(StatusAlarmList && StatusAlarmList.unReadCnt ? StatusAlarmList.unReadCnt : 0);
      }
    }
  };

  useLayoutEffect(() => {
    const isFirstLunch = localDataLoad('isFirstLunch');

    if (userData && isFirstLunch) {
      setTimeout(() => {
        dispatch(officeActions.alertList(userData['user_sno'], userData['brand_code'], null));
        dispatch(officeActions.orderDetailList(userData['user_sno'], userData['brand_code'], null));
        setIsForegroundTransform(true);
      }, 2000);

      setLocalData('isFirstLunch', false);
    }
  }, []);

  useLayoutEffect(() => {
    if (!userData) {
      return;
    }

    if (isForegroundTransform && userData['auth_type'] === 'D1') {
      const isUnreadCount = orderDetailList && orderDetailList.unReadCnt;

      if (isUnreadCount) {
        dispatch(commonActions.setIsSlideShow(true));
      }

      setIsForegroundTransform(false);
    }

    orderDetailListRef.current = orderDetailList;
    alertListRef.current = alertList;

    setCommonNotifyBadgeCount();

    setTimeout(() => {
      setCommonNotifyBadgeCount();
    }, 1000);
  }, [orderDetailList, alertList]);

  useEffect(() => {
    isSlideShowRef.current = isSlideShow;
  }, [isSlideShow]);

  useEffect(() => {
    setAppHeaderColor('#F4F6F8');
    if (!searchParams) {
      dispatch(
        commonActions.setFilterParams(
          {
            startDate: startOfMonthDay(),
            endDate: dateToStr(),
          },
          true,
        ),
      );
    }

    window.addEventListener('message', onMessage);
    document.addEventListener('message', onMessage);
    document.addEventListener('visibilitychange', () => setVisibilityFlag(document.visibilityState));
    WebSocket.init(dispatch);

    return () => {
      window.removeEventListener('message', onMessage);
      document.removeEventListener('message', onMessage);
    };
  }, []);

  const onMessage = async event => {
    if (!event || !event['data']) {
      return;
    }

    try {
      const message = JSON.parse(event['data']);

      if (message['type'] === 'appInfo') {
        dispatch(authActions.setAppInfo(message['data']));
      }

      if (message['type'] === 'isAlarmPermissionDenied') {
        // 시스템 알림 권한 설정
        dispatch(authActions.setIsAlarmPermissionDenied(message['data']));

        // 알림 리스트 설정
        const userData = getMyUserData(false);

        if (isSlideShowRef.current) {
          await officeService.readAlert(
            userData['user_sno'],
            userData['brand_code'],
            NOTI_TYPES_INDEX['realTime'],
            null,
          );

          dispatch(officeActions.orderDetailList(userData['user_sno'], userData['brand_code'], ''));
        } else {
          if (userData) {
            dispatch(officeActions.alertList(userData['user_sno'], userData['brand_code'], null));
            dispatch(officeActions.orderDetailList(userData['user_sno'], userData['brand_code'], null));
          }

          // orderList slide show
          setIsForegroundTransform(true);
        }
      }

      if (message['type'] === 'requestNotifyBadgeCount') {
        setTimeout(() => {
          setCommonNotifyBadgeCount();
        }, 1000);
      }
    } catch (error) {}
  };

  if (localDataLoad('doRefresh')) {
    setLocalData('doRefresh', false);
    window.location.reload();
    return null;
  }

  // 404에러 페이지 판별
  let parsedUrl = window.location.hash
    .toString()
    .split('?')[0]
    .replace('#', '')
    .replace(/\/+$/, '');

  const checkUrlRoute = Object.keys(PATHS).findIndex(path => {
    if (window.location.hash.indexOf('brandDetailNotice') > 0 || window.location.hash.indexOf('brandEdit') > 0) {
      return true;
    }

    return parsedUrl.toLowerCase() === PATHS[path].url.toLowerCase() ? true : false;
  });

  if (checkUrlRoute === -1) {
    return <PublicRoute path="*" component={NotFoundPage} />;
  }

  let loginCheckWhiteList = ['#/policy', '#/members/wmpo', '#/mobile/paymentCard'];
  let alertWhiteList = ['#/policy'];
  let toastWhiteList = [
    '#/policy',
    '#/members/wmpo',
    '#/marketboro/marketboroAgree',
    '#/marketboro/mobileMarketboroAgree',
  ];

  if (window.location) {
    let urlStr = window.location.toString().split('?');
    let query = QueryString.parse(urlStr[urlStr.length - 1].slice(0));

    if (query && query['compare_user']) {
      const userData = getMyUserData(false);
      if (!userData) {
        window.history.replaceState({}, null, '#/auth/login');
        window.location.reload();
      }

      if (userData && toSha256(userData['user_sno']) !== query['compare_user']) {
        WebSocket.destory();
        window.history.replaceState({}, null, '#/auth/login');
        window.location.reload();
      }
    }
  }

  // 이벤트 페이지 인프라 전용 로직
  if (window.location.host === 'event.attracker.shop') {
    return (
      <>
        {alertWhiteList.findIndex(v => window.location.hash.indexOf(v) !== -1) === -1 && <CommonAlert />}
        <PublicRoute path="/event" component={PromotionPage} />
        {/* 메인으로 접속했을 경우 홈페이지로 이동 */}
        {window.location.hash === '#/' && window.location.replace('https://attracker.shop')}
      </>
    );
  }

  return (
    <>
      {loginCheckWhiteList.findIndex(v => window.location.hash.indexOf(v) !== -1) === -1 && (
        <>
          <CheckLogin />
        </>
      )}
      {alertWhiteList.findIndex(v => window.location.hash.indexOf(v) !== -1) === -1 && (
        <>
          <CommonAlert />
        </>
      )}

      {toastWhiteList.findIndex(v => window.location.hash.indexOf(v) !== -1) === -1 && (
        <>
          <Toast />
        </>
      )}

      {/* 공통 Modal */}
      <Modal />

      {/*유저 권한에 따라 페이지 접근 컨트롤 (현재는 마스터 관리자 action만 존재함)*/}
      <CheckAuthTypeAction />

      <PublicRoute path="/auth" component={UserPages} />
      <PrivateRoute path="/dashboard" component={Dashborads} />
      <PrivateRoute path="/delivery" component={Deliverys} />
      <PrivateRoute path="/material" component={Materials} />
      <PrivateRoute path="/officeDetail" component={OfficeDetails} />
      <PrivateRoute path="/menuDetail" component={MenuDetails} />
      <PrivateRoute path="/indicators" component={Indicators} />
      <PrivateRoute path="/analysis" component={Analysis} />
      <PrivateRoute path="/mobile" component={Mobile} />
      <PrivateRoute path="/materialDetail" component={MaterialDetails} />
      <PrivateRoute path="/report" component={Reports} />
      <PublicRoute path="/back" component={BackViews} />
      <PrivateRoute path="/service" component={ServicePages} />
      <PublicRoute path="/members" component={Members} />
      <PrivateRoute path="/brand" component={BrandManage} />
      <PrivateRoute path="/manage" component={BrandManage} />
      <PrivateRoute path="/devUITest" component={DevUITest} />
      <PublicRoute path="/redirect" component={RedirectPages} />
      <PublicRoute path="/policy" component={PolicyPages} />
      <PublicRoute path="/event" component={PromotionPage} />
      <PrivateRoute path="/preview" component={PreviewPages} />
      <PrivateRoute path="/marketboro" component={MarketboroPage} />
      <PublicRoute path="/marketbom" component={MarketbomPage} showLoading={false} />
      <PrivateRoute exact path="/" component={Mains} />
      <PrivateRoute exact path="/component" component={ComponentPage} />
      <PrivateRoute exact path="/popup" component={PopupPage} />
      <PrivateRoute path="/pos" component={PosPages} />
      <PrivateRoute path="/redirect/withdrawcancel" component={RedirectWithDrawCancel} />
    </>
  );
};

class AppMain extends React.Component {
  state = {
    hasError: false,
    error: undefined,
    clsssnaming: '',
  };

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
    };
  }

  componentDidMount() {
    if (window.location.href.includes('/account')) {
      this.setState({ clsssnaming: 'master-page' });
    }

    function setScreenSize() {
      // 먼저 뷰포트 높이를 얻고 1%를 곱하여 vh 단위 값을 얻습니다.
      // 그런 다음 --vh 사용자 정의 속성의 값을 문서의 루트로 설정합니다.
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    setScreenSize();
    window.addEventListener('resize', () => setScreenSize());

    if (isMobile() === true) {
      setMobileViewPort();
      window.addEventListener('orientationchange', () => setScreenSize());
    }
  }

  render() {
    const {
      colorScheme,
      enableFixedHeader,
      enableFixedSidebar,
      enableFixedFooter,
      enableClosedSidebar,
      closedSmallerSidebar,
      enableMobileMenu,
      enablePageTabsAlt,
      enableNarrowViewSidebar, // 상단 헤더, 없는경우 height: 100vh - 60px 제거
      isMapFull,
    } = this.props;

    const { hasError, error } = this.state;

    if (hasError === true) {
      if (
        /loading chunk \d* failed/i.test(error.message) ||
        /Loading chunk \d* failed/i.test(error.message) ||
        /Loading CSS chunk \d* failed/i.test(error.message)
      ) {
        let lastRefreshedUnixTime = localDataLoad('pageRefreshedByError');
        let currentUnixTime = getUnixTime();

        if (currentUnixTime - lastRefreshedUnixTime > 10000) {
          setLocalData('pageRefreshedByError', currentUnixTime);
          window.location.reload();
        }
      } else {
        return <ErrorBountary error={error} />;
      }
    }

    return (
      <ResizeDetector
        handleWidth
        render={({ width }) => {
          if (width < 576 && enableMobileMenu) {
            $('body').css('overflow', 'hidden');
          } else {
            $('body').css('overflow', 'initial');
          }

          if (isMobile() === true) {
            $('body').css('background-color', '#f4f6f8');
          }

          return (
            <Container>
              <div
                id="app-container"
                className={cx(
                  `app-container app-theme-${colorScheme}`,
                  { 'fixed-header': enableFixedHeader },
                  { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
                  { 'fixed-footer': enableFixedFooter },
                  { 'closed-sidebar': enableClosedSidebar || width < 1250 },
                  { 'closed-sidebar-mobile': closedSmallerSidebar || width < 1250 },
                  { 'open-sidebar-mobile': width < 576 && enableMobileMenu }, // 모바일뷰 사이즈 아닐때도 추가되어 조건 보완
                  { 'body-tabs-shadow-btn': enablePageTabsAlt },
                  { 'narrow-view-sidebar': enableNarrowViewSidebar },
                  { 'mobile-page': isMobile() },
                  { 'not-mobile-page': !isMobile() },
                  { 'is-map-full': isMapFull }, // 지도뷰에서 지도가 full화면일때 is-map-full 클래스 추가
                )}
              >
                {/* <UtilToolkit /> */}
                <Main />
              </div>
            </Container>
          );
        }}
      />
    );
  }
}

const mapStateToProp = state => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
  enableNarrowViewSidebar: state.ThemeOptions.enableNarrowViewSidebar,
  isMapFull: state.setMapFullSize.isMapFull,
});

export default withRouter(connect(mapStateToProp)(AppMain));

const Container = styled.div``;
