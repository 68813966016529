import React from 'react';
import styled from 'styled-components';
import { content } from './PrivacyContent';
import { getUrlValue } from '../../../helpers';

export const Privacy = () => {
  const isBack = getUrlValue('isBack') && getUrlValue('isBack') === 'true';

  return <Wrapper isBack={isBack} dangerouslySetInnerHTML={{ __html: content }} />;
};

const Wrapper = styled.div`
  p,
  li,
  div,
  span {
    line-height: 1.6 !important;
  }

  span.c24 {
    margin-bottom: 16px;
    display: inline-block;
  }

  span.c12 {
    margin: 16px 0 8px;
    display: inline-block;
  }

  table.c20 {
    margin: 8px 0;
    display: inline-block;
  }

  padding: ${props => (props.isBack ? '40px 0 32px' : '40px 32px 32px')};

  .ol {
    margin: 0;
    padding: 0;
  }
  table td,
  table th {
    padding: 0;
  }
  .c23 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 100.8pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c15 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 125.8pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c22 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 103pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c5 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 60.8pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c11 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 225.8pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c21 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 36.1pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c13 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 286.9pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c1 {
    background-color: #ffffff;
    margin-left: 10pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c10 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 9pt;
    font-family: 'Malgun Gothic';
    font-style: normal;
  }
  .c16 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 6pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c2 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 9pt;
    font-family: 'Malgun Gothic';
    font-style: normal;
  }
  .c0 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 10pt;
    font-family: 'Malgun Gothic';
    font-style: normal;
  }
  .c3 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c14 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.3;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c18 {
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-family: 'Malgun Gothic';
    font-style: normal;
  }
  .c6 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
  }
  .c8 {
    font-size: 10pt;
    font-family: 'MS Gothic';
    color: #000000;
    font-weight: 400;
  }
  .c20 {
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto;
  }
  .c9 {
    background-color: #ffffff;
    max-width: 487.3pt;
    padding: 72pt 54pt 72pt 54pt;
  }
  .c24 {
    color: #000000;
    font-size: 16pt;
  }
  .c12 {
    color: #000000;
    font-size: 10pt;
  }
  .c19 {
    orphans: 2;
    widows: 2;
  }
  .c7 {
    height: 10pt;
  }
  .c17 {
    height: 0pt;
  }
  .c4 {
    margin-left: 20pt;
  }
  .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: 'Malgun Gothic';
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    text-align: justify;
  }
  .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: 'Georgia';
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    font-style: italic;
    text-align: justify;
  }
  li {
    color: #000000;
    font-size: 9pt;
    font-family: 'Malgun Gothic';
  }
  p {
    margin: 0;
    color: #000000;
    font-size: 9pt;
    font-family: 'Malgun Gothic';
  }
  h1 {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 6pt;
    font-family: 'Malgun Gothic';
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    text-align: justify;
  }
  h2 {
    padding-top: 18pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 4pt;
    font-family: 'Malgun Gothic';
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    text-align: justify;
  }
  h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: 'Malgun Gothic';
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    text-align: justify;
  }
  h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;
    font-family: 'Malgun Gothic';
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    text-align: justify;
  }
  h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    font-family: 'Malgun Gothic';
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    text-align: justify;
  }
  h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 9pt;
    padding-bottom: 2pt;
    font-family: 'Malgun Gothic';
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    text-align: justify;
  }
`;
