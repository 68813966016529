import React, { useState } from 'react';
import styled from 'styled-components';
import ATInput from './ATInput';
import classNames from 'classnames';
import { MOBILE_AUTH_SUCCESS } from '../../../pages/UserPages/MobileAuth/Helper';

const MobileATUploader = props => {
  const {
    mobileAuthState,
    className = '',
    name = 'businessFile',
    getValue = () => {},
    accept = 'image/x-png, image/gif,image/jpeg, application/pdf',
    maxFileSize = 20971520, // 20MB
    type,
  } = props;

  const [value, setValue] = useState(null);

  const scrollingRight = () => {
    [...document.querySelectorAll('input[name=fileName]')].forEach(el => {
      // if(el.scrollWidth !== 0 ) {
      el.scrollLeft = el.scrollWidth;
      // document.getElementById('file').style.textIndent = '-100px';
      // }
    });
  };

  function handleLoadFile(e) {
    const { files } = e.target;

    let file = files[0];
    if (!file) {
      setValue(null);
      getValue({ name: name, value: file });
      return;
    }

    let fileSize = Math.round(file.size);
    if (fileSize > maxFileSize) {
      alert('첨부파일 사이즈는 20MB 이내로 등록 가능합니다.');
      e.target.value = null;
      return;
    }
    setValue(file);
    getValue({ name: name, value: file });

    setTimeout(() => {
      scrollingRight();
    }, 10);
  }

  return (
    <Wrapper>
      <div className="flex">
        <ATInput
          type="text"
          name="fileName"
          className={classNames(
            'inputwrap',
            'no-pointer-even',
            'file',
            mobileAuthState == MOBILE_AUTH_SUCCESS ? '' : 'is-disabled is-readonly',
          )}
          id="file"
          dataText={type === 'event' ? '이미지 첨부 (PDF, IMG)' : '사업자 등록증 첨부 (PDF, IMG)'}
          required
          astro={true}
          defaultValue={value ? value['name'] : ''}
          style={{ width: '100%' }}
        />
        <input
          type="file"
          name={name}
          onChange={handleLoadFile}
          disabled={mobileAuthState != MOBILE_AUTH_SUCCESS}
          accept={accept}
          className={`file-uploader ${className ? className : ''}`}
        />
        <div className={`btn-action ${value ? 'is-disabled' : ''}`}>파일첨부</div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  margin-top: 8px;

  .inputwrap {
    width: calc(100% - 88px);
  }

  input {
    &.file-uploader {
      opacity: 0;
      position: absolute;
      width: 100% !important;
      height: 100%;
      z-index: 1;
      cursor: pointer;
    }
  }

  .btn-action {
    margin-left: 9px;
    padding: 0 12px;
    word-break: keep-all;
    background: #9598ff;
    border-radius: 12px;
    color: white;
    line-height: 40px;
    border: none;

    &.is-disabled {
      background: #dee2e4;
      pointer-events: none;
    }
  }
`;

export default MobileATUploader;
