import React, { useEffect, useState } from 'react';
import { Link } from 'react-scroll';
import styled from 'styled-components';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { commonActions, userActions } from '../../../../actions';
import IconChecked from '../../../../assets/utils/images/icon_check_circle_active_blue.svg';
import IconCheck from '../../../../assets/utils/images/icon_check_circle_default_2.svg';
import IconPolygon from '../../../../assets/utils/images/polygon_top.svg';
import { GA_EVENT_CONST, isMobile } from '../../../../constants';
import { PATHS } from '../../../../constants/Routes';
import { FEEDBACK_OPTIONS } from '../../../../constants/Withdraw';
import { getMyUserData, trackEvent } from '../../../../helpers';
import MypageHeader from '../../MyPage/Container/MyPageHeader';

const WithdrawModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = getMyUserData(false);

  const [selectOptionList, setSelectOptionList] = useState([]);
  const [textOption, setTextOption] = useState('');
  const [isBtnActive, setIsBtnActive] = useState(false);
  const [withdrawStep, setWithdrawStep] = useState(0);
  const { withdrawalRequest } = useSelector(state => state.withdrawalRequest);

  useEffect(() => {
    if (withdrawalRequest && withdrawalRequest === 1) {
      setWithdrawStep(2);
    }
    dispatch(userActions.myUserData(userData['user_sno'], userData['service_type']));
  }, [withdrawalRequest]);

  const handleSelectOptions = value => {
    if (selectOptionList.includes(value)) {
      setSelectOptionList(selectOptionList.filter(option => option !== value));
    } else {
      setSelectOptionList([...selectOptionList, value]);
    }
  };

  const handleTextOption = e => {
    setTextOption(e.target.value);
  };

  const nextStepModal = () => {
    trackEvent(GA_EVENT_CONST['M0711']);
    setWithdrawStep(1);
  };

  const submitWithdraw = () => {
    dispatch(
      userActions.withdrawalRequest(
        userData['user_sno'],
        parseInt(userData['service_type']),
        selectOptionList,
        textOption,
      ),
    );
    dispatch(userActions.withdrawalCancel(''));
  };

  const closeModal = () => {
    !isMobile() && dispatch(commonActions.alertMessage(null));
    setWithdrawStep(0);

    window.location.href = `#/`;
  };

  return (
    <ServiceWithdrawal>
      <div className="wrap">
        <MypageHeader title="서비스 해지/탈퇴" />

        <section className="withdrawal-wrap">
          <h2>
            <span>서비스 해지/탈퇴</span>시,
            <br />
            아래 내용을 꼭 확인해주세요!
            <div class="border"></div>
          </h2>

          <div className="text-wrap">
            <h3>데이터 및 개인정보 처리</h3>
            <div class="txt-inner">
              개인 정보와 매장 정보를 포함한 <span>가입 시 기입한 모든 정보</span> 및 매장 내 매출, 메뉴, 리뷰, 원부자재
              분석 데이터와 분류된 메뉴 데이터 등 <span>데이터 분석 제공 기록은 모두 삭제</span>되어, 삭제된 데이터는
              복구되지 않으며, 재가입 시 제공되지 않습니다. 삭제되는 내용을 확인하시고 필요한 데이터는 미리 백업을
              해주세요.
            </div>
          </div>

          <div className="text-wrap">
            <h3>기납부 이용료 처리</h3>
            <div class="txt-inner">
              앳트래커는 매월 이용료를 선납한 후 서비스가 제공되므로, 서비스 이용기간 중 해지 시 이용료 납부일이 속한
              달의 말일 까지 서비스가 제공되며 기간 만료 후 해지 처리됩니다.
            </div>
          </div>

          <div className="reaseon-wrap">
            <h3>
              탈퇴/해지 사유 <span>(복수 선택 가능)</span>
            </h3>

            <form className="checkbox-wrap">
              {FEEDBACK_OPTIONS.map(option => (
                <label>
                  <input
                    type="checkbox"
                    name="reaseon"
                    id={`option${option.value}`}
                    value={option.value}
                    onChange={() => handleSelectOptions(option.value)}
                  />
                  {option.label}
                </label>
              ))}

              <label>
                <input
                  type="checkbox"
                  name="reaseon"
                  value={textOption}
                  checked={textOption ? 'checked' : ''}
                  onClick={'checked'}
                />
                기타 의견
              </label>
            </form>

            <textarea
              placeholder="기타 의견을 작성해 주시면 더 나은 서비스를 위해 참고하여 서비스에 반영될 수 있도록 고민하겠습니다.&#13;&#10;(0/300)"
              className="textArea"
              maxLength={300}
              onChange={handleTextOption}
            ></textarea>
          </div>

          <div className="agree-wrap">
            <h3>정보확인 동의</h3>
            <form class="checkbox-wrap">
              <label>
                <input
                  type="checkbox"
                  name="agreement"
                  value="true"
                  onChange={() => {
                    setIsBtnActive(!isBtnActive);
                  }}
                />
                안내사항을 모두 확인하였으며, 이에 동의합니다.
              </label>
            </form>
          </div>
          <div
            className={classNames(isBtnActive && (selectOptionList.length !== 0 || textOption) ? 'active-btn' : 'btn')}
            onClick={nextStepModal}
          >
            서비스 해지/탈퇴
          </div>

          {withdrawStep === 1 && (
            <div className="btn-modal">
              <div className="message">
                <p>
                  멤버십 종료 후 <br />
                  현재 사용하고 있는 서비스를
                  <br />
                  더 이상 이용할 수 없습니다.
                  <br />
                  <span style={{ lineHeight: '46px' }}>서비스를 해지/탈퇴 하시겠습니까?</span>
                </p>
                <div className="btn-wrap">
                  <button type="button" onClick={() => setWithdrawStep(0)}>
                    아니요
                    {/* <div className='free-modal'>
                                        서비스를 계속 이용할 경우 비용없이<br/><span>무료</span>로 <span>매출만 분석</span> 가능한 서비스로<br/>전환이 가능합니다.
                                    </div> */}
                  </button>
                  <button type="button" onClick={submitWithdraw}>
                    네, 탈퇴할게요
                  </button>
                </div>
              </div>
            </div>
          )}

          {withdrawStep === 2 && (
            <div className="btn-modal">
              <div className="message">
                <p>서비스 해지/탈퇴 신청이 완료되었습니다.</p>
                <div className="btn-wrap">
                  <button type="submit" onClick={closeModal}>
                    확인
                  </button>
                </div>
              </div>
            </div>
          )}
        </section>
      </div>
    </ServiceWithdrawal>
  );
};

export default WithdrawModal;

const ServiceWithdrawal = styled.div`
  //border-radius: 16px;
  background-color: #f4f6f8;
  min-width: 280px;
  max-width: 395px;
  width: 100%;
  color: #3c3f43;

  @media (max-width: 560px) {
    max-width: none;
  }

  .modal-header {
    background: #f4f6f8 !important;
    border-bottom: 0;
    border-radius: 16px 16px 0 0;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  .withdrawal-wrap {
    padding: 24px 24px 40px;
    position: relative;

    h2 {
      font-size: 20px;
      line-height: 150%;
      color: #232323;
      font-weight: 700;
      position: relative;

      span {
        color: #515ef6;
      }

      .border {
        display: block;
        background-color: #6773ff;
        opacity: 0.2;
        position: absolute;
        top: 47px;
        width: 150px;
        height: 14px;
        border-radius: 2px;
      }
    }

    h3 {
      font-size: 15px;
      font-weight: 700;
      color: #3c3f43;
      margin-bottom: 12px;

      span {
        color: #afb5bd;
        font-size: 12px;
        font-weight: 400;
      }
    }

    form {
      font-size: 14px;
      color: #3c3f43;
      padding-top: 5px;
    }

    label {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 6px;
      margin-bottom: 13px;

      :nth-last-child() {
        margin: 0;
      }
    }

    input[type='checkbox'] {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background: url(${IconCheck}) no-repeat center;
      width: 25px;
      height: 25px;

      :checked {
        background: url(${IconChecked}) no-repeat center;
        width: 25px;
        height: 25px;
      }
    }

    textarea {
      width: 100%;
      height: 120px;
      border: 1px solid #dfe2e4;
      border-radius: 4px;
      padding: 8px 12px 0;
      font-size: 14px;
      line-height: 21px;
      resize: none;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
      }

      ::placeholder {
        color: #000;
        opacity: 0.3;
      }
    }

    .wrap {
      div {
        margin: 35px 0;
      }
    }

    .text-wrap {
      margin: 35px 0;
      padding: 0;
      .txt-inner {
        font-size: 14px !important;
        line-height: 160%;
        font-weight: 400;
        padding: 0 !important;

        span {
          color: #e84545;
        }
      }
    }
    .btn {
      margin: 0;
      width: 100%;
      padding: 20px 0;
      text-align: center;
      background: #e7ebed;
      color: #fff;
      border-radius: 16px;
      pointer-events: none;
    }

    .active-btn {
      margin: 0;
      width: 100%;
      padding: 20px 0;
      text-align: center;
      color: #fff;
      border-radius: 16px;
      background: #686cff;
      font-size: 0.8rem;
      font-weight: 500;
      line-height: 1.5;
    }

    .agree-wrap {
      margin-top: 35px;
    }

    .btn-modal {
      width: 100%;
      max-height: 800px;
      height: 100%;
      text-align: center;
      overflow: hidden;
      background: rgba(244, 246, 248, 0.7);
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 10;
      font-size: 15px;
      font-weight: 600;
      overflow: hidden;

      .message {
        width: calc(100% - 30px);
        max-height: 275px;
        height: auto;
        padding: 40px 24px 32px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        border: 1px solid #e1e3e8;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        align-items: center;

        p {
          line-height: 150%;
        }

        button {
          background: #686cff;
          color: #fefefe;
          padding: 10px 23px;
          line-height: 18px;
          border-radius: 24px;
          border: none;

          .free-modal {
            position: absolute;
            top: 225px;
            left: 2px;
            padding: 16px 24px;
            font-size: 14px;
            line-height: 150%;
            letter-spacing: -0.03em;
            font-weight: 600;
            color: #fefefe;
            text-align: left;
            background-color: #535675;
            border-radius: 8px;
            opacity: 0.95;

            span {
              color: #ffff05;
            }

            &::before {
              content: ' ';
              background: url(${IconPolygon}) no-repeat center;
              position: absolute;
              top: -9px;
              left: 95px;
              display: block;
              width: 23px;
              height: 12px;
            }
          }
        }
      }
    }
  }
`;
