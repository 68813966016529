import { authHeader, handleResponse, isOfficeManageV2 } from '../helpers';

export const brandService = {
  noticeList,
  noticeOneLoad,
  noticeCreate,
  noticeEdit,
  noticeWriteComment,
  noticeDisableComment,
  noticeConfirm,
  noticeReadList,
  noticeDisable,
  allBrandList,
  getUserInfoByIdx,

  getFranchiseOfficeStatus,
  getFranchiseOfficeStatusVer2,
  getOrderAppOfficeStatus,
  checkSignUpLinkClick,
  insertMultiOfficeManagerInfo,
  insertSingleOfficeManagerInfo,
  editOfficeManagerInfo,
  manageFranchiseOfficeStatus,
  checkOfficeClosing,
  removeFranchiseOffice,
  sendBulkInviteMsg,
};

function noticeList(userSno, brandCode, limitStart, limitCount) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
      limitStart: limitStart,
      limitCount: limitCount,
    }),
  };

  return fetch('/brand/noticeList', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function noticeOneLoad(brandCode, noticeSno) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandCode: brandCode,
      noticeSno: noticeSno,
    }),
  };

  return fetch('/brand/noticeOneLoad', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function noticeCreate(
  noticeSno,
  userSno,
  brandCode,
  noticeType,
  title,
  contents,
  officeSnoList,
  userSnoList,
  filePaths,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      noticeSno: noticeSno,
      userSno: userSno,
      brandCode: brandCode,
      noticeType: noticeType,
      title: title,
      contents: contents,
      officeSnoList: officeSnoList,
      userSnoList: userSnoList,
      filePaths: filePaths,
    }),
  };

  return fetch('/brand/noticeCreate', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function noticeEdit(noticeSno, userSno, brandCode, noticeType, title, contents, officeSnoList, userSnoList, filePaths) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      noticeSno: noticeSno,
      userSno: userSno,
      brandCode: brandCode,
      noticeType: noticeType,
      title: title,
      contents: contents,
      officeSnoList: officeSnoList,
      userSnoList: userSnoList,
      filePaths: filePaths,
    }),
  };

  return fetch('/brand/noticeEdit', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function noticeWriteComment(noticeSno, userSno, brandCode, comment) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      noticeSno: noticeSno,
      userSno: userSno,
      brandCode: brandCode,
      comment: comment,
    }),
  };

  return fetch('/brand/noticeWriteComment', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function noticeDisableComment(userSno, commentIdx) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      commentIdx: commentIdx,
    }),
  };

  return fetch('/brand/noticeDisableComment', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function noticeConfirm(userSno, noticeSno) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      noticeSno: noticeSno,
    }),
  };

  return fetch('/brand/noticeConfirm', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function noticeReadList(userSno, brandCode) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      brandCode: brandCode,
    }),
  };

  return fetch('/brand/noticeReadList', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function noticeDisable(userSno, noticeSno) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      userSno: userSno,
      noticeSno: noticeSno,
    }),
  };

  return fetch('/brand/noticeDisable', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function allBrandList() {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };

  return fetch('/brand/allBrandList', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

// 매장 관리 & 사용자관리
function getUserInfoByIdx(officeIdx, type) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      idx: officeIdx,
      type: type,
    }),
  };

  return fetch('/office/getUserInfoByIdx', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function getFranchiseOfficeStatus(brandCode) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandCode,
    }),
  };

  return fetch('/office/getFranchiseOfficeStatus', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function getFranchiseOfficeStatusVer2(brandCode, userSno) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandCode,
      userSno
    }),
  };

  return fetch('/office/getFranchiseOfficeStatusVer2', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function getOrderAppOfficeStatus(brandCode) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandCode,
    }),
  };

  return fetch('/office/getOrderAppOfficeStatus', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function insertMultiOfficeManagerInfo(companyCode, brandCode, list, isUpload) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      companyCode,
      brandCode,
      list,
      isUpload,
    }),
  };

  const targetURL = isOfficeManageV2() ? '/office/insertMultiOfficeManagerInfoV2' : '/office/insertMultiOfficeManagerInfo';

  return fetch(targetURL, requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function insertSingleOfficeManagerInfo(companyCode, brandCode, userName, officeName, phone, email) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      companyCode,
      brandCode,
      userName,
      officeName,
      phone,
      email,
    }),
  };

  return fetch('/office/insertSingleOfficeManagerInfo', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function editOfficeManagerInfo(idx, officeName, userName, phone, email) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      idx, // user_sno와 다르게 매장담당자를 생성했을 때 발생하는 고유키
      officeName,
      userName,
      phone,
      email,
    }),
  };

  return fetch('/office/editOfficeManagerInfo', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function manageFranchiseOfficeStatus(
  brandName,
  brandCode,
  dispatcherUserSno,
  dispatcherUserName,
  officeName,
  userName,
  phoneNumber,
  email,
  status,
) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandName,
      brandCode,
      dispatcherUserSno,
      dispatcherUserName,
      officeName,
      userName,
      phoneNumber,
      email,
      status,
    }),
  };

  return fetch('/office/manageFranchiseOfficeStatus', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function checkSignUpLinkClick(brandCode, phoneNumber) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandCode,
      phoneNumber,
    }),
  };

  return fetch('/office/checkSignUpLinkClick', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function checkOfficeClosing(idx, userSno) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      idx: idx,
      userSno: userSno,
    }),
  };

  return fetch('/office/checkOfficeClosing', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function removeFranchiseOffice(idx) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      idx: idx,
    }),
  };

  return fetch('/office/removeFranchiseOffice', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}

function sendBulkInviteMsg(brandCode, brandName, dispatcherUserSno, dispatcherUserName) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      brandCode: brandCode,
      brandName: brandName,
      dispatcherUserSno: dispatcherUserSno,
      dispatcherUserName: dispatcherUserName,
    }),
  };

  return fetch('/office/sendBulkInviteMsg', requestOptions)
    .then(handleResponse)
    .then(result => {
      return result;
    });
}
