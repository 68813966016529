import { mComma } from "../helpers";

const chartLegendRemove = (rel = 3) => {
  var matches = document.getElementsByClassName("apexcharts-legend-series");

  for (var i = 0; i < matches.length; i++) {
    if (parseInt(matches[i].getAttribute('rel')) === rel) {
      matches[i].style.display = 'none';
    }
  }
}

export const MAX_CORRECTION = 1.1;

export const CHART_OPTIONS = {
  height: 360,
  stacked: false,
  toolbar: { show: false },
  zoom: { enabled: false },
  offsetX: -5,
  events: {
    beforeMount: () => { chartLegendRemove() },
    mounted: () => { chartLegendRemove() },
    updated: () => { chartLegendRemove() },
  }
}

export const STROKE_OPTIONS = {
  width: [0, 0, 0.4],
  curve: 'smooth',
  dashArray: [0, 0, 3],
}

export const PLOT_OPTIONS = {
  bar: { columnWidth: '50%' }
}

export const FILL_OPTIONS = {
  colors: ['#d7d9dd', '#18da94', '#d7d9dd'],
  opacity: [1, 1, 0.3],
  gradient: {
    inverseColors: false,
    shade: 'light',
    type: "vertical",
    opacityFrom: 0.85,
    opacityTo: 0.55,
    stops: [0, 100, 100, 100]
  }
}

export const FILL_STACKED_OPTIONS = {
  colors: ['#18da94', '#dee1e6', '#d7d9dd'],
  opacity: [1, 1, 0.3],
  gradient: {
    inverseColors: false,
    shade: 'light',
    type: "vertical",
    opacityFrom: 0.85,
    opacityTo: 0.55,
    stops: [0, 100, 100, 100]
  }
}

export const XAXIS_OPTIONS = {
  axisBorder: { show: false },
  axisTicks: { show: false },
  labels: { style: { fontSize: '10.5px', fontFamily: 'Spoqa-Han-Sans' } },
  tooltip: { enabled: false }
}

export const YAXIS_OPTIONS = {
  min: 0,
  forceNiceScale: true,
  rotate: 0,
  labels: {
    style: { fontSize: '12px', fontFamily: 'Spoqa-Han-Sans' }
  },
}

export const TOOLTIP_OPTIONS = {
  shared: true,
  intersect: false,
  marker: { show: false },
  // custom: function ({ series, seriesIndex, dataPointIndex, w }) {
  //   return (
  //     '<div class="custom-tooltip">' +
  //     '<p class="label">' + mComma(series[seriesIndex + 1][dataPointIndex]) + '원</p>' +
  //     '<p class="desc">' + mComma(series[seriesIndex][dataPointIndex]) + '원</p>' +
  //     '<div class="divider" style="margin:6px 0px;"></div>' +
  //     '<p class="desc fsize-0-8">평균 : ' + mComma(parseInt(series[seriesIndex + 2][dataPointIndex])) + '원</p>' +
  //     '</div>'
  //   )
  // }
}

export const GRID_OPTIONS = {
  borderColor: 'rgba(0, 0, 0, 0.035)',
}

export const LEGEND_OPTIONS = {
  position: "top",
  horizontalAlign: 'right',
  itemMargin: { vertical: 10 },
  offsetY: 20,
  labels: {
    colors: '#c8cacc',
    fontFamily: 'Spoqa-Han-Sans',
  },
}

export const COLORS_OPTIONS = ['#d7d9dd', '#18da94', '#4d5eff'];
export const COLORS_STACKED_OPTIONS = ['#18da94', '#dee1e6', '#4d5eff'];

export const DATALABELS_OPTIONS = {
  enabled: true,
  enabledOnSeries: [0, 2],
  offsetY: -25,
  style: {
    fontSize: '12px',
    fontWeight: 'bold',
    colors: ['#0eba84'],
  },
}

export const STATES_OPTIONS = {
  hover: { filter: { type: 'none' } },
  active: { filter: { type: 'none' } },
}

// pieChartColor in LocationDong
export const pieChartColor = {
  PIE_CHART_COLORS: [
    '#CDD1DB',
    '#9CA2B8',
    '#9CA2B8',
    '#626EF7',
    '#818BF9',
    '#5B9AC8',
    '#61B9B1',
    '#66D79A',
    '#94E3B8',
    '#7FD0BE',
    '#6ABDC4',
    '#65AECB',
    '#5882E2',
    '#799BE8',
    '#9184DD',
    '#C399E0',
    '#A96ED2',
    '#C967B7',
    '#F16DAC',
    '#F7A7CD',
    '#E2BCD4',
    '#BFAFC6',
    '#9CA2B8',
  ],
  getList (colorIndexList) {
    let result = [];
    colorIndexList.map(i => {
      result.push(this.PIE_CHART_COLORS[i]);
    });

    return result;
  },
  defaultPieColorSet (length) {
    return {
      1: this.getList([3]),
      2: this.getList([3, 7]),
      3: this.getList([3, 5, 7]),
      4: this.getList([3, 5, 6, 7]),
      5: this.getList([3, 5, 6, 7, 8]),
      6: this.getList([3, 4, 5, 6, 7, 8]),
      7: this.getList([3, 4, 5, 6, 7, 8, 10]),
      8: this.getList([3, 4, 5, 6, 7, 8, 10, 13]),
      9: this.getList([3, 4, 5, 6, 7, 8, 10, 13, 15]),
      10: this.getList([3, 4, 5, 6, 7, 8, 10, 13, 14, 15]),
      11: this.getList([3, 4, 5, 6, 7, 8, 10, 13, 14, 15, 18]),
      12: this.getList([3, 4, 5, 6, 7, 8, 10, 12, 13, 14, 15, 18]),
      13: this.getList([3, 4, 5, 6, 7, 8, 10, 12, 13, 14, 15, 18, 19]),
      14: this.getList([3, 4, 5, 6, 7, 8, 10, 12, 13, 14, 15, 17, 18, 19]),
      15: this.getList([3, 4, 5, 6, 7, 8, 10, 11, 12, 13, 14, 15, 17, 18, 19]),
      16: this.getList([3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 17, 18, 19]),
      17: this.getList([3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]),
      18: this.getList([3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]),
      19: this.getList([3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21]),
      20: this.getList([3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 0]),
      21: this.getList([3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 0, 1]),
      22: this.getList([3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 0, 1, 2]),
    }[length];
  }
};
