import React, { useState } from 'react';
import './componentPage.scss';
import styled from 'styled-components';
import OfifceListModal from '../../components/Modal/OfifceListModal';

const PageStyle = styled.div`
  padding: 10px;
  button {
    background: #85dda4;
    border: 1px solid #346044;
    margin: 10px;
  }
`;

const PopupPage = () => {
  const [popupStat, setPopupStat] = useState('');

  return (
    <PageStyle className="component-page">
      <h5>팝업 모음</h5>
      <h6>기본 팝업 유형(타입)</h6>
      <button>타입1 - (헤더, 내용, 버튼)</button>

      <h6>팝업 종류별</h6>
      <button onClick={() => setPopupStat('OfifceListModal')}>테스트1 </button>

      {/* 여기서부터 팝업 컴포넌트 모음 */}
      {popupStat === 'OfifceListModal' && <OfifceListModal />}
    </PageStyle>
  );
};

export default PopupPage;
